import React, { useEffect, useState } from "react";
import { Typography, Grid } from "@mui/material";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
export default function StatusBar({ isPagado = false, estatus = "" }) {
  let statusComponent;

  return (
    <>
    {
      estatus=="pago" ?
      isPagado  ? ( 
            <Grid
              container
              direction="row"
              justifyContent="flex-start"
              alignItems="center"
              style={{
                width: "100%",
                height: "50px",
                background: "#D5F5E0",
              }}
            >
              <CheckCircleOutlineIcon
                style={{ color: "#3ABE88" }}
                sx={{ width: 40, height: 40, padding: "5px" }}
              />
              <Typography style={{ marginLeft: "13px" }}>Pagado {isPagado} </Typography>
            </Grid>
          ) : (
            <Grid
              container
              direction="row"
              justifyContent="flex-start"
              alignItems="center"
              style={{
                width: "100%",
                height: "50px",
                background: "#F5D5D5",
              }}
            >
              <CancelOutlinedIcon
                style={{ color: "#D14343" }}
                sx={{ width: 40, height: 40, padding: "5px" }}
              />
              <Typography style={{ marginLeft: "13px" }}>No Pagado</Typography>
            </Grid>
          )  
         
    :   <>
    
    <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
        >
          {estatus == "0" ? (
            <Grid
              container
              direction="row"
              justifyContent="flex-start"
              alignItems="center"
              style={{
                width: "100%",
                height: "50px",
                background: "#FECFBD",
              }}
            >
              <WarningAmberIcon
                style={{ color: "#FE8D27" }}
                sx={{ width: 40, height: 40, padding: "5px" }}
              />

              <Typography style={{ marginLeft: "13px" }}>
                Reservacion no atendida
              </Typography>
            </Grid>
          ) : estatus == "1" ? (
            <Grid
              container
              direction="row"
              justifyContent="flex-start"
              alignItems="center"
              style={{
                width: "100%",
                height: "50px",
                background: "#D5F5E0",
              }}
            >
              <CheckCircleOutlineIcon
                style={{ color: "#3ABE88" }}
                sx={{ width: 40, height: 40, padding: "5px" }}
              />
              <Typography style={{ marginLeft: "13px" }}>
                Reservacion atendida
              </Typography>
            </Grid>
          ) : estatus == "2" ? (
            <Grid
              container
              direction="row"
              justifyContent="flex-start"
              alignItems="center"
              style={{
                width: "100%",
                height: "50px",
                background: "#F5D5D5",
              }}
            >
              <CancelOutlinedIcon
                style={{ color: "#d04646" }}
                sx={{ width: 40, height: 40, padding: "5px" }}
              />
              <Typography style={{ marginLeft: "13px" }}>
                Reservacion cancelada
              </Typography>
            </Grid>
          ) : (
            <></>
          )}
        </Grid>
    
    </>


    }
  
    </>
  );
}
