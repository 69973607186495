import { stringify } from "query-string";
import { getData, postdData, postUrl } from "../../../Context/backend";
import validator from "validator";
import { IMG } from "../../../Lib/Funciones Generales/Constantes";

///////////////////
/* FUNCIONES GET */
///////////////////
export const getLista = async (iTake, iSkip, Order, query) => {
  //onsole.log(query)
  let data = [];
  let queryParamsObj = {
    iTake: iTake,
    iSkip: iSkip,
    Order: Order,
    query: query,
  };
  const url = `colaboradores/Listar?${stringify(queryParamsObj)}`;
  const res = await getData(url);
  return new Promise((resolve, reject) => {
    if (!res.error) {
      data = res.data.respuesta;
      resolve(data);
    } else {
      reject(res);
    }
  });
};

export const getInfoEmpleado = async (id, imgDefault) => {
  let data = [];
  let queryParamsObj = {
    id: id,
  };

  const url = `colaboradores/Consultar?${stringify(queryParamsObj)}`;
  const res = await getData(url);
  return new Promise((resolve, reject) => {
    if (!res.error) {
      data = JSON.parse(res.data.respuesta.data);
      //console.log(data);
      if (data.length == 0) {
        resolve({
          err: [],
          mensaje: "No se encontró información de este empleado",
        });
      } else {
        data = JSON.parse(res.data.respuesta.data)[0];
        let horarios = JSON.parse(res.data.respuesta.horarios);
        let Values = {
          data: {
            id: String(data.ID),
            activo: String(data.ACTIVO),
            nombre: String(data.NOMBRE),
            apellido_paterno: String(data.APELLIDO_PATERNO),
            apellido_materno: String(data.APELLIDO_MATERNO),
            correo: String(data.CORREO),
            telefono: String(data.TELEFONO),
            id_puesto: String(data.ID_PUESTO),
            id_grupo: String(data.ID_GRUPO),
            img: String(data.IMG) == "" ? imgDefault : String(data.IMG),
          },
          horarios,
        };
        resolve(Values);
      }
    } else {
      reject({
        mensaje: "Error al conectar a internet, revisa tu conexion a internet",
      });
    }
  });
};

export const getOpciones = async (ID) => {
  let data = [];
  let queryParamsObj = {
    ID_SUCURSAL: ID,
  };
  const url = `colaboradores/getOpciones?${stringify(queryParamsObj)}`;
  const res = await getData(url);
  return new Promise((resolve, reject) => {
    if (!res.error) {
      data = res.data.respuesta;
      // console.log(data);
      resolve(data);
    } else {
      reject(res);
    }
  });
};


export const getProductosByColaborador = async (ID) => {
  let data = [];
  let queryParamsObj = {
    ID_COLABORADOR: ID,
  };
  const url = `productos/getListProductosByColaborador?${stringify(queryParamsObj)}`;
  const res = await getData(url);
  return new Promise((resolve, reject) => {
    if (!res.error) {
      data = res.data.respuesta; 
      resolve(data);
    } else {
      reject(res);
    }
  });
};


export const getOpcionesv2 = async (ID) => {
  let data = [];
  let queryParamsObj = {
    ID_SUCURSAL: ID,
  };
  const url = `colaboradores/getColaboradoresBySucursal?${stringify(queryParamsObj)}`;
  const res = await getData(url);
  return new Promise((resolve, reject) => {
    if (!res.error) {
      data = res.data.respuesta;
      // console.log(data);
      resolve(data);
    } else {
      reject(res);
    }
  });
};

export const getSucursalesandColabora = async (ID) => {
  let data = [];
  let queryParamsObj = {
    ID: ID,
  };
  const url = `colaboradores/getSucursalesandColabora?${stringify(
    queryParamsObj
  )}`;
  const res = await getData(url);
  return new Promise((resolve, reject) => {
    if (!res.error) {
      data = res.data.respuesta;
      resolve(data);
    } else {
      reject(res);
    }
  });
};

////////////////////
/* FUNCIONES POST */
////////////////////
export const guardar = async (
  Values,
  Errores,
  setErrores,
  Horarios,
  SetHorarios,
  servicios
) => {
  let error = false;

  if (!Values.nombre || Values.nombre == "") {
    let Errores2 = Errores;
    Errores2.nombre = true;
    setErrores(Errores2);
    error = true;
  }
  /*if (!Values.apellido_paterno || Values.apellido_paterno == "") {
    Errores.apellido_paterno = true;
    setErrores(Errores);
    error = true;
  }
  if (!Values.apellido_materno || Values.apellido_materno == "") {
    Errores.apellido_materno = true;
    setErrores(Errores);
    error = true;
  }*/

  /*if (
    !Values.id_sucursal ||
    Values.id_sucursal == "" ||
    Values.id_sucursal == "0" ||
    Values.id_sucursal == null ||
    Values.id_sucursal == "null"
  ) {
    Errores.id_sucursal = true;
    setErrores(Errores);
    error = true;
  }*/
  if (!Values.correo || Values.correo == "") {
    Errores.correo = true;
    setErrores(Errores);
    error = true;
  } else {
    if (!validator.isEmail(Values.correo)) {
      Errores.correo = true;
      setErrores(Errores);
      error = true;
    }
  }

  if (!Values.telefono || Values.telefono == "") {
    Errores.telefono = true;
    setErrores(Errores);
    error = true;
  } else {
    if (!validator.isNumeric(Values.telefono)) {
      Errores.telefono = true;
      setErrores(Errores);
      error = true;
    } else if (Values.telefono.length != 10) {
      Errores.telefono = true;
      setErrores(Errores);
      error = true;
    }
  }

  if (
    !Values.id_puesto ||
    Values.id_puesto == "" ||
    Values.id_puesto == "0" ||
    Values.id_puesto == null ||
    Values.id_puesto == "null"
  ) {
    Errores.id_puesto = true;
    setErrores(Errores);
    error = true;
  }

  if (
    !Values.id_grupo ||
    Values.id_grupo == "" ||
    Values.id_grupo == "0" ||
    Values.id_grupo == null ||
    Values.id_grupo == "null"
  ) {
    Errores.id_grupo = true;
    setErrores(Errores);
    error = true;
  }

  /*Horarios.forEach((element) => {
    if (element.ERROR_FIN || element.ERROR_INICIO) {
      if (element.ACTIVO) {
        error = true;
      }
    }
  });*/
  //console.log(Errores);
  //console.log(error);

  if (!error) {
    let horarios = [];
    let imagen = "";

    if (Values.img.includes("base64")) {
      imagen = Values.img.split("base64,")[1];
    } else {
      imagen = "";
    }

    if (
      Values.id == "0" ||
      Values.id == 0 ||
      (Values.id == "" && Values.img != "")
    ) {
      Values.img_nueva = {
        id: "",
        tipo: IMG.TIPO_COLABORADORES,
        img64: imagen,
      };
      //Values.img = "";
    }

    /*try{

      Horarios.forEach((element, index) => {
        const newObject = {};
        newObject.id = element.ID;
        newObject.id_colaborador = Values.id;
        newObject.dia = index;
        newObject.inicio = element.INICIO;
        newObject.fin = element.FIN;
        newObject.activo = element.ACTIVO;
        horarios.push(newObject);
      });

    }catch(error){

    }*/


    const relacion = servicios
  .filter(objeto => objeto.ACTIVO === 1)
  .map(objeto => objeto.ID);

    let data = [];
    const body = {
      id: Values.id,
      nombre: Values.nombre,
      apellido_paterno: Values.apellido_paterno,
      apellido_materno: Values.apellido_materno,
      correo: Values.correo,
      telefono: Values.telefono,
      img_nueva: Values.img_nueva,
      img: imagen,
      id_puesto: Values.id_puesto,
      id_grupo: Values.id_grupo,
      horarios: horarios,
      relacion:  relacion
    };

  
    const url =
      Values.id == undefined || Values.id == "" || Values.id == 0
        ? "colaboradores/Insertar"
        : "colaboradores/Editar";
    const res = await postdData(url, body);
    return new Promise((resolve, reject) => {
      if (!res.error) {
        data = res.data;
        resolve(data);
      } else {
        reject(res);
      }
    });
  } else {
    return new Promise((resolve, reject) => {
      resolve({ mensaje: "Completa todos los campos requeridos" });
    });
  }
};

export const enviarAcceso = async (id) => {
  if (id != undefined) {
    let data = [];
    let queryParamsObj = {
      id: id,
    };
    const url = `colaboradores/EnviarAcceso?${stringify(queryParamsObj)}`;
    const res = await postUrl(url);
    return new Promise((resolve, reject) => {
      if (!res.error) {
        data = res.data;
        resolve(data);
      } else {
        reject(res);
      }
    });
  } else {
    return new Promise((resolve, reject) => {
      resolve({ mensaje: "No se pudo obtener el identificador" });
    });
  }
};
export const Eliminar = async (id) => {
  if (id != undefined) {
    let data = [];
    let queryParamsObj = {
      id: id,
    };
    const url = `colaboradores/Eliminar?${stringify(queryParamsObj)}`;
    const res = await postUrl(url);
    return new Promise((resolve, reject) => {
      if (!res.error) {
        data = res.data;
        resolve(data);
      } else {
        reject(res);
      }
    });
  } else {
    return new Promise((resolve, reject) => {
      resolve({ mensaje: "No se pudo obtener el identificador" });
    });
  }
};

export const Desactivar = async (id, checked) => {
  if (id != undefined) {
    let data = [];
    let queryParamsObj = {
      id: id,
      activo: checked,
    };
    const url = `colaboradores/Desactivar?${stringify(queryParamsObj)}`;
    const res = await postUrl(url);
    return new Promise((resolve, reject) => {
      if (!res.error) {
        data = res.data;
        resolve(data);
      } else {
        reject(res);
      }
    });
  } else {
    return new Promise((resolve, reject) => {
      resolve({ mensaje: "No se pudo obtener el identificador" });
    });
  }
};

export const updateSuc = async (idColab, idSuc) => {
  if (idColab != undefined) {
    let data = [];
    let queryParamsObj = {
      idColab: idColab,
      idSuc: idSuc,
    };
    const url = `colaboradores/EditarSuc?${stringify(queryParamsObj)}`;
    const res = await postUrl(url);
    return new Promise((resolve, reject) => {
      if (!res.error) {
        data = res.data;
        resolve(data);
      } else {
        reject(res);
      }
    });
  } else {
    return new Promise((resolve, reject) => {
      resolve({ mensaje: "No se pudo obtener el identificador" });
    });
  }
};

export const getHorariosColabora = async (id_colaborador, fecha, suc) => {
  let data = [];
  let queryParamsObj = {
    ID_COLABORADOR: id_colaborador,
    fecha: fecha,
    ID_SUCURSAL: suc,
  };
  const url = `sucursales/gethorariosByColaborador?${stringify(queryParamsObj)}`;
  const res = await getData(url);
  return new Promise((resolve, reject) => {
    if (!res.error) {
      data = res.data.respuesta;
      // console.log(data);
      resolve(data);
    } else {
      reject(res);
    }
  });
};



export const getHorariosSucursal = async (sucursal, fecha) => {
  let data = [];
  let queryParamsObj = {
    ID_SUCURSAL: sucursal,
    fecha: fecha,
  };
  const url = `sucursales/getHorariosandcolabora?${stringify(queryParamsObj)}`;
  const res = await getData(url);
  return new Promise((resolve, reject) => {
    if (!res.error) {
      data = res.data.respuesta;
      // console.log(data);
      resolve(data);
    } else {
      reject(res);
    }
  });
};
////  COMISIONES
