import { getData, postdData } from "./../../../Context/backend";
import validator from "validator";
import { setLocalStorageJWT } from "../../../Context/Storage";
import RFCValidator from "../../../Lib/Funciones Generales/ValidarRFC";

///////////////////
/* FUNCIONES GET */
///////////////////

export const getInfoPerfil = async (imgDefault, setTok = false) => {
  let data = [];
  const url = `perfil/Consultar?nwt=${setTok}`;
  const res = await getData(url);
  return new Promise((resolve, reject) => {
    if (!res.error) {
      data = JSON.parse(res.data.respuesta.data)[0];
      //console.log(data);
      let Values = {
        id: String(data.ID),
        nombre: String(data.NOMBRE),
        pass_default: String(data.DEFAULT_PASS) == "0" ? false : true,
        razon_social: String(data.RAZON_SOCIAL),
        regimen_fiscal: String(data.REGIMEN_FISCAL),
        rfc: String(data.RFC),
        direccion: String(data.DIRECCION),
        cp: String(data.CP),
        correo: String(data.CORREO),
        telefono: String(data.TELEFONO),
        facebook: String(data.FACEBOOK),
        youtube: String(data.YOUTUBE),
        instagram: String(data.INSTAGRAM),
        img:
          String(data.IMG) == ""
            ? imgDefault
            : String(data.IMG) + "?" + Math.floor(Math.random() * 1000000) + 1,
        type: res.data.respuesta.typeUser,
        apellido_paterno: String(data.APELLIDO_PATERNO),
        apellido_materno: String(data.APELLIDO_MATERNO),
      };
      let permisos = JSON.parse(res.data.respuesta.permisos);

      if (setTok) {
        if (res.data.respuesta.setToken.confirm) {
          setLocalStorageJWT(res.data.respuesta.setToken.token);
        }
      }
      resolve({ Values: Values, permisos: permisos });
    } else {
      reject(res);
    }
  });
};

////////////////////
/* FUNCIONES POST */
////////////////////
export const guardar = async (Values, Errores, setErrores, perfilType) => {
  let error = false;

  if (!Values.nombre || Values.nombre == "") {
    Errores.nombre = true;
    setErrores(Errores);
    error = true;
  }
  if (!Values.correo || Values.correo == "") {
    Errores.correo = true;
    setErrores(Errores);
    error = true;
  } else {
    if (!validator.isEmail(Values.correo)) {
      Errores.correo = true;
      setErrores(Errores);
      error = true;
    }
  }
  if (!Values.telefono || Values.telefono == "") {
    Errores.telefono = true;
    setErrores(Errores);
    error = true;
  } else {
    if (!validator.isNumeric(Values.telefono)) {
      Errores.telefono = true;
      setErrores(Errores);
      error = true;
    } else if (Values.telefono.length != 10) {
      Errores.telefono = true;
      setErrores(Errores);
      error = true;
    }
  }

  if (perfilType) {
    //razon Social
    if (!Values.razon_social || Values.razon_social == "") {
      Errores.razon_social = true;
      setErrores(Errores);
      error = true;
    }
    //regimen fizcal
    if (
      !Values.regimen_fiscal ||
      Values.regimen_fiscal == "" ||
      Values.regimen_fiscal == "0"
    ) {
      Errores.regimen_fiscal = true;
      setErrores(Errores);
      error = true;
    }
    //rfc
    if (!Values.rfc || Values.rfc == "") {
      Errores.rfc = true;
      setErrores(Errores);
      error = true;
    } else {
      if (!RFCValidator(Values.rfc)) {
        Errores.rfc = true;
        setErrores(Errores);
        error = true;
      }
    }
    //direccion
    if (!Values.direccion || Values.direccion == "") {
      Errores.cp = true;
      setErrores(Errores);
      error = true;
    }
    //cp
    if (!Values.cp || Values.cp == "" || Values.cp.length > 5) {
      Errores.cp = true;
      setErrores(Errores);
      error = true;
    }
  } else {
    if (!Values.apellido_paterno || Values.apellido_paterno == "") {
      Errores.apellido_paterno = true;
      setErrores(Errores);
      error = true;
    }
    if (!Values.apellido_materno || Values.apellido_materno == "") {
      Errores.apellido_materno = true;
      setErrores(Errores);
      error = true;
    }
  }

  //pass
  /*if (!Values.contrasena_actual || Values.contrasena_actual == "") {
    Errores.contrasena_actual = true;
    setErrores(Errores);
    error = true;
  } else {
    if (!Values.nueva_contrasena || Values.nueva_contrasena == "") {
      Errores.nueva_contrasena = true;
      setErrores(Errores);
      error = true;
    } else {
      if (Values.repetir_contrasena != Values.nueva_contrasena) {
        Errores.repetir_contrasena = true;
        setErrores(Errores);
        error = true;
      }
    }
  }*/

  if (!error) {
    let imgData = Values.img;
    if (imgData.includes("base64")) {
      imgData = imgData.split("base64,")[1];
    }
    let data = [];
    const body = { ...Values, img: imgData };

    console.log(body);
    const url = "perfil/Editar";
    const res = await postdData(url, body);
    return new Promise((resolve, reject) => {
      if (!res.error) {
        data = res.data;

        resolve(data);
      } else {
        reject(res);
      }
    });
  } else {
    return new Promise((resolve, reject) => {
      resolve({ mensaje: "Completa todos los campos requeridos" });
    });
  }
};

export const actualizarContrasena = async (Values, Errores, setErrores) => {
  let error = false;

  if (!Values.contrasena_actual || Values.contrasena_actual == "") {
    Errores.contrasena_actual = true;
    setErrores(Errores);
    error = true;
  } else {
    if (!Values.nueva_contrasena || Values.nueva_contrasena == "") {
      Errores.nueva_contrasena = true;
      setErrores(Errores);
      error = true;
    } else {
      if (Values.repetir_contrasena != Values.nueva_contrasena) {
        Errores.repetir_contrasena = true;
        setErrores(Errores);
        error = true;
      }
    }
  }

  if (!error) {
    let data = [];
    const body = Values;

    const url = "perfil/CambiarPass";
    const res = await postdData(url, body);
    return new Promise((resolve, reject) => {
      if (!res.error) {
        data = res.data;
        //console.log(data);
        setLocalStorageJWT(data.respuesta.nwToken);
        resolve(data);
      } else {
        reject(res);
      }
    });
  } else {
    return new Promise((resolve, reject) => {
      resolve({ mensaje: "Completa todos los campos requeridos" });
    });
  }
};
