import {
    Alert,
    Backdrop,
    CircularProgress,
    Snackbar,
    Typography,
  } from "@mui/material";
  
  import React, { useState, useEffect } from "react";
  import Card from "@mui/material/Card";
  import CardContent from "@mui/material/CardContent";
  import TextField from "@mui/material/TextField";
  import Button from "@mui/material/Button";
  import { Link, useNavigate } from "react-router-dom";
  import withReactContent from "sweetalert2-react-content";
  import BACKEND_URL from "../../Context/backend";
  import loogo from "./../../assets/LogoTextoNegro.svg";
  import Swal from "sweetalert2";
  import { useRef } from "react";

  const MySwal = withReactContent(Swal);
  
  
  
  export default function BuscarCorreo() {
    const [Values, setValues] = useState({
      Correo: "",
    });
    const [openLoading, setOpenLoading] = useState(false);
    const navigate = useNavigate();
    const [CorreoError, setCorreoError] = useState(false);
    const [mensaje, setMensaje] = useState("");
    const [open, setOpen] = useState(false);
  
    const refIniciar = useRef(null)
  
    const handlInputChange = ({ target }) => {
      setValues({
        ...Values,
        [target.name]: target.value,
      });
  
      if (!Values.Correo || Values.Correo == "") {
        setCorreoError(true);
      } else {
        setCorreoError(false);
      }
    };
    const handleKeyDown = (e) => {
      if (e.key === "Enter") {
        clicLogin();
      }
    };
    useEffect(() => {
      console.log(navigate.length);
    })
  
    const handleClose = () => {
      setOpen(false);
    };
    const clicLogin = () => {
      let error = false;
      if (!Values.Correo || Values.Correo == "") {
        setCorreoError(true);
        error = true;
      }
      if (!error) {
        setOpenLoading(true);
        const info = {
          Correo: Values.Correo,
        };
  
        const requestOptions = {
          method: "POST",
          headers: { "Content-Type": "text/html" },
          body: "",
        };
        fetch(BACKEND_URL + "acceso/recuperarcontrasena?correo=" + Values.Correo, requestOptions)
          .then((response) => response.json())
          .then((response) => {
            setOpenLoading(false);
            if (response.resp.codigo == "200") {
              MySwal.fire({
                title: "Correcto",
                html: "<h4>Se ha enviado información a tu correo para reestablecer tu contraseña.</h4>",
                icon: "success",
                confirmButtonColor: "#FD8C27",
                showConfirmButton: true,
              }).then((result) => {
                console.log("I was closed by the timer");
                refIniciar.current.click();
                window.location.href = "/";
              });
            } else {
              setMensaje("El correo no esta en nuestro sistema, verifica tu correo");
              setOpen(true);
  
            }
          })
          .catch((error) => {
            setOpenLoading(false);
            setMensaje(
              "Ha ocurrido un error al conectar con nuestros servidores, intenta mas tarde."
  
            );
            setOpen(true);
            return;
          });
      }
    };
  
    React.useEffect(() => {
  
      document.title = `Recuperar contraseña`;
    }, []);
  
    return (
      <>
        <Snackbar
          direction="left"
          open={open}
          autoHideDuration={6000}
          onClose={handleClose}
        >
          <Alert onClose={handleClose} severity="error" sx={{ width: "100%" }}>
            {mensaje}
          </Alert>
        </Snackbar>
  
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={openLoading}
        >
          <CircularProgress color="primary" />
        </Backdrop>
        <div className="mt-5"></div>
        <Card className="shadow-lg p-4 m-auto mt-5" sx={{ maxWidth: 350 }}>
          <center>
            <img
              src={loogo}
              className="my-4"
              width={150}
              style={{ margin: "auto" }}
            />
          </center>
          <CardContent>
            <TextField
              error={CorreoError}
              fullWidth
              id="Correo"
              name="Correo"
              label="Ingresa tu correo "
              variant="outlined"
              value={Values.Correo}
              onChange={handlInputChange}
            />
            <Button
              fullWidth
              variant="contained"
              size="large"
              className="btn-Kalendar mt-3"
              onClick={clicLogin}
            >
              <Typography
                style={{
                  fontFamily: "Avenir",
                  textTransform: "none",
                }}
              >
                Reestablecer contraseña
              </Typography>
            </Button>
                      
            <Button fullWidth color="primary" className="mt-4" ref={refIniciar} >
              <Link style={{
                fontFamily: "Avenir",
                textTransform: "none",
                color: "#FD8C27",
                fontWeight: 650,
                textDecoration: "none",
  
              }} to="/">Regresar</Link>
            </Button>
          </CardContent>
        </Card>
      </>
    );
  }
  