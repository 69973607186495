import { Autocomplete, TextField } from "@mui/material";
import { useEffect, useState } from "react";
import { getCliente } from "../Funciones/SelectClientes";

const Clientes = ({
  Value,
  Error,
  handlInputChange,
  Name = "id_cliente",
  size = "",
  isEditar = false,
}) => {
  const opcionInicial = { ID: 0, DESCRIPCION: "Cliente" };
  const [ValorFiltrado, setFiltro] = useState();
  const [Cliente, setCliente] = useState({
    cargado: false,
    lista: [opcionInicial],
  });

  useEffect(() => {
    getCliente()
      .then((resp) => {
        //console.log("clientes", resp);
        let list = JSON.parse(resp.data);
        if (list.length != 0) {
          setCliente({ cargado: true, lista: list });
        } else {
          setCliente({
            cargado: true,
            lista: [{ ID: 0, DESCRIPCION: "Sin clientes disponibles" }],
          });
        }
      })
      .catch((resp) => {
        setCliente({
          cargado: true,
          lista: [{ ID: 0, DESCRIPCION: "Sin clientes disponibles" }],
        });
      });
  }, [Cliente]);

  const getOpcion = () => {
    // console.log("CLIENTE: ", Value)
    const opcion = Cliente.lista.find((x) => x.ID == Value);
    if (opcion) {
      return opcion;
    } else {
      return null;
    }
  };

  return Cliente.cargado ? (
    <Autocomplete
      fullWidth
      size={size}
      disabled={isEditar}
      defaultValue={getOpcion}
      isOptionEqualToValue={(option, value) => {
        return option.ID == value.ID;
      }}
      inputValue={ValorFiltrado}
      onChange={(e, value) => {
        if (value) {
          handlInputChange({ target: { name: Name, value: value.ID } });
        } else {
          handlInputChange({ target: { name: Name, value: "" } });
        }
      }}
      onInputChange={(event, newInputValue) => {
        setFiltro(newInputValue);
      }}
      options={Cliente.lista}
      getOptionLabel={(option) => {
        return option.DESCRIPCION;
      }}
      renderInput={(params) => {
        return (
          <TextField
            {...params}
            error={Error}
            helperText={Error ? "Selecciona un cliente" : ""}
            label="Cliente"
          />
        );
      }}
    />
  ) : (
    <TextField fullWidth disabled={true} value={"Cliente"} />
  );
};

export default Clientes;
