import React, { useEffect, useState } from "react";
import logo from "../assets/LogoTextoNegro.svg";
 

const StartLoad = ({ isLoged }) => {
  const [logueado, setLogueado] = useState(true);
  return (
    <div className="d-flex justify-content-center  ling-items-center" style={{height:"100vh",   backgroundColor: "white"}}>
      {<img src={logo} alt="asd" className="img-fluid" style={{maxWidth:"150px"}} />} 
    </div>
  );
};

export default StartLoad;
