const ReportesLateral = ({ color }) => {
    return (
        <svg width="19px" height="24px" viewBox="0 0 19 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
            <title>1.8 ReportesLateralON</title>
            <g id="Michelle-Pourroy-MAnager" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd" strokeLinecap="round" strokeLinejoin="round">
                <g id="1.1-Dashboard" transform="translate(-37.000000, -500.000000)" stroke={color} strokeWidth="1.5">
                    <g id="1-copy-6" transform="translate(0.000000, 482.000000)">
                        <g id="1.8ReportesLateralON" transform="translate(38.000000, 19.000000)">
                            <path d="M16.9454148,8.02450601 L16.9454148,19.9437882 C16.9454148,21.0789579 16.0243727,22 14.889203,22 L2.05474521,22 C0.919575478,22 0,21.0789579 0,19.9437882 L0,2.05533186 C0,0.920162129 0.919575478,0.000586651023 2.05474521,0.000586651023 L8.92149543,0.000586651023 L16.9454148,8.02450601 Z" id="Stroke-1"></path>
                            <polyline id="Stroke-3" points="8.92090878 0 8.92090878 8.02391936 16.9448281 8.02391936"></polyline>
                        </g>
                    </g>
                </g>
            </g>
        </svg>
    )
}

export default ReportesLateral
