import React from "react";
import { Col, Row } from "react-bootstrap";
import {
  Dialog,
  Tooltip,
  IconButton,
  DialogContent,
  TextField,
  Stack,
  Button,
  DialogActions,
  Autocomplete,
} from "@mui/material";
import { BasicDatePicker } from "../../../Includes/DatePicker";
import MultiSelect from "../../../Includes/MultiSelect";
import AccEliminar from "../../../assets/AccEliminar.svg";
import SelectSucursal from "../../../Configuraciones/Sucursales/Componentes/SelectSucursal";
import { LoadingButton } from "@mui/lab";
import CloseIcon from "@mui/icons-material/Close";

export default function ModalBloqueoHorario({
  valuesFechaBloqueo,
  EliminarHorarioBloqueado = () => {},
  setShowModalBloquearHorario = () => {},
  setValuesFechaBloqueo = () => {},
  initFechaBloqueo,
  Horas,
  IsGuardando,
  GuardarHorarioBloqueado = () => {},
  handlInputChange = () => {},
  opcColaboradores,
  Minutos,
  errorFechaBloqueo,
  setBanderaSelectColaboradores = () => {},
  selectColaboradores,
  setSelectColaboradores = () => {},
}) {
  return (
    <>
      <Dialog open={true} fullWidth maxWidth={"sm"}>
        {valuesFechaBloqueo.id !== 0 && (
          <Tooltip
            className="me-2"
            title="Eliminar dia bloqueado"
            placement="top"
            style={{ position: "absolute", right: "50px", top: "18px" }}
          >
            <IconButton
              onClick={() => EliminarHorarioBloqueado(valuesFechaBloqueo.id)}
            >
              <img src={AccEliminar} />
            </IconButton>
          </Tooltip>
        )}

        <IconButton
          aria-label="delete"
          className="m-3"
          style={{ position: "absolute", right: "0%" }}
          onClick={() => {
            setShowModalBloquearHorario(false);
            setValuesFechaBloqueo({ ...initFechaBloqueo });
          }}
        >
          <CloseIcon size="lg" />
        </IconButton>

        <DialogContent>
          <h6 className="font-AvenirBold"> Bloqueo de horas</h6>
          <Row className="mt-4">
            <Col sm={6} md={6} className="p-3">
              <BasicDatePicker
                size="small"
                format="DD/MM/YYYY"
                label="Fecha de inicio"
                fecha={valuesFechaBloqueo.fecha_inicio}
                setFecha={(fecha) => {
                  setValuesFechaBloqueo({
                    ...valuesFechaBloqueo,
                    fecha_inicio: fecha,
                    fecha_fin: fecha,
                  });
                }}
              />
            </Col>
            <Col sm={6} md={3} className="p-3">
              <Autocomplete
                size="small"
                variant="outlined"
                options={Horas}
                value={valuesFechaBloqueo.hora_inicio.substring(0, 2)}
                onInputChange={(event, newInputValue) => {
                  setValuesFechaBloqueo({
                    ...valuesFechaBloqueo,
                    hora_inicio:
                      newInputValue +
                      ":" +
                      valuesFechaBloqueo.hora_inicio.substring(3, 5),
                  });
                }}
                renderInput={(params) => {
                  return (
                    <TextField
                      {...params}
                      label="Horas"
                      helperText={
                        errorFechaBloqueo.horas ? "Horas no validas" : ""
                      }
                      error={errorFechaBloqueo.horas}
                      handlInputChange={handlInputChange}
                    />
                  );
                }}
              />
            </Col>
            <Col sm={6} md={3} className="p-3">
              <Autocomplete
                size="small"
                variant="outlined"
                options={Minutos}
                value={valuesFechaBloqueo.hora_inicio.substring(3, 5)}
                onInputChange={(event, newInputValue) => {
                  setValuesFechaBloqueo({
                    ...valuesFechaBloqueo,
                    hora_inicio:
                      valuesFechaBloqueo.hora_inicio.substring(0, 2) +
                      ":" +
                      newInputValue,
                  });
                }}
                renderInput={(params) => {
                  return (
                    <TextField
                      {...params}
                      label="Minutos"
                      helperText={
                        errorFechaBloqueo.minutos ? "Minutos no validos" : ""
                      }
                      error={errorFechaBloqueo.minutos}
                      handlInputChange={handlInputChange}
                    />
                  );
                }}
              />
            </Col>
          </Row>

          <Row>
            <Col sm={6} md={6} className="p-3">
              <BasicDatePicker
                size="small"
                format="DD/MM/YYYY"
                label="Fecha de fin"
                fecha={valuesFechaBloqueo.fecha_fin}
                setFecha={(fecha) => {
                  setValuesFechaBloqueo({
                    ...valuesFechaBloqueo,
                    fecha_inicio: fecha,
                    fecha_fin: fecha,
                  });
                }}
              />
            </Col>

            <Col sm={6} md={3} className="p-3">
              <Autocomplete
                size="small"
                variant="outlined"
                options={Horas}
                value={valuesFechaBloqueo.hora_fin.substring(0, 2)}
                onInputChange={(event, newInputValue) => {
                  setValuesFechaBloqueo({
                    ...valuesFechaBloqueo,
                    hora_fin:
                      newInputValue +
                      ":" +
                      valuesFechaBloqueo.hora_fin.substring(3, 5),
                  });
                }}
                renderInput={(params) => {
                  return (
                    <TextField
                      {...params}
                      label="Horas"
                      helperText={
                        errorFechaBloqueo.horas ? "Horas no validas" : ""
                      }
                      error={errorFechaBloqueo.horas}
                      handlInputChange={handlInputChange}
                    />
                  );
                }}
              />
            </Col>
            <Col sm={6} md={3} className="p-3">
              <Autocomplete
                size="small"
                variant="outlined"
                options={Minutos}
                value={valuesFechaBloqueo.hora_fin.substring(3, 5)}
                onInputChange={(event, newInputValue) => {
                  setValuesFechaBloqueo({
                    ...valuesFechaBloqueo,
                    hora_fin:
                      valuesFechaBloqueo.hora_fin.substring(0, 2) +
                      ":" +
                      newInputValue,
                  });
                }}
                renderInput={(params) => {
                  return (
                    <TextField
                      {...params}
                      label="Minutos"
                      helperText={
                        errorFechaBloqueo.minutos ? "Minutos no validos" : ""
                      }
                      error={errorFechaBloqueo.minutos}
                      handlInputChange={handlInputChange}
                    />
                  );
                }}
              />
            </Col>
          </Row>

          <Row>
            <Col sm={12} className="py-3">
              <SelectSucursal
                Value={valuesFechaBloqueo.id_sucursal}
                Error={errorFechaBloqueo.id_sucursal}
                handlInputChange={handlInputChange}
              />
            </Col>

            <Col sm={12} className="p-3">
              <MultiSelect
                titulo={"Colaboradores"}
                opciones={opcColaboradores}
                seleccionados={selectColaboradores}
                setSeleccionados={setSelectColaboradores}
                setBanderaSeleccion={setBanderaSelectColaboradores}
              />
            </Col>
          </Row>

          <Row>
            <Col sm={12} className="p-3">
              <TextField
                name="motivo_etiqueta"
                value={valuesFechaBloqueo.motivo_etiqueta}
                error={errorFechaBloqueo.motivo_etiqueta}
                onChange={handlInputChange}
                fullWidth
                label="Motivo / Etiqueta"
                multiline
                rows={3}
              />
            </Col>
          </Row>

          <DialogActions>
            <Stack
              className="p-3"
              spacing={2}
              direction={{ xs: "column", sm: "row" }}
              style={{}}
            >
              <Button
                onClick={() => {
                  setShowModalBloquearHorario(false);
                  setValuesFechaBloqueo({ ...initFechaBloqueo });
                }}
                className="btn btn-cancelar font-AvenirMedium py-2 px-4"
                variant="outlined"
              >
                Cancelar
              </Button>

              <LoadingButton
                loading={IsGuardando}
                loadingPosition="start"
                onClick={() => GuardarHorarioBloqueado()}
                className="btn btn-Kalendar font-AvenirMedium  py-2 px-4 ms-2 "
                variant="contained"
                xs={{ with: "100%" }}
              >
                <span className={IsGuardando ? "px-4" : "px-2"}>
                  {IsGuardando ? "Guardando bloqueo..." : "Guardar bloqueo"}
                </span>
              </LoadingButton>
            </Stack>
          </DialogActions>
        </DialogContent>
      </Dialog>
    </>
  );
}
