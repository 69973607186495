import { refType } from "@mui/utils";

export function getTiempoFromMinutosToHoras(minutos) {
  if (parseInt(minutos) < 60) return minutos + " Minutos";
  if (parseInt(minutos) >= 60) {
    let min = minutos % 60;
    let horas = (minutos - min) / 60;
    return (
      Math.round(horas) +
      " Hrs " +
      (min > 0 ? " " + Math.round(min) + " Min" : "")
    );
  }
}

export function getTiempoFromHorasToMinutos(horas) {
  try {
    let parts = horas.split(":");
    return parseInt(parts[0] * 60 + parseInt(parts[1]));
  } catch {
    return 0;
  }
}
export function formatMoneda(number) {
  try {
    const formattedNumber = new Intl.NumberFormat("es-MX", {
      style: "currency",
      currency: "MXN",
    }).format(number);
    return formattedNumber;
  } catch {
    return "$0";
  }
}
export function getTiempoFromMinutos(minutes) {
  const MINUTE = 1;
  const HOUR = 60 * MINUTE;
  const DAY = 24 * HOUR;
  const WEEK = 7 * DAY;
  const MONTH = 30 * DAY;
  const YEAR = 365 * DAY;

  if (minutes < HOUR) {
    return `${minutes} Min${minutes !== 1 ? "" : ""}`;
  } else if (minutes < DAY) {
    const hours = Math.floor(minutes / HOUR);
    const remainderMinutes = minutes % HOUR;
    const hoursText = `${hours} Hr${hours !== 1 ? "s" : ""}`;
    if (remainderMinutes === 0) {
      return hoursText;
    } else {
      const minutesText = `${remainderMinutes} Min${
        remainderMinutes !== 1 ? "" : ""
      }`;
      return `${hoursText} y ${minutesText}`;
    }
  } else if (minutes < WEEK) {
    const days = Math.floor(minutes / DAY);
    const remainderMinutes = minutes % DAY;
    const daysText = `${days} día${days !== 1 ? "s" : ""}`;
    if (remainderMinutes === 0) {
      return daysText;
    } else {
      const durationText = getTiempoFromMinutos(remainderMinutes);
      return `${daysText}, ${durationText}`;
    }
  } else if (minutes < MONTH) {
    const weeks = Math.floor(minutes / WEEK);
    const remainderMinutes = minutes % WEEK;
    const weeksText = `${weeks} semana${weeks !== 1 ? "s" : ""}`;
    if (remainderMinutes === 0) {
      return weeksText;
    } else {
      const durationText = getTiempoFromMinutos(remainderMinutes);
      return `${weeksText}, ${durationText}`;
    }
  } else if (minutes < YEAR) {
    const months = Math.floor(minutes / MONTH);
    const remainderMinutes = minutes % MONTH;
    const monthsText = `${months} mes${months !== 1 ? "es" : ""}`;
    if (remainderMinutes === 0) {
      return monthsText;
    } else {
      const durationText = getTiempoFromMinutos(remainderMinutes);
      return `${monthsText}, ${durationText}`;
    }
  } else {
    const years = Math.floor(minutes / YEAR);
    const remainderMinutes = minutes % YEAR;
    const yearsText = `${years} año${years !== 1 ? "s" : ""}`;
    if (remainderMinutes === 0) {
      return yearsText;
    } else {
      const durationText = getTiempoFromMinutos(remainderMinutes);
      return `${yearsText}, ${durationText}`;
    }
  }
}

export function getPorcentaje(porcentaje = "") {
  let porciento = porcentaje.toString();
  let clase = "info";
  if (porciento <= 20) {
    return (clase = "bg-warning");
  } else if (porciento <= 50) {
    return (clase = "bg-info");
  } else if (porciento <= 70) {
    return (clase = "bg-primary");
  } else if (porciento == 100) {
    return (clase = "bg-success");
  }
}

export function firstLeterMayus(miOracion) {
  const palabras = miOracion?.split(" ");
  //console.log(palabras);

  for (let i = 0; i < palabras?.length; i++) {
    if (palabras[i] != "") {
      //console.log(palabras[i]);
      palabras[i] = palabras[i][0]?.toUpperCase() + palabras[i]?.substr(1);
    } else {
      palabras[i] = "";
    }
  }
  return palabras?.join(" ");
}

export function format2decimales(valu) {
  return valu.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export function avatarLetters(texto = "") {
  try {
    const palabras = texto.toLowerCase().split(" ");
    const palabrasExcluidas = [
      "de",
      "los",
      "al",
      "la",
      "el",
      "las",
      "y",
      "con",
      "por",
      "para",
      "a",
      "en",
    ];

    let palabrasIncluidas = palabras
      .filter((palabra) => !palabrasExcluidas.includes(palabra))
      .slice(0, 2);
    //console.log(palabrasIncluidas);
    let inicial1 = palabrasIncluidas[0].charAt(0);
    let inicial2 =
      palabrasIncluidas.length > 1
        ? palabrasIncluidas[1].charAt(0)
        : palabrasIncluidas[0].charAt(1);
    if (texto.length === 1) {
      return inicial1.toUpperCase();
    }
    return inicial1.toUpperCase() + inicial2.toUpperCase();
  } catch {
    return "0";
  }
}

export function recortarHoraAgregarTexto(hora) {
  //console.log(hora);
  let nuevaHora = hora?.slice(0, 5);
  nuevaHora += " Hrs";
  //console.log(nuevaHora);
  return nuevaHora;
}

export function formatearFecha(formato = "", fecha, incluirAnio = false) {
  //console.log(fecha);
  if (!fecha || fecha == "" || fecha == null || fecha == undefined) {
    return formato;
  }
  const meses = [
    "enero",
    "febrero",
    "marzo",
    "abril",
    "mayo",
    "junio",
    "julio",
    "agosto",
    "septiembre",
    "octubre",
    "noviembre",
    "diciembre",
  ];
  const dias = [
    "Domingo",
    "Lunes",
    "Martes",
    "Miércoles",
    "Jueves",
    "Viernes",
    "Sábado",
  ];
  try {
    const formatoSeparado = formato.split("/");
    const fechaSeparada =
      fecha.split("/").length > 1 ? fecha.split("/") : fecha.split("-");
    //console.log(fechaSeparada);
    let fechaNueva = "";
    let d;
    let m;
    let y;

    formatoSeparado.filter((de, i) => {
      if (de == "dd") {
        d = fechaSeparada[i];
      }
      if (de == "mm") {
        m = fechaSeparada[i];
      }
      if (de == "yyyy" || d == "yy") {
        y = fechaSeparada[i];
      }
    });

    fechaNueva = m + "/" + d + "/" + y;
    //console.log(fechaNueva);

    const fechaObjeto = new Date(fechaNueva); //mm-dd-yyyy
    const dia = dias[fechaObjeto.getDay()];
    const diaMes = fechaObjeto.getDate();
    const mes = meses[fechaObjeto.getMonth()];
    const anio = fechaObjeto.getFullYear();

    let fechaFormateada = `${dia} ${diaMes} de ${mes}`;
    if (incluirAnio) {
      fechaFormateada += ` de ${anio}`;
    }

    return fechaFormateada;
  } catch (e) {
    return formato;
  }
}

function Unidades(num) {
  switch (num) {
    case 1:
      return "UN";
    case 2:
      return "DOS";
    case 3:
      return "TRES";
    case 4:
      return "CUATRO";
    case 5:
      return "CINCO";
    case 6:
      return "SEIS";
    case 7:
      return "SIETE";
    case 8:
      return "OCHO";
    case 9:
      return "NUEVE";
  }

  return "";
} //Unidades()

function Decenas(num) {
  let decena = Math.floor(num / 10);
  let unidad = num - decena * 10;

  switch (decena) {
    case 1:
      switch (unidad) {
        case 0:
          return "DIEZ";
        case 1:
          return "ONCE";
        case 2:
          return "DOCE";
        case 3:
          return "TRECE";
        case 4:
          return "CATORCE";
        case 5:
          return "QUINCE";
        default:
          return "DIECI" + Unidades(unidad);
      }
    case 2:
      switch (unidad) {
        case 0:
          return "VEINTE";
        default:
          return "VEINTI" + Unidades(unidad);
      }
    case 3:
      return DecenasY("TREINTA", unidad);
    case 4:
      return DecenasY("CUARENTA", unidad);
    case 5:
      return DecenasY("CINCUENTA", unidad);
    case 6:
      return DecenasY("SESENTA", unidad);
    case 7:
      return DecenasY("SETENTA", unidad);
    case 8:
      return DecenasY("OCHENTA", unidad);
    case 9:
      return DecenasY("NOVENTA", unidad);
    case 0:
      return Unidades(unidad);
  }
} //Unidades()

function DecenasY(strSin, numUnidades) {
  if (numUnidades > 0) return strSin + " Y " + Unidades(numUnidades);

  return strSin;
} //DecenasY()

function Centenas(num) {
  let centenas = Math.floor(num / 100);
  let decenas = num - centenas * 100;

  switch (centenas) {
    case 1:
      if (decenas > 0) return "CIENTO " + Decenas(decenas);
      return "CIEN";
    case 2:
      return "DOSCIENTOS " + Decenas(decenas);
    case 3:
      return "TRESCIENTOS " + Decenas(decenas);
    case 4:
      return "CUATROCIENTOS " + Decenas(decenas);
    case 5:
      return "QUINIENTOS " + Decenas(decenas);
    case 6:
      return "SEISCIENTOS " + Decenas(decenas);
    case 7:
      return "SETECIENTOS " + Decenas(decenas);
    case 8:
      return "OCHOCIENTOS " + Decenas(decenas);
    case 9:
      return "NOVECIENTOS " + Decenas(decenas);
  }

  return Decenas(decenas);
} //Centenas()

function Seccion(num, divisor, strSingular, strPlural) {
  let cientos = Math.floor(num / divisor);
  let resto = num - cientos * divisor;

  let letras = "";

  if (cientos > 0)
    if (cientos > 1) letras = Centenas(cientos) + " " + strPlural;
    else letras = strSingular;

  if (resto > 0) letras += "";

  return letras;
} //Seccion()

function Miles(num) {
  let divisor = 1000;
  let cientos = Math.floor(num / divisor);
  let resto = num - cientos * divisor;

  let strMiles = Seccion(num, divisor, "UN MIL", "MIL");
  let strCentenas = Centenas(resto);

  if (strMiles == "") return strCentenas;

  return strMiles + " " + strCentenas;
} //Miles()

function Millones(num) {
  let divisor = 1000000;
  let cientos = Math.floor(num / divisor);
  let resto = num - cientos * divisor;

  let strMillones = Seccion(num, divisor, "UN MILLON DE", "MILLONES DE");
  let strMiles = Miles(resto);

  if (strMillones == "") return strMiles;

  return strMillones + " " + strMiles;
} //Millones()

export function convertirNumeroATexto(num) {
  var data = {
    numero: num,
    enteros: Math.floor(num),
    centavos: Math.round(num * 100) - Math.floor(num) * 100,
    letrasCentavos: "",
    letrasMonedaPlural: "PESOS", //"PESOS", 'Dólares', 'Bolívares', 'etcs'
    letrasMonedaSingular: "PESO", //"PESO", 'Dólar', 'Bolivar', 'etc'

    letrasMonedaCentavoPlural: "CENTAVOS",
    letrasMonedaCentavoSingular: "CENTAVO",
  };

  if (data.centavos > 0) {
    data.letrasCentavos =
      "CON " +
      (function () {
        if (data.centavos == 1)
          return (
            Millones(data.centavos) + " " + data.letrasMonedaCentavoSingular
          );
        else
          return Millones(data.centavos) + " " + data.letrasMonedaCentavoPlural;
      })();
  }

  if (data.enteros == 0)
    return "CERO " + data.letrasMonedaPlural + " " + data.letrasCentavos;
  if (data.enteros == 1)
    return (
      Millones(data.enteros) +
      " " +
      data.letrasMonedaSingular +
      " " +
      data.letrasCentavos
    );
  else
    return (
      Millones(data.enteros) +
      " " +
      data.letrasMonedaPlural +
      " " +
      data.letrasCentavos
    );
} //NumeroALetras()

export function generarHorarios(horaInicio, horaFin, incremento) {
  const horarios = [];
  let horaActual = horaInicio;

  while (horaActual <= horaFin) {
    horarios.push(horaActual);
    const [hora, minuto, segundo] = horaActual.split(":").map(Number);
    const tiempoEnMinutos = hora * 60 + minuto + incremento;
    horaActual = new Date(
      0,
      0,
      0,
      Math.floor(tiempoEnMinutos / 60),
      tiempoEnMinutos % 60,
      segundo
    ).toLocaleTimeString([], {
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
    });
  }

  return horarios;
}

export function generarHorarios2(horaInicio, horaFin, incrementoMinutos) {
  const horarios = [];
  const fechaInicio = new Date(`01/01/2000 ${horaInicio}`);
  const fechaFin = new Date(`01/01/2000 ${horaFin}`);
  let fechaActual = fechaInicio;
  let i = 0;
  console.log(fechaActual, "-", fechaFin);
  /*while (fechaActual <= fechaFin) {
    console.log(i);
    i++;
    //horarios.push(fechaActual.toLocaleTimeString([], {hour: '2-digit', minute: '2-digit'}));
    //fechaActual = new Date(fechaActual.getTime() + incrementoMinutos * 60000);
  }*/

  return horarios;
}

export function generarHorarios3(horaInicio, horaFin, incrementoMinutos) {
  //console.log(horaInicio, horaFin, incrementoMinutos);
  const horarios = [];
  const fechaInicio = new Date(`01/01/2000 ${horaInicio}`);
  const fechaFin = new Date(`01/01/2000 ${horaFin}`);

  let fechaActual = fechaInicio;
  //let i = 0;
  //console.log(fechaActual, "-", fechaFin, " - ", incrementoMinutos);

  //console.log("INCREMENTO", incrementoMinutos);
  if (Number(incrementoMinutos) <= 0) {
    throw new Error("El valor de incrementoMinutos debe ser mayor que 0.");
  }

  while (fechaActual <= fechaFin) {
    //console.log(i);
    //i++;
    horarios.push(
      fechaActual.toLocaleTimeString([], { hour: "2-digit", minute: "2-digit" })
    );
    fechaActual = new Date(fechaActual.getTime() + incrementoMinutos * 60000);
  }

  return horarios;
}

export function convertirTiempo(numeroString) {
  try {
    let numero = parseInt(numeroString);

    let horas = Math.floor(numero / 60);
    let minutos = numero % 60;
    let segundos = 0;

    let horasFormateadas = horas.toString().padStart(2, "0");
    let minutosFormateados = minutos.toString().padStart(2, "0");
    let segundosFormateados = segundos.toString().padStart(2, "0");

    return `${horasFormateadas}:${minutosFormateados}:${segundosFormateados}`;
  } catch (error) {
    return "00:15:00";
  }
}

export function encontrarHoraMasCercana(horas, horaReferencia) {
  const [referenciaHoras, referenciaMinutos] = horaReferencia.split(":");
  const referenciaTotalMinutos =
    parseInt(referenciaHoras) * 60 + parseInt(referenciaMinutos);

  let horaMasCercana = "";
  let diferenciaMinima = Infinity;

  for (let i = 0; i < horas.length; i++) {
    const [hora, minutos] = horas[i].split(":");
    const totalMinutos = parseInt(hora) * 60 + parseInt(minutos);

    const diferencia = Math.abs(totalMinutos - referenciaTotalMinutos);

    if (diferencia < diferenciaMinima) {
      diferenciaMinima = diferencia;
      horaMasCercana = horas[i];
    }
  }

  return horaMasCercana;
}
