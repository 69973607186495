import React from "react";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  AsistenciaConfirmacion,
  getEstatusAsistenciaConfirmacion,
} from "../../Reservaciones/Funciones/Reservaciones";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { styled, Snackbar, Alert, Button, Typography } from "@mui/material";
import KALENDAR from "../../assets/LogoTextoNegro.svg";
import WarningIcon from "@mui/icons-material/Warning";
import ThumbUpIcon from "@mui/icons-material/ThumbUp";
import SentimentVeryDissatisfiedIcon from "@mui/icons-material/SentimentVeryDissatisfied";

const MySwal = withReactContent(Swal);

export default function AsistenciaConfirm() {
  const ESTATUS_CONFIRMACION_L = {
    NO_CONFIRMADA: 0,
    CONFIRMADA: 1,
    NO_ASISTIRA: 2,
  };
  const { uuid } = useParams();
  const [open, setOpen] = useState(false);
  const [mensaje, setMensaje] = useState("");
  const [ESTATUS_CONFIRMACION, setESTATUS_CONFIRMACION] = useState(10);
  const [typeMsj, setTypeMsj] = useState("error");

  const handleClose = () => {
    setOpen(false);
  };

  const confirmar = () => {
    MySwal.fire({
      title: "Atención",
      html: "Se confirmara tu asistencia a la reservación",
      icon: "info",
      confirmButtonColor: "#3ABE88",
      showConfirmButton: true,
      showCancelButton: true,
      allowEscapeKey: false,
      allowEnterKey: false,
      allowOutsideClick: false,
    }).then((c) => {
      if (c.isConfirmed) {
        AsistenciaConfirmacion(uuid, ESTATUS_CONFIRMACION_L.CONFIRMADA)
          .then((data) => {
            setMensaje("Actualizado Correctamente");
            setTypeMsj("success");
            setOpen(true);
            setESTATUS_CONFIRMACION(ESTATUS_CONFIRMACION_L.CONFIRMADA);
          })
          .catch((err) => {
            console.log(`File: AsistenciaConfirm.jsx ~ 45 -->`, err);
            setMensaje(err.mensaje);
            setTypeMsj("error");
            setOpen(true);
          });
      }
    });
  };

  useEffect(() => {
    document.title = "Confirmar asistencia";

    getEstatusAsistenciaConfirmacion(uuid)
      .then((data) => {
        setESTATUS_CONFIRMACION(data);
        //setESTATUS_CONFIRMACION(-1);
      })
      .catch((data) => {
        //console.log(`File: AsistenciaConfirm.jsx ~ 58 -->`, data);
        setESTATUS_CONFIRMACION(-1);
      });
  }, []);

  const StyledSnackbar = styled((props) => <Snackbar {...props} />)(
    ({ theme }) => ({
      "& .MuiSnackbar-root": {
        top: theme.spacing(15),
      },
    })
  );
  return (
    <>
      <StyledSnackbar
        direction="right"
        open={open}
        autoHideDuration={6000}
        onClose={handleClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      >
        <Alert onClose={handleClose} severity={typeMsj} sx={{ width: "100%" }}>
          {mensaje}
        </Alert>
      </StyledSnackbar>
      <div className="container-fluid pt-5" style={{ background: "" }}>
        <div className="d-flex flex-column align-items-center mt-5">
          <img src={KALENDAR} alt="LG" />
          <br />
          <br />
          <div className="p-4 d-flex flex-column align-items-center">
            {ESTATUS_CONFIRMACION == -1 ? (
              <>
                <WarningIcon
                  className="w-100"
                  style={{ color: "red", fontSize: 60 }}
                />

                <Typography variant="h6" className="w-100">
                  No pudimos encontrar información de su reservación
                </Typography>
              </>
            ) : ESTATUS_CONFIRMACION == 0 ? (
              <>
                <Button
                  onClick={() => confirmar()}
                  className="btn-Kalendar mb-4"
                  //style={{ width: "300px" }}
                >
                  Confirmar asistencia
                </Button>

                <Typography style={{ fontSize: "16px" }}>
                  Al confirmar tu reservación se estara asegurando que se
                  presentara a la reservación. <br /> No podras cambiar el
                  estatus una vez hayas confirmado.
                </Typography>
              </>
            ) : ESTATUS_CONFIRMACION == 1 ? (
              <>
                <Typography variant="h5" className="w-100 mb-3">
                  Su reservación se a confirmado
                </Typography>
                <ThumbUpIcon
                  className="w-100"
                  style={{ color: "#5EE312", fontSize: 65 }}
                />
              </>
            ) : ESTATUS_CONFIRMACION == 2 ? (
              <>
                <Typography variant="h5" className="w-100 mb-3">
                  Se a confirmado que no asistira a su reservación
                </Typography>
                <SentimentVeryDissatisfiedIcon
                  className="w-100"
                  style={{ color: "#C3C622", fontSize: 65 }}
                />
              </>
            ) : (
              <></>
            )}
          </div>
        </div>
      </div>
    </>
  );
}
