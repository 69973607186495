import {
  Alert,
  Avatar,
  Button,
  Card,
  CardContent,
  Snackbar,
  Stack,
  TextField,
} from "@mui/material";

import React, { useState, useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { actualizarContrasena, getInfoPerfil } from "../Funciones/Perfiles";
import Loading from "../../../Includes/Loading";
import LoadingButton from "@mui/lab/LoadingButton";
import { styled } from "@mui/material/styles";
import { useParams } from "react-router-dom";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import AccEditarImagen from "./../../../assets/EditarImagen.svg";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { setLocalStorageJWT } from "../../../Context/Storage";

const MySwal = withReactContent(Swal);

const UpdatePassowrd = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [IsLoading, setIsLoading] = useState(true);
  const [IsGuardando, setIsGuardando] = useState(false);
  const [open, setOpen] = useState(false);
  const [mensaje, setMensaje] = useState("");
  const [Values, setValues] = useState({
    id: id ?? "0",
    contrasena_actual: "",
    nueva_contrasena: "",
    repetir_contrasena: "",
  });

  const [Errores, setErrores] = useState({
    contrasena_actual: false,
    nueva_contrasena: false,
    repetir_contrasena: false,
  });

  const handleClose = () => {
    setOpen(false);
  };

  const handlInputChange = ({ target, buscador }) => {
    const Name = target ? target.name : buscador.name;
    const Value = target ? target.value : buscador.value;
    let NewValue = {
      ...Values,
      [Name]: Value,
    };

    setValues(NewValue);
    setErrores({
      ...Errores,
      [Name]: false,
    });
  };

  useEffect(() => {
    setIsLoading(true);
    getInfoPerfil(AccEditarImagen)
      .then((resp) => {
        setValues(resp.Values);
        setIsLoading(false);
      })
      .catch((resp) => {
        setIsLoading(false);
      });
  }, []);

  const ActualizarContrasena = () => {
    setIsGuardando(true);
    actualizarContrasena(Values, Errores, setErrores)
      .then((data) => {
        if (data.codigo == "200") {
          setIsGuardando(false);
          MySwal.fire({
            title: "Correcto",
            html: data.mensaje,
            icon: "success",
            confirmButtoColor: "#3ABE88",
            showConfirmButton: false,
            timer: 1700,
          }).then((result) => {
            window.location.href = "../";
          });
        } else {
          setMensaje(data.mensaje);
          setOpen(true);
          setIsGuardando(false);
        }
      })
      .catch((data) => {
        // alert(data.mensaje);
        setMensaje(data.mensaje);
        setOpen(true);
        setIsGuardando(false);
      });
  };

  const StyledSnackbar = styled((props) => <Snackbar {...props} />)(
    ({ theme }) => ({
      "& .MuiSnackbar-root": {
        top: theme.spacing(15),
      },
    })
  );

  return (
    <>
      <StyledSnackbar
        direction="right"
        open={open}
        autoHideDuration={6000}
        onClose={handleClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      >
        <Alert onClose={handleClose} severity="error" sx={{ width: "100%" }}>
          {mensaje}
        </Alert>
      </StyledSnackbar>
      <Button
        onClick={() => navigate(-1)}
        className="btn  font-AvenirBold my-4 text-star ps-0 pe-4  "
        variant="text"
      >
        <ArrowBackIcon className="me-3" />
        Regresar
      </Button>
      <Card elevation={3}>
        <CardContent className="p-0">
          <div className="p-3">
            <h6 className="mb-0 text-left font-AvenirBold">Mi perfil</h6>
          </div>
          <hr
            style={{ backgroundColor: "#DFDEE0", height: "1px", opacity: "1" }}
            className="m-0 p-0"
          />

          {IsLoading ? (
            <Row className="px-3">
              <Col sm={12} className="p-3">
                <Loading />
              </Col>
            </Row>
          ) : (
            <Row className="p-3">
              <Col sm={12} className="px-3">
                <h6 className="text-mutted font-AvenirBold">
                  {" "}
                  Cambio de contraseña{" "}
                </h6>
              </Col>
              <Col sm={12} md={4} className="p-3">
                <TextField
                  fullWidth
                  name="contrasena_actual"
                  label="Contraseña actual"
                  variant="outlined"
                  helperText={
                    Errores.contrasena_actual
                      ? "La contraseña es incorrecta"
                      : ""
                  }
                  value={Values.contrasena_actual}
                  error={Errores.contrasena_actual}
                  onChange={handlInputChange}
                  type="password"
                />
              </Col>
              <Col sm={12} md={4} className="p-3">
                <TextField
                  fullWidth
                  name="nueva_contrasena"
                  label="Nueva contraseña"
                  variant="outlined"
                  helperText={
                    Errores.nueva_contrasena
                      ? "Las contraseñas no coinciden"
                      : ""
                  }
                  value={Values.nueva_contrasena}
                  error={Errores.nueva_contrasena}
                  onChange={handlInputChange}
                  type="password"
                />
              </Col>
              <Col sm={12} md={4} className="p-3">
                <TextField
                  fullWidth
                  name="repetir_contrasena"
                  label="Repite la contraseña"
                  variant="outlined"
                  helperText={
                    Errores.repetir_contrasena
                      ? "Las contraseñas no coinciden"
                      : ""
                  }
                  value={Values.repetir_contrasena}
                  error={Errores.repetir_contrasena}
                  onChange={handlInputChange}
                  type="password"
                />
              </Col>
            </Row>
          )}
          <Stack
            className="p-3"
            spacing={2}
            direction={{ xs: "column", sm: "row" }}
            style={{}}
          >
            <LoadingButton
              loading={IsGuardando}
              loadingPosition="start"
              disabled={IsLoading}
              onClick={() => ActualizarContrasena()}
              className="btn btn-Kalendar font-AvenirMedium  py-2 px-4 "
              variant="contained"
              xs={{ with: "100$" }}
            >
              <span className={IsGuardando ? "px-4" : "px-2"}>
                Cambiar contraseña
              </span>
            </LoadingButton>

            {/*<Button
              onClick={() => navigate(-1)}
              className="btn btn-cancelar font-AvenirMedium py-2 px-4"
              variant="outlined"
            >
              Regresar
            </Button>*/}
          </Stack>
        </CardContent>
      </Card>
    </>
  );
};

export default UpdatePassowrd;
