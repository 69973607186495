import {
    Alert,
    Button,
    Dialog,
    DialogContent,
    DialogTitle,
    IconButton,
    Snackbar,
    TextField
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import React, { useState } from "react";
import { Row, Col } from "react-bootstrap";
import { styled } from "@mui/material/styles";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "./../../Includes/Switch";
import RegimenFiscal from "../../Lib/SAT/Componentes/RegimenFiscal";
import { saveCliente } from "../../Catalogos/Clientes/Funciones/Clientes";


import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";

const MySwal = withReactContent(Swal);
const StyledSnackbar = styled((props) => <Snackbar {...props} />)(
    ({ theme }) => ({
        "& .MuiSnackbar-root": {
            top: theme.spacing(15),
        },
    })
);


const ModalCliente = ({SetshowModalCliente
}) => {

    const [mensaje, setMensaje] = useState("");
    const [openAlert, setOpenAlert] = useState(false);
    const [Values, setValues] = useState({
        nombre: "",
        apellidos: "",
        correo: "",
        telefono: "",
        rfc: "",
        razon_social: "",
        cp: "",
        regimen_fiscal: "",
        requiere_factura: false
      });
      const [Errores, setErrores] = useState({
        nombre: false,
        correo: false,
        telefono: false,
        rfc: false,
        razon_social: false,
        cp: false,
        regimen_fiscal: false,
        requiere_factura: false,
      });

      const handlInputChange = ({ target }) => {
        const Name = target.name;
        const Value = target.value;
        let NewValue = {
          ...Values,
          [Name]: Value,
        };
    
       if (Name === "cp" || Name === "telefono") {
          NewValue = { ...NewValue, [Name]: Value.toNumber() };
        }
        if (Name === "requiere_factura") {
          NewValue = { ...NewValue, requiere_factura: Value };
        }
    
        setValues(NewValue);
        setErrores({
          ...Errores,
          [Name]: false,
        });
      };



    const GuardarCliente = () => {
        new Swal({
            title: "¿Estas seguro de guardar cliente?",
            text: "Esta acción no se puede deshacer",
            icon: "warning",
            showDenyButton: true,
            denyButtonText: "No, cancelar",
            confirmButtonText: "Si, estoy seguro",
            confirmButtonColor: "#3ABE88",
            denyButtonColor: "#65748B",
            reverseButtons: true,
        }).then(function (isConfirm) {

            if (isConfirm.isConfirmed) {

              saveCliente(Values, Errores, setErrores)
              .then((data) => {
                if (data.codigo === "200") {

                  MySwal.fire({
                    title: "Correcto",
                    html: data.mensaje,
                    icon: "success",
                    confirmButtoColor: "#3ABE88",
                    showConfirmButton: false,
                    timer: 1700,
                  }).then((result) => {

                    SetshowModalCliente(false);
                
                  });
                } else {
                    setMensaje(data.mensaje);
                  //console.log("DATA: "+data);
                }
              })
            .catch((data) => {
              setMensaje(JSON.stringify(data.mensaje))
              new Swal({
                title: "Mensaje",
                text: data.mensaje,
                icon: "warning",
                confirmButtonColor: "#3ABE88"
              })
            });
        }
    });
}


    return (

        <>
            <StyledSnackbar
                direction="right"
                open={openAlert}
                autoHideDuration={6000}
                onClose={() => setOpenAlert(false)}
                anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
            >
                <Alert onClose={() => setOpenAlert(false)} severity="error" sx={{ width: "100%" }}>
                    {mensaje}
                </Alert>
            </StyledSnackbar>

            <Dialog
                fullWidth
                maxWidth="sm"
                open={true}
                PaperProps={{ elevation: 0 }}
            >
                <IconButton
                    aria-label="Close"
                    className="m-3 mt-2"
                    style={{ position: "absolute", right: "0%" }}
                    onClick={() => {
                        SetshowModalCliente(false);
                    }}
                >
                    <CloseIcon />
                </IconButton>
                <DialogTitle>
                    <h6 className="fw-bolder f-12 mt-2">Añadir cliente nuevo</h6>
                </DialogTitle>
                <DialogContent>

         <Row className="mt-0 mx-auto">


                  <Col sm={12} md={6} className="p-3">
                    <TextField
                      fullWidth
                      inputProps={{maxLength: 100}}
                      name="nombre"
                      label="Nombre"
                      variant="outlined"
                      value={Values.nombre}
                      error={Errores.nombre}
                      helperText={Errores.nombre ? "Ingresa nombre" : ""}
                      onChange={handlInputChange}
                    />
                  </Col>

                  <Col sm={12} md={6} className="p-3">
                    <TextField
                      fullWidth
                      inputProps={{maxLength: 100}}
                      name="apellidos"
                      label="Apellidos"
                      variant="outlined"
                      value={Values.apellidos}
                      error={Errores.apellidos}
                      helperText={Errores.apellidos ? "Ingresa apellidos" : ""}
                      onChange={handlInputChange}
                    />
                  </Col>

                  <Col sm={12} md={6} className="p-3">
                    <TextField
                      fullWidth
                      inputProps={{maxLength: 150}}
                      name="correo"
                      label="Correo electrónico"
                      variant="outlined"
                      error={Errores.correo}
                      helperText={Errores.correo ? "Correo incorrecto" : ""}
                      value={Values.correo}
                      onChange={handlInputChange}
                    />
                  </Col>

                  <Col sm={12} md={6} className="p-3">
                    <TextField
                      fullWidth
                      name="telefono"
                      label="Teléfono"
                      variant="outlined"
                      inputProps={{ maxLength: 10 }}
                      helperText={Errores.telefono ? "Teléfono inválido" : ""}
                      value={Values.telefono}
                      error={Errores.telefono}
                      onChange={handlInputChange}
                    />
                  </Col>
                </Row>
                <Col Col sm={12} className="p-3">
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={Values.requiere_factura}
                        name="requiere_factura"
                        error={Errores.requiere_factura}
                        onChange={(checked) => {
                          handlInputChange({
                            target: {
                              name: "requiere_factura",
                              value: checked,
                            },
                          });
                        }}
                        tituloActivo="No requiere factura"
                        tituloInactivo="Requiere factura"
                        on
                      />
                    }
                    label="Requiere Factura"
                  />
                </FormGroup>
                </Col>

                {Values.requiere_factura ? (
                  <Row>
                  <Col sm={12} md={6} className="p-3">
                    <TextField
                      fullWidth
                      name="rfc"
                      label="RFC"
                      variant="outlined"
                      helperText={Errores.rfc ? "RFC inválido" : ""}
                      value={Values.rfc}
                      error={Errores.rfc}
                      onChange={handlInputChange}
                      inputProps={{ maxLength: 13 }}
                    />
                  </Col>

                  <Col sm={12} md={6} className="p-3">
                    <TextField
                      fullWidth
                      inputProps={{maxLength: 250}}
                      name="razon_social"
                      label="Razón social"
                      variant="outlined"
                      helperText={
                        Errores.razon_social ? "Razón social inválida" : ""
                      }
                      value={Values.razon_social}
                      error={Errores.razon_social}
                      onChange={handlInputChange}
                    />
                  </Col>

                  <Col sm={12} md={6} className="p-3">
                    <TextField
                      fullWidth
                      name="cp"
                      inputProps={{ maxLength: 5 }}
                      label="Código postal"
                      variant="outlined"
                      helperText={Errores.cp ? "Código postal inválido" : ""}
                      value={Values.cp}
                      error={Errores.cp}
                      onChange={handlInputChange}
                    />
                  </Col>

                  <Col sm={12} md={6} className="p-3">
                    <RegimenFiscal
                       Value={Values.regimen_fiscal}
                       Error={Errores.regimen_fiscal}
                       handlInputChange={handlInputChange}
                    />
                  </Col>
                </Row>
                ) : (
                  <p></p>
                )}


                        <Col sm={12} className="pt-3 ">
                            <Col sm={12} className="d-flex justify-content-end">
                                <Button
                                    onClick={() => {
                                        SetshowModalCliente(false)
                                    }}
                                    loadingPosition="start"
                                    className="btn btn-cancelar font-AvenirMedium py-2 px-4 me-2 "
                                    variant="outlined"
                                >
                                    <span>Cancelar</span>
                                </Button>


                                <Button
                                    onClick={GuardarCliente}
                                    loadingPosition="start"
                                    className="btn btn-Kalendar font-AvenirMedium  py-2 px-4 "
                                    variant="contained"
                                >
                                    <span>Guardar</span>
                                </Button>

                            </Col>
                        </Col>
              


                </DialogContent>
            </Dialog>


        </>
    )
}

export default ModalCliente;