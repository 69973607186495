import React, { useEffect, useState, createContext } from "react";
import { styled, useTheme, Theme, CSSObject } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import Box from "@mui/material/Box";
import MuiDrawer from "@mui/material/Drawer";
import MuiAppBar, { AppBarProps as MuiAppBarProps } from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import CssBaseline from "@mui/material/CssBaseline";
import IconButton from "@mui/material/IconButton";
import MoreVert from "@mui/icons-material/MoreVert";
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
import Dashboard from "../Inicio/Dashboard";
import Sidebar from "./Sidebar";
import AccountMenu from "./MenuPerfil";
import Error404 from "./Error404";
import MenuIcon from "@mui/icons-material/Menu";
import Productos from "../Catalogos/Productos/Productos";
import Colaboradores from "../Catalogos/Colaboradores/Colaboradores";
import Clientes from "../Catalogos/Clientes/Clientes";
import Puestos from "../Catalogos/Puestos/Puestos";
import Categorias from "../Catalogos/Categorias/Categorias";
import ValidadorWebMovile from "../Lib/Funciones Generales/ValidadorWebMovile";
import Perfil from "../Catalogos/Perfil/Perfil";
import Sucursales from "../Configuraciones/Sucursales/Sucursales";
import Generales from "../Configuraciones/Generales/Generales";
import MenuNotificaciones from "./Notificaciones";
import { getLocalStorage } from "../Context/Storage";
import { Backdrop, Chip, CircularProgress } from "@mui/material";
import AccEditarImagen from "../assets/AvatarMenu.svg";

import { getInfoPerfil } from "../Catalogos/Perfil/Funciones/Perfiles";
import Reservaciones from "../Reservaciones/Componentes/RepReservaciones";
import Cuponera from "../Catalogos/Cuponera/Cuponera";
import Descuentos from "../Descuentos/Descuentos/Descuentos";
import ReporteReservaciones from "../Reservaciones/Reporte/ReporteReservaciones";
import ReporteComisiones from "../Catalogos/Colaboradores/Reportes/ReporteComisiones";
import GruposAcceso from "../Configuraciones/gruposacceso/GruposAcceso";
import ErrorAccess from "./ErrorAccess";
import Usuario from "../Configuraciones/Usuarios/Usuarios";
import Licencias from "../Catalogos/Licencias/Licencias";
import FormaPago from "../Configuraciones/FormaPago/FormaPago";
import { usePermisos } from "../Context/PermissionsContext";
import HorarioSemanal from "../HorarioSemanal/HorarioSemanal";
import HorarioDelColaborador from "../HorarioDelColaborador/HorarioDelColaborador";

const drawerWidth = 245; //240

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
  backgroundColor: "#101727",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
  backgroundColor: "#101727",
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: 5,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

export const IconContext = createContext();

const Menu = () => {
  const theme = useTheme();
  const [open, setOpen] = useState(true);
  const [url, setUrl] = useState("");
  const [permisos, setPermisos] = useState([]);

  const { userPermissions, handleSetUserPermissions, IsLoading, setIsLoading } =
    usePermisos();

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const isTabletOrMobile = useMediaQuery("(max-width: 1224px)");

  useEffect(() => {
    setOpen(ValidadorWebMovile());
  }, []);

  useEffect(() => {
    setOpen(!isTabletOrMobile);
  }, [isTabletOrMobile]);

  const [Values, setValues] = useState({
    img: AccEditarImagen,
  });

  useEffect(() => {
    setIsLoading(true);
    getInfoPerfil(AccEditarImagen, true)
      .then((resp) => {
        setValues(resp.Values);
        handleSetUserPermissions(resp.permisos);
        setIsLoading(false);
      })
      .catch((resp) => {
        setIsLoading(false);
      });
    setIsLoading(false);
  }, []);
  //console.log(permisos);

  /*
  React.useEffect(
    function () {
      let Permisos = [];
      if (getLocalStorage("tipo") != "EMPRESA") {
        const x = permisos.map((row) => {
          row.permisos.map((row2) => {
            if (row2.tienepermiso) {
              console.log("Tiene acceso");
              permisosMenu.push(row2.nombre);
              setPermisosMenu(permisosMenu);
            }
          });
        });
      }
    },

    [permisos]
  );
  */

  //console.log(userPermissions);

  return (
    <>
      {IsLoading && (
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={true}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      )}
      <IconContext.Provider value={{ url, setUrl, open, setOpen }}>
        <Box sx={{ display: "flex" }}>
          <CssBaseline />
          <AppBar
            position="fixed"
            open={open}
            color="transparent"
            style={{ boxShadow: "none", zIndex: 10 }}
          >
            <Toolbar
              className="pe-0"
              style={open ? { paddingLeft: "0px" } : {}}
            >
              <div className="d-flex justify-content-between w-100">
                <div>
                  {open ? (
                    <DrawerHeader style={{ backgroundColor: "#FFFFFF" }}>
                      <IconButton onClick={handleDrawerClose}>
                        {theme.direction === "rtl" ? (
                          <MoreVert />
                        ) : (
                          <MoreVert />
                        )}
                      </IconButton>
                    </DrawerHeader>
                  ) : (
                    <IconButton onClick={handleDrawerOpen}>
                      <MenuIcon style={{ color: "white", zIndex: 10 }} />
                    </IconButton>
                  )}
                </div>
                <div
                  className="d-flex justify-content-end w-100 align-content-center"
                  style={{ backgroundColor: "#FFFFFF" }}
                >
                  <div className="p-2 d-flex algin-content-center">
                    {
                      <Chip
                        className="align-self-center"
                        color="success"
                        label={
                          getLocalStorage("tipo") == "EMPRESA"
                            ? "Empresa"
                            : "Colaborador"
                        }
                        size="small"
                      />
                    }
                    <MenuNotificaciones
                      Values={Values}
                      IsLoading={IsLoading}
                      className="align-self-center"
                    ></MenuNotificaciones>
                    <AccountMenu
                      Values={Values}
                      IsLoading={IsLoading}
                      className="align-self-center"
                    ></AccountMenu>
                  </div>
                </div>
              </div>
            </Toolbar>
          </AppBar>
          <Drawer
            variant="permanent"
            open={open}
            style={{
              backgroundColor: "#101727",
              height: "100%",
              color: "white",
              zIndex: 2,
            }}
          >
            <Sidebar openMenu={open} permisosMenu={userPermissions} />
          </Drawer>
          <Box
            component="main"
            sx={{ flexGrow: 1 }}
            className="p-xsm-0 p-sm-3 "
            style={{ backgroundColor: "#F9FAFC", minHeight: "100vh" }}
          >
            <div className="mb-5"></div>
            <Routes>
              {!IsLoading && (
                <>
                  <Route path="inicio" element={<Dashboard />}></Route>
                  <Route path="/" element={<Dashboard />}></Route>
                  <Route
                    path="/productos/*"
                    element={
                      userPermissions.includes("Productos") ||
                      getLocalStorage("tipo") == "EMPRESA" ? (
                        <Productos />
                      ) : (
                        <ErrorAccess />
                      )
                    }
                  ></Route>
                  <Route
                    path="/categorias/*"
                    element={
                      userPermissions.includes("Categorías") ||
                      getLocalStorage("tipo") == "EMPRESA" ? (
                        <Categorias />
                      ) : (
                        <ErrorAccess />
                      )
                    }
                  ></Route>
                  <Route
                    path="/colaboradores/*"
                    element={
                      userPermissions.includes("Colaboradores") ||
                      getLocalStorage("tipo") == "EMPRESA" ? (
                        <Colaboradores />
                      ) : (
                        <ErrorAccess />
                      )
                    }
                  ></Route>
                  <Route
                    path="/clientes/*"
                    element={
                      userPermissions.includes("Clientes") ||
                      getLocalStorage("tipo") == "EMPRESA" ? (
                        <Clientes />
                      ) : (
                        <ErrorAccess />
                      )
                    }
                  ></Route>
                  <Route
                    path="/formaspago/*"
                    element={
                      userPermissions.includes("Formasdepago") == false ||
                      getLocalStorage("tipo") == "EMPRESA" ? (
                        <FormaPago />
                      ) : (
                        <ErrorAccess />
                      )
                    }
                  ></Route>
                  <Route
                    path="/puestos/*"
                    element={
                      userPermissions.includes("Puestos") ||
                      getLocalStorage("tipo") == "EMPRESA" ? (
                        <Puestos />
                      ) : (
                        <ErrorAccess />
                      )
                    }
                  ></Route>
                  <Route path="/perfil/*" element={<Perfil />}></Route>
                  <Route
                    path="/sucursales/*"
                    element={
                      userPermissions.includes("Sucursales") ||
                      getLocalStorage("tipo") == "EMPRESA" ? (
                        <Sucursales />
                      ) : (
                        <ErrorAccess />
                      )
                    }
                  ></Route>
                  <Route
                    path="/configuraciones/*"
                    element={
                      userPermissions.includes("Generales") ||
                      getLocalStorage("tipo") == "EMPRESA" ? (
                        <Generales />
                      ) : (
                        <ErrorAccess />
                      )
                    }
                  ></Route>

                  <Route
                    path="/reservaciones/*"
                    element={
                      userPermissions.includes("Reservaciones") ||
                      getLocalStorage("tipo") == "EMPRESA" ? (
                        <Reservaciones />
                      ) : (
                        <ErrorAccess />
                      )
                    }
                  ></Route>
                  <Route
                    path="/cupones/*"
                    element={
                      userPermissions.includes("Cupones") ||
                      getLocalStorage("tipo") == "EMPRESA" ? (
                        <Cuponera />
                      ) : (
                        <ErrorAccess />
                      )
                    }
                  ></Route>
                  <Route
                    path="/licencias/*"
                    element={
                      userPermissions.includes("Licencias") == false ||
                      getLocalStorage("tipo") == "EMPRESA" ? (
                        <Licencias />
                      ) : (
                        <ErrorAccess />
                      )
                    }
                  ></Route>
                  <Route
                    path="/descuentos/*"
                    element={
                      userPermissions.includes("Descuentos") ||
                      getLocalStorage("tipo") == "EMPRESA" ? (
                        <Descuentos />
                      ) : (
                        <ErrorAccess />
                      )
                    }
                  ></Route>
                  <Route
                    path="/represervaciones"
                    element={
                      userPermissions.includes("Reporte Reservaciones") ||
                      getLocalStorage("tipo") == "EMPRESA" ? (
                        <ReporteReservaciones />
                      ) : (
                        <ErrorAccess />
                      )
                    }
                  />
                  <Route
                    path="/repcomisiones"
                    element={
                      userPermissions.includes("Reporte Comisiones") ||
                      getLocalStorage("tipo") == "EMPRESA" ? (
                        <ReporteComisiones />
                      ) : (
                        <ErrorAccess />
                      )
                    }
                  />
                  <Route
                    path="/gruposacceso/*"
                    element={
                      userPermissions.includes("Grupos de acceso") ||
                      getLocalStorage("tipo") == "EMPRESA" ? (
                        <GruposAcceso />
                      ) : (
                        <ErrorAccess />
                      )
                    }
                  />

                  <Route
                    path="/usuarios/*"
                    element={
                      userPermissions.includes("Usuarios") ||
                      getLocalStorage("tipo") == "EMPRESA" ? (
                        <Usuario />
                      ) : (
                        <ErrorAccess />
                      )
                    }
                  ></Route>
                  <Route
                    path="horario_semanal/*"
                    element={
                      userPermissions.includes("Usuarios") ||
                      getLocalStorage("tipo") == "EMPRESA" ? (
                        <HorarioSemanal />
                      ) : (
                        <ErrorAccess />
                      )
                    }
                  />

                  <Route path="*" element={<Error404 />} />
                  <Route
                    path="horario_colaborador/*"
                    element={
                      userPermissions.includes("Usuarios") ||
                      getLocalStorage("tipo") == "EMPRESA" ? (
                        <HorarioDelColaborador />
                      ) : (
                        <ErrorAccess />
                      )
                    }
                  />

                  <Route path="*" element={<Error404 />} />
                </>
              )}
              ;
            </Routes>
          </Box>
        </Box>
      </IconContext.Provider>
    </>
  );
};

export default Menu;
