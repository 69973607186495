import React, { useEffect, useState } from "react";
import { BasicDatePicker } from "../../Includes/DatePicker";
import { Col, Row } from "react-bootstrap";
import { Button, Card, CardContent, Stack } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { getOpciones } from "../Funciones/Reservaciones";
import { Link } from "react-router-dom";
import { GetDate } from "../../Lib/Funciones Generales/ObtenerFecha";

const ReporteReservaciones = () => {
  const [fechaInicio, setFechaInicio] = useState(GetDate(-7));
  const [fechaFin, setFechaFin] = useState(GetDate());
  useEffect(() => {
    document.title = `Reporte de reservaciones`;
  }, []);
  const theme = useTheme();
  const [sucursales, setSucursales] = useState([]);
  const [seleccionados, setSeleccionados] = useState([]);
  const todasLasSucursales = { ID: -1, DESCRIPCION: "Seleccionar todas" };
  const opcionesSucursales = [...sucursales, todasLasSucursales];
  const [banderaSeleccion, setBanderaSeleccion] = useState(false);

  useEffect(() => {
    getOpciones()
      .then((resp) => {
        const opciones = JSON.parse(resp.data);
        setSucursales(opciones);
      })
      .catch((err) => console.error(err));
  }, []);

  // const handleChange = (event) => {
  //   setSeleccionados(event.target.value);
  // };

  const handleSelectAll = (event) => {
    if (event.target.value.includes(-1)) {
      setSeleccionados(opcionesSucursales.filter(s => s.ID !== -1).map(s => s.ID));
      setBanderaSeleccion(true); // actualizar el estado de la bandera
    } else {
      setSeleccionados([]);
      setBanderaSeleccion(false); // actualizar el estado de la bandera
    }
  };

  const handleChangeFechaIn = (event) => {
    var fecha = new Date(event);
    fecha.setDate(fecha.getDate());
    const year = fecha.getFullYear();
    const month = fecha.getMonth() + 1;
    const day = fecha.getDate();
    let FECHA = year + "-" + (month < 10 ? "0" + month : month) + "-" + (day < 10 ? "0" + day : day);
    setFechaInicio(FECHA);
  };

  const handleChangeFechaFIn = (event) => {
    var fecha = new Date(event);
    fecha.setDate(fecha.getDate());
    const year = fecha.getFullYear();
    const month = fecha.getMonth() + 1;
    const day = fecha.getDate();
    let FECHA = year + "-" + (month < 10 ? "0" + month : month) + "-" + (day < 10 ? "0" + day : day);
    setFechaFin(FECHA);
  };

  console.log("El estado de la bandera es:", banderaSeleccion);
  return (
    <>
      <Card elevation={3} style={{ marginTop: "150px" }}>
        <CardContent className="p-3">
          <div className="p-3">
            <h6 className="mb-0 text-left font-AvenirBold">
              Reporte de reservaciones
            </h6>
          </div>
          <hr
            style={{ backgroundColor: "#DFDEE0", height: "1px", opacity: "1" }}
            className="m-0 p-0"
          />
          <Row>
            <Col xs={12} md={4} style={{ marginTop: '15px' }}>
              <BasicDatePicker
                fullWidth
                format="DD/MM/YYYY"
                label="Fecha de inicio"
                fecha={fechaInicio}
                setFecha={handleChangeFechaIn}
              />
            </Col>
            <Col xs={12} md={4} style={{ marginTop: '15px' }}>
              <BasicDatePicker
                fullWidth
                format="DD/MM/YYYY"
                label="Fecha de fin"
                fecha={fechaFin}
                setFecha={handleChangeFechaFIn}
              />
            </Col>
            <Col xs={12} md={4} style={{ marginTop: '15px' }}>
              <div>
                <FormControl fullWidth>
                  <InputLabel id="demo-multiple-sucursal-label">
                    Sucursales
                  </InputLabel>
                  <Select
                    fullWidth
                    labelId="demo-multiple-sucursal-label"
                    id="demo-multiple-sucursal"
                    multiple
                    value={seleccionados}
                    onChange={(e) => {
                      if (e.target.value.includes(-1)) {
                        handleSelectAll(e);
                      } else {
                        setSeleccionados(e.target.value);
                        console.log(e.target.value);
                        setBanderaSeleccion(false); // actualizar el estado de la bandera
                      }
                    }}
                    input={<OutlinedInput label="Sucursales" />}
                  >
                    {opcionesSucursales.map((sucursal) => (
                      <MenuItem
                        key={sucursal.ID}
                        value={sucursal.ID}
                        style={getStyles(
                          sucursal.DESCRIPCION,
                          opcionesSucursales,
                          theme
                        )}
                      >
                        {sucursal.DESCRIPCION} {seleccionados.includes(sucursal.ID) && ""}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </div>
            </Col>
          </Row>
          <Stack
            className="py-4"
            spacing={2}
            direction={{ xs: "column", sm: "row" }}
            style={{ float: "right" }}
          > 
            <Link
              target={seleccionados === "" ? "_self" : "_blank"}
              to={
                seleccionados === ""
                  ? "#"
                  : `../pdf/reservaciones/${banderaSeleccion ? "TODAS" : seleccionados}/${fechaInicio}/${fechaFin}`
              }
              style={{ textDecoration: "none" }}
            >
              <Button
                disabled={seleccionados == ""}
                className="font-AvenirMedium  py-2 px-4 "
                variant="contained"
                color="error"
              >
                Generar Reporte
              </Button>
            </Link>
          </Stack>
        </CardContent>
      </Card>
    </>
  );
};
function getStyles(name, sucursales, theme) {
  return {
    fontWeight:
      sucursales.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}
export default ReporteReservaciones;
