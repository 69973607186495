import { Autocomplete, TextField } from "@mui/material";
import { useEffect, useState } from "react";

const TipoPermisosFaltas = ({ Value, Error, handlInputChange, Name = "id_tipo_permiso_falta", disabled = false, size = "" }) => {


    const lista = [
        // { ID: 0, DESCRIPCION: "Tipo de permiso / falta" },
        { ID: 1, DESCRIPCION: "Permiso" },
        { ID: 2, DESCRIPCION: "Falta" },
        { ID: 3, DESCRIPCION: "Inasistencia" }
    ];
    const [ValorFiltrado, setFiltro] = useState();
    const [PermisoFalta, setPermisoFalta] = useState({
        cargado: true,
        lista,
    });


    const getOpcion = () => {
        const opcion = PermisoFalta.lista.find((x) => x.ID == Value);
        if (opcion) {
            return opcion;
        } else {
            return null;
        }
    };

    return PermisoFalta.cargado ? (
        <Autocomplete
            fullWidth
            size={size}
            disabled={disabled}
            defaultValue={getOpcion}
            isOptionEqualToValue={(option, value) => {
                return option.ID == value.ID;
            }}
            inputValue={ValorFiltrado}
            onChange={(e, value) => {
                if (value) {
                    handlInputChange({ target: { name: Name, value: value.ID } });
                } else {
                    handlInputChange({ target: { name: Name, value: "" } });
                }
            }}
            onInputChange={(event, newInputValue) => {
                setFiltro(newInputValue);
            }}
            options={PermisoFalta.lista}
            getOptionLabel={(option) => {
                return option.DESCRIPCION;
            }}
            renderInput={(params) => {
                return (
                    <TextField
                        {...params}
                        error={Error}
                        helperText={Error ? "Selecciona un tipo de permiso / falta" : ""}
                        label="Tipo de permiso / falta"
                    />
                );
            }}
        />
    ) : (
        <TextField fullWidth disabled={true} value={"Tipo de permiso / falta"} />
    );
};

export default TipoPermisosFaltas;
