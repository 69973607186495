import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import RepHorarioDelColaborador from "./Components/RepHorarioDelColaborador";
import Error404 from "../Includes/Error404";
import HorarioSemanal from "../HorarioSemanal/Components/HorarioSemanal";

const HorarioDelColaborador = () => {
  return (
    <Routes>
      <Route
        path=""
        element={
          <HorarioSemanal
            title="Horario de colaborador"
            isReverseAll={true}
            titlePage="Horario semanal colaborador"
          />
        }
      />
      <Route path="/*" element={<Error404 />} />
    </Routes>
  );
};
export default HorarioDelColaborador;
