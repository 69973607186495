import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Error404 from "../Includes/Error404";
import RepHorarioSemanal from "./Components/HorarioSemanal";
const HorarioSemanal = () => {
  return (
    <Routes>
      <Route path="" element={<RepHorarioSemanal isVista />} />
      <Route path="/*" element={<Error404 />} />
    </Routes>
  );
};
export default HorarioSemanal;
