import {
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useState, useEffect } from "react";
import { getInfoComprobante } from "../../Funciones/Licencias";
import Loading from "../../../../Includes/Loading";

const ModalComprobante = ({ 
  setShowDialogVerComprobante
  , setLicenciaSeleccionada
  , licenciaSeleccionada 
}) => {

  const [IsLoading, setIsLoading] = useState(true)
  const [url, setUrl] = useState("");

  useEffect(() => {
    getInfoComprobante(licenciaSeleccionada).then(resp => {
      setUrl(resp.URL)
      setIsLoading(false)
    }).catch(error => {
      console.log(error)
      setIsLoading(false)
    })
  }, [])

  return (
    <Dialog
      fullWidth
      maxWidth="md"
      open={true}
      PaperProps={{ elevation: 0 }}
    >
      <IconButton
        aria-label="Close"
        className="m-3"
        style={{ position: "absolute", right: "0%" }}
        onClick={() => {
          setLicenciaSeleccionada(-1)
          setShowDialogVerComprobante(false);
        }}
      >
        <CloseIcon style={{ width: "60px", height: "60px" }} />
      </IconButton>
      <DialogTitle>
        <h3 className="fw-bolder f-12 mt-2">Comprobante de pago</h3>
      </DialogTitle>
      <DialogContent className={url == "mt-3" ? "d-flex justify-content-center" : "mt-3"}>

        {
          IsLoading ? (
            <div className="col-12 py-4 w-100">
              <Loading />
            </div>
          ) : (

            url == "" ? (
              <div className="p-5">
                <h5 className="text-center"> Comprobante no disponible</h5>
              </div>
            ) : (
              url.includes(".pdf") ? (
                <iframe src={"https://docs.google.com/gview?url=" + url + "&embedded=true"} style={{ width: '100%', height: 700 }} frameborder="0" ></iframe>
              ) : (
                <img src={url} className="img-fluid mt-3" />
              )

            )
          )
        }

      </DialogContent>
    </Dialog>
  )
}

export default ModalComprobante