import * as React from "react";
import { useEffect, useState } from "react";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import {
  encontrarHoraMasCercana,
  generarHorarios2,
  generarHorarios3,
} from "../../../Lib/Funciones Generales/Generales";
import {
  getHorario,
  getHorariosColabora,
  getHorariosSucursal,
} from "../Funciones/Colaboradores";

export default function SelectHorariosColab({
  configData = {},
  fecha,
  Value,
  Error,
  id_colaborador,
  handlInputChange = () => {},
  Name = "hora_de_reserva",
  disabled,
  sucursal,
}) {
  const [ValorFiltrado, setFiltro] = React.useState();
  const [horarios, setHorarios] = React.useState([]);

  const GetOpcionesHorarios = () => {
    setHorarios([]);
    if (id_colaborador != "" && id_colaborador != 0) {
      getHorariosColabora(id_colaborador, fecha, sucursal)
        .then((resp) => {
          let list = JSON.parse(resp.data);
          if (list.length != 0) {
            const filteredResources = list.map((resource) => {
              return resource.HoraInicio.toString().substring(0, 5);
            });
            setHorarios(filteredResources);
          }
        })
        .catch((resp) => {
          console.log(resp);
        });
    } else {
      // alert(sucursal)
      if (sucursal != "" && sucursal != 0) {
        getHorariosSucursal(sucursal, fecha)
          .then((resp) => {
            let list = JSON.parse(resp.horarios);

            if (list.length != 0) {
              const filteredResources = list.map((resource) => {
                return resource.HoraInicio.toString().substring(0, 5);
              });
              setHorarios(filteredResources);
            }
          })
          .catch((resp) => {
            console.log(resp);
          });
      }
    }
  };

  useEffect(() => {
    GetOpcionesHorarios();
  }, []);

  useEffect(() => {
    GetOpcionesHorarios();
  }, [id_colaborador, fecha, configData, sucursal]);

  const getOpcion = () => {
    try {
      const opcion = horarios.find((x, i) => x == Value);
      if (opcion) {
        return opcion;
      } else {
        const h = encontrarHoraMasCercana(horarios, Value);
        //console.log("h: ", h);
        if (h) {
          return h;
        } else {
          return null;
        }
      }
    } catch {
      return null;
    }
  };

  return horarios.length == 0 ? (
    <TextField fullWidth disabled={true} value={"Horarios"} size="small" />
  ) : (
    /**
    <Autocomplete
      disablePortal
      id="combo-box-demo"
      options={Horarios}
      fullWidth
      size='small'
     
      renderInput={(params) =>  
      <TextField {...params} 
      Value={Value}
      Error={Error}
      id_colaborador={id_colaborador}
      onChange={handlInputChange}
      disabled={disabled}
      name='hora_de_reserva'  
      label="Horario"
      />}
    />

     */

    <Autocomplete
      fullWidth
      size="small"
      disabled={disabled}
      defaultValue={getOpcion}
      isOptionEqualToValue={(option, value) => {
        return option == value;
      }}
      inputValue={ValorFiltrado}
      onChange={(e, value) => {
        if (value) {
          handlInputChange({ target: { name: Name, value: value } });
        } else {
          handlInputChange({ target: { name: Name, value: "" } });
        }
      }}
      onInputChange={(event, newInputValue) => {
        setFiltro(newInputValue);
      }}
      options={horarios}
      getOptionLabel={(option) => {
        return option;
      }}
      renderInput={(params) => {
        return (
          <TextField
            {...params}
            error={Error}
            helperText={Error ? "Sin horarios" : ""}
            label="Hora"
          />
        );
      }}
    />
  );
}
