import { Autocomplete, TextField } from "@mui/material";
import { useEffect, useState } from "react";
import { getOpciones, getOpcionesv2 } from "../Funciones/Colaboradores";

const Colaboradores = ({
  Value,
  Error,
  handlInputChange,
  Name = "id_colaborador",
  disabled = false,
  id_sucursal =0 ,
  size = "",
  id = 0,
  showTodos=false
}) => {
  const opcionInicial = { ID: 0, DESCRIPCION: "Colaborador" };
  const [ValorFiltrado, setFiltro] = useState();
  const [Colaborador, setColaborador] = useState({
    cargado: false,
    lista: [opcionInicial],
  });

  const cargar = () => {
    setColaborador({
      cargado: false,
      lista: [opcionInicial],
    });
  
     
      getOpcionesv2(Number(id_sucursal))
        .then((resp) => {
          let list = JSON.parse(resp.data);
          //console.log(list);
          if (list.length != 0) {
            if (showTodos) { 
              list.unshift({ ID: 0, DESCRIPCION: "Todos los colaboradores" });
              handlInputChange({ target: { name: Name, value: list[0].ID } });
           }

            setColaborador({ cargado: true, lista: [...list] });
            if(list.length ==1)
            {
              handlInputChange({ target: { name: Name, value: list[0].ID } });
            } 
           
          } else {
            setColaborador({
              cargado: false,
              lista: [{ ID: 0, DESCRIPCION: "Sin colaboradores" }],
            });
          }
        })
        .catch((resp) => {
          console.log(resp);
          setColaborador({
            cargado: true,
            lista: [{ ID: 0, DESCRIPCION: "Error al consultar colaboradores" }],
          });
        });
    
  };

  useEffect(() => {
    cargar();
  }, [id_sucursal]);

  const getOpcion = () => {
    const opcion = Colaborador.lista.find((x) => x.ID == Value);

    if (opcion) {
      return opcion;
    } else {
      return null;
    }
  };

  return Colaborador.cargado ? (
    <Autocomplete
      fullWidth
      defaultValue={getOpcion}
      size={size}
      // disabled={disabled}
      isOptionEqualToValue={(option, value) => { 
        return option.ID == value.ID;
      }}
      inputValue={ValorFiltrado}
      onChange={(e, value) => {
        if (value) {
          handlInputChange({ target: { name: Name, value: value.ID } });
        } else { 
          handlInputChange({ target: { name: Name, value: "" } });
        }
      }}
      onInputChange={(event, newInputValue) => {
        setFiltro(newInputValue);
      }}
      options={Colaborador.lista}
      getOptionLabel={(option) => {
        return option.DESCRIPCION;
      }}
      renderInput={(params) => {
        return (
          <TextField
            {...params}
            error={Error}
            helperText={Error ? "Selecciona un Colaborador" : ""}
            label="Colaborador"
            size={size}
          />
        );
      }}
    />
  ) : (
    <TextField fullWidth disabled={true} value={"Colaborador"} size={size} />
  );
};

  export default Colaboradores;
