import { IconButton, Tooltip } from "@mui/material";
import React from "react";
import CircleIcon from "@mui/icons-material/Circle";

const EstatusReservacion = ({
  id,
  estatus,
  cancelarReservacion,
  cambiarEstatusReservacion,
}) => {
  let style = { backgroundColor: "##efefef" };

  return (
    <div className=" d-flex flex-wrap justify-content-start align-content-center align-items-center">
      {estatus != 2 && (
        <Tooltip title="No atendida" placement="top">
          <div className="p-2 d-flex justify-content-start align-content-center align-items-center  ">
            <IconButton
              onClick={() => {
                cambiarEstatusReservacion(0);
              }}
              aria-label="delete"
              size="small"
              style={estatus == 0 ? style : {}}
            >
             
              <CircleIcon fontSize="small" style={{ color: "#FE8D27" }} />
            </IconButton>
            {estatus == 0 ? (
              <span
                className="  text-nowrap"
                style={{ fontSize: "12px", color: "#FE8D27" }}
              >
                No atendida
              </span>
            ) : (
              ""
            )}
          </div>
        </Tooltip>
      )}


      {estatus != 2 && (
        <Tooltip title="Atendida" placement="top">
          <div className="p-2 d-flex justify-content-start align-content-center align-items-center  ">
            <IconButton
              onClick={() => {
                cambiarEstatusReservacion(1);
              }}
              aria-label="delete"
              size="small"
              tyle={estatus == 1 ? style : {}}
            >
              <CircleIcon fontSize="small" style={{ color: "#3ABE88" }} />
            </IconButton>
            {estatus == 1 ? (
              <span
                className=" "
                style={{ fontSize: "12px", color: "#3ABE88" }}
              >
                Atendida{" "}
              </span>
            ) : (
              ""
            )}
          </div>
        </Tooltip>
      )}

      <Tooltip title="Cancelada" placement="top">
        <div className="p-2 d-flex justify-content-start align-content-center align-items-center  ">
          <IconButton
            onClick={cancelarReservacion}
            aria-label="delete"
            size="small"
            tyle={estatus == 2 ? style : {}}
          >
            <CircleIcon fontSize="small" style={{ color: "#d04646" }} />
          </IconButton>
          {estatus == 2 ? (
            <span className=" " style={{ fontSize: "12px", color: "#d04646" }}>
              Cancelada{" "}

            
            </span>
          ) : (
            ""
          )}
        </div>
      </Tooltip>
      {estatus != 2 && (
        <Tooltip title="No se presento" placement="top">
          <div className="p-2 d-flex justify-content-start align-content-center align-items-center  ">
            <IconButton
              onClick={() => {
                cambiarEstatusReservacion(3);
              }}
              aria-label="delete"
              size="small"
              tyle={estatus == 3 ? style : {}}
            >
              <CircleIcon fontSize="small" style={{ color: "#c5be02" }} />
            </IconButton>
            {estatus == 3 ? (
              <span
                className=" "
                style={{ fontSize: "12px", color: "#c5be02" }}
              >
                No se presentó 
              </span>
            ) : (
              ""
            )}
          </div>
        </Tooltip>
      )}
    </div>
  );
};

export default EstatusReservacion;
