import React, { useEffect, useState } from "react";

import { useNavigate } from "react-router-dom";

import {
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
  Tabs,
  Tab,
  Snackbar,
  Alert,
} from "@mui/material";
import { useParams } from "react-router-dom";
import { GetDate } from "../../Lib/Funciones Generales/ObtenerFecha";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import {
  getReservacionBYid,
  guardar,
  validarDisponibilidadColaborador,
} from "../Funciones/Reservaciones";
import CloseIcon from "@mui/icons-material/Close";
import {
  ConvertDate,
  ConvertTime,
} from "../../Lib/Funciones Generales/ObtenerFecha";
import dayjs from "dayjs";
import Box from "@mui/material/Box";
import DatosReservacion from "./Tabs/DatosReservacion";
import Pago from "./Tabs/Pago";
import { styled } from "@mui/material/styles";

import ModalCliente from "./ModalNewCliente";

const MySwal = withReactContent(Swal);

function Reservacion({
  setShowModalReserva,
  getReservaciones,
  idReserv = "0",
  fechaReserva,
  setIdReserv = () => {},
  folioReservacion,
  setFolioReservacion = () => {},
  config,
  setConfig = () => {},
  id_suc,
}) {
  const { id } = useParams();
  const navigate = useNavigate();
  const [fecha, setFecha] = useState(
    fechaReserva == null ? GetDate() : dayjs(fechaReserva).format("YYYY-MM-DD")
  );
  const [fechaMin, setFechaMin] = useState(GetDate());
  const [hora, setHora] = useState(
    fechaReserva == null
      ? ""
      : // String(dayjs().hour() + ":" + dayjs().minute())
        dayjs(fechaReserva).format("HH:mm")
  );
  const [mensaje, setMensaje] = useState("");
  const [open, setOpen] = useState(false);
  const [IsGuardando, setIsGuardando] = useState(false);
  const [IsLoading, setIsLoading] = useState(true);
  const [productosCarrito, setProductosCarrito] = useState([]);
  const [descuento, setDescuento] = useState({ id: 0, descuento: 0, tipo: 0 });
  const [codigoDesc, setCodigoDesc] = useState("");
  //const [ShowCliente, setShowCliente] = useState(false);
  const [showModalCliente, SetshowModalCliente] = useState(false);

  const [formasPago, setFormasPago] = useState({
    efectivo: { active: false, monto: 0 },
    tarjeta: { active: false, monto: 0 },
    monedero: { active: false, monto: 0 },
    otros: { active: false, monto: 0 },
  });

  /*if (fechaReserva != null) {
    //console.log(dayjs(fechaReserva).format("YYYY-MM-DD"))
  }*/

  const [Values, setValues] = useState({
    id: idReserv ?? "0",
    fecha_de_reservacion:
      fechaReserva == null
        ? GetDate()
        : dayjs(fechaReserva).format("YYYY-MM-DD"),
    hora_de_reserva:
      fechaReserva == null
        ? String(dayjs().hour() + ":" + dayjs().minute())
        : dayjs(fechaReserva).format("HH:mm"),
    id_cliente: "",
    id_colaborador: "",
    id_sucursal: id_suc ?? "",
    total: 0,
    descuento: 0,
    duracion: "",
    pagado: false,
    notas: "",
    productos: [],
    codigo: "",
  });

  const [Errores, setErrores] = useState({
    fecha_de_reservacion: false,
    hora_de_reserva: false,
    id_cliente: false,
    id_colaborador: false,
    id_sucursal: false,
    id_producto_o_servicio: false,
    precio: false,
    duracion: false,
    pagado: false,
  });

  /*const [config, setConfig] = useState({
    id: 0,
    encabezado: "",
    pie: "",
    linea_1: "",
    incrementos_horarios: "",
    si_cobro: false,
    ancho: 80,
    fuente: 11,
  });*/

  const [openAlert, setOpenAlert] = useState(false);

  const [value, setValue] = React.useState(0);

  const ErrorFecha =
    Errores.fecha_de_reservacion ||
    Values.fecha_de_reservacion == "" ||
    Values.fecha_de_reservacion == null
      ? true
      : false;

  const ErrorHora =
    Errores.hora_de_reserva ||
    Values.hora_de_reserva == "" ||
    Values.hora_de_reserva == null
      ? true
      : false;

  //#region HANDLES
  const handleShowC = () => {
    //alert("Esta opción aún no esta disponible");
   SetshowModalCliente(true)

    
  };

  const handlInputChange = ({ target }) => {
    let Name = target.name;
    let Value = target.value;
    if (Name == "precio") {
      Value = Value.toDecimal();

      if (Name == "hora_de_reserva") {
        //console.log("SET HORA: ", Value);
      }
    }
    if (Name == "id_sucursal") {
      //console.log("Cambio de sucursal");
      Values.id_colaborador = 0;
      //Values.hora_de_reserva = "";
    }
    setValues({
      ...Values,
      [Name]: Value,
    });
    setErrores({
      ...Errores,
      [Name]: false,
    });
  };

  const handleClose = () => {
    setOpenAlert(false);
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  //#endregion

  //#region FUNCIONES

  const GuardarReserva = () => {
    validarDisponibilidadColaborador(
      Values.id_sucursal,
      Values.id_colaborador,
      Values.fecha_de_reservacion,
      Values.hora_de_reserva
    ).then((resp) => {
      if (resp.mensaje != "") {
        MySwal.fire({
          title: "Lo sentimos",
          html: resp.mensaje,
          icon: "error",
          confirmButtoColor: "#3ABE88",
          showConfirmButton: false,
          timer: 1700,
        });
        return;
      }

      setIsGuardando(true);
      // alert("Guardando")
      guardar(
        Values,
        Errores,
        setErrores,
        productosCarrito,
        descuento,
        formasPago
      )
        .then((data) => {
          if (data.codigo == "200") {
            setIsGuardando(false);
            MySwal.fire({
              title: "Correcto",
              html: data.mensaje,
              icon: "success",
              confirmButtoColor: "#3ABE88",
              showConfirmButton: false,
              timer: 1700,
            }).then((result) => {
              getReservaciones();
              setProductosCarrito([]);
              setShowModalReserva(false);
            });
          } else {
            setMensaje(data.mensaje);
            setOpenAlert(true);
            setIsGuardando(false);
          }
        })
        .catch((data) => {
          setMensaje(data.mensaje);
          setOpenAlert(true);
          setIsGuardando(false);
        });
    });
  };

  //#endregion

  //#region EFFECTS

  useEffect(() => {
    //console.log(id_suc);
    //console.log(hora);
    //console.log(config);
    //render que valida cuando el idReserv no es 0
    let array = [];
    if (idReserv != "0") {
      getReservacionBYid(idReserv)
        .then((resp) => {
          setValues({
            id: resp.Values.id,
            fecha_de_reservacion: resp.Values.fecha_reservacion,
            hora_de_reserva: resp.Values.hora,
            id_cliente: resp.Values.id_cliente,
            id_colaborador: resp.Values.id_colaborador,
            id_sucursal: resp.Values.id_sucursal,
            total: Number(resp.Values.total),
            descuento: Number(resp.Values.descuento),
            duracion: resp.Values.duracion,
            pagado: false,
            notas: resp.Values.notas,
            productos: [],
            codigo: "",
          });
          //console.log(Values);
          //console.log(resp.Values);

          array = [];
          resp.detalle.map((row) => {
            array.push({
              ID: row.ID,
              DESCRIPCION: row.DESCRIPCION,
              PRECIO: row.PRECIO,
              DURACION: row.DURACION,
              CANTIDAD: row.CANTIDAD,
              DESCUENTO: 0,
              TIPO_DESCUENTO: 0,
            });
          });
          setProductosCarrito(array);

          setHora(resp.Values.hora);
          setFecha(resp.Values.fecha_reservacion);
        })
        .catch((resp) => {
          console.log(resp);
        });
    }
  }, []);

  useEffect(() => {
    if (fecha != "") {
      setValues({
        ...Values,
        fecha_de_reservacion: ConvertDate(fecha),
        //hora_de_reserva: "",
      });
      setErrores({ ...Errores, fecha_de_reservacion: false });
    }
  }, [fecha]);

  useEffect(() => {
    //console.log("DATOS HORA: ", hora);
    if (hora != "") {
      setValues({ ...Values, hora_de_reserva: ConvertTime(hora) });
      setErrores({ ...Errores, hora_de_reserva: false });
    }
  }, [hora]);

  useEffect(() => {
    setValues({ ...Values, codigo: codigoDesc });
  }, [codigoDesc]);

  //#endregion

  //console.log("ID_COLABORADOR: ", Values.id_colaborador);
  const StyledSnackbar = styled((props) => <Snackbar {...props} />)(
    ({ theme }) => ({
      "& .MuiSnackbar-root": {
        top: theme.spacing(15),
      },
    })
  );
  return (
    <>
      <StyledSnackbar
        direction="right"
        open={openAlert}
        autoHideDuration={6000}
        onClose={handleClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      >
        <Alert onClose={handleClose} severity="error" sx={{ width: "100%" }}>
          {mensaje}
        </Alert>
      </StyledSnackbar>

      <Dialog fullWidth maxWidth="xl" open={true} style={{ minHeight: "80%" }}>
        <IconButton
          aria-label="delete"
          className="m-3"
          style={{ position: "absolute", right: "0%" }}
          onClick={() => {
            setIdReserv("0");
            setShowModalReserva(false);
            SetshowModalCliente(false);
            setFolioReservacion("0");
          }}
        >
          <CloseIcon size="lg" />
        </IconButton>
        <DialogTitle>
          <h6 className="fw-bolder f-12">
            {idReserv != "0"
              ? "Editar de reservación #" +
                folioReservacion.toString().padStart(5, "0")
              : "Agregar nueva reservación"}
          </h6>
        </DialogTitle>
        <DialogContent style={{ minHeight: "80%" }} className="">
          <div className="h-100 w-100">
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <Tabs
                value={value}
                onChange={handleChange}
                textColor="#212529"
                aria-label="basic tabs example"
              >
                <Tab
                  label="Productos y servicios"
                  className="font-AvenirBold"
                ></Tab>
                <Tab label="Reservación" className="font-AvenirBold" />
              </Tabs>
            </Box>

            {
              <>
                <div className={value == 0 ? "" : "d-none"}>
                  <DatosReservacion
                    productosCarrito={productosCarrito}
                    setProductosCarrito={setProductosCarrito}
                    Values={Values}
                    Errores={Errores}
                    handlInputChange={handlInputChange}
                    fecha={fecha}
                    setFecha={setFecha}
                    ErrorFecha={ErrorFecha}
                    setHora={setHora}
                    ErrorHora={ErrorHora}
                    handleShowC={handleShowC}
                    setShowModalReserva={setShowModalReserva}
                    setValue={setValue}
                    descuento={descuento}
                    setDescuento={setDescuento}
                    formasPago={formasPago}
                    setFormasPago={setFormasPago}
                    codigoDesc={codigoDesc}
                    setCodigoDesc={setCodigoDesc}
                  />
                </div>

                <div className={value == 0 ? "d-none" : ""}>
                  <Pago
                    productosCarrito={productosCarrito}
                    setProductosCarrito={setProductosCarrito}
                    Values={Values}
                    Errores={Errores}
                    handlInputChange={handlInputChange}
                    fecha={fecha}
                    setFecha={setFecha}
                    ErrorFecha={ErrorFecha}
                    hora={hora}
                    setHora={setHora}
                    ErrorHora={ErrorHora}
                    handleShowC={handleShowC}
                    setShowModalReserva={setShowModalReserva}
                    IsGuardando={IsGuardando}
                    GuardarReserva={GuardarReserva}
                    setDescuento={setDescuento}
                    descuento={descuento}
                    formasPago={formasPago}
                    setFormasPago={setFormasPago}
                    setValue={setValue}
                    setValuesReservacion={setValues}
                    config={config}
                    fechaMin={fechaMin}
                  />
                </div>
              </>
            }
            {/* {value == 0 ? (
              <DatosReservacion
                productosCarrito={productosCarrito}
                setProductosCarrito={setProductosCarrito}
                Values={Values}
                Errores={Errores}
                handlInputChange={handlInputChange}
                fecha={fecha}
                setFecha={setFecha}
                ErrorFecha={ErrorFecha}
                setHora={setHora}
                ErrorHora={ErrorHora}
                handleShowC={handleShowC}
                setShowModalReserva={setShowModalReserva}
                setValue={setValue}
                descuento={descuento}
                setDescuento={setDescuento}
                formasPago={formasPago}
                setFormasPago={setFormasPago}
                codigoDesc={codigoDesc}
                setCodigoDesc={setCodigoDesc}
              />
            ) : (
              <Pago
                productosCarrito={productosCarrito}
                setProductosCarrito={setProductosCarrito}
                Values={Values}
                Errores={Errores}
                handlInputChange={handlInputChange}
                fecha={fecha}
                setFecha={setFecha}
                ErrorFecha={ErrorFecha}
                setHora={setHora}
                ErrorHora={ErrorHora}
                handleShowC={handleShowC}
                setShowModalReserva={setShowModalReserva}
                IsGuardando={IsGuardando}
                GuardarReserva={GuardarReserva}
                setDescuento={setDescuento}
                descuento={descuento}
                formasPago={formasPago}
                setFormasPago={setFormasPago}
                setValue={setValue}
                setValuesReservacion={setValues}
                config={config}
              />
            )} */}
          </div>
        </DialogContent>

        {showModalCliente ? (
        <ModalCliente SetshowModalCliente={SetshowModalCliente} />

        
      ) : (
        ""
      )}
      </Dialog>




    </>
  );
}

export default Reservacion;
