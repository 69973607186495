import React from "react";
import { useState, useEffect } from "react";
import { getData } from "../../../Context/backend";
import Loading from "../../../Includes/Loading";

export default function PdfTableClientes() {
  const [base64Data, setBase64PDF] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    getData("ReportesPDF/catalogo/clientes")
      .then((data) => {
        //console.log(data);
        if (!data.error) {
          const base64 = data.data.respuesta.base64;
          setBase64PDF(base64);
        }
      })
      .catch((err) => {
        console.log(err);
      })
      .finally((f) => {
        console.log(f);
        setIsLoading(false);
      });
  }, []);

  return (
    <>
      {isLoading ? (
        <>
          <h6 className="text-center mt-5">
            Cargando reporte, por favor espere
          </h6>
          <div className="p-5 mt-3">
            <Loading />
          </div>
        </>
      ) : (
        <>
          {!base64Data ? (
            <div>
              No se pudo obtener el archivo PDF, revisa tu conexión a internet e
              intenta de nuevo
            </div>
          ) : (
            <object
              data={`data:application/pdf;base64,${base64Data}`}
              style={{ height: "100vh" }}
              type="application/pdf"
              width="100%"
              name="tabla"
            />
          )}
        </>
      )}
    </>
  );
}
