import React, { useEffect, useState } from "react";
import {
  Grid,
  Card,
  CardContent,
  TextField,
  Autocomplete,
  Typography,
  Tooltip,
} from "@mui/material";
import { Col, Row } from "react-bootstrap";
import Box from "@mui/material/Box";
import LinearProgress from "@mui/material/LinearProgress";
import { updateRadial } from "../../Funciones/Dashboard";
import SelectSucursal from "../../../Configuraciones/Sucursales/Componentes/SelectSucursal";
import {
  avatarLetters,
  formatMoneda,
} from "../../../Lib/Funciones Generales/Generales";

const getDateByNumber = (moth4Number, year) => {
  let moth;
  switch (moth4Number) {
    case 1:
      moth = "Enero";
      break;
    case 2:
      moth = "Febrero";
      break;
    case 3:
      moth = "Marzo";
      break;
    case 4:
      moth = "Abril";
      break;
    case 5:
      moth = "Mayo";
      break;
    case 6:
      moth = "Junio";
      break;
    case 7:
      moth = "Julio";
      break;
    case 8:
      moth = "Agosto";
      break;
    case 9:
      moth = "Septiembre";
      break;
    case 10:
      moth = "Octubre";
      break;
    case 11:
      moth = "Noviembre";
      break;
    case 12:
      moth = "Diciembre";
      break;
  }

  return moth + " - " + year;
};

var currentMoth = new Date();
currentMoth.setMonth(currentMoth.getMonth());
var oneMothAgo = new Date();
oneMothAgo.setMonth(oneMothAgo.getMonth() - 1);
var twoMothAgo = new Date();
twoMothAgo.setMonth(twoMothAgo.getMonth() - 2);
var treeMothAgo = new Date();
treeMothAgo.setMonth(treeMothAgo.getMonth() - 3);
var fourMothAgo = new Date();
fourMothAgo.setMonth(fourMothAgo.getMonth() - 4);
var fiveMothAgo = new Date();
fiveMothAgo.setMonth(fiveMothAgo.getMonth() - 5);
var sixMothAgo = new Date();
sixMothAgo.setMonth(sixMothAgo.getMonth() - 6);

const options = [
  getDateByNumber(currentMoth.getMonth() + 1, currentMoth.getFullYear()),
  getDateByNumber(oneMothAgo.getMonth() + 1, oneMothAgo.getFullYear()),
  getDateByNumber(twoMothAgo.getMonth() + 1, twoMothAgo.getFullYear()),
  getDateByNumber(treeMothAgo.getMonth() + 1, treeMothAgo.getFullYear()),
  getDateByNumber(fourMothAgo.getMonth() + 1, fourMothAgo.getFullYear()),
  getDateByNumber(fiveMothAgo.getMonth() + 1, fiveMothAgo.getFullYear()),
  getDateByNumber(sixMothAgo.getMonth() + 1, sixMothAgo.getFullYear()),
];

export default function ChartBar({
  titulo = "",
  color = "#005bd6",
  porcentajes,
  nombreBarras = { N1: "", N2: "", N3: "", N4: "" },
  nombreBarrasResponsive = { NR1: "", NR2: "", NR3: "", NR4: "" },
  type,
  cargado,
}) {
  const [value, setValue] = useState(options[0]);
  const [inputValue, setInputValue] = useState("");
  const [newData, setData] = useState(false);
  const [newValues, setNewValues] = useState({
    B1: 0,
    B2: 0,
    B3: 0,
    B4: 0,
  });
  const [total, setNewTotales] = useState({
    T1: 0,
    T2: 0,
    T3: 0,
    T4: 0,
  });

  const [Values, setValues] = useState({
    id_sucursal: "",
  });

  const handlInputChange = ({ target }) => {
    let Name = target.name;
    let Value = target.value;

    setValues({
      ...Values,
      [Name]: Value,
    });
  };

  const handlUpdateData = (datos) => {
    if (datos.length === 0) {
      setNewValues({
        B1: 0,
        B2: 0,
        B3: 0,
        B4: 0,
      });
      setNewTotales({
        T1: 0,
        T2: 0,
        T3: 0,
        T4: 0,
      });
    }

    if (datos.length === 1) {
      const [B1] = datos;
      setNewValues({
        B1: B1.CANTIDAD,
        B2: 0,
        B3: 0,
        B4: 0,
      });
      setNewTotales({
        T1: B1.TOTAL,
        T2: 0,
        T3: 0,
        T4: 0,
      });
    }

    if (datos.length === 2) {
      const [B1, B2] = datos;
      const sum = B1.CANTIDAD + B2.CANTIDAD;
      setNewValues({
        B1: (B1.CANTIDAD * 100) / sum,
        B2: (B2.CANTIDAD * 100) / sum,
        B3: 0,
        B4: 0,
      });
      setNewTotales({
        T1: B1.TOTAL,
        T2: B2.TOTAL,
        T3: 0,
        T4: 0,
      });
    }

    if (datos.length === 3) {
      const [B1, B2, B3] = datos;
      const sum = B1.CANTIDAD + B2.CANTIDAD + B3.CANTIDAD;
      setNewValues({
        B1: (B1.CANTIDAD * 100) / sum,
        B2: (B2.CANTIDAD * 100) / sum,
        B3: (B3.CANTIDAD * 100) / sum,
        B4: 0,
      });
      setNewTotales({
        T1: B1.TOTAL,
        T2: B2.TOTAL,
        T3: B3.TOTAL,
        T4: 0,
      });
    }
    if (datos.length === 4) {
      const [B1, B2, B3, B4] = datos;
      const sum = B1.CANTIDAD + B2.CANTIDAD + B3.CANTIDAD + B4.CANTIDAD;
      setNewValues({
        B1: (B1.CANTIDAD * 100) / sum,
        B2: (B2.CANTIDAD * 100) / sum,
        B3: (B3.CANTIDAD * 100) / sum,
        B4: (B4.CANTIDAD * 100) / sum,
      });
      setNewTotales({
        T1: B1.TOTAL,
        T2: B2.TOTAL,
        T3: B3.TOTAL,
        T4: B4.TOTAL,
      });
    }
  };

  useEffect(() => {
    if (cargado) {
      updateRadial(type, value).then((resp) => {
        if (resp?.err?.length == 0) {
        } else {
          setData(true);
          let datos = JSON.parse(resp.data);
          handlUpdateData(datos);
        }
      });
    }
  }, [value[0]]);

  useEffect(() => {
    if (cargado) {
      updateRadial(type, Values.id_sucursal).then((resp) => {
        if (resp?.err?.length == 0) {
        } else {
          setData(true);
          let datos = JSON.parse(resp.data);
          handlUpdateData(datos);
        }
      });
    }
  }, [Values.id_sucursal]);

  if (porcentajes == undefined) {
    return (
      <Box sx={{ width: "100%" }}>
        <LinearProgress />
      </Box>
    );
  }

  const arr = JSON.parse(porcentajes);
  porcentajes = arr ? arr : [];

  let B1, B2, B3, B4;
  let sum = 0;

  if (porcentajes.length == 4) {
    [B1, B2, B3, B4] = porcentajes;
    sum = B1.CANTIDAD + B2.CANTIDAD + B3.CANTIDAD + B4.CANTIDAD;
  }
  if (porcentajes.length === 3) {
    [B1, B2, B3] = porcentajes;
    sum = B1.CANTIDAD + B2.CANTIDAD + B3.CANTIDAD;
    B4 = { CANTIDAD: 0 };
  }

  if (porcentajes.length == 2) {
    [B1, B2] = porcentajes;
    sum = B1.CANTIDAD + B2.CANTIDAD;
    B3 = { CANTIDAD: 0 };
    B4 = { CANTIDAD: 0 };
  }
  if (porcentajes.length == 1) {
    [B1] = porcentajes;
    sum = B1.CANTIDAD;
    B2 = { CANTIDAD: 0 };
    B3 = { CANTIDAD: 0 };
    B4 = { CANTIDAD: 0 };
  }
  if (porcentajes.length == 0) {
    B1 = { CANTIDAD: 0 };
    B2 = { CANTIDAD: 0 };
    B3 = { CANTIDAD: 0 };
    B4 = { CANTIDAD: 0 };
  }

  const style = {
    fontSize: "11px",
  };

  return (
    <>
      <Card className="shadow-sm" elevation={3} style={{ height: "491px" }}>
        <CardContent className="p-0">
          <div className="p-3">
            <h6 className="mb-0 text-left font-AvenirBold">{titulo}</h6>
          </div>
          <hr
            style={{
              backgroundColor: "#DFDEE0",
              height: "1px",
              opacity: "1",
            }}
            className="m-0 p-0"
          />
          <div lassName="p-3 m-3">
            <Row className="p-1 m-2">
              <Col>
                <Row xs={4} sm={4} md={4}>
                  <Grid
                    container
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                    rowSpacing={0}
                    //columnSpacing={{ xs: 0, sm: 2, md: 3 }}
                    style={{ background: "" }}
                  >
                    <Tooltip
                      placement="right"
                      title={
                        newData === true ? total.T1 : formatMoneda(B1.TOTAL)
                      }
                    >
                      <div className="barcontainer">
                        <div
                          className="bar"
                          style={{
                            height:
                              newData === true
                                ? `${newValues.B1}%`
                                : `${
                                    (B1.CANTIDAD * 100) / sum > 0
                                      ? (B1.CANTIDAD * 100) / sum
                                      : 0
                                  }%`,
                            backgroundColor: color,
                            maxHeight: "100%",
                          }}
                        ></div>
                      </div>
                    </Tooltip>

                    <Tooltip
                      placement="right"
                      title={newData === true ? total.T2 : B2.TOTAL}
                    >
                      <div className="barcontainer">
                        <div
                          className="bar"
                          style={{
                            height:
                              newData === true
                                ? `${newValues.B2}%`
                                : `${
                                    (B2.CANTIDAD * 100) / sum > 0
                                      ? (B2.CANTIDAD * 100) / sum
                                      : 0
                                  }%`,
                            backgroundColor: color,
                            maxHeight: "100%",
                          }}
                        ></div>
                      </div>
                    </Tooltip>

                    <Tooltip
                      placement="right"
                      title={newData === true ? total.T3 : B3.TOTAL}
                    >
                      <div className="barcontainer">
                        <div
                          className="bar"
                          style={{
                            height:
                              newData === true
                                ? `${newValues.B3}%`
                                : `${
                                    (B3.CANTIDAD * 100) / sum > 0
                                      ? (B3.CANTIDAD * 100) / sum
                                      : 0
                                  }%`,
                            backgroundColor: color,
                            maxHeight: "100%",
                          }}
                        ></div>
                      </div>
                    </Tooltip>

                    <Tooltip
                      placement="right"
                      title={newData === true ? total.T4 : B4.TOTAL}
                    >
                      <div className="barcontainer">
                        <div
                          className="bar"
                          style={{
                            height:
                              newData === true
                                ? `${newValues.B4}%`
                                : `${
                                    (B4.CANTIDAD * 100) / sum > 0
                                      ? (B4.CANTIDAD * 100) / sum
                                      : 0
                                  }%`,
                            backgroundColor: color,
                            maxHeight: "100%",
                          }}
                        ></div>
                      </div>
                    </Tooltip>
                  </Grid>
                </Row>
                <Row xs={3} sm={3} md={3}>
                  <Grid
                    container
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                    rowSpacing={0}
                    style={{
                      background: "",
                    }}
                  >
                    <Typography
                      className="  justify-content-center ms-auto me-auto"
                      style={style}
                    >
                      {B1.NOMBRE === undefined
                        ? nombreBarras.N1
                        : avatarLetters(B1.NOMBRE)}
                    </Typography>
                    <Typography
                      className="  justify-content-center ms-auto me-auto"
                      style={style}
                    >
                      {B2.NOMBRE === undefined
                        ? nombreBarras.N2
                        : avatarLetters(B2.NOMBRE)}
                    </Typography>
                    <Typography
                      className="  justify-content-center ms-auto me-auto"
                      style={style}
                    >
                      {B3.NOMBRE === undefined
                        ? nombreBarras.N3
                        : avatarLetters(B3.NOMBRE)}
                    </Typography>
                    <Typography
                      className="  justify-content-center ms-auto me-auto"
                      style={style}
                    >
                      {B4.NOMBRE === undefined
                        ? nombreBarras.N4
                        : avatarLetters(B4.NOMBRE)}
                    </Typography>
                  </Grid>
                </Row>
              </Col>
            </Row>
            <Row className="p-1 m-2">
              <Col>
                <Grid
                  container
                  direction="column"
                  justifyContent="center"
                  alignItems="center"
                >
                  {type === 1 ? (
                    <SelectSucursal
                      Value={Values.id_sucursal}
                      handlInputChange={handlInputChange}
                    />
                  ) : (
                    <Autocomplete
                      value={value}
                      onChange={(event, newValue) => {
                        setValue(newValue);
                      }}
                      inputValue={inputValue}
                      onInputChange={(event, newInputValue) => {
                        setInputValue(newInputValue);
                      }}
                      id="controllable-states-demo"
                      options={options}
                      sx={{ width: "100%" }}
                      renderInput={(params) => (
                        <TextField {...params} label="Rango de Fechas" />
                      )}
                      disableClearable={true}
                    />
                  )}
                </Grid>
              </Col>
              {/*<button
                onClick={() => {
                  setCont(cont + 4);
                }}
              >
                +1
              </button>
              <button
                onClick={() => {
                  setCont(0);
                }}
              >
                Reset
              </button>*/}
            </Row>
          </div>
        </CardContent>
      </Card>
    </>
  );
}

//grid item
/*

<Grid
                      item
                      container
                      direction="column"
                      justifyContent="center"
                      alignItems="center"
                      xs={3}
                      className=""
                      style={{
                        background: "red",
                        //marginRight: "10px",
                      }}
                    ></Grid>
                    <Grid
                      item
                      xs={3}
                      className=""
                      style={{ background: "yellow" }}
                    >
                      <div
                        className="progress"
                        style={{
                          width: "200px",
                          transform: "rotate(-90deg)",
                          marginBottom: "100px",
                          marginTop: "100px",
                        }}
                      >
                        <div
                          className="progress-bar "
                          role="progressbar"
                          style={{ width: cont + "%" }}
                        ></div>
                      </div>
                    </Grid>
                    <Grid
                      item
                      xs={3}
                      className=""
                      style={{ background: "blue" }}
                    >
                      <div
                        className="progress"
                        style={{
                          width: "200px",
                          transform: "rotate(-90deg)",
                          marginBottom: "100px",
                          marginTop: "100px",
                        }}
                      >
                        <div
                          className="progress-bar "
                          role="progressbar"
                          style={{ width: cont + "%" }}
                        ></div>
                      </div>
                    </Grid>
                    <Grid
                      item
                      xs={3}
                      className=""
                      style={{ background: "green" }}
                    >
                      <div
                        className="progress"
                        style={{
                          width: "200px",
                          transform: "rotate(-90deg)",
                          marginBottom: "100px",
                          marginTop: "100px",
                        }}
                      >
                        <div
                          className="progress-bar "
                          role="progressbar"
                          style={{ width: cont + "%" }}
                        ></div>
                      </div>
                    </Grid>

*/

//no se
/*<div
                        className="progress"
                        style={{
                          width: "200px",
                          transform: "rotate(-90deg)",
                          marginBottom: "100px",
                          marginTop: "100px",
                          marginRight: "100px ",
                        }}
                      >
                        <div
                          className="progress-bar "
                          role="progressbar"
                          style={{ width: cont + "%" }}
                        ></div>
                      </div>*/
