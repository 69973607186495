import { useJsApiLoader } from '@react-google-maps/api';
import { useEffect } from 'react';
import { useState } from 'react';
import { useCallback } from 'react';

const Mapa = ({
    clickMapa,
    obtenerPointByAddress,
    centerMap = { lat: 20.661724, lng: -103.349921 },
    zoomMap,
    ListaPuntos,
    setListaPuntos
}) => {


    const { isLoaded } = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: "AIzaSyDgKnIbq4vYdOeYR2ruvmtT-StPw5RpFyI",
        libraries: ['places']
    })
    const [Zoom, SetZoom] = useState({ init: false, level: 0 })
    const [Map, setMap] = useState(null)

    const onLoad = useCallback(function callback(mapCallback) {
        const bounds = new window.google.maps.LatLngBounds(centerMap);
        mapCallback.fitBounds(bounds);
        setMap(mapCallback)
    }, [])

    const onUnmount = useCallback(function callback(mapCallback) {
        setMap(null)
    }, [])

    const onMapClick = (...props) => {
        const { lat, lng } = props[0].latLng
        clickMapa(lat(), lng())
    }

    const buscarAddress = (address) => {
        const geocoder = new window.google.maps.Geocoder()
        geocoder.geocode({ address }, function (results, status) {
            if (status == window.google.maps.GeocoderStatus.OK) {
                obtenerPointByAddress(results[0].geometry.location.lat(), results[0].geometry.location.lng())
            } else {
                obtenerPointByAddress(0, 0)
            }
        });
    }


    const crearMarker = ({ lat, lng }) => {

        if (ListaPuntos.length != 0 && window.indexPunto != ListaPuntos.length) {
            const ListaPuntosMap = []
            ListaPuntos.map((punto, index) => {
                if (window.indexPunto == index) {
                    ListaPuntosMap.push({ lat, lng })
                }
                ListaPuntosMap.push(punto)
            })
            setListaPuntos([...ListaPuntosMap])
        }
        else {
            if (window.indexPunto == ListaPuntos.length) {
                window.indexPunto = ListaPuntos.length + 1
            }
            setListaPuntos([...ListaPuntos, { lat, lng }])
        }

    }

    const actualizarMarker = (index, lat, lng) => {
        console.log("ACTUALIZANDO")
        ListaPuntos[index] = { lat, lng }
        setListaPuntos([...ListaPuntos])
    }

    const eliminarMarker = (index) => {
        window.indexPunto = index
        setListaPuntos([...(ListaPuntos.filter((x, i) => i !== index))])
    }

    const moverCamera = (lat, lng, zoom = zoomMap) => {
        Map.moveCamera({
            tilt: 0,
            heading: 0,
            zoom: zoom,
            center: { lat, lng }
        })
    }

    useEffect(() => {
        if (Map) {
            setTimeout(() => {

                // if (ListaPuntos) {
                //     if (ListaPuntos.length > 0) {
                //         Map.moveCamera({
                //             tilt: 0,
                //             heading: 0,
                //             zoom: zoomMap,
                //             center: { lat: ListaPuntos[0].lat, lng: ListaPuntos[0].lng }
                //         })
                //     }
                // }
                SetZoom({ init: true, level: zoomMap })
                // console.log(ListaPuntos)
            }, 1000)
        }
    }, [Map])

    return {
        onUnmount,
        onMapClick,
        onLoad,
        isLoaded,
        ListaPuntos,
        crearMarker,
        actualizarMarker,
        eliminarMarker,
        buscarAddress,
        moverCamera,
        Zoom: Zoom.level,
        ZoomInit: Zoom.init,
        Map
    }
}

export default Mapa