import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import PDFComisiones from "../Catalogos/Colaboradores/Reportes/Componentes/PDFComisiones";
import Error404 from "../Includes/Error404";
import PDFReportes from "../Reservaciones/Reporte/PDFReportes";
import PdfTableClientes from "../Catalogos/Clientes/Reportes/PdfTableClientes";
import ProductosPDF from "../Catalogos/Productos/Reportes/ProductosPDF";
import PdfTableColaboradores from "../Catalogos/Colaboradores/Reportes/PdfTableColaboradores";

const ReportesPDF = () => {
  return (
    <Routes>
      <Route
        path="reservaciones/:ids/:fecha1/:fecha2"
        element={<PDFReportes />}
      ></Route>
      <Route
        path="comisiones/:ids/:idcola/:fecha1/:fecha2"
        element={<PDFComisiones />}
      ></Route>
      <Route path="catalogo/clientes/" element={<PdfTableClientes />} />
      <Route path="catalogo/productos/" element={<ProductosPDF />} />
      <Route
        path="catalogo/colaboradores/"
        element={<PdfTableColaboradores />}
      />
      <Route path="/*" element={<Error404 />} />
    </Routes>
  );
};
export default ReportesPDF;
