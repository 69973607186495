import {
  Button,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
} from "@mui/material";
import { BasicTimePicker } from "../../../../../Includes/TimePicker";
import {
  ConvertDate,
  ConvertTime,
  GetDate,
} from "../../../../../Lib/Funciones Generales/ObtenerFecha";
import { BasicDatePicker } from "../../../../../Includes/DatePicker";
import { crearDia, Eliminar, validarHorario } from "../funciones/Horarios";
import { Col, Row } from "react-bootstrap";
import Switch from "../../../../../Includes/Switch";

//import AccEliminar from "../../../../assets/AccEliminar.svg";
import Loading from "../../../../../Includes/Loading";
import AddIcon from "@mui/icons-material/Add";
import HelperError from "../../../../../Includes/HelperError";
import Swal from "sweetalert2";
import { useEffect } from "react";

const dias = [
  "Lunes",
  "Martes",
  "Miercoles",
  "Jueves",
  "Viernes",
  "Sábado",
  "Domingo",
];

const RepHorarios = ({ FechaEvento, Horarios = [], SetHorarios }) => {
  const setDate = (IndexHorario, fecha, name) => {
    Horarios[IndexHorario][name] = ConvertDate(fecha);
    //validarHorario(Horarios, FechaEvento, Horarios[IndexHorario], IndexHorario);
    SetHorarios([...Horarios]);
  };

  useEffect(() => {
    //console.log(Horarios);
  });

  const setTime = (IndexHorario, hora, name) => {
    Horarios[IndexHorario][name] = ConvertTime(hora);
    console.log("NUEVO HORARIO: ", Horarios, IndexHorario, hora, name)
    validarHorario(Horarios, FechaEvento, Horarios[IndexHorario], IndexHorario);
    SetHorarios([...Horarios]);
  };

  const onChange = (checked, id) => {
    //console.log(id, checked);
    const arrNew = Horarios.filter((item, i) => {
      if (i == id) {
        item.ACTIVO = checked;
      }
      return item;
    });

    SetHorarios(arrNew);

    //console.log(arrNew);
  };
  useEffect(() => {
    //console.log(Horarios);
  });
  console.log("HORARIOS: ", Horarios)

  return (
    <Row className="p-3">
      <Col sm={12}>
        <TableContainer
          component={Paper}
          style={{ border: "0px", boxShadow: "none" }}
        >
          <Table aria-label="custom pagination table">
            <TableHead>
              <TableRow>
                <TableCell className="font-Avenir font-AvenirBold" align="left">
                  DÍA
                </TableCell>

                <TableCell className="font-Avenir font-AvenirBold" align="left">
                  ESTADO
                </TableCell>

                <TableCell className="font-Avenir font-AvenirBold" align="left">
                  HORARIO DE APERTURA
                </TableCell>

                <TableCell className="font-Avenir font-AvenirBold" align="left">
                  HORARIO DE CIERRE
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {Horarios?.length != 0 ? (
                Horarios?.map((horario, IndexHorario) => {
                  const ErrorHorario =
                    horario.ERROR_HORARIO || horario.error ? true : false;
                  const ErrorInicio =
                    horario.ERROR_INICIO || horario.error ? true : false;
                  const ErrorFin =
                    horario.ERROR_FIN || horario.error ? true : false;

                  return (
                    <TableRow key={IndexHorario}>
                      <TableCell component="th" scope="row" align="left">
                        {dias[IndexHorario]}
                      </TableCell>

                      <TableCell component="th" scope="row" align="left">
                        <Switch
                          checked={horario.ACTIVO}
                          onChange={onChange}
                          id={IndexHorario}
                          tituloActivo="Desactivar Horario "
                          tituloInactivo="Activar Horario"
                          on
                        />
                      </TableCell>

                      <TableCell component="th" scope="row" align="left">
                        <BasicTimePicker
                          label="Inicio"
                          hora={horario.INICIO}
                          setTime={(hora) => {
                            setTime(IndexHorario, hora, "INICIO");
                          }}
                          disabledd={!horario.ACTIVO}
                        />
                        {
                          <HelperError
                            Error={ErrorInicio}
                            Mensaje={
                              horario.ACTIVO ? horario.MENSAJE_INICIO : ""
                            }
                          />
                        }
                      </TableCell>

                      <TableCell component="th" scope="row" align="left">
                        <BasicTimePicker
                          label="Fin"
                          hora={horario.FIN}
                          setTime={(hora) => {
                            setTime(IndexHorario, hora, "FIN");
                          }}
                          disabledd={!horario.ACTIVO}
                        />
                        {
                          <HelperError
                            Error={ErrorFin}
                            Mensaje={
                              horario.ACTIVO ? horario.MENSAJE_INICIO : ""
                            }
                          />
                        }
                      </TableCell>
                    </TableRow>
                  );
                })
              ) : (
                <TableRow>
                  <TableCell component="th" scope="row" colSpan={5}>
                    Sin horarios
                  </TableCell>
                </TableRow>
              )}
              <TableRow style={{ border: "0px" }}></TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Col>
    </Row>
  );
};

export default RepHorarios;
