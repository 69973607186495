import { stringify } from "query-string";
import validator from "validator";

import { getData, postdData, postUrl } from "../../../Context/backend";

///////////////////
/* FUNCIONES GET */
///////////////////
export const getPuestos = async (iTake, iSkip, Order, query) => {
  let data = [];
  let queryParamsObj = {
    iTake: iTake,
    iSkip: iSkip,
    Order: Order,
    query: query,
  };

  const url = `puestos/Listar?${stringify(queryParamsObj)}`;
  const res = await getData(url);
  return new Promise((resolve, reject) => {
    if (!res.error) {
      data = res.data.respuesta;
      resolve(data);
    } else {
      reject(res);
    }
  });
};

export const getOpciones = async (query = "") => {
  let data = [];
  let queryParamsObj = {
    query: query,
  };
  const url = `puestos/getOpciones?${stringify(queryParamsObj)}`;
  const res = await getData(url);
  return new Promise((resolve, reject) => {
    if (!res.error) {
      data = res.data.respuesta;
      resolve(data);
    } else {
      reject(res);
    }
  });
};

export const guardarPuesto = async (Values, Errores, setErrores) => {
  let error = false;

  if (!Values.nombre || Values.nombre == "") {
    Errores.nombre = true;
    setErrores(Errores);
    error = true;
  }

  if (
    !Values.sueldo_diario ||
    Values.sueldo_diario == "" ||
    Values.sueldo_diario == "0" ||
    Values.sueldo_diario == 0 ||
    Number(Values.sueldo_diario) < 0
  ) {
    Values.sueldo_diario = "0";
    /*Errores.sueldo_diario = true;
    setErrores(Errores);
    error = true;*/
  }

  if (
    !Values.comision ||
    Values.comision == "" ||
    Values.comision === "0" ||
    Values.comision === 0 ||
    Number(Values.comision) < 0 ||
    Number(Values.comision) > 100
  ) {
    Errores.comision = true;
    setErrores(Errores);
    error = true;
  }

  if (!error) {
    let data = [];
    const url =
      Values.id == undefined || Values.id == "" || Values.id == 0
        ? "puestos/Insertar"
        : "puestos/Editar";

    const body = {
      id: Values.id,
      nombre: Values.nombre,
      descripcion: Values.descripcion,
      sueldO_DIARIO: Number(Values.sueldo_diario),
      comision: Number(Values.comision),
    };
    const res =
      /*{ error: false, data: { codigo: "2000", mensaje: "correcto" } };*/ await postdData(
        url,
        body
      );

    return new Promise((resolve, reject) => {
      if (!res.error) {
        data = res.data;
        resolve(data);
      } else {
        reject(res);
      }
    });
  } else {
    return new Promise((resolve, reject) => {
      resolve({ mensaje: "Completa todos los campos requeridos" });
    });
  }
};

export const eliminarPuesto = async (id) => {
  if (id != undefined) {
    let data = [];
    let queryParamsObj = {
      id: id,
    };
    const url = `puestos/Eliminar?${stringify(queryParamsObj)}`;
    const res = await postdData(url);
    return new Promise((resolve, reject) => {
      if (!res.error) {
        data = res.data;
        resolve(data);
      } else {
        reject(res);
      }
    });
  } else {
    return new Promise((resolve, reject) => {
      resolve({ mensaje: "No se pudo obtener el identificador" });
    });
  }
};

export const getInfPuesto = async (id) => {
  let data = [];
  let queryParamsObj = {
    ID: id,
  };
  const url = `puestos/Consultar?${stringify(queryParamsObj)}`;
  const res = await getData(url);
  return new Promise((resolve, reject) => {
    if (!res.error) {
      data = JSON.parse(res.data.respuesta.data);
      //console.log(data);
      if (data.length == 0) {
        resolve({
          err: [],
          mensaje: "No se encontró información de este puesto",
        });
      } else {
        data = JSON.parse(res.data.respuesta.data)[0];
        let Value = {
          id: String(data.ID),
          nombre: String(data.NOMBRE),
          descripcion: String(data.DESCRIPCION),
          sueldo_diario: String(data.SUELDO_DIARIO),
          comision: String(data.COMISION),
          //extras: String(data.SUELDO_HORAS_EXTRA),
          //jornada: String(data.JORNADA_LABORAL),
        };
        resolve(Value);
      }
    } else {
      reject({
        mensaje: "Error al conectar a internet, revisa tu conexion a internet",
      });
    }
  });
};

export const Desactivar = async (id, checked) => {
  //return "ok";
  if (id != undefined) {
    let data = [];
    let queryParamsObj = {
      id: id,
      activo: checked,
    };
    const url = `puestos/Desactivar?${stringify(queryParamsObj)}`;
    const res = await postUrl(url);
    return new Promise((resolve, reject) => {
      if (!res.error) {
        data = res.data;
        resolve(data);
      } else {
        reject(res);
      }
    });
  } else {
    return new Promise((resolve, reject) => {
      resolve({ mensaje: "No se pudo obtener el identificador" });
    });
  }
};
