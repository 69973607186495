import {
  Alert,
  AlertTitle,
  Avatar,
  Button,
  Card,
  CardContent,
  Snackbar,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useNavigate } from "react-router-dom";
import { guardar, getInfoUsuario, getSucursales } from "../Funciones/Usuarios";
import Loading from "../../../Includes/Loading";
import LoadingButton from "@mui/lab/LoadingButton";
import { styled } from "@mui/material/styles";
import { useParams } from "react-router-dom";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import {
  ConvertDate,
  GetDate,
} from "../../../Lib/Funciones Generales/ObtenerFecha";
import ModalCargarImagen from "../../../Lib/Imagen/Componentes/ModalCargarImagen";
import AccEditarImagen from "./../../../assets/EditarImagen.svg";
import { IMG } from "../../../Lib/Funciones Generales/Constantes";
import "../../../Lib/Funciones Generales/Prototipos";
import SelectSucursal from "../../Sucursales/Componentes/SelectSucursal";
import SelectGrupoAcceso from "../../gruposacceso/Componentes/SelectGrupoAcceso";
import Switch from "../../../Includes/Switch";

const MySwal = withReactContent(Swal);

const Usuario = () => {
  // Definicion de variables para la carga de imagenes
  const [openModal, setOpenModal] = React.useState(false);
  const handleOpenModal = () => setOpenModal(true);
  const handleCloseModal = () => setOpenModal(false);
  const fnDevolverImagen = (img) => {
    setValues({ ...Values, img: img });
  };

  const [sucursales, setSucursales] = useState([]);
  const [isAll, setIsAll] = useState(false);

  //Definicion de variables para el formulario
  const { id } = useParams();
  const navigate = useNavigate();
  const [IsLoading, setIsLoading] = useState(true);
  const [IsGuardando, setIsGuardando] = useState(false);
  const [open, setOpen] = useState(false);
  const [mensaje, setMensaje] = useState("");
  const [Values, setValues] = useState({
    id: id ?? "0",
    // activo: "1",
    nombre: "",
    apellido_paterno: "",
    apellido_materno: "",
    correo: "",
    telefono: "",
    img: AccEditarImagen,
    id_sucursal: 0,
    id_grupo: 0,
  });

  const [Errores, setErrores] = useState({
    nombre: false,
    apellido_paterno: false,
    apellido_materno: false,
    correo: false,
    telefono: false,
    id_grupo: false,
  });

  const handleClose = () => {
    setOpen(false);
  };

  const handlInputChange = ({ target }) => {
    let Name = target.name;
    let Value = target.value ? target.value : "";

    if (Name == "telefono") {
      Value = Value.toNumber();
    }

    setValues({
      ...Values,
      [Name]: Value,
    });
    setErrores({
      ...Errores,
      [Name]: false,
    });
  };

  useEffect(() => {
    setIsLoading(true);

    if (id != undefined) {
      getInfoUsuario(id, AccEditarImagen)
        .then((resp) => {
          if (resp?.err?.length == 0) {
            MySwal.fire({
              title: "Error",
              html: resp.mensaje,
              icon: "error",
              confirmButtonColor: "#3ABE88",
              showConfirmButton: true,
              allowEscapeKey: false,
              allowEnterKey: false,
              allowOutsideClick: false,
            }).then(() => {
              setIsLoading(false);
              navigate(-1);
            });
          } else {
            setValues(resp);
            setIsLoading(false);
          }
        })
        .catch((resp) => {
          MySwal.fire({
            title: "Error",
            html: resp.mensaje,
            icon: "error",
            confirmButtonColor: "#3ABE88",
            showConfirmButton: true,
            allowEscapeKey: false,
            allowEnterKey: false,
            allowOutsideClick: false,
          }).then(() => {
            setIsLoading(false);
            navigate(-1);
          });
          //setIsLoading(false);
        });
    } else {
      setIsLoading(false);
    }

    getSucursales(id == undefined ? 0 : id)
      .then((resp) => {
        if (resp?.err?.length == 0) {
          setSucursales([]);
        } else {
          setSucursales(JSON.parse(resp.data));
        }
      })
      .catch((resp) => {
        setSucursales([]);
      });
  }, []);

  const GuardarEmpledo = () => {
    setIsGuardando(true);
    guardar(Values, Errores, setErrores, sucursales)
      .then((data) => {
        if (data.codigo == "200") {
          setIsGuardando(false);
          MySwal.fire({
            title: "Correcto",
            html: data.mensaje,
            icon: "success",
            confirmButtoColor: "#3ABE88",
            showConfirmButton: false,
            timer: 1700,
          }).then((result) => {
            navigate(-1);
          });
        } else {
          setMensaje(data.mensaje);
          setOpen(true);
          setIsGuardando(false);
        }
      })
      .catch((data) => {
        // alert(data.mensaje);
        setMensaje(data.mensaje);
        setOpen(true);
        setIsGuardando(false);
      });
  };

  const StyledSnackbar = styled((props) => <Snackbar {...props} />)(
    ({ theme }) => ({
      "& .MuiSnackbar-root": {
        top: theme.spacing(15),
      },
    })
  );

  const img_url = Values.img == AccEditarImagen ? AccEditarImagen : Values.img;

  return (
    <>
      <StyledSnackbar
        direction="right"
        open={open}
        autoHideDuration={6000}
        onClose={handleClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      >
        <Alert onClose={handleClose} severity="error" sx={{ width: "100%" }}>
          {mensaje}
        </Alert>
      </StyledSnackbar>
      <Button
        onClick={() => navigate(-1)}
        className="btn  font-AvenirBold my-4 text-star ps-0 pe-4  "
        variant="text"
      >
        <ArrowBackIcon className="me-3" />
        Usuarios del sistema
      </Button>
      <Card elevation={3} className="mb-4">
        <CardContent className="p-0">
          <div className="p-3">
            <h6 className="mb-0 text-left font-AvenirBold">
              {id ? "Editar usuario del sistema" : "Nuevo usuario del sistema"}
            </h6>
          </div>
          <hr
            style={{ backgroundColor: "#DFDEE0", height: "1px", opacity: "1" }}
            className="m-0 p-0"
          />

          {IsLoading ? (
            <Row className="px-3">
              <Col sm={12} className="p-3">
                <Loading />
              </Col>
            </Row>
          ) : (
            <Row className="p-3">
              <Col
                sm={12}
                md={12}
                lg={4}
                className="d-flex flex-wrap align-items-center justify-content-center"
              >
                <Avatar
                  className="shadow"
                  sx={{ width: 120, height: 120 }}
                  src={img_url}
                />
                <strong
                  className="w-100 text-center px-0 pt-3"
                  style={{ color: "#3ABE88", cursor: "pointer" }}
                  onClick={handleOpenModal}
                >
                  Editar foto
                </strong>
              </Col>
              <Col sm={12} md={4} className="p-3 mt-0 mt-md-5">
                <TextField
                  fullWidth
                  inputProps={{ maxLength: 250 }}
                  name="nombre"
                  label="Nombre"
                  variant="outlined"
                  value={Values.nombre}
                  error={Errores.nombre}
                  helperText={Errores.nombre ? "Nombre no valido" : ""}
                  onChange={handlInputChange}
                />
              </Col>
              <Col sm={12} md={4} className="p-3 mt-0 mt-md-5">
                <TextField
                  fullWidth
                  inputProps={{ maxLength: 250 }}
                  name="apellido_paterno"
                  label="Apellido paterno"
                  variant="outlined"
                  value={Values.apellido_paterno}
                  error={Errores.apellido_paterno}
                  helperText={
                    Errores.apellido_paterno ? "Apellido paterno no valido" : ""
                  }
                  onChange={handlInputChange}
                />
              </Col>
              <Col sm={12} md={4} className="p-3">
                <TextField
                  fullWidth
                  inputProps={{ maxLength: 250 }}
                  name="apellido_materno"
                  label="Apellido materno"
                  variant="outlined"
                  value={Values.apellido_materno}
                  error={Errores.apellido_materno}
                  helperText={
                    Errores.apellido_materno ? "Apellido materno no valido" : ""
                  }
                  onChange={handlInputChange}
                />
              </Col>

              <Col sm={12} md={4} className="p-3">
                <TextField
                  fullWidth
                  inputProps={{ maxLength: 250 }}
                  name="correo"
                  label="Correo electrónico"
                  variant="outlined"
                  helperText={Errores.correo ? "Correo incorrecto" : ""}
                  value={Values.correo}
                  error={Errores.correo}
                  onChange={handlInputChange}
                />
              </Col>

              <Col sm={12} md={4} className="p-3">
                <TextField
                  fullWidth
                  name="telefono"
                  label="Teléfono"
                  variant="outlined"
                  inputProps={{ maxLength: 10 }}
                  helperText={Errores.telefono ? "Teléfono inválido" : ""}
                  value={Values.telefono}
                  error={Errores.telefono}
                  onChange={handlInputChange}
                />
              </Col>

              {/*
            
            <Col sm={12} md={4} className="p-3">
                <SelectSucursal
                  Value={Values.id_sucursal}
                  Error={Errores.id_sucursal}
                  handlInputChange={handlInputChange}
                />
              </Col>
            */}

              <Col sm={12} md={4} className="p-3">
                <SelectGrupoAcceso
                  Value={Values.id_grupo}
                  Error={Errores.id_grupo}
                  handlInputChange={handlInputChange}
                ></SelectGrupoAcceso>
              </Col>

              <Col sm={12} className="p-3">
                <div className="row">
                  <div className="col-12 col-md-7 ">
                    <h6
                      lassName="font-Avenir mt-0 p-0"
                      style={{ fontSize: "13px" }}
                    >
                      Selecciona las sucursales a las cual éste usuario tendrá
                      acceso
                    </h6>
                  </div>

                  <div className="col-12 col-md-5 text-end ">
                    <Typography className="w-100 ">
                      {"Seleccionar todos"}
                      <Switch
                        tituloActivo="Desactivar"
                        tituloInactivo="Activar"
                        id={0}
                        checked={isAll}
                        onChange={(checked) => {
                          const resultado = sucursales.map((objeto) => {
                            return { ...objeto, ACTIVO: checked ? 1 : 0 };
                          });
                          setSucursales([...resultado]);
                          setIsAll(checked);
                        }}
                      />
                    </Typography>
                  </div>
                  <div className="col-12 mt-2 border-bottom"></div>

                  <div className="col-12 col-md-6 col-lg-4">
                    {sucursales.map((prod, index) => {
                      if (index % 3 === 0) {
                        return (
                          <div className="d-flex justify-content-between border-bottom">
                            <Typography>{prod.DESCRIPCION}</Typography>
                            <Switch
                              checked={prod.ACTIVO == 1}
                              tituloActivo="Desactivar"
                              tituloInactivo="Activar"
                              id={prod.ID}
                              onChange={(checked) => {
                                const indice = sucursales.findIndex(
                                  (objeto) => objeto.ID === prod.ID
                                );
                                if (indice !== -1) {
                                  sucursales[indice].ACTIVO = checked ? 1 : 0;
                                  setSucursales([...sucursales]);
                                }
                              }}
                            />
                          </div>
                        );
                      }
                      return null;
                    })}
                  </div>
                  <div className="col-12 col-md-6 col-lg-4">
                    {sucursales.map((prod, index) => {
                      if (index % 3 === 1) {
                        return (
                          <div className="d-flex justify-content-between border-bottom">
                            <Typography>{prod.DESCRIPCION}</Typography>
                            <Switch
                              checked={prod.ACTIVO == 1}
                              tituloActivo="Desactivar"
                              tituloInactivo="Activar"
                              id={prod.ID}
                              onChange={(checked) => {
                                const indice = sucursales.findIndex(
                                  (objeto) => objeto.ID === prod.ID
                                );
                                if (indice !== -1) {
                                  sucursales[indice].ACTIVO = checked ? 1 : 0;
                                  setSucursales([...sucursales]);
                                }
                              }}
                            />
                          </div>
                        );
                      }
                      return null;
                    })}
                  </div>
                  <div className="col-12 col-md-6 col-lg-4">
                    {sucursales.map((prod, index) => {
                      if (index % 3 === 2) {
                        return (
                          <div className="d-flex justify-content-between border-bottom">
                            <Typography>{prod.DESCRIPCION}</Typography>
                            <Switch
                              checked={prod.ACTIVO == 1}
                              tituloActivo="Desactivar"
                              tituloInactivo="Activar"
                              id={prod.ID}
                              onChange={(checked) => {
                                const indice = sucursales.findIndex(
                                  (objeto) => objeto.ID === prod.ID
                                );
                                if (indice !== -1) {
                                  sucursales[indice].ACTIVO = checked ? 1 : 0;
                                  setSucursales([...sucursales]);
                                }
                              }}
                            />
                          </div>
                        );
                      }
                      return null;
                    })}
                  </div>
                </div>
              </Col>

              <Col sm={12} className="p-3">
                {Values.id == 0 ? (
                  <>
                    <Alert severity="info">
                      <AlertTitle>Mensaje</AlertTitle>
                      Se enviará una contraseña temporal vía correo electrónico
                    </Alert>
                  </>
                ) : (
                  <></>
                )}
              </Col>
            </Row>
          )}
          <Stack
            className="p-3"
            spacing={2}
            direction={{ xs: "column", sm: "row" }}
            style={{}}
          >
            <LoadingButton
              loading={IsGuardando}
              loadingPosition="start"
              disabled={IsLoading}
              onClick={() => GuardarEmpledo()}
              className="btn btn-Kalendar font-AvenirMedium  py-2 px-4 "
              variant="contained"
              xs={{ with: "100$" }}
            >
              <span className={IsGuardando ? "px-4" : "px-2"}>
                {IsGuardando ? "Guardando..." : id ? "Guardar" : "Crear"}
              </span>
            </LoadingButton>
            <Button
              onClick={() => navigate(-1)}
              className="btn btn-cancelar font-AvenirMedium py-2 px-4"
              variant="outlined"
            >
              Cancelar
            </Button>
          </Stack>
        </CardContent>
      </Card>

      {openModal && (
        <ModalCargarImagen
          id={Values.id}
          tipo={IMG.TIPO_USUARIOS_SISTEMA}
          Titulo="Cargar imagen del usuario del sistema"
          mostrar={openModal}
          imgUrl={Values.img}
          fnCerrar={handleCloseModal}
          fnDevolverImagen={fnDevolverImagen}
        />
      )}
    </>
  );
};

export default Usuario;
{
  /* <code>
<pre>
  {
    JSON.stringify(Values, null, 2)
  }
</pre>
</code> */
}
