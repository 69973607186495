import {
    Alert,
    Button,
    Dialog,
    DialogContent,
    DialogTitle,
    IconButton,
    Snackbar,
    TextField,
    Tooltip
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useState, useEffect, useRef } from "react";
import { Row, Col } from "react-bootstrap";
import { styled } from "@mui/material/styles";
import UploadIcon from '@mui/icons-material/Upload';
import { BasicDatePicker } from '../../../../Includes/DatePicker'

import {
    ConvertDate,
    GetDate,
} from "../../../../Lib/Funciones Generales/ObtenerFecha";


import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import { getInfoComprobante, guardarInfoComprobante } from "../../Funciones/Licencias";
import FormasDePago from "../../../../Lib/SAT/Componentes/FormasDePago";
const MySwal = withReactContent(Swal);
const StyledSnackbar = styled((props) => <Snackbar {...props} />)(
    ({ theme }) => ({
        "& .MuiSnackbar-root": {
            top: theme.spacing(15),
        },
    })
);


const ModalComprobanteCargar = ({
    setLicenciaSeleccionada
    , licenciaSeleccionada
    , setShowDialogoCargarComprobante
    ,getListaLicencia
}) => {

    const fileRef = useRef(null)
    const [IsGuardando, setIsGuardando] = useState(false)
    const [mensaje, setMensaje] = useState("");
    const [openAlert, setOpenAlert] = useState(false);
    const [Values, setValues] = useState({
        id: 0,
        id_licencia: licenciaSeleccionada,
        img: "",
        nombre: "",
        comentario: "",
        forma_de_pago: -1,
        fecha: GetDate(),
        actualizar_comprobante: false,
    });

    const [Errores, setErrores] = useState({
        forma_de_pago: false,
    })

    const GuardarComprobante = () => {
        new Swal({
            title: "¿Estas seguro de guardar el comprobante?",
            text: "Esta acción no se puede deshacer",
            icon: "warning",
            showDenyButton: true,
            denyButtonText: "No, cancelar",
            confirmButtonText: "Si, estoy seguro",
            confirmButtonColor: "#3ABE88",
            denyButtonColor: "#65748B",
            reverseButtons: true,
        }).then(function (isConfirm) {
            console.log(isConfirm.isConfirmed);
            if (isConfirm.isConfirmed) {
                guardarInfoComprobante(Values, Errores, setErrores)
                    .then((data) => {
                        if (data.codigo == "200") {
                            MySwal.fire({
                                title: "Correcto",
                                html: data.mensaje,
                                icon: "success",
                                confirmButtoColor: "#3ABE88",
                                showConfirmButton: false,
                                timer: 1700,
                            }).then((result) => {
                                getListaLicencia();
                                setLicenciaSeleccionada(-1)
                                setShowDialogoCargarComprobante(false);
                            });
                        } else {
                            setMensaje(data.mensaje);
                            setOpenAlert(true);
                        }
                    })
                    .catch((data) => {
                        setMensaje(data.mensaje);
                        setOpenAlert(true);
                    });
            }
        });
    }

    const handleChangeFecha = (fecha) => {
        fecha = ConvertDate(fecha);
        if(fecha){
            setValues({ ...Values, fecha });
        }
        
    };


    const handleChangeImagen = ({ target }) => {
        const fileReader = new FileReader();
        fileReader.readAsDataURL(target.files[0]);
        fileReader.onload = (e) => {
            setValues({
                ...Values,
                nombre: target.files[0].name,
                img: e.target.result,
                actualizar_comprobante: true
            })
        };
    };

    useEffect(() => {
        if (licenciaSeleccionada != 0) {
            getInfoComprobante(licenciaSeleccionada).then(resp => {
                setValues({
                    ...Values,
                    img: resp.URL,
                    nombre: resp.URL,
                    comentario: resp.COMENTARIO,
                    actualizar_comprobante: false,
                    forma_de_pago: resp.FORMA_DE_PAGO,
                    fecha: resp.FECHA
                })
            }).catch(error => {
                console.log(error)
            })
        }
    }, [])

    useEffect(() => {
        if(!Values.fecha){
            setValues({...Values, fecha: GetDate()})
        }
    }, [Values.fecha])

    console.log(Values)
    return (

        <>
            <StyledSnackbar
                direction="right"
                open={openAlert}
                autoHideDuration={6000}
                onClose={() => setOpenAlert(false)}
                anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
            >
                <Alert onClose={() => setOpenAlert(false)} severity="error" sx={{ width: "100%" }}>
                    {mensaje}
                </Alert>
            </StyledSnackbar>

            <Dialog
                fullWidth
                maxWidth="sm"
                open={true}
                PaperProps={{ elevation: 0 }}
            >
                <IconButton
                    aria-label="Close"
                    className="m-3 mt-2"
                    style={{ position: "absolute", right: "0%" }}
                    onClick={() => {
                        setLicenciaSeleccionada(-1)
                        setShowDialogoCargarComprobante(false);
                    }}
                >
                    <CloseIcon />
                </IconButton>
                <DialogTitle>
                    <h6 className="fw-bolder f-12 mt-2">Cargar comprobante de pago</h6>
                </DialogTitle>
                <DialogContent>

                    <Row className="mt-0">
                        <Col sm={12} className="p-3 d-flex justify-content-between align-items-center">

                            <TextField
                                className="text-truncate me-2"
                                fullWidth
                                disabled={true}
                                maxRows={1}
                                value={Values.nombre}
                                multiline={true}
                                name="nombre"
                                label="Seleccionar comprobante"
                                placeholder="Comprobante"
                                variant="outlined"
                                inputProps={{ maxLength: 300 }}
                            />

                            <Tooltip title="Cargar comprobante">
                                <IconButton
                                    style={{ position: 'relative', zIndex: 10, right: 0, width: 56, height: 56 }}
                                    className="btn btn-Kalendar p-2"
                                    onClick={() => fileRef.current.click()}>
                                    <UploadIcon style={{ color: "#FFF", fontSize: 30 }} />
                                </IconButton>
                            </Tooltip>

                        </Col>
                        <Col sm={12} md={6} className="p-3">
                            <FormasDePago
                                name="forma_de_pago"
                                Value={Values.forma_de_pago}
                                Error={Errores.forma_de_pago}
                                handleInputChange={({ target }) => {
                                    setValues({ ...Values, forma_de_pago: target.value })
                                    setErrores({ ...Errores, forma_de_pago: false })
                                }}
                            />
                        </Col>
                        <Col sm={12} md={6} className="p-3">
                            <BasicDatePicker
                                fullWidth
                                format="DD/MM/YYYY"
                                label="Fecha de pago"
                                fecha={Values.fecha}
                                setFecha={handleChangeFecha}
                            />
                        </Col>

                        <Col sm={12} className="p-3">
                            <TextField
                                onChange={({ target }) => {
                                    setValues({ ...Values, comentario: target.value });
                                }}
                                value={Values.comentario}
                                fullWidth
                                multiline={true}
                                maxRows={3}
                                minRows={3}
                                size="small"
                                name="comprobante"
                                label="Observaciones"
                                placeholder="(Máx 300)"
                                variant="outlined"
                                inputProps={{ maxLength: 300 }}
                            />
                        </Col>

                        <Col sm={12} className="pt-3 px-3">
                            <Col sm={12} className="d-flex justify-content-end p-3">
                                <Button
                                    onClick={() => {
                                        setShowDialogoCargarComprobante(false)
                                        setLicenciaSeleccionada(-1)
                                    }}
                                    loadingPosition="start"
                                    className="btn btn-cancelar font-AvenirMedium py-2 px-4 me-2 "
                                    variant="outlined"
                                >
                                    <span>Cancelar</span>
                                </Button>


                                <Button
                                    onClick={GuardarComprobante}
                                    loadingPosition="start"
                                    className="btn btn-Kalendar font-AvenirMedium  py-2 px-4 "
                                    variant="contained"
                                >
                                    <span>Guardar</span>
                                </Button>

                            </Col>
                        </Col>
                    </Row>




                    <input
                        className="d-none"
                        ref={fileRef}
                        accept=".jpg, .jpeg, .png, .webp, .pdf"
                        type="file"
                        onChange={handleChangeImagen} />

                </DialogContent>
            </Dialog>
        </>
    )
}

export default ModalComprobanteCargar