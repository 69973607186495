const DescuentosLateral = ({ color }) => {
    return (
        <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
            <title>1.6 DescuentosLateralON</title>
            <g id="Michelle-Pourroy-MAnager" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g id="1.1-Dashboard" transform="translate(-32.000000, -381.000000)">
                    <g id="1-copy-3" transform="translate(0.000000, 363.000000)">
                        <g id="1.6-DescuentosLateralON" transform="translate(33.000000, 19.000000)">
                            <path d="M22,12.4166156 L12.4166235,22 L0.473326926,10.0567035 C0.155054134,9.73989737 -0.0150824741,9.30428898 0.0010511698,8.85548035 L0.256256082,1.81387815 C0.287056674,0.966128504 0.966136411,0.287048768 1.81241937,0.256248175 L8.85548825,0.00104326304 C9.30429689,-0.0150903808 9.73990528,0.156512922 10.0567114,0.473319019 L22,12.4166156 Z" id="Stroke-1" stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                            <path d="M12.1916325,15.7573065 C11.9510945,15.7573065 11.7384237,15.5871699 11.6914895,15.3422319 L10.2482617,7.79021984 C10.1954607,7.5144812 10.3758642,7.24607604 10.6530695,7.19327502 C10.9288082,7.1434074 11.1972133,7.32234417 11.2500143,7.59954951 L12.6932421,15.1515615 C12.7460431,15.4287669 12.564173,15.6957053 12.2884343,15.7485063 C12.256167,15.7543731 12.2238998,15.7573065 12.1916325,15.7573065" id="Fill-3" fill={color}></path>
                            <path d="M15.3341729,10.8415319 C15.1200355,10.8415319 14.9190983,10.9251335 14.7680287,11.0762031 C14.6169591,11.2272727 14.5333575,11.4282099 14.5333575,11.6408806 C14.5333575,11.8550181 14.6169591,12.0559553 14.7680287,12.2070249 C15.0701679,12.509164 15.598178,12.509164 15.9003172,12.2070249 C16.0513867,12.0559553 16.1349884,11.8550181 16.1349884,11.6408806 C16.1349884,11.4282099 16.0513867,11.2272727 15.9003172,11.0762031 L15.9003172,11.0762031 C15.7492476,10.9251335 15.5483104,10.8415319 15.3341729,10.8415319 M15.3341729,13.4625157 C14.8472302,13.4625157 14.3910881,13.273312 14.0464148,12.9286387 C13.7032082,12.5854321 13.5140046,12.1278233 13.5140046,11.6408806 C13.5140046,11.1554046 13.7032082,10.6977958 14.0464148,10.3545892 C14.7562952,9.64470887 15.9120507,9.64470887 16.6219311,10.3545892 L16.6219311,10.3545892 C16.9651377,10.6977958 17.1543413,11.1554046 17.1543413,11.6408806 C17.1543413,12.1278233 16.9651377,12.5854321 16.6219311,12.9286387 C16.2772578,13.273312 15.8211157,13.4625157 15.3341729,13.4625157" id="Fill-5" fill={color}></path>
                            <path d="M7.60659755,10.4999387 C7.3924601,10.4999387 7.19298959,10.5835403 7.04192002,10.7346098 C6.88938375,10.8856794 6.80724883,11.0866166 6.80724883,11.3007541 C6.80724883,11.5134248 6.88938375,11.714362 7.04045332,11.8654316 C7.34405916,12.1690375 7.87060263,12.1675708 8.17274178,11.8654316 C8.32381135,11.714362 8.40741296,11.5134248 8.40741296,11.3007541 C8.40741296,11.0866166 8.32381135,10.8856794 8.17274178,10.7346098 C8.02167221,10.5835403 7.820735,10.4999387 7.60659755,10.4999387 M7.60659755,13.1209224 C7.11965484,13.1209224 6.66351273,12.9317188 6.31883943,12.5870455 C5.97563283,12.2438389 5.78642918,11.7862301 5.78642918,11.3007541 C5.78642918,10.8138114 5.97563283,10.3562026 6.31883943,10.012996 C7.00671934,9.32511605 8.20647576,9.32511605 8.89435567,10.012996 C9.604236,10.7228763 9.604236,11.8771652 8.89435567,12.5870455 C8.54968237,12.9317188 8.09354026,13.1209224 7.60659755,13.1209224" id="Fill-7" fill={color}></path>
                            <path d="M5.17349739,3.32105383 C5.68537391,3.83293035 5.68537391,4.66307966 5.17349739,5.17348948 C4.66162087,5.685366 3.83147156,5.685366 3.32106174,5.17348948 C2.80918522,4.66307966 2.80918522,3.83293035 3.32106174,3.32105383 C3.83147156,2.80917731 4.66162087,2.80917731 5.17349739,3.32105383" id="Fill-9" fill={color}></path>
                        </g>
                    </g>
                </g>
            </g>
        </svg>
    )
}

export default DescuentosLateral
