import { Dialog, DialogContent, DialogTitle, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { Row, Col, Button } from "react-bootstrap";
import { BasicDatePicker } from "../../../Includes/DatePicker";
import { GetDate } from "../../../Lib/Funciones Generales/ObtenerFecha";
import { useEffect, useState } from "react";
import RepComisiones from "./RepReservaciones";
import { getComisiones } from "../Funciones/Reservaciones";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
const MySwal = withReactContent(Swal);

const Comisiones = ({ colaborador, setShowComisiones }) => {
  const [fechaInicio, setFechaInicio] = useState(GetDate(-7));
  const [fechaFin, setFechaFin] = useState(GetDate());
  const [onlyPagadas, SetOnlyPagadas] = useState(true);
  const [IsLoading, setIsLoading] = useState(true);
  const [IsGuardando, setIsGuardando] = useState(false);
  const [comisiones, setComisiones] = useState([]);

  const handleChangeFechaIn = (event) => {
    var fecha = new Date(event);
    fecha.setDate(fecha.getDate());
    const year = fecha.getFullYear();
    const month = fecha.getMonth() + 1;
    const day = fecha.getDate();
    let FECHA =
      year +
      "-" +
      (month < 10 ? "0" + month : month) +
      "-" +
      (day < 10 ? "0" + day : day);
    setFechaInicio(FECHA);
  };

  const handleChangeFechaFIn = (event) => {
    var fecha = new Date(event);
    fecha.setDate(fecha.getDate());
    const year = fecha.getFullYear();
    const month = fecha.getMonth() + 1;
    const day = fecha.getDate();
    let FECHA =
      year +
      "-" +
      (month < 10 ? "0" + month : month) +
      "-" +
      (day < 10 ? "0" + day : day);
    setFechaFin(FECHA);
  };

  useEffect(() => {
    getComisionesByColabora();
  }, []);

  const getComisionesByColabora = () => {
    setIsLoading(true);

    if (colaborador.id != undefined) {
      getComisiones(colaborador.id, fechaInicio, fechaFin)
        .then((resp) => {
          if (resp?.err?.length == 0) {
            MySwal.fire({
              title: "Error",
              html: resp.mensaje,
              icon: "error",
              confirmButtonColor: "#3ABE88",
              showConfirmButton: true,
              allowEscapeKey: false,
              allowEnterKey: false,
              allowOutsideClick: false,
            }).then(() => {
              setIsLoading(false);
            });
          } else {
            setComisiones(resp);
            setIsLoading(false);
          }
        })
        .catch((resp) => {
          MySwal.fire({
            title: "Error",
            html: resp.mensaje,
            icon: "error",
            confirmButtonColor: "#3ABE88",
            showConfirmButton: true,
            allowEscapeKey: false,
            allowEnterKey: false,
            allowOutsideClick: false,
          }).then(() => {
            setIsLoading(false);
          });
          //setIsLoading(false);
        });
    } else {
      setIsLoading(false);
    }
  };

  return (
    <Dialog fullWidth maxWidth="xl" open={true}>
      <IconButton
        aria-label="delete"
        className="m-3"
        style={{ position: "absolute", right: "0%" }}
        onClick={() => {
          setShowComisiones(false);
        }}
      >
        <CloseIcon size="lg" />
      </IconButton>
      <DialogTitle>
        <h6 className="fw-bolder f-12">Reservaciones de {colaborador.nombre}</h6>
      </DialogTitle>
      <DialogContent style={{ minHeight: "80vh" }}>
        <Row>
          <Col xs={12} md={3} className="py-3">
            <BasicDatePicker
              className="my-3"
              fullWidth
              size="small"
              format="DD/MM/YYYY"
              label="Fecha de inicio"
              fecha={fechaInicio}
              setFecha={handleChangeFechaIn}
            />
          </Col>
          <Col xs={12} md={3} className="py-3">
            <BasicDatePicker
              fullWidth
              size="small"
              format="DD/MM/YYYY"
              label="Fecha de fin"
              fecha={fechaFin}
              setFecha={handleChangeFechaFIn}
            />
          </Col>

          <Col xs={12} md={3}>
            <div className="d-flex justify-content-start align-items-center h-100">
              <Button onClick={()=> getComisionesByColabora() } className="btn btn-Kalendar px-4 " variant="contained">
                filtrar
              </Button>
            </div>
          </Col>
          <Col>
            <RepComisiones comisiones={comisiones} />
          </Col>
        </Row>
      </DialogContent>
    </Dialog>
  );
};

export default Comisiones;
