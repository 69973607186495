import React, { useState, useEffect } from "react";
import useMediaQuery from "@mui/material/useMediaQuery";
import {
  Grid,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  Avatar,
  Snackbar,
  Alert,
} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import CloseIcon from "@mui/icons-material/Close";
import {
  cambiarEstatusReserva,
  cancelarReserva,
  getReservacionBYid,
} from "../Funciones/Reservaciones";
import { Row, Col, Button } from "react-bootstrap";
import LocalPrintshopOutlinedIcon from "@mui/icons-material/LocalPrintshopOutlined";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import AttachMoneySharpIcon from "@mui/icons-material/AttachMoneySharp";
import EventAvailableOutlinedIcon from "@mui/icons-material/EventAvailableOutlined";
import Ticket from "./Ticket";
import Carrito from "./carrito";
import {
  formatearFecha,
  getTiempoFromMinutos,
  recortarHoraAgregarTexto,
} from "../../Lib/Funciones Generales/Generales";
import StatusBar from "./StatusBar";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { getInfoConfigGeneral } from "../../Configuraciones/Generales/Funciones/Generales";
import { printTicket } from "../../Lib/Funciones Generales/print";
import Cita from "../../assets/Cita.svg";
import Sucursal from "../../assets/Sucursal.svg";
import PagoReservacion from "./PagoReservacion";
import { styled } from "@mui/material/styles";
import Loading from "../../Includes/Loading";
import EstatusReservacion from "./EstatusReservacion";
import BarAsistencia from "./BarAsistencia";

const MySwal = withReactContent(Swal);
export default function DetallesReservacion({
  showModalReserva,
  setShowReservacion = () => {},
  setShowModalReserva = () => {},
  idReserv = "",
  setIdReserv = () => {},
  folioReservacion,
  setFolioReservacion = () => {},
  getReservaciones = () => {},
}) {
  const [productosCarrito, setProductosCarrito] = useState([]);

  const [visibleTicket, setVisibleTicket] = useState(true);
  const [showModalPago, setShowModalPago] = useState(false);

  const [descuento, setDescuento] = useState({ id: 0, descuento: 0, tipo: 2 });
  const [mensaje, setMensaje] = useState("");
  const [openAlert, setOpenAlert] = useState(false);
  const [IsLoading, setIsLoading] = useState(true);

  const [Values, setValues] = useState({
    id: idReserv,
    formato_completo: "",
    fecha_reservacion: "",
    hora: "",
    total: "0",
    subtotal: "0",
    descuento: "0",
    tipo_descuento: "",
    pagado: false,
    duracion: "",
    notas: "",
    nombre_colaborador: "",
    sucursal: "",
    direccion: "",
    cliente: "",
    apellidos: "",
    img_emp: "",
    nombre_empresa: "",
    correo: "",
    img_cliente: "",
    img_colab: "",
    estatus: "",
    efectivo: 0,
    tarjeta: 0,
    monedero: 0,
    otros: 0,
    is_confirmado: false,
  });

  const [config, setConfig] = useState({
    id: 0,
    encabezado: "",
    pie: "",
    linea_1: "",
    incrementos_horarios: "",
    si_cobro: false,
    ancho: 80,
    fuente: 11,
  });

  let array = [];

  const getInfo = () => {
    getReservacionBYid(idReserv)
      .then((resp) => {
        setValues(resp.Values);
        array = [];
        resp.detalle.map((row) => {
          array.push({
            ID: row.ID,
            DESCRIPCION: row.DESCRIPCION,
            PRECIO: row.PRECIO,
            DURACION: row.DURACION,
            CANTIDAD: row.CANTIDAD,
            DESCUENTO: 0,
            TIPO_DESCUENTO: 0,
          });
        });
        setProductosCarrito(array);
        setDescuento({
          id: 0,
          descuento: parseFloat(resp.Values.descuento),
          tipo: parseInt(resp.Values.tipo_descuento), // se queda el 2, por que ya viene neto
        });
        setIsLoading(false);
      })
      .catch((resp) => {});
  };

  useEffect(() => {
    getInfo();
  }, []);

  useEffect(() => {
    getInfoConfigGeneral()
      .then((resp) => {
        if (resp?.err?.length != 0) {
          // console.log(resp);
          setConfig(resp.values);
        }
      })
      .catch((resp) => {});
  }, []);

  const cancelarReservacion = () => {
    new Swal({
      title: "¿Estas seguro de cancelar esta reservación?",
      text: "esta acción no se puede deshacer",
      icon: "warning",
      showDenyButton: true,
      denyButtonText: "No, cancelar",
      confirmButtonText: "Si, estoy seguro",
      confirmButtonColor: "#3ABE88",
      denyButtonColor: "#65748B",
      reverseButtons: true,
    }).then(function (isConfirm) {
      //console.log(isConfirm.isConfirmed);
      if (isConfirm.isConfirmed) {
        //  setIsGuardando(true);
        cancelarReserva(idReserv)
          .then((data) => {
            if (data.codigo == "200") {
              //      setIsGuardando(false);
              MySwal.fire({
                title: "Correcto",
                html: data.mensaje,
                icon: "success",
                confirmButtoColor: "#3ABE88",
                showConfirmButton: false,
                timer: 1700,
              }).then((result) => {
                getInfo(false);
                getReservaciones();
              });
            } else {
              setMensaje(data.mensaje);
              setOpenAlert(true);
              // setIsGuardando(false);
            }
          })
          .catch((data) => {
            setMensaje(data.mensaje);
            setOpenAlert(true);
            // setIsGuardando(false);
          });
      }
    });
  };
  const cambiarEstatusReservacion = (estatus) => {
    new Swal({
      title: "¿Estas seguro cambiar es estatus de esta reservación?",
      text: "Podrás volver a cambiar el estatus",
      icon: "warning",
      showDenyButton: true,
      denyButtonText: "No, cancelar",
      confirmButtonText: "Si, estoy seguro",
      confirmButtonColor: "#3ABE88",
      denyButtonColor: "#65748B",
      reverseButtons: true,
    }).then(function (isConfirm) {
      //console.log(isConfirm.isConfirmed);
      if (isConfirm.isConfirmed) {
        //  setIsGuardando(true);
        cambiarEstatusReserva(idReserv, estatus)
          .then((data) => {
            if (data.codigo == "200") {
              //      setIsGuardando(false);
              MySwal.fire({
                title: "Correcto",
                html: data.mensaje,
                icon: "success",
                confirmButtoColor: "#3ABE88",
                showConfirmButton: false,
                timer: 1700,
              }).then((result) => {
                getInfo(false);
                getReservaciones();
              });
            } else {
              setMensaje(data.mensaje);
              setOpenAlert(true);
              // setIsGuardando(false);
            }
          })
          .catch((data) => {
            setMensaje(data.mensaje);
            setOpenAlert(true);
            // setIsGuardando(false);
          });
      }
    });
  };
  const confirmarReagendar = () => {
    new Swal({
      title: "¿Estas seguro de modificar esta reservación?",
      text: "esta acción no se puede deshacer",
      icon: "question",
      showDenyButton: true,
      denyButtonText: "No, cancelar",
      confirmButtonText: "Si, estoy seguro",
      confirmButtonColor: "#3ABE88",
      denyButtonColor: "#65748B",
      reverseButtons: true,
    }).then(function (isConfirm) {
      //console.log(isConfirm.isConfirmed);
      if (isConfirm.isConfirmed) {
        setShowReservacion(false);
        setShowModalReserva(true);
        //console.log("Caakjsdhasd");
        //console.log(setShowReservacion);
        //console.log("--");
        //console.log(setShowModalReserva);
      }
    });
  };

  const StyledSnackbar = styled((props) => <Snackbar {...props} />)(
    ({ theme }) => ({
      "& .MuiSnackbar-root": {
        top: theme.spacing(15),
      },
    })
  );

  const handleClose = () => {
    setOpenAlert(false);
  };

  return (
    <>
      <StyledSnackbar
        direction="right"
        open={openAlert}
        autoHideDuration={6000}
        onClose={handleClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      >
        <Alert onClose={handleClose} severity="error" sx={{ width: "100%" }}>
          {mensaje}
        </Alert>
      </StyledSnackbar>
      <Dialog fullWidth maxWidth="xl" open={true}>
        <IconButton
          aria-label="delete"
          className="m-3"
          style={{ position: "absolute", right: "0%" }}
          onClick={() => {
            setIdReserv("0");
            setFolioReservacion("0");
            setShowReservacion(false);
          }}
        >
          <CloseIcon size="lg" />
        </IconButton>
        <DialogTitle>
          {showModalPago ? (
            <h6 className="fw-bolder f-12">Pago de reservación </h6>
          ) : (
            <h6 className="fw-bolder f-12">
              Detalles de reservación #
              {folioReservacion.toString().padStart(5, "0")}
            </h6>
          )}
        </DialogTitle>

        {showModalPago ? (
          <PagoReservacion
            setShowModalPago={setShowModalPago}
            productosCarrito={productosCarrito}
            totalReserva={Values.total}
            idReserv={idReserv}
            descuento={descuento}
            getInfo={getInfo}
            folioReservacion={folioReservacion}
            setFolioReservacion={setFolioReservacion}
          />
        ) : (
          <DialogContent>
            {IsLoading ? (
              <Loading />
            ) : (
              <div
                className="d-flex justify-content-start flex-wrap flex-lg-nowrap 
           flex-sm-row-reverse   flex-lg-row  w-100"
              >
                {config.si_cobro ? (
                  <>
                    <div className=" align-self-center align-self-lg-start  order-1 order-lg-0">
                      <IconButton
                        onClick={() => {
                          setVisibleTicket(!visibleTicket);
                        }}
                        style={{
                          position: visibleTicket ? "relative" : "block",
                          backgroundColor: "#e7e7e7",
                          left: visibleTicket
                            ? "calc(" + config.ancho + "mm - 35px )"
                            : "0px",
                          top: visibleTicket ? "50px" : "50px",
                        }}
                      >
                        {!visibleTicket ? (
                          <RemoveRedEyeIcon />
                        ) : (
                          <VisibilityOffIcon />
                        )}
                      </IconButton>
                      {visibleTicket ? (
                        <Ticket
                          className={visibleTicket ? "" : "d-none"}
                          config={config}
                          Values={Values}
                          productosCarrito={productosCarrito}
                          descuento={descuento}
                          idReserv={idReserv}
                          folioReservacion={folioReservacion}
                          setFolioReservacion={setFolioReservacion}
                        />
                      ) : (
                        <></>
                      )}
                    </div>
                  </>
                ) : (
                  <></>
                )}

                <div className="flex-grow-1 order-0 order-lg-1">
                  <Row>
                    <Col
                      sm={12}
                      md={12}
                      className=""
                      style={{ background: "" }}
                    >
                      <Row>
                        <Col
                          sm={12}
                          md={6}
                          className="p-3"
                          style={{ background: "" }}
                        >
                          <div className="d-flex justify-content-start mb-0 ps-0 p-2 align-items-center">
                            <div>
                              <Avatar
                                className="shadow"
                                sx={{ width: 50, height: 50 }}
                                src={Values.img_colab}
                              />
                            </div>
                            <div>
                              <Grid style={{ marginLeft: "20px" }}>
                                <h6
                                  className="font-AvenirBold mb-0"
                                  style={{ color: "black" }}
                                >
                                  {Values.nombre_colaborador}
                                </h6>
                                <Typography className="font-Avenir">
                                  Colaborador
                                </Typography>
                              </Grid>
                            </div>
                          </div>
                          <div className="d-flex justify-content-start mb-0 ps-0 p-2 align-items-top mt-3">
                            <div>
                              <Avatar
                                className="shadow"
                                sx={{ width: 50, height: 50 }}
                                src={Sucursal}
                              />
                            </div>
                            <div>
                              <Grid style={{ marginLeft: "20px" }}>
                                <h6
                                  className="font-AvenirBold mb-0"
                                  style={{ color: "black" }}
                                >
                                  {"Sucursal  " + Values.sucursal}
                                </h6>
                                <Typography className="font-Avenir">
                                  {Values.direccion}
                                </Typography>
                              </Grid>
                            </div>
                          </div>
                          <div className="d-flex justify-content-start mb-3 ps-0 p-2 align-items-top mt-3">
                            <div>
                              <Avatar
                                className="shadow"
                                sx={{ width: 50, height: 50 }}
                                src={Cita}
                              />
                            </div>
                            <div>
                              <Grid style={{ marginLeft: "20px" }}>
                                <h6
                                  className="font-AvenirBold mb-0"
                                  style={{ color: "black" }}
                                >
                                  {formatearFecha(
                                    //"dd/mm/yyyy",
                                    "yyyy/mm/dd",
                                    Values.fecha_reservacion
                                  )}
                                </h6>
                                <Typography className="font-Avenir">
                                  {recortarHoraAgregarTexto(Values.hora)}
                                  <br></br>
                                  {getTiempoFromMinutos(Values.duracion)}
                                </Typography>
                              </Grid>
                            </div>
                          </div>
                          <TextField
                            name="Notas"
                            readyonl
                            placeholder="Notas"
                            label="Notas"
                            className="form-control ms-0 m-2"
                            multiline
                            rows={2}
                            variant="outlined"
                            value={Values.notas}
                            InputProps={{
                              readOnly: true,
                            }}
                          />
                          <div className="m-2 ms-0 w-100">
                            <StatusBar
                              isPagado={false}
                              estatus={Values.estatus}
                            />
                          </div>
                          <EstatusReservacion
                            cancelarReservacion={cancelarReservacion}
                            cambiarEstatusReservacion={
                              cambiarEstatusReservacion
                            }
                            estatus={Values.estatus}
                          />
                          {config.si_cobro ? (
                            <div className="m-2 ms-0 w-100">
                              <StatusBar
                                isPagado={Values.pagado}
                                estatus={"pago"}
                              />
                            </div>
                          ) : (
                            <></>
                          )}
                          <BarAsistencia isConfirado={Values.is_confirmado} />
                        </Col>
                        <Col
                          sm={12}
                          md={6}
                          className="p-3"
                          style={{ background: "" }}
                        >
                          <div className="d-flex justify-content-start mb-3 p-2 align-items-center">
                            <div>
                              <Avatar
                                className="shadow"
                                sx={{ width: 50, height: 50 }}
                                src={Values.img_cliente}
                              />
                            </div>
                            <div>
                              <Grid style={{ marginLeft: "20px" }}>
                                <h6
                                  className="font-AvenirBold mb-0"
                                  style={{ color: "black" }}
                                >
                                  {Values.cliente + " " + Values.apellidos}
                                </h6>
                                <Typography className="font-Avenir">
                                  {Values.correo}
                                </Typography>
                              </Grid>
                            </div>
                          </div>

                          {/**
                         <Row style={{ marginBottom: "10px" }}>
                        <FormControl sx={{ m: 1, width: "full" }} >
                          <InputLabel id="demo-multiple-name-label">
                            Estatus
                          </InputLabel>
                          <Select
                            labelId="demo-multiple-name-label"
                            id="demo-multiple-name"
                            input={<OutlinedInput label="Estatus" />}
                          >
                            <MenuItem>Terminado</MenuItem>
                            <MenuItem>asd</MenuItem>
                          </Select>
                        </FormControl>
                      </Row>
                         */}

                          <Carrito
                            productosCarrito={productosCarrito}
                            //setProductosCarrito={() => {}}
                            //Values={Valuess}
                            //Errores={errores}
                            //handlInputChange={() => {}}
                            descuento={descuento}
                            // formasPago={formaspago}
                            //setDescuento={() => {}}
                            isVista
                          />
                        </Col>
                      </Row>

                      <Row>
                        <Grid
                          container
                          direction="row"
                          justifyContent="end"
                          alignItems="center"
                          margin={"10px"}
                        >
                          {config.si_cobro ? (
                            <Button
                              onClick={printTicket}
                              className="m-1 m-md-4 ms-0 btn-accion"
                              container
                              direction="column"
                              justifyContent="center"
                              alignItems="center"
                              style={{
                                background: "#65748B",
                              }}
                            >
                              {" "}
                              <LocalPrintshopOutlinedIcon
                                style={{ color: "white" }}
                                sx={{ width: 40, height: 40 }}
                              />
                              <Typography
                                style={{
                                  color: "white",
                                }}
                              >
                                Imprimir
                              </Typography>
                            </Button>
                          ) : (
                            <></>
                          )}

                          {Values.estatus != "2" && Values.estatus != "1" ? (
                            <Button
                              onClick={() => {
                                cancelarReservacion();
                              }}
                              className="m-1 m-md-4 btn-accion"
                              direction="column"
                              justifyContent="center"
                              alignItems="center"
                              style={{
                                background: "#D14343",
                              }}
                            >
                              <CancelOutlinedIcon
                                style={{ color: "white" }}
                                sx={{ width: 40, height: 40 }}
                              />
                              <Typography
                                style={{
                                  color: "white",
                                }}
                              >
                                Cancelar
                              </Typography>
                            </Button>
                          ) : (
                            ""
                          )}

                          {!Values.pagado &&
                          Values.estatus != "2" &&
                          config.si_cobro ? (
                            <Button
                              onClick={() => setShowModalPago(true)}
                              className="m-1 m-md-4 btn-accion"
                              container
                              direction="column"
                              justifyContent="center"
                              alignItems="center"
                              style={{
                                background: "#3ABE88",
                              }}
                            >
                              <AttachMoneySharpIcon
                                style={{ color: "white" }}
                                sx={{ width: 40, height: 40 }}
                              />
                              <Typography
                                style={{
                                  color: "white",
                                }}
                              >
                                Pagar
                              </Typography>
                            </Button>
                          ) : (
                            ""
                          )}

                          {!Values.pagado &&
                          (Values.estatus != "2" || Values.estatus != "1") ? (
                            <Button
                              className="m-1 m-md-4 btn-accion"
                              container
                              direction="column"
                              justifyContent="center"
                              alignItems="center"
                              style={{
                                background: "#FE8D27",
                              }}
                              onClick={() => {
                                confirmarReagendar();
                              }}
                            >
                              <EventAvailableOutlinedIcon
                                style={{ color: "white" }}
                                sx={{ width: 40, height: 40 }}
                              />
                              <Typography
                                style={{
                                  fontSize: "20px",
                                  fontWeight: "40px",
                                  color: "white",
                                }}
                              >
                                Reagendar
                              </Typography>
                            </Button>
                          ) : (
                            ""
                          )}
                        </Grid>
                      </Row>
                    </Col>
                  </Row>
                </div>
              </div>
            )}
          </DialogContent>
        )}
      </Dialog>
    </>
  );
}
