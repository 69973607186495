import {
  Alert,
  Button,
  Card,
  CardContent,
  FormControl,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  Snackbar,
  Stack,
  TextField,
  Autocomplete,
  FormControlLabel,
  Box,
  Checkbox,
  MenuItem,
  Select,
  Accordion, AccordionSummary, Typography
} from "@mui/material";
import React, { useState, useEffect, useMemo  } from "react";
import { Col, Row } from "react-bootstrap";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Await, useNavigate } from "react-router-dom";
import {
  guardar,
  getInfoProducto,
  getListSucColabs,
} from "../Funciones/Productos";
import Loading from "../../../Includes/Loading";
import LoadingButton from "@mui/lab/LoadingButton";
import { styled } from "@mui/material/styles";
import { useParams } from "react-router-dom";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import Categoria from "../../Categorias/Componentes/SelectCategorias";
import "../../../Lib/Funciones Generales/Prototipos";
import {
  Horas,
  Impuestos,
  Minutos,
  Monedas,
} from "../../../Lib/Funciones Generales/Constantes";
import Switch from "../../../Includes/Switch";
import ProductoServicioSat from "../../SAT/Componentes/ProductoServicioSat";
import UnidadMedidaSat from "../../SAT/Componentes/UnidadMedidaSat";


const MySwal = withReactContent(Swal);

const Producto = () => {

  //Variables del formulario
  const { id } = useParams();
  const navigate = useNavigate();
  const [IsLoading, setIsLoading] = useState(true);
  const [IsGuardando, setIsGuardando] = useState(false);
  const [open, setOpen] = useState(false);
  const [mensaje, setMensaje] = useState("");
  const [SucuCola, setSucuCola] = useState([]);
 

  const [Values, setValues] = useState({
    id: id ?? "0",
    iS_SERVICIO: true,
    codigo: "",
    descripcion: "",
    descripcion_larga: "",
    horas: "",
    minutos: "",
    precio: "",
    id_categoria: "0",
    relacion: [],
    iS_FACTURA: true,
    unidad_de_medida: "",
    moneda: "MXN",
    iva: "16",
    clave_codigo_prod_sat: "",
    clave_unidad_medida_sat: "",
    colaboradores: []
    
  });



  const [Errores, setErrores] = useState({
    codigo: false,
    descripcion: false,
    descripcion_larga: false,
    precio: false,
    horas: false,
    minutos: false,
    activo: false,
    id_categoria: false,
    unidad_de_medida: false,
    moneda: false,
    iva: false,
    clave_codigo_prod_sat: false,
    clave_unidad_medida_sat: false,
  });



  //mostrar los check de colaborador

  useEffect(() => {
    const timer = setTimeout(() => {
      Values.relacion.forEach(element => {
        agregarNumero(element.ID_COLABORADOR);
        
      })
    }, 2000);
    return () => clearTimeout(timer);
         
  }, [SucuCola]);


 



  




  const [checkedItems, setCheckedItems] = useState([]);


  //agregar los ID de colaborador


  const agregarNumero = (ID) => {
    const nuevoNumero = ID 
    setCheckedItems(prevNumeros => [...prevNumeros, nuevoNumero]);
  };
 
  
  const SelecColaboraChange = (ID_COLABORADOR) => {

    const isChecked = checkedItems.includes(ID_COLABORADOR);
    if (isChecked) {
      setCheckedItems(checkedItems.filter(item => item !== ID_COLABORADOR));
    } else {
      setCheckedItems([...checkedItems, ID_COLABORADOR]);
    }


  };
 


  //console.log("Sucu y Cola", checkedItems);


  const onChange = (checked) => {
    setValues({ ...Values, iS_SERVICIO: checked });
  };
  const onChangeStatus = (checked) => {
    setValues({ ...Values, iS_FACTURA: checked });
  };
  const handleClose = () => {
    setOpen(false);
  };

  const handlInputChange = ({ target }) => {
    let Name = target.name;
    let Value = target.value;

    if (Name == "precio") {
      Value = Value.toDecimal();
    }

    setValues({
      ...Values,
      [Name]: Value,
    });
    setErrores({
      ...Errores,
      [Name]: false,
    });
  };

  useEffect(() => {
    setIsLoading(true);


    if (id != undefined) {
      getInfoProducto(id)
        .then((resp) => {
          //console.log("aqui"+resp);
          if (resp?.err?.length == 0) {
            MySwal.fire({
              title: "Error",
              html: resp.mensaje,
              icon: "error",
              confirmButtonColor: "#3ABE88",
              showConfirmButton: true,
              allowEscapeKey: false,
              allowEnterKey: false,
              allowOutsideClick: false,
            }).then(() => {
              setIsLoading(false);
              navigate(-1);
            });
          } else {
            setValues(resp);
            setSucuCola(resp.colaboradores);
       
            setIsLoading(false);
            
          }
        })
        .catch((resp) => {
          MySwal.fire({
            title: "Error",
            html: resp.mensaje,
            icon: "error",
            confirmButtonColor: "#3ABE88",
            showConfirmButton: true,
            allowEscapeKey: false,
            allowEnterKey: false,
            allowOutsideClick: false,
          }).then(() => {
            setIsLoading(false);
            navigate(-1);
          });
          //setIsLoading(false);
        });
    } else {
   
      getListSucColabs()
      .then((resp) => {

        setSucuCola(resp);
      })
      .catch((e) => {
        console.log(e);
      });
      setIsLoading(false);
    }
  },  []);

  const GuardarProducto = () => {
    setIsGuardando(true);
    guardar(Values, Errores, setErrores, checkedItems)
      .then((data) => {
        if (data.codigo == "200") {
          setIsGuardando(false);
          MySwal.fire({
            title: "Correcto",
            html: data.mensaje,
            icon: "success",
            confirmButtoColor: "#3ABE88",
            showConfirmButton: false,
            timer: 1700,
          }).then((result) => {
            console.log("I was closed by the timer");
            navigate(-1);
          });
        } else {
          setMensaje(data.mensaje);
          setOpen(true);
          setIsGuardando(false);
        }
      })
      .catch((data) => {
        setMensaje(data.mensaje);
        setOpen(true);
        setIsGuardando(false);
      });
  };

  const StyledSnackbar = styled((props) => <Snackbar {...props} />)(
    ({ theme }) => ({
      "& .MuiSnackbar-root": {
        top: theme.spacing(15),
      },
    })
  );

  // const handleListCheckBoxsCOLAB = ({ target }) => {
  //   //console.log(data);
  //   const newColabs = data.filter((item) => {
  //     if (item.ID_SUCURSAL == target.id) {
  //       item.colaboradores.map((colab) => {
  //         if (colab.ID == target.name) {
  //           colab.CHECK = target.checked;
  //           item.INDETERMINATE = true;
  //           setIdsuc(item.ID_SUCURSAL);
  //         }
  //       });
  //       const indicadorAllFalse = item.colaboradores.every(
  //         (c) => c.CHECK == false
  //       );
  //       const indicadorAllTrue = item.colaboradores.every((c) => {
  //         //console.log(c.CHECK);
  //         return c.CHECK == true;
  //       });
  //       //console.log(indicadorAllTrue);
  //       if (indicadorAllTrue) {
  //         item.INDETERMINATE = false;
  //         item.CHECK = true;
  //       }
  //       if (indicadorAllFalse) {
  //         item.INDETERMINATE = false;
  //         item.CHECK = false;
  //       }
  //       return item;
  //     } else {
  //       return item;
  //     }
  //   });
  //   //console.log(newColabs);
  //   setData(newColabs);
  //   setValues({ ...Values, relacion: data });
  // };

  // const handleListCheckBoxsSUC = ({ target }) => {
  //   const newSucs = data.filter((item) => {
  //     if (item.ID_SUCURSAL == target.name) {
  //       item.CHECK = target.checked;
  //       if (item.CHECK) {
  //         item.INDETERMINATE = false;
  //         item.colaboradores.map((colab) => {
  //           colab.CHECK = true;
  //         });
  //       } else {
  //         item.colaboradores.map((colab) => {
  //           colab.CHECK = false;
  //         });
  //       }
  //       return item;
  //     } else {
  //       return item;
  //     }
  //   });
  //   setData(newSucs);
  //   setValues({ ...Values, relacion: data });
  // };

  return (
    <>
      <StyledSnackbar
        direction="right"
        open={open}
        autoHideDuration={6000}
        onClose={handleClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      >
        <Alert onClose={handleClose} severity="error" sx={{ width: "100%" }}>
          {mensaje}
        </Alert>
      </StyledSnackbar>
      <Button
        onClick={() => navigate(-1)}
        className="btn  font-AvenirBold my-4 text-star ps-0 pe-4  "
        variant="text"
      >
        <ArrowBackIcon className="me-3" />
        Productos y servicios
      </Button>
      <Card elevation={3}>
        <CardContent className="p-0">
          <div className="p-3">
            <h6 className="mb-0 text-left font-AvenirBold">
              {id ? "Editar producto o servicio" : "Nuevo producto o servicio"}
            </h6>
          </div>
          <hr
            style={{ backgroundColor: "#DFDEE0", height: "1px", opacity: "1" }}
            className="m-0 p-0"
          />

          {IsLoading ? (
            <Row className="px-3">
              <Col sm={12} className="p-3">
                <Loading />
              </Col>
            </Row>
          ) : (
            <Row className="p-3">
              <Col sm={12} className="p-3 pt-1 text-end">
                <Switch
                  checked={Values.iS_SERVICIO}
                  onChange={onChange}
                  id={Values.id}
                  tituloActivo="Desactivar"
                  tituloInactivo="Activar"
                  on
                />
                <label>Es servicio</label>
              </Col>

              <Col sm={12} md={4} className="p-3">
                <TextField
                  fullWidth
                  inputProps={{ maxLength: 50 }}
                  label={
                    Values.iS_SERVICIO
                      ? "Descripción del servicio"
                      : "Descripción del producto"
                  }
                  variant="outlined"
                  name="descripcion"
                  helperText={
                    Errores.descripcion ? "Descripción no valida" : ""
                  }
                  value={Values.descripcion}
                  error={Errores.descripcion}
                  onChange={handlInputChange}
                />
              </Col>

              <Col sm={12} md={8} className="p-3">
                <TextField
                  fullWidth
                  inputProps={{ maxLength: 1500 }}
                  label={
                    Values.iS_SERVICIO
                      ? "Descripción larga del servicio"
                      : "Descripción larga del producto"
                  }
                  variant="outlined"
                  name="descripcion_larga"
                  helperText={
                    Errores.descripcion ? "Descripción larga no valida" : ""
                  }
                  value={Values.descripcion_larga}
                  error={Errores.descripcion_larga}
                  onChange={handlInputChange}
                  multiline
                />
              </Col>

              <Col sm={12} md={4} className="p-3">
                <TextField
                  fullWidth
                  inputProps={{ maxLength: 250 }}
                  label="Código"
                  variant="outlined"
                  name="codigo"
                  helperText={Errores.codigo ? "Código no valido" : ""}
                  value={Values.codigo}
                  error={Errores.codigo}
                  onChange={handlInputChange}
                />
              </Col>

              <Col sm={12} md={4} className="p-3">
                <Categoria
                  Value={Values.id_categoria}
                  Error={Errores.id_categoria}
                  handlInputChange={handlInputChange}
                />
              </Col>

              <Col sm={12} md={4} className="p-3">
                <FormControl fullWidth>
                  <InputLabel htmlFor="outlined-adornment-amount">
                    Precio
                  </InputLabel>
                  <OutlinedInput
                    startAdornment={
                      <InputAdornment position="start">$</InputAdornment>
                    }
                    name="precio"
                    helperText={Errores.precio ? "Precio no valido" : ""}
                    value={Values.precio}
                    error={Errores.precio}
                    label="Precio"
                    placeholder="Precio"
                    onChange={handlInputChange}
                  />
                </FormControl>
              </Col>
              {Values.iS_SERVICIO ? (
                <Col sm={12} md={4} className="p-3 pt-1">
                  <fieldset className="rounded border border-secondary">
                    <legend id="estiloLegend">Duración</legend>
                    <Stack
                      spacing={2}
                      direction={{ xs: "column", sm: "row" }}
                      style={{}}
                      className="m-2"
                    >
                      <Autocomplete
                        fullWidth
                        variant="outlined"
                        options={Horas}
                        value={Values.horas}
                        onInputChange={(event, newInputValue) => {
                          setValues({ ...Values, horas: newInputValue });
                        }}
                        renderInput={(params) => {
                          return (
                            <TextField
                              {...params}
                              label="Horas"
                              helperText={
                                Errores.horas ? "Horas no validas" : ""
                              }
                              error={Errores.horas}
                              handlInputChange={handlInputChange}
                            />
                          );
                        }}
                      />

                      <Autocomplete
                        fullWidth
                        variant="outlined"
                        options={Minutos}
                        value={Values.minutos}
                        onInputChange={(event, newInputValue) => {
                          setValues({ ...Values, minutos: newInputValue });
                        }}
                        renderInput={(params) => {
                          return (
                            <TextField
                              {...params}
                              label="Minutos"
                              helperText={
                                Errores.minutos ? "Minutos no validos" : ""
                              }
                              error={Errores.minutos}
                              handlInputChange={handlInputChange}
                            />
                          );
                        }}
                      />
                    </Stack>
                  </fieldset>
                </Col>
              ) : (
                ""
              )}
              <Col sm={12} className="p-3 pt-1 text-end">
                <Switch
                  checked={Values.iS_FACTURA}
                  onChange={onChangeStatus}
                  id={Values.id}
                  tituloActivo="Desactivar"
                  tituloInactivo="Activar"
                  on
                />
                <label>Es facturable</label>
              </Col>
              {Values.iS_FACTURA ? (
                <>
                  <Col sm={12} md={4} className="p-3">
                    <TextField
                      fullWidth
                      label="Unidad de medida interna"
                      name="unidad_de_medida"
                      helperText={
                        Errores.unidad_de_medida
                          ? "Unidad de medida no valida"
                          : ""
                      }
                      onChange={handlInputChange}
                      value={Values.unidad_de_medida}
                      error={Errores.unidad_de_medida}
                    />
                  </Col>
                  <Col sm={12} md={4} className="p-3">
                    <ProductoServicioSat
                      Value={Values.clave_codigo_prod_sat}
                      Error={Errores.clave_codigo_prod_sat}
                      handlInputChange={handlInputChange}
                    />
                  </Col>
                  <Col sm={12} md={4} className="p-3">
                    <UnidadMedidaSat
                      Value={Values.clave_unidad_medida_sat}
                      Error={Errores.clave_unidad_medida_sat}
                      handlInputChange={handlInputChange}
                    />
                  </Col>
                  <Col sm={12} md={4} className="p-3">
                    <TextField
                      fullWidth
                      id="Moneda"
                      select
                      label="Moneda"
                      name="moneda"
                      helperText={Errores.moneda ? "Moneda no valida" : ""}
                      value={Values.moneda}
                      error={Errores.moneda}
                      onChange={handlInputChange}
                    >
                      {Monedas.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Col>
                  <Col sm={12} md={4} className="p-3">
                    <TextField
                      fullWidth
                      select
                      label="Impuestos"
                      name="iva"
                      helperText={Errores.iva ? "Impuesto no valido" : ""}
                      value={Values.iva}
                      error={Errores.iva}
                      onChange={handlInputChange}
                    >
                      {Impuestos.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Col>
                </>
              ) : (
                ""
              )}
              {Values.iS_SERVICIO && (
                <>
                  <hr />
                  
                  {SucuCola.map((COLABORADOR, index) => (

                    <Row className="p-3" >

                              {<Accordion key={index}>
                                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                  <Typography>Colaboradores</Typography>
                                </AccordionSummary>
                                {COLABORADOR.colaboradores.map((colaborador) => (
                               
                          
                                <Typography key={colaborador.ID}> 
                                
                                <Col sm={4}>

                                    <Switch 
                                      checked={checkedItems.includes(colaborador.ID)}                      
                                      tituloActivo="Desactivar"
                                      tituloInactivo="Activar"
                                      id={colaborador.ID}
                                      onChange={() => SelecColaboraChange(colaborador.ID)}

                                    />
                                                                 
                                   {colaborador.DESCRIPCION}

                               </Col>
                             
                                      
                                </Typography>

                              

                                  ))}
                              </Accordion>}

                      
             
                    </Row>
                  ))}
                </>
              )}
            </Row>
          )}
          <Stack
            className="p-3"
            spacing={2}
            direction={{ xs: "column", sm: "row" }}
            style={{}}
          >
            <LoadingButton
              loading={IsGuardando}
              loadingPosition="start"
              disabled={IsLoading}
              onClick={() => GuardarProducto()}
              className="btn btn-Kalendar font-AvenirMedium  py-2 px-4 "
              variant="contained"
              xs={{ with: "100$" }}
            >
              <span className={IsGuardando ? "px-4" : "px-2"}>
                {IsGuardando ? "Guardando..." : id ? "Guardar" : "Crear"}
              </span>
            </LoadingButton>
            <Button
              onClick={() => navigate(-1)}
              className="btn btn-cancelar font-AvenirMedium py-2 px-4"
              variant="outlined"
            >
              Cancelar
            </Button>
          </Stack>
        </CardContent>
      </Card>
    </>
  );
};

export default Producto;
