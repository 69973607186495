export const printTicket = () => {
    const div = document.getElementById("divTiket");
    const divHtml = div.innerHTML;
    imprimirTexto(divHtml);
    return;
  };

  function imprimirTexto(texto) {
    var paginaTemporal =
      " <style>.border-dotted { font-size: 15px; white-space: nowrap;overflow: hidden;}</style>" +
      " <html><head><title>Tiket de compra</title></head><body>" +
      texto +
      "</body></html>";

    var ventana = window.open("", "");
    ventana.document.write(paginaTemporal);
    ventana.print();
    ventana.focus();
    ventana.close();
  }
