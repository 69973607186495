import * as React from "react";
import { DataGrid, GridColDef, GridValueGetterParams } from "@mui/x-data-grid";
import { formatMoneda } from "../../../Lib/Funciones Generales/Generales";
import { Button, Col, Row } from "react-bootstrap";
import { useState } from "react";
import { procesarPagoPermisosFaltas } from "../Funciones/PermisosFaltas";
import withReactContent from "sweetalert2-react-content";
import { styled } from "@mui/material/styles";
import Swal from "sweetalert2";
import { Alert, Snackbar } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import TablePermisosFaltas from "./TablaPermisosFaltas";
const MySwal = withReactContent(Swal);


const columns = [
  { field: "id", headerName: "Folio", align: "left" },
  { field: "fecha", headerName: "Fecha", align: "left", width: 200 },
  { field: "descripcion", headerName: "Tipo de permiso / falta", align: "left", width: 300 },
  { field: "observacion", headerName: "Observación", align: "left", width: 300 },
];


export default function RepPermisosFaltas({ PermisosFaltas, colaborador, IsLoading, getPermisosFaltasByColabora,
  setPermisosFaltas }) {
  const [open, setOpen] = useState(false);
  const [mensaje, setMensaje] = useState("");



  const handleClose = () => {
    setOpen(false);
  };


  const StyledSnackbar = styled((props) => <Snackbar {...props} />)(
    ({ theme }) => ({
      "& .MuiSnackbar-root": {
        top: theme.spacing(15),
      },
    })
  );



  return (
    <>

      <StyledSnackbar
        direction="right"
        open={open}
        autoHideDuration={6000}
        onClose={handleClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      >
        <Alert onClose={handleClose} severity="error" sx={{ width: "100%" }}>
          {mensaje}
        </Alert>
      </StyledSnackbar>

      <Row className="mt-4">
        <Col sm={12}>
          <TablePermisosFaltas
            Lista={PermisosFaltas ?? []}
            IsLoading={IsLoading}
            setLista={() => { }}
            ValueBusqueda={""}
          />
        </Col>
      </Row>
    </>
  );
}
