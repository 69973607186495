import { Alert, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Snackbar, Stack, TextField, styled } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { Row, Col, Button } from "react-bootstrap";
import { BasicDatePicker } from "../../../Includes/DatePicker";
import { GetDate } from "../../../Lib/Funciones Generales/ObtenerFecha";
import { useEffect, useState } from "react";
import Switch from "../../../Includes/Switch";
import RepPermisosFaltas from "./RepPermisosFaltas";
import { getPermisosFaltas, guardarPermisoFalta } from "../Funciones/PermisosFaltas";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import { LoadingButton } from "@mui/lab";
import TipoPermisosFaltas from "./SelectTipoPermisoFalta";





const MySwal = withReactContent(Swal);

const ModalPermisosFaltas = ({ ValuesColaborador, setShowPermisosFaltas }) => {
  const [fechaInicio, setFechaInicio] = useState(GetDate(-7));
  const [fechaFin, setFechaFin] = useState(GetDate());
  const [IsLoading, setIsLoading] = useState(true);
  const [IsGuardando, setIsGuardando] = useState(false);
  const [PermisosFaltas, setPermisosFaltas] = useState([]);



  const initPermisoFalta = {
    id: 0,
    id_colaborador: ValuesColaborador.id,
    id_sucursal: ValuesColaborador.id_sucursal,
    fecha_falta: "",
    id_tipo_permiso_falta: 0,
    observacion: ""
  }
  const initErrorPermisoFalta = {
    fecha_falta: false,
    id_tipo_permiso_falta: false,
    observacion: false,
  }

  const [open, setOpen] = useState(false);
  const [mensaje, setMensaje] = useState("");
  const [ValuesPermisoFalta, setValuesPermisoFalta] = useState(initPermisoFalta);
  const [errorPermisoFalta, setErrorPermisoFalta] = useState(initErrorPermisoFalta)
  const [showModalPermisoFalta, setShowModalPermisoFalta] = useState(false);

  const GuardarPermisoFalta = () => {
    setIsGuardando(true);
    guardarPermisoFalta(ValuesPermisoFalta, errorPermisoFalta, setErrorPermisoFalta)
      .then((data) => {
        //console.log(data);
        if (data.codigo == "200") {
          setIsGuardando(false);
          setOpen(true)
          setValuesPermisoFalta({ ...initPermisoFalta })
          setMensaje(data.mensaje)
          getPermisosFaltasByColabora();
          setShowModalPermisoFalta(false)
        } else {
          setMensaje(data.mensaje);
          setOpen(true);
          setIsGuardando(false);
        }
      })
      .catch((data) => {
        setMensaje(data.mensaje);
        setOpen(true);
        setIsGuardando(false);
      });
  }
  const handlInputChange = ({ target }) => {
    setValuesPermisoFalta({ ...ValuesPermisoFalta, [target.name]: target.value })
  };




  const handleChangeFechaIn = (event) => {
    var fecha = new Date(event);
    fecha.setDate(fecha.getDate());
    const year = fecha.getFullYear();
    const month = fecha.getMonth() + 1;
    const day = fecha.getDate();
    let FECHA =
      year +
      "-" +
      (month < 10 ? "0" + month : month) +
      "-" +
      (day < 10 ? "0" + day : day);
    setFechaInicio(FECHA);
  };

  const handleChangeFechaFIn = (event) => {
    var fecha = new Date(event);
    fecha.setDate(fecha.getDate());
    const year = fecha.getFullYear();
    const month = fecha.getMonth() + 1;
    const day = fecha.getDate();
    let FECHA =
      year +
      "-" +
      (month < 10 ? "0" + month : month) +
      "-" +
      (day < 10 ? "0" + day : day);
    setFechaFin(FECHA);
  };



  const getPermisosFaltasByColabora = () => {
    setIsLoading(true);

    if (ValuesColaborador.id != undefined) {

      getPermisosFaltas(ValuesColaborador.id, false, fechaInicio, fechaFin)
        .then((resp) => {
          if (resp?.err?.length == 0) {
            MySwal.fire({
              title: "Error",
              html: resp.mensaje,
              icon: "error",
              confirmButtonColor: "#3ABE88",
              showConfirmButton: true,
              allowEscapeKey: false,
              allowEnterKey: false,
              allowOutsideClick: false,
            }).then(() => {
              setIsLoading(false);
            });
          } else {
            setPermisosFaltas(resp);
            setIsLoading(false);
          }
        })
        .catch((resp) => {
          MySwal.fire({
            title: "Error",
            html: resp.mensaje,
            icon: "error",
            confirmButtonColor: "#3ABE88",
            showConfirmButton: true,
            allowEscapeKey: false,
            allowEnterKey: false,
            allowOutsideClick: false,
          }).then(() => {
            setIsLoading(false);
          });
          //setIsLoading(false);
        });
    } else {
      setIsLoading(false);
    }
  };


  useEffect(() => {
    getPermisosFaltasByColabora();
  }, []);


  const StyledSnackbar = styled((props) => <Snackbar {...props} />)(
    ({ theme }) => ({
      "& .MuiSnackbar-root": {
        top: theme.spacing(15),
      },
    })
  );

  return (
    <>



      {
        !showModalPermisoFalta ? (
          <Dialog fullWidth maxWidth="lg" open={true}>
            <IconButton
              aria-label="delete"
              className="m-3"
              style={{ position: "absolute", right: "0%" }}
              onClick={() => {
                setShowPermisosFaltas(false);
              }}
            >
              <CloseIcon size="lg" />
            </IconButton>
            <DialogTitle>
              <h6 className="fw-bolder f-12">Permisos y faltas de {ValuesColaborador.nombre}</h6>
            </DialogTitle>
            <DialogContent style={{ minHeight: "230px" }}>
              <Row>

                <Col xs={12} md={3} className="py-3">
                  <BasicDatePicker
                    className="my-3"
                    fullWidth
                    size="small"
                    format="DD/MM/YYYY"
                    label="Fecha de inicio"
                    fecha={fechaInicio}
                    setFecha={handleChangeFechaIn}
                  />
                </Col>
                <Col xs={12} md={3} className="py-3">
                  <BasicDatePicker
                    fullWidth
                    size="small"
                    format="DD/MM/YYYY"
                    label="Fecha de fin"
                    fecha={fechaFin}
                    setFecha={handleChangeFechaFIn}
                  />
                </Col>

                <Col xs={12} md={3}>
                  <div className="d-flex justify-content-start align-items-center h-100">
                    <Button onClick={() => getPermisosFaltasByColabora()} className="btn btn-Kalendar px-4 " variant="contained">
                      filtrar
                    </Button>
                  </div>
                </Col>

                <Col xs={12} md={3}>
                  <div className="d-flex justify-content-end align-items-center h-100">
                    <Button onClick={() => setShowModalPermisoFalta(true)} className="btn btn-Kalendar px-4 " variant="contained">
                      Crear nuevo
                    </Button>
                  </div>
                </Col>


                <Col xs={12} className="p-3">
                  <RepPermisosFaltas PermisosFaltas={PermisosFaltas}
                    setPermisosFaltas={setPermisosFaltas}
                    getPermisosFaltasByColabora={getPermisosFaltasByColabora}
                    colaborador={ValuesColaborador}
                    IsLoading={IsLoading} />
                </Col>



              </Row>
            </DialogContent>
          </Dialog>
        ) : (
          <>
            <Dialog fullWidth maxWidth="sm" open={true}>
              <IconButton
                aria-label="delete"
                className="m-3"
                style={{ position: "absolute", right: "0%" }}
                onClick={() => {
                  setShowModalPermisoFalta(false);
                }}
              >
                <CloseIcon size="lg" />
              </IconButton>
              <DialogTitle>
                <h6 className="fw-bolder f-12"> Crear nuevo registro</h6>
              </DialogTitle>
              <DialogContent style={{ minHeight: "230px" }}>
                <Row>

                  <Col xs={12} className="p-3">
                    <BasicDatePicker
                      format="DD/MM/YYYY"
                      label="Fecha de falta / permiso"
                      fecha={ValuesPermisoFalta.fecha_falta}
                      setFecha={(fecha) => {
                        setValuesPermisoFalta({ ...ValuesPermisoFalta, fecha_falta: fecha })
                      }}
                    />
                  </Col>

                  <Col sm={12} className="p-3">
                    <TipoPermisosFaltas
                      Value={ValuesPermisoFalta.id_colaborador}
                      Error={errorPermisoFalta.id_colaborador_error}
                      handlInputChange={handlInputChange}
                    />
                  </Col>


                  <Col sm={12} className="p-3">
                    <TextField
                      fullWidth
                      inputProps={{ maxLength: 500 }}
                      name="observacion"
                      value={ValuesPermisoFalta.observacion}
                      error={errorPermisoFalta.observacion}
                      onChange={handlInputChange}
                      label="Observación"
                      multiline
                      rows={3}
                    />
                  </Col>

                </Row>



                <DialogActions>
                  <Stack
                    className="p-3"
                    spacing={2}
                    direction={{ xs: "column", sm: "row" }}
                    style={{}}
                  >
                    <Button
                      onClick={() => {
                        setShowModalPermisoFalta(false)
                      }}
                      className="btn btn-cancelar font-AvenirMedium py-2 px-4"
                      variant="outlined"
                    >
                      Cancelar
                    </Button>

                    <LoadingButton
                      loading={IsGuardando}
                      loadingPosition="start"
                      onClick={() => GuardarPermisoFalta()}
                      className="btn btn-Kalendar font-AvenirMedium  py-2 px-4 ms-2 "
                      variant="contained"
                      xs={{ with: "100%" }}
                    >
                      <span className={IsGuardando ? "px-4" : "px-2"}>
                        {IsGuardando ? "Guardando..." : "Guardar"}
                      </span>
                    </LoadingButton>

                  </Stack>
                </DialogActions>
              </DialogContent>
            </Dialog>


          </>
        )
      }

      <StyledSnackbar
        direction="right"
        open={open}
        autoHideDuration={1500}
        onClose={() => {
          setOpen(false)
        }}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      >
        <Alert onClose={() => {
          setOpen(false)
          setShowModalPermisoFalta(false)
          setValuesPermisoFalta({ ...initPermisoFalta })
        }

        } severity={mensaje.includes("correctamente") ? 'success' : 'error'} sx={{ width: "100%" }}>
          {mensaje}
        </Alert>
      </StyledSnackbar>

    </>



  );
};

export default ModalPermisosFaltas;
