import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import { Col, Row } from "react-bootstrap";
import { Link, Stack, TextField } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import Carrito from "./../carrito";
import BuscarProductos from "./../BuscarProductos";
import Descuentos from "../Descuentos";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const DatosReservacion = ({
  productosCarrito,
  setProductosCarrito,
  Values,
  Errores,
  handlInputChange,
  fecha,
  setFecha,
  ErrorFecha,
  setHora,
  ErrorHora,
  handleShowC,
  setShowModalReserva,
  setValue,
  descuento,
  formasPago,
  setFormasPago,
  setDescuento,
  codigoDesc,
  setCodigoDesc,
}) => {
  return (
    <>
      <Row className="p-0 h-100 modalReserva">
        <Col
          xs={12}
          md={6}
          lg={7}
          className=" ps-1  h-100 "
          style={{ backgroundColor: "#f9fafc" }}
        >
          <div className="d-flex flex-column justify-content-between h-100 ">
            <BuscarProductos
              className="h-100 flex-grow-1"
              productosCarrito={productosCarrito}
              productos={productosCarrito}
              setProductosCarrito={setProductosCarrito}
            />
          </div>
          {/*
      <Descuentos
              className="h-100 flex-grow-1"
              productosCarrito={productosCarrito}
              productos={productosCarrito}
              setProductosCarrito={setProductosCarrito}
              setDescuento={setDescuento}
              descuento={descuento}
            />
            
            <Col sm={12} className="p-1 my-3 d-flex flex-column justify-content-start "> 
            <div
                className="d-flex flex-column justify-content-end mt-3"
                style={{ maxWidth: "500px" }}
              >
                  <TextField
                className="flex-grow-1"
                id="outlined-basic"
                label="Código de descuento"
                placeholder="Ingresa el codigo de descuento"
                fullWidth
                variant="outlined"
                size="small"
              />
                <div className="align-self-end mt-3"> 
                  <Link
                    className=" font-AvenirMedium "
                    variant="text"
                    href="#"
                    underline="none"
                    style={{ color: "#fd8c27", textAlign: "end" }}
                  >
                    Aplicar código
                  </Link>
                </div>
              </div>
              </Col>



  */}
        </Col>
        <Col xs={12} md={6} lg={5} className="p-3 mt-3 pb-0">
          <div className="mb-3">
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <h6 className="my-0  font-AvenirMedium mb-1 ">
                  Añadir descuento
                </h6>
              </AccordionSummary>
              <AccordionDetails>
                <Descuentos
                  className="h-100 flex-grow-1"
                  productosCarrito={productosCarrito}
                  productos={productosCarrito}
                  setProductosCarrito={setProductosCarrito}
                  setDescuento={setDescuento}
                  descuento={descuento}
                  codigoDesc={codigoDesc}
                  setCodigoDesc={setCodigoDesc}
                />
              </AccordionDetails>
            </Accordion>
          </div>

          <div className="d-flex justify-content-between flex-column">
            {/*

 <div
          className="d-flex flex-column justify-content-end mt-3 "
          style={{ maxWidth: "500px" }}
        >
          <TextField
            className="flex-grow-1"
            id="outlined-basic"
            label="Código de descuento"
            placeholder="Ingresa el codigo de descuento"
            fullWidth
            variant="outlined"
            size="small"
          />
          <div className="align-self-end mt-3">
            <Link
              className=" font-AvenirMedium "
              variant="text"
              href="#"
              underline="none"
              style={{ color: "#fd8c27", textAlign: "end" }}
            >
              Aplicar código
            </Link>
          </div>
        </div>
          */}
            <Carrito
              productosCarrito={productosCarrito}
              setProductosCarrito={setProductosCarrito}
              Values={Values}
              Errores={Errores}
              handlInputChange={handlInputChange}
              descuento={descuento}
              formasPago={formasPago}
              setFormasPago={setFormasPago}
              setDescuento={setDescuento}
            />

            <Stack
              className="pt-2 pb-0"
              spacing={2}
              justifyContent="flex-end"
              direction={{ xs: "column", sm: "row" }}
              style={{}}
            >
              <Button
                onClick={() => setShowModalReserva(false)}
                className="btn btn-cancelar font-AvenirMedium py-2 px-4 mx-2 me-sm-0  mt-4 mt-md-2"
                variant="outlined"
              >
                Cancelar
              </Button>

              <LoadingButton
                loadingPosition="start"
                onClick={() => setValue(1)}
                className="btn btn-Kalendar font-AvenirMedium  py-2 px-4  mx-2  mt-2"
                variant="contained"
              >
                <span className={"px-2"}>{"Siguiente"}</span>
              </LoadingButton>
            </Stack>
          </div>
        </Col>
      </Row>
    </>
  );
};
export default DatosReservacion;
