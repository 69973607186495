import { stringify } from "query-string";
import { getData, postdData, postUrl } from "../../../Context/backend";
import validator from "validator";

///////////////////
/* FUNCIONES GET */
///////////////////


export const getOpciones = async (query = "") => {
  let data = [];
  let queryParamsObj = {
    query: query,
  };
  const url = `planes/getOpciones?${stringify(queryParamsObj)}`;
  const res = await getData(url);
  return new Promise((resolve, reject) => {
    if (!res.error) {
      data = res.data.respuesta;
      // console.log(data);
      resolve(data);
    } else {
      reject(res);
    }
  });
};
