export default function Desvincular() {
    return (
      <div className="w-100 mt-5 text-center ">
       <div className="w-50 mt-3 mx-auto">
        <h4 className="font-Avenir font-AvenirBold"> Eliminar mis datos </h4>


        <p class="mt-5 font-Avenir text-start">
                        Para proceder a eliminar sus datos de sesión registrados en Poltrone,
                        realice los siguientes pasos: 

        </p>

        <ul class="text-start font-Avenir mt-4">
                        <li class="mt-1"><strong>1.</strong> Vaya a Configuración y privacidad de su cuenta de Facebook, haga clic en "Configuración"</li>
                        <li class="mt-1"><strong>2.</strong> Busque "Aplicaciones y sitios web" y verá todas las aplicaciones y sitios web que vinculó con su Facebook.</li>
                        <li class="mt-1"><strong>3.</strong> Identifique "Poltrone" en las aplicaciones. </li>
                        <li class="mt-1"><strong>4.</strong> Haga clic en "Eliminar" y confirme con el boton Eliminar</li>
                        <li class="mt-1"><strong>5.</strong> Ya se han eliminado los datos de sesión concedidos.</li>
                    </ul>
        </div>
      </div>
    )
  }