import Login from "./Login/componentes/login";
import Desvincular from "./Politicas/Componentes/Desvincular";
import { useEffect, useState } from "react";
import { getLocalStorageJWT } from "./Context/Storage";
import { PermissionsProvider } from './Context/PermissionsContext';
//import { Helmet } from "react-helmet";
import StartLoad from "./Includes/StartLoad";
import Menu from "./Includes/menu";


const App = () => {
  const [isLoged, setIsLoged] = useState(false);
  const [siDesvincular, setDesvincular] = useState("");
  const [load,setLoad] = useState(true)
  //console.log(siPoliticas)
 
  const getInfoUsuario = async () => {
    if (getLocalStorageJWT() != "") {
      setIsLoged(true);
    }
  };
  useEffect(()=>{
  setLoad(true);
  setDesvincular(window.location.href.slice(window.location.href.length - 11))

  getInfoUsuario().then(
    setTimeout(()=>{

      setLoad(false)
    },1500)
  );
  }, []);
  return load?(
    <StartLoad />

  ) :isLoged ? (
    <>
       <PermissionsProvider>
         <Menu></Menu>
       </PermissionsProvider>
     
    </>
  ) :siDesvincular=== "desvincular" ? (

    <Desvincular />

  ) : (
    <Login />
  );
};
export default App;
