import React from "react";
import {
  Avatar,
  Dialog,
  DialogContent,
  ListItem,
  ListItemButton,
  ListItemAvatar,
  ListItemText,
  DialogActions,
  Button,
  List,
} from "@mui/material";
import dayjs from "dayjs";
import BlockIcon from "@mui/icons-material/Block";
import AddIcon from "@mui/icons-material/Add";

export default function ModalDiaSeleccionado({
  selectedRange,
  setSelectedRange = () => {},
  setShowModalReserva = () => {},
  setShowModalBloquearHorario = () => {},
  setValuesFechaBloqueo = () => {},
  setFechaReserva = () => {},
  valuesFechaBloqueo,
}) {
  return (
    <>
      <Dialog open={true}>
        <DialogContent>
          <h6 className="font-AvenirBold">Selecciona una acción</h6>
          <h6>
            <small>
              {dayjs(selectedRange.start).format("DD-MM-YYYY")}{" "}
              {dayjs(selectedRange.start).format("HH:mm")}Hrs
            </small>
          </h6>
          <List sx={{ pt: 0 }}>
            {dayjs(selectedRange.start).diff(Date.now(), "hours") < 0 ? (
              <></>
            ) : (
              <ListItem disableGutters>
                <ListItemButton
                  autoFocus={false}
                  className="ps-0"
                  onClick={() => {
                    setSelectedRange(null);
                    setShowModalReserva(true);
                  }}
                >
                  <ListItemAvatar>
                    <Avatar>
                      <AddIcon />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText primary="Nueva reservación" />
                </ListItemButton>
              </ListItem>
            )}
            <ListItem disableGutters>
              <ListItemButton
                className="ps-0"
                onClick={() => {
                  const hora_inicio = selectedRange.startStr.substring(11, 16);
                  const hora_fin = selectedRange.endStr.substring(11, 16);
                  setShowModalBloquearHorario(true);
                  // console.log("RANGO: ", hora_inicio, hora_fin, selectedRange)
                  setValuesFechaBloqueo({
                    ...valuesFechaBloqueo,
                    fecha_inicio: selectedRange.startStr,
                    fecha_fin: selectedRange.endStr,
                    hora_inicio,
                    hora_fin,
                  });
                }}
              >
                <ListItemAvatar>
                  <Avatar>
                    <BlockIcon />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText
                  primary="Bloquear horario"
                  secondary="Aún no dísponible"
                />
              </ListItemButton>
            </ListItem>
          </List>
          <DialogActions>
            <Button
              onClick={() => {
                setSelectedRange(null);
                setFechaReserva(null);
              }}
              autoFocus
            >
              Cancelar
            </Button>
          </DialogActions>
        </DialogContent>
      </Dialog>
    </>
  );
}
