import React, { useEffect, useState } from "react";
import {
  Avatar,
  Dialog,
  DialogContent,
  ListItem,
  ListItemButton,
  ListItemAvatar,
  ListItemText,
  DialogActions,
  Button,
  List,
  Chip,
  Stack,
  IconButton,
  Tooltip,
  TextField,
} from "@mui/material";
import dayjs from "dayjs";
import BlockIcon from "@mui/icons-material/Block";
import AddIcon from "@mui/icons-material/Add";
import {
  LoadingButton,
  Timeline,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineItem,
  TimelineSeparator,
} from "@mui/lab";
import TimelineOppositeContent, {
  timelineOppositeContentClasses,
} from "@mui/lab/TimelineOppositeContent";
import Swal from "sweetalert2";
import {
  Eliminar,
  getHorarioById,
  guardarHorario,
} from "../Funciones/Horarios";
import Loading from "../../Includes/Loading";
import CloseIcon from "@mui/icons-material/Close";
import AccEliminar from "../../assets/AccEliminar.svg";
import { BasicTimePicker } from "../../Includes/TimePicker";
import { ConvertTime } from "../../Lib/Funciones Generales/ObtenerFecha";

export default function DialogSetHJorario({
  dataI = [],
  setIsOpenDialogoHorario,
  setRangoSelec,
  getHorarios,
  eventClickInfo,
}) {
  const [IsGuardando, setIsGuardando] = useState(false);
  const [data, setDataa] = useState({
    id: "",
    resource_id: "",
    sucursal: "",
    colaborador: "",
    inicio: "",
    fin: "",
    dia: "",
    diaName: "",
  });
  const [Errores, setErrores] = useState({
    inicio: false,
    fin: false,
  });
  const [IsLoading, setIsLoading] = useState(false);

  const handleInputChange = ({ target }) => {
    const Name = target.name;
    const Value = target.value;

    //console.log(Name, Value);

    setDataa({ ...data, [Name]: Value });
    setErrores({ ...Errores, [Name]: false });
  };

  const GuardarHorario = () => {
    setIsGuardando(true);
    guardarHorario(data, Errores, setErrores)
      .then((data) => {
        //console.log(data);
        if (data.codigo == "200") {
          setIsGuardando(false);
          Swal.fire({
            title: "Correcto",
            html: data.mensaje,
            icon: "success",
            confirmButtoColor: "#3ABE88",
            showConfirmButton: false,
            timer: 1700,
          }).then((result) => {
            setRangoSelec(null);
            getHorarios();
            setIsOpenDialogoHorario(false);
          });
        } else {
          setIsGuardando(false);

          Swal.fire({
            title: "Error",
            html: data.mensaje,
            icon: "info",
            confirmButtoColor: "#3ABE88",
          }).then((result) => {
            //setRangoSelec(null);
            //setIsOpenDialogoHorario(false);
          });
        }
      })
      .catch((data) => {
        setIsGuardando(false);
        Swal.fire({
          title: "Error",
          html: data.mensaje,
          icon: "info",
          confirmButtoColor: "#3ABE88",
        }).then((result) => {});
      });
  };

  const deleteHorario = () => {
    const event = eventClickInfo.event;
    const eventId = event.id;
    const title = event.title;
    let id = eventId;
    new Swal({
      title: "¿Estas seguro de eliminar este cliente?",
      text: "esta acción no se puede deshacer",
      icon: "warning",
      showDenyButton: true,
      denyButtonText: "No, cancelar",
      confirmButtonText: "Si, estoy seguro",
      confirmButtonColor: "#3ABE88",
      denyButtonColor: "#65748B",
      reverseButtons: true,
    }).then(function (isConfirm) {
      //console.log(isConfirm.isConfirmed);
      if (isConfirm.isConfirmed) {
        if (id != undefined) {
          Eliminar(data)
            .then((resp) => {
              new Swal({
                title: "Exito!",
                text: "Eliminado correctamente",
                icon: "success",
              }).then(function () {
                getHorarios();
                setRangoSelec(null);
                setIsOpenDialogoHorario(false);
              });
            })
            .catch((resp) => {
              Swal.fire({
                title: "Error",
                html: resp.mensaje,
                icon: "info",
                confirmButtoColor: "#3ABE88",
              }).then((result) => {});
            });
        }
      }
    });
  };

  useEffect(() => {
    if (dataI.id != 0 && dataI.id != "" && dataI.id != "0") {
      setIsLoading(true);
      //console.log(data);
      getHorarioById(dataI.id)
        .then((d) => {
          let info = JSON.parse(d.data);
          //console.log(info);
          const daysOfWeek = [
            "Domingo",
            "Lunes",
            "Martes",
            "Miércoles",
            "Jueves",
            "Viernes",
            "Sábado",
          ];
          setDataa({
            ...data,
            id: info[0].ID,
            resource_id: info[0].ID_SUCURSAL + "|" + info[0].ID_COLABORADOR,
            sucursal: info[0].NOMBRE_SUC,
            colaborador: info[0].NOMBRE_COLAB,
            inicio: info[0].INICIO,
            fin: info[0].FIN,
            dia: info[0].DIA,
            diaName: daysOfWeek[info[0].DIA],
          });
          //console.log(data);
          setIsLoading(false);
        })
        .catch((d) => {
          console.log(d);
          setIsLoading(false);
        });
    } else {
      setDataa(dataI);
    }
  }, []);

  return (
    <>
      <Dialog open={true} maxWidth="lg" className="w-100">
        {dataI.id != 0 && dataI.id != "" && dataI.id != "0" && (
          <Tooltip
            className="me-2"
            title="Eliminar horario"
            placement="top"
            style={{ position: "absolute", right: "50px", top: "18px" }}
          >
            <IconButton
              onClick={() => {
                deleteHorario();
              }}
            >
              <img src={AccEliminar} />
            </IconButton>
          </Tooltip>
        )}

        <IconButton
          aria-label="delete"
          className="m-3"
          style={{ position: "absolute", right: "0%" }}
          onClick={() => {
            setRangoSelec(null);
            setIsOpenDialogoHorario(false);
          }}
          //disabled={IsLoading}
        >
          <CloseIcon size="lg" />
        </IconButton>
        <DialogContent>
          {IsLoading ? (
            <>
              <div className="w-100 mt-5 mb-1">
                <Loading />
              </div>
            </>
          ) : (
            <>
              <h5 className="font-AvenirBold w-100">Horario de colaborador</h5>

              <div className="w-100 mt-4">
                <div className="d-flex  justify-content-between">
                  <div className="text-center me-2">
                    <h6 className="font-AvenirBold">Sucursal</h6>
                    <h6 className="font-AvenirMedium">{data.sucursal}</h6>
                  </div>
                  <div className=" text-center ms-2">
                    <h6 className="font-AvenirBold">Colaborador</h6>
                    <h6 className="font-AvenirMedium">{data.colaborador}</h6>
                  </div>
                </div>

                <center>
                  <Chip
                    className="mt-3"
                    label={data.diaName}
                    color="primary"
                    variant="outlined"
                    style={{ fontWeight: "bolder", fontSize: "20px" }}
                  />

                  <Timeline className="mt-2 mb-0">
                    <TimelineItem>
                      <TimelineOppositeContent color="textSecondary">
                        <BasicTimePicker
                          size="small"
                          label={""}
                          setTime={(a) => {
                            handleInputChange({
                              target: {
                                name: "inicio",
                                value: ConvertTime(a).slice(0, -3),
                              },
                            });
                            //console.log(ConvertTime(a).slice(0, -3));
                          }}
                          hora={data.inicio}
                        />
                        {/*
                        <TextField
                          size="small"
                          value={data.inicio}
                          error={Errores.inicio}
                          helperText={
                            Errores.inicio ? "Formato incorrecto" : ""
                          }
                          inputProps={{ maxLength: 5 }}
                          name="inicio"
                          onChange={handleInputChange}
                        />
                        
                        data.inicio
                        */}
                      </TimelineOppositeContent>
                      <TimelineSeparator>
                        <TimelineDot color="success" />
                        <TimelineConnector />
                      </TimelineSeparator>
                      <TimelineContent>Inicio </TimelineContent>
                    </TimelineItem>
                    <TimelineItem>
                      <TimelineOppositeContent color="textSecondary">
                        <BasicTimePicker
                          size="small"
                          label={""}
                          setTime={(a) => {
                            handleInputChange({
                              target: {
                                name: "fin",
                                value: ConvertTime(a).slice(0, -3),
                              },
                            });
                            //console.log(ConvertTime(a).slice(0, -3));
                          }}
                          hora={data.fin}
                        />
                        {/*
                        <TextField
                          size="small"
                          value={data.fin}
                          error={Errores.fin}
                          helperText={Errores.fin ? "Formato incorrecto" : ""}
                          inputProps={{ maxLength: 5 }}
                          name="fin"
                          onChange={handleInputChange}
                        />
                        data.fin
                        */}
                      </TimelineOppositeContent>
                      <TimelineSeparator>
                        <TimelineDot color="info" />
                      </TimelineSeparator>
                      <TimelineContent>Fin</TimelineContent>
                    </TimelineItem>
                  </Timeline>
                  <label className="text-muted font-Avenir">
                    ** Al modififcar los horarios se verificará que<br></br>no
                    existan reservaciones pendientes **{" "}
                  </label>
                </center>
              </div>
            </>
          )}
        </DialogContent>
        <DialogActions>
          <Stack
            className="p-3"
            spacing={2}
            justifyContent="flex-end"
            direction={{ xs: "column", sm: "row" }}
            style={{}}
          >
            <Button
              onClick={() => {
                setRangoSelec(null);
                setIsOpenDialogoHorario(false);
              }}
              className="btn btn-cancelar font-AvenirMedium py-2 px-4"
              variant="outlined"
            >
              Cancelar
            </Button>

            <LoadingButton
              loading={IsGuardando}
              disabled={IsLoading}
              loadingPosition="start"
              onClick={() => {
                GuardarHorario();
              }}
              className="btn btn-Kalendar font-AvenirMedium  py-2 px-4  mx-2  "
              variant="contained"
            >
              <span className={IsGuardando ? "px-4" : "px-2"}>
                {IsGuardando ? "Guardando..." : "Guardar"}
              </span>
            </LoadingButton>
          </Stack>
        </DialogActions>
      </Dialog>
    </>
  );
}
