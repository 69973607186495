import { useContext } from 'react';
import { createContext, useState } from 'react';

  const PermissionsContext = createContext();

  const PermissionsProvider = ({ children }) => {
  const [userPermissions, setUserPermissions] = useState([]);
   const [IsLoading, setIsLoading] = useState(true);

   
  const handleSetUserPermissions = (permissions) => {

 
    let Permisos = [];
    const x = permissions.map((row) => { 
      row.permisos.map((row2) => {
        if (row2.tienepermiso) { 
          permissions.push(row2.nombre);
          setUserPermissions(permissions);
        }
      });
    });  
  };

  return (
    <PermissionsContext.Provider value={{ userPermissions, setUserPermissions, handleSetUserPermissions, IsLoading, setIsLoading }}>
      {children}
    </PermissionsContext.Provider>
  );
 

};  

const usePermisos = () => {
    const context = useContext(PermissionsContext);
    if (!context) {
      throw new Error('useCartInfo must be used within a PermissionsContext');
    }
    return context;
  };
  
  export { PermissionsProvider, usePermisos };
  