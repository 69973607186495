import { getData, postdData, postUrl } from "../../Context/backend";
import { stringify } from "query-string";

///////////////////
/* FUNCIONES GET */
///////////////////
export const getLista = async (
  fecha_inicio,
  fecha_final,
  id_colaborador,
  id_sucursal
) => {
  if (id_colaborador == "" && id_colaborador == undefined) {
    id_colaborador = "0";
  }
  if (id_sucursal == "" && id_sucursal == undefined) {
    id_sucursal = "0";
  }

  let data = [];
  let queryParamsObj = {
    fecha_inicio: fecha_inicio,
    fecha_final: fecha_final,
    id_colaborador: id_colaborador,
    id_sucursal: id_sucursal,
  };

  const url = `reservaciones/Listar?${stringify(queryParamsObj)}`;
  const res = await getData(url);
  return new Promise((resolve, reject) => {
    if (!res.error) {
      data = res.data.respuesta;

      resolve(data);
    } else {
      reject(res);
    }
  });
};

export const getListaDiasBloqueados = async (id_colaborador, id_sucursal) => {
  if (id_colaborador == "" || id_colaborador == undefined) {
    id_colaborador = "0";
  }
  if (id_sucursal == "" || id_sucursal == undefined) {
    id_sucursal = "0";
  }
  let data = [];
  let queryParamsObj = { id_colaborador, id_sucursal };
  const url = `reservaciones/ListarDiasBloqueados?${stringify(queryParamsObj)}`;
  const res = await getData(url);
  return new Promise((resolve, reject) => {
    if (!res.error) {
      data = res.data.respuesta;
      resolve(data);
    } else {
      reject(res);
    }
  });
};

export const getListaColaboradoresBloqueadosByHorario = async (
  id_sucursal,
  id_horario
) => {
  if (id_sucursal == "" || id_sucursal == undefined) {
    id_sucursal = "0";
  }
  let data = [];
  let queryParamsObj = { id_sucursal, id_horario };
  const url = `reservaciones/ListarColaboradoresBloqueadosByHorario?${stringify(
    queryParamsObj
  )}`;
  const res = await getData(url);
  return new Promise((resolve, reject) => {
    console.log("RESPUESTA: ", res);
    if (!res.error) {
      data = res.data.respuesta;
      resolve(data);
    } else {
      reject(res);
    }
  });
};

export const getReservacionBYid = async (id) => {
  let data = [];
  let detalle = [];
  let queryParamsObj = {
    ID: id,
  };
  const url = `reservaciones/Consultar?${stringify(queryParamsObj)}`;
  const res = await getData(url);
  return new Promise((resolve, reject) => {
    if (!res.error) {
      data = JSON.parse(res.data.respuesta.data)[0];
      detalle = JSON.parse(res.data.respuesta.detalle);
      //console.log(data);
      let Values = {
        id: String(data.ID),
        formato_completo: String(data.FORMATO_COMPLETO),
        fecha_reservacion: String(data.FECHA_RESERVACION_),
        hora: String(data.HORA),
        total: String(data.TOTAL),
        subtotal: String(data.SUBTOTAL),
        descuento: String(data.DESCUENTO),
        tipo_descuento: String(data.TIPO_DESCUENTO),
        pagado: Boolean(data.PAGADO),
        duracion: String(data.DURACION),
        notas: String(data.NOTAS),
        nombre_colaborador: String(data.NOMBRE_COLABORADOR),
        sucursal: String(data.SUCURSAL),
        direccion: String(data.DIRECCION),
        cliente: String(data.CLIENTE),
        apellidos: String(data.APELLIDOS),
        img_emp: String(data.IMG_EMP),
        nombre_empresa: String(data.NOMBRE_EMPRESA),
        correo: String(data.CORREO),
        img_cliente: String(data.IMG_CLIENTE),
        img_colab: String(data.IMG_COLAB),
        estatus: String(data.ESTATUS),
        efectivo: data.EFECTIVO,
        tarjeta: data.TARJETA,
        monedero: data.MONEDERO,
        otros: data.OTROS,
        id_colaborador: data.ID_COLAB,
        id_sucursal: data.ID_SUCURSAL,
        id_cliente: data.ID_CLIENTE,
        is_confirmado: Boolean(data.IS_CONFIRMADO),
      };
      //console.log(Values);
      resolve({ Values: Values, detalle: detalle });
    } else {
      reject({ Values: data, detalle: detalle });
    }
  });
};

////////////////////
/* FUNCIONES POST */
////////////////////
export const guardar = async (
  Values,
  Errores,
  setErrores,
  productosCarrito,
  descuento,
  formasPago
) => {
  let error = false;

  /* REQUERIDOS */
  if (!Values.fecha_de_reservacion || Values.fecha_de_reservacion == "") {
    Errores.fecha_de_reservacion = true;
    setErrores(Errores);
    error = true;
  }

  if (!Values.hora_de_reserva || Values.hora_de_reserva == "") {
    Errores.hora_de_reserva = true;
    setErrores(Errores);
    error = true;
  }

  if (!Values.id_cliente || Values.id_cliente == "") {
    Errores.id_cliente = true;
    setErrores(Errores);
    error = true;
  }

  if (
    !Values.id_colaborador ||
    Values.id_colaborador == "" ||
    Values.id_colaborador == 0 ||
    Values.id_colaborador == "0"
  ) {
    Errores.id_colaborador = true;
    setErrores(Errores);
    error = true;
  }

  if (!Values.id_sucursal || Values.id_sucursal == "") {
    Errores.id_sucursal = true;
    setErrores(Errores);
    error = true;
  }
  if (productosCarrito.length == 0) {
    // error = true;
  }
  Values.productos = productosCarrito;

  let Total = 0;
  let Duracion = 0;
  productosCarrito.map((row) => {
    Total += row.PRECIO * row.CANTIDAD;
    Duracion += row.DURACION;
  });

  Values.total = Total;
  Values.duracion = Duracion;
  Values.descuento = descuento.descuento;
  Values.tipo_descuento = descuento.tipo;

  let totalDescuento =
    descuento.tipo == 0
      ? 0
      : descuento.tipo == 1
      ? (Total * descuento.descuento) / 100
      : descuento.descuento;

  let cambio = 0;
  let TotalPagado = 0;

  Total = Total - totalDescuento;
  let efectivo =
    formasPago.efectivo.monto == "" || formasPago.efectivo.monto == undefined
      ? 0
      : parseFloat(formasPago.efectivo.monto);
  let tarjeta =
    formasPago.tarjeta.monto == "" || formasPago.tarjeta.monto == undefined
      ? 0
      : parseFloat(formasPago.tarjeta.monto);
  let monedero =
    formasPago.monedero.monto == "" || formasPago.monedero.monto == undefined
      ? 0
      : parseFloat(formasPago.monedero.monto);
  let otros =
    formasPago.otros.monto == "" || formasPago.otros.monto == undefined
      ? 0
      : parseFloat(formasPago.otros.monto);

  TotalPagado = efectivo + tarjeta + monedero + otros;

  if (formasPago.efectivo.monto != 0) {
    cambio = TotalPagado - Total;
  }

  if (TotalPagado < Total && Values.pagado) {
    return new Promise((resolve, reject) => {
      resolve({
        mensaje: "Los montos ingresados no son suficientes para cubrir el pago",
      });
    });
  }

  Values.efectivo = efectivo;
  Values.tarjeta = tarjeta;
  Values.monedero = monedero;
  Values.otros = otros;
  Values.SI_PAGO = true;
  //console.log(Errores);

  if (!error) {
    let data = [];
    Values.id = Number(Values.id);
    const body = Values;
    const url =
      Values.id == undefined || Values.id == "" || Values.id == 0
        ? "reservaciones/Insertar"
        : "reservaciones/Editar";
    const res = /* {
      error: false,
      data: { codigo: "201", mensaje: "prueba correcta" },
    }; */ await postdData(url, body);
    //console.log(body);
    //console.log(url);
    return new Promise((resolve, reject) => {
      if (!res.error) {
        data = res.data;
        //console.log(data);
        resolve(data);
      } else {
        reject(res);
      }
    });
  } else {
    return new Promise((resolve, reject) => {
      resolve({ mensaje: "Completa todos los campos requeridos" });
    });
  }
};

export const pagarReservacion = async (
  idReserv,
  formasPago,
  totalPagado,
  total,
  cambio
) => {
  let error = false;

  if (totalPagado < total) {
    return new Promise((resolve, reject) => {
      resolve({
        mensaje: "Los montos ingresados no son suficientes para cubrir el pago",
      });
    });
  }

  if (formasPago.tarjeta + formasPago.monedero + formasPago.otros > total) {
    /*
  return new Promise((resolve, reject) => {
    resolve({
      mensaje: "Los montos ingresados ",
    });
  });
  */
  }
  let data = [];
  const body = {
    id: idReserv,
    efectivo: formasPago.efectivo.monto,
    tarjeta: formasPago.tarjeta.monto,
    monedero: formasPago.monedero.monto,
    otros: formasPago.otros.monto,
    incentivos: formasPago.incentivo.monto,
    total_Pagado: totalPagado,
  };

  console.log(body);

  const url = "reservaciones/pagarReservacion";
  const res = await postdData(url, body);
  return new Promise((resolve, reject) => {
    if (!res.error) {
      data = res.data;
      console.log(data);
      resolve(data);
    } else {
      reject(res);
    }
  });
};

export const cambiarEstatusReserva = async (id, estatus) => {
  if (id != undefined) {
    let data = [];
    let queryParamsObj = {
      id: id,
      estatus: estatus,
    };
    const url = `reservaciones/cambiarEstatusReserva?${stringify(
      queryParamsObj
    )}`;
    const res = await postdData(url);
    return new Promise((resolve, reject) => {
      if (!res.error) {
        data = res.data;
        resolve(data);
      } else {
        reject(res);
      }
    });
  } else {
    return new Promise((resolve, reject) => {
      resolve({ mensaje: "No se pudo obtener el identificador" });
    });
  }
};

export const cancelarReserva = async (id) => {
  if (id != undefined) {
    let data = [];
    let queryParamsObj = {
      id: id,
    };
    const url = `reservaciones/cancelarReservacion?${stringify(
      queryParamsObj
    )}`;
    const res = await postdData(url);
    return new Promise((resolve, reject) => {
      if (!res.error) {
        data = res.data;
        resolve(data);
      } else {
        reject(res);
      }
    });
  } else {
    return new Promise((resolve, reject) => {
      resolve({ mensaje: "No se pudo obtener el identificador" });
    });
  }
};

export const getOpciones = async (query = "") => {
  let data = [];
  let queryParamsObj = {
    query: query,
  };
  const url = `sucursales/getOpciones?${stringify(queryParamsObj)}`;
  const res = await getData(url);
  return new Promise((resolve, reject) => {
    if (!res.error) {
      data = res.data.respuesta;
      resolve(data);
    } else {
      reject(res);
    }
  });
};

export const actualizarHoraReserva = async (id, fecha, id_colab = 0) => {
  if (id != undefined) {
    let data = [];
    let queryParamsObj = {
      ID: id,
      FECHA: fecha,
      ID_COLAB: id_colab,
    };
    const url = `reservaciones/actualizarFechaReserva?${stringify(
      queryParamsObj
    )}`;
    const res = await postdData(url);
    return new Promise((resolve, reject) => {
      if (!res.error) {
        data = res.data;
        console.log(data);
        resolve(data);
      } else {
        reject(res);
      }
    });
  } else {
    return new Promise((resolve, reject) => {
      resolve({ mensaje: "No se pudo obtener el identificador" });
    });
  }
};

export const validarDisponibilidadColaborador = async (
  id_sucursal,
  id_colaborador,
  fecha_reservacion,
  hora_reservacion
) => {
  if (id_colaborador != undefined && id_sucursal != undefined) {
    let data = [];
    let queryParamsObj = {
      id_sucursal,
      id_colaborador,
      fecha_reservacion,
      hora_reservacion,
    };
    const url = `reservaciones/validarDisponibilidadColaborador?${stringify(
      queryParamsObj
    )}`;
    const res = await getData(url);
    return new Promise((resolve, reject) => {
      if (!res.error) {
        let mensaje = res.data.respuesta.data;
        resolve({ mensaje });
      } else {
        reject(res);
      }
    });
  } else {
    return new Promise((resolve, reject) => {
      resolve({ mensaje: "No se pudo obtener el identificador" });
    });
  }
};

export const guardarHorarioBloqueado = async (
  Values,
  SelectColaboradores,
  banderaSelectColaboradores,
  Errores,
  setErrores
) => {
  let error = false;
  SelectColaboradores = Array.isArray(SelectColaboradores)
    ? SelectColaboradores
    : [];
  SelectColaboradores = SelectColaboradores.filter((x) => x != -1 && x != 0);

  if (SelectColaboradores.length == 0) {
    return new Promise((resolve, reject) => {
      resolve({ mensaje: "Selecciona por lo menos un colaborador" });
    });
  }

  if (!error) {
    let data = [];

    let queryParamsObj = {};
    const url =
      Values.id == undefined || Values.id == "" || Values.id == 0
        ? "reservaciones/InsertarHorarioBloqueado"
        : "reservaciones/EditarHorarioBloqueado";

    let { fecha_inicio, fecha_fin, hora_inicio, hora_fin } = Values;

    if (typeof fecha_inicio != "string") {
      fecha_inicio =
        fecha_inicio.toISOString().substring(0, 10) + "T" + hora_inicio;
    } else {
      fecha_inicio = fecha_inicio.substring(0, 10) + "T" + hora_inicio;
    }

    if (typeof fecha_fin != "string") {
      fecha_fin = fecha_fin.toISOString().substring(0, 10) + "T" + hora_fin;
    } else {
      fecha_fin = fecha_fin.substring(0, 10) + "T" + hora_fin;
    }

    const body = {
      id: Values.id,
      fecha_inicio,
      fecha_fin,
      si_todo_colaborador: banderaSelectColaboradores ? 1 : 0,
      colaboradores: banderaSelectColaboradores ? [] : SelectColaboradores,
      id_sucursal: Values.id_sucursal,
      motivo_etiqueta: Values.motivo_etiqueta,
    };
    console.log("CUERPO: ", body);

    // return;

    const res = await postdData(url, body);

    return new Promise((resolve, reject) => {
      if (!res.error) {
        data = res.data;
        resolve(data);
      } else {
        reject(res);
      }
    });
  } else {
    console.log("CON ERROR");
    return new Promise((resolve, reject) => {
      resolve({ mensaje: "Completa todos los campos requeridos" });
    });
  }
};

export const eliminarHorarioBloqueado = async (id) => {
  if (id != undefined) {
    let data = [];
    let queryParamsObj = { id };
    const url = `reservaciones/eliminarHorarioBloqueado?${stringify(
      queryParamsObj
    )}`;
    const res = await postdData(url);
    return new Promise((resolve, reject) => {
      if (!res.error) {
        data = res.data;
        console.log(data);
        resolve(data);
      } else {
        reject(res);
      }
    });
  } else {
    return new Promise((resolve, reject) => {
      resolve({ mensaje: "No se pudo obtener el identificador" });
    });
  }
};

export const AsistenciaConfirmacion = async (token, estatus) => {
  if (token != undefined) {
    let data = [];
    let queryParamsObj = {
      token: token,
      estatus: estatus,
    };
    const url = `reservaciones/AsistenciaConfirmacion?${stringify(
      queryParamsObj
    )}`;
    const res = await postUrl(url);
    return new Promise((resolve, reject) => {
      if (!res.error) {
        data = res.data;
        resolve(data);
      } else {
        reject(res);
      }
    });
  } else {
    return new Promise((resolve, reject) => {
      resolve({ mensaje: "No se pudo obtener el uuid" });
    });
  }
};

export const getEstatusAsistenciaConfirmacion = async (token) => {
  if (token != undefined) {
    let data = [];
    let detalle = [];
    let queryParamsObj = {
      token: token,
    };
    const url = `reservaciones/getEstatusAsistenciaConfirmacion?${stringify(
      queryParamsObj
    )}`;
    const res = await getData(url);
    return new Promise((resolve, reject) => {
      if (!res.error) {
        data = JSON.parse(res.data.respuesta.data);

        resolve(data);
      } else {
        reject(false);
      }
    });
  } else {
    return new Promise((resolve, reject) => {
      reject(false);
    });
  }
};
