import { stringify } from "query-string";
import { getData, postUrl } from "./../../../../../Context/backend";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import {
  GetDate,
  isValidDate,
} from "../../../../../Lib/Funciones Generales/ObtenerFecha";

const MySwal = withReactContent(Swal);

export const crearDia = () => {
  return {
    ID: 0,
    FECHA_HORARIO: GetDate(),
    ACTIVO: true,
    INICIO: "00:00",
    FIN: "00:00",
  };
};

export const getListaTemplateHorario = (FechaEvento) => {
  const newHorario = crearDia();
  validarHorario([], FechaEvento, newHorario, -1);
  //console.log("ASDASD", newHorario);
  return [newHorario];
};

export const getLista = async (evento) => {
  let data = [];
  let queryParamsObj = {
    evento: evento,
  };
  const url = `eventos/ListarHorarios?${stringify(queryParamsObj)}`;
  const res = await getData(url);
  return new Promise((resolve, reject) => {
    if (!res.error) {
      data = res.data.respuesta;
      resolve(data);
    } else {
      reject(res);
    }
  });
};

export const Eliminar = async (id) => {
  if (id != undefined) {
    let data = [];
    let queryParamsObj = {
      id: id,
    };
    const url = `eventos/EliminarHorario?${stringify(queryParamsObj)}`;
    const res = await postUrl(url);
    return new Promise((resolve, reject) => {
      if (!res.error) {
        data = res.data;
        resolve(data);
      } else {
        reject(res);
      }
    });
  } else {
    return new Promise((resolve, reject) => {
      resolve({ mensaje: "No se pudo obtener el identificador" });
    });
  }
};

export const validarHorario = (Horarios, FechaEvento, horario, index) => {
  let errorHorario = false;
  const FechaFake = "1999-01-01";

  let error = false;
  let errorFecha = false;
  let errorHora = false;
  let repetido = null;
  horario.ERROR_HORARIO = false;

  if (new Date(horario.FECHA_HORARIO) == "Invalid Date") {
    errorFecha = true;
    error = true;
    horario.ERROR_HORARIO = true;
    horario.MENSAJE_HORARIO = "Fecha no disponible";
  } else {
    if (
      horario.FECHA_HORARIO == "" ||
      horario.FECHA_HORARIO == null ||
      !isValidDate(horario.FECHA_HORARIO)
    ) {
      errorFecha = true;
      error = true;
      horario.ERROR_HORARIO = true;
      horario.MENSAJE_HORARIO = "Fecha invalida";
    } else {
      if (horario.FECHA_HORARIO.includes("NaN")) {
        errorFecha = true;
        error = true;
        horario.ERROR_HORARIO = true;
        horario.MENSAJE_HORARIO = "Fecha invalida";
      } else {
        errorFecha = false;
        error = false;
        horario.ERROR_HORARIO = false;
        horario.MENSAJE_HORARIO = "";
      }
    }
  }

  if (!errorFecha) {
    horario.ERROR_HORARIO = false;
    horario.MENSAJE_HORARIO = "";
    const fechaHorario = new Date(horario.FECHA_HORARIO);
    const fechaEvento = new Date(FechaEvento);
    if (!errorFecha) {
      if (fechaHorario > fechaEvento) {
        error = true;
        errorFecha = true;
        horario.ERROR_HORARIO = true;
        horario.MENSAJE_HORARIO = "Fecha no disponible";
      }
    }

    if (!errorFecha) {
      Horarios.map((h, i) => {
        if (h.FECHA_HORARIO == horario.FECHA_HORARIO && i != index) {
          repetido = h;
        }
      });
      if (repetido) {
        error = true;
        horario.ERROR_HORARIO = true;
        horario.MENSAJE_HORARIO = "Fecha repetida";
      } else {
        horario.ERROR_HORARIO = false;
        horario.MENSAJE_HORARIO = "";
      }
    }
  }

  if (horario.INICIO.includes("NaN")) {
    errorHora = true;
    error = true;
    horario.ERROR_INICIO = true;
    horario.MENSAJE_INICIO = "Formato invalido";
  } else {
    horario.ERROR_INICIO = false;
    horario.MENSAJE_INICIO = "";
  }
  if (horario.FIN.includes("NaN")) {
    errorHora = true;
    error = true;
    horario.ERROR_FIN = true;
    horario.MENSAJE_FIN = "Formato invalido";
  } else {
    horario.ERROR_FIN = false;
    horario.MENSAJE_FIN = "";
  }

  if (!errorHora) {
    if (horario.INICIO == horario.FIN) {
      error = true;
      errorHora = true;
      horario.ERROR_INICIO = true;
      horario.ERROR_FIN = true;
      horario.MENSAJE_INICIO = "La hora es igual";
      horario.MENSAJE_FIN = "La hora es igual";
    } else {
      horario.ERROR_INICIO = false;
      horario.ERROR_FIN = false;
      horario.MENSAJE_INICIO = "";
      horario.MENSAJE_FIN = "";
    }
  }

  if (!errorHora) {
    if (
      new Date(FechaFake + " " + horario.INICIO) >
      new Date(FechaFake + " " + horario.FIN)
    ) {
      errorHora = true;
      error = true;
      horario.ERROR_INICIO = true;
      horario.MENSAJE_INICIO = "El inicio es mayor al fin";
    } else {
      horario.ERROR_INICIO = false;
      horario.MENSAJE_INICIO = "";
    }
  }
  if(!horario.ERROR_INICIO && !horario.ERROR_FIN){
    horario.ERROR_HORARIO = false;
    error = false;
  }
  if (error) {
    errorHorario = true;
  }
  horario.error = error;

  return { horario: horario, error: errorHorario };
};

export const validarHorarios = (Horarios, FechaEvento, fnCallback) => {
  if (Array.isArray(Horarios)) {
    let errorHorario = false;
    const newList = Horarios.map((horario, index) => {
      const validador = validarHorario(Horarios, FechaEvento, horario, index);
      errorHorario = validador.error;
      return validador.horario;
    });
    fnCallback(errorHorario, newList);
  }
};
