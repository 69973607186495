import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import { Col, Row } from "react-bootstrap";
import { Stack, TextField } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import Carrito from "./../carrito";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import SelectSucursal from "../../../Configuraciones/Sucursales/Componentes/SelectSucursal";
import Colaboradores from "../../../Catalogos/Colaboradores/Componentes/SelectColaboradores";
import HelperError from "../../../Includes/HelperError";
import { BasicTimePicker } from "../../../Includes/TimePicker";
import { BasicDatePicker } from "../../../Includes/DatePicker";
import ClientesSelect from "../../../Catalogos/Clientes/Componentes/SelectClientes";
import { generarHorarios2 } from "../../../Lib/Funciones Generales/Generales";
import SelectHoarios from "../../../Configuraciones/Sucursales/Componentes/SelectHorarios";
import { validarDisponibilidadColaborador } from "../../Funciones/Reservaciones";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import dayjs from "dayjs";
import { GetDate } from "../../../Lib/Funciones Generales/ObtenerFecha";
import SelectHorariosColab from "../../../Catalogos/Colaboradores/Componentes/SelectHorariosColab";

const MySwal = withReactContent(Swal);

const Pago = ({
  productosCarrito,
  setProductosCarrito = () => {},
  Values,
  Errores,
  handlInputChange = () => {},
  fecha,
  setFecha = () => {},
  ErrorFecha,
  hora,
  setHora = () => {},
  ErrorHora,
  handleShowC = () => {},
  setShowModalReserva = () => {},
  IsGuardando,
  GuardarReserva = () => {},
  setDescuento = () => {},
  descuento,
  formasPago,
  setFormasPago = () => {},
  setValue = () => {},
  setValuesReservacion = () => {},
  config,
  fechaMin,
}) => {
  /*useEffect(() => {
    setHora("00:01");
  }, []);*/
  const [dataConfig, setDataConfig] = useState({});
  useEffect(() => {
    if (config.incrementos_horarios != "") {
      setDataConfig(config);
      //console.log("CONFIG: ", config);
    }
  }, [config]);
  /*useEffect(() => {
    console.log(Values);
  }, [Values]);*/
  //console.log("VALUES: ", Values.id_colaborador)
  //console.log(config);

  return (
    <>
      <Row className="p-0 h-100">
        <Col
          xs={12}
          md={6}
          lg={7}
          className="p-0"
          style={{ backgroundColor: "#f9fafc" }}
        >
          <div className=" ">
            {/*
<Descuentos
              className="h-100 flex-grow-1"
              productosCarrito={productosCarrito}
              productos={productosCarrito}
              setProductosCarrito={setProductosCarrito}
              setDescuento={setDescuento}
              descuento={descuento}
            />
 <div
          className="d-flex flex-column justify-content-end mt-3 "
          style={{ maxWidth: "500px" }}
        >
          <TextField
            className="flex-grow-1"
            id="outlined-basic"
            label="Código de descuento"
            placeholder="Ingresa el codigo de descuento"
            fullWidth
            variant="outlined"
            size="small"
          />
          <div className="align-self-end mt-3">
            <Link
              className=" font-AvenirMedium "
              variant="text"
              href="#"
              underline="none"
              style={{ color: "#fd8c27", textAlign: "end" }}
            >
              Aplicar código
            </Link>
          </div>
        </div>
          */}

            <Col md={12} className="p-3">
              <Carrito
                productosCarrito={productosCarrito}
                setProductosCarrito={setProductosCarrito}
                Values={Values}
                Errores={Errores}
                handlInputChange={handlInputChange}
                descuento={descuento}
                formasPago={formasPago}
                setFormasPago={setFormasPago}
                setDescuento={setDescuento}
                isVista={true}
              />
            </Col>
          </div>
        </Col>
        <Col xs={12} md={6} lg={5}>
          <Row>
            <Col md={12} className="p-3">
              <h6 className="my-0  font-AvenirMedium mb-1 ">
                Datos de reservación
              </h6>
            </Col>

            <Col sm={12} className="py-3">
              <SelectSucursal
                size="small"
                Value={Values.id_sucursal}
                Error={Errores.id_sucursal}
                handlInputChange={handlInputChange}
                Name="id_sucursal"
              />
            </Col>
            <Col sm={12} className="py-3">
              <Colaboradores
                size="small"
                Value={Values.id_colaborador}
                Error={Errores.id_colaborador}
                id_sucursal={Values.id_sucursal}
                handlInputChange={handlInputChange}
                disabled={Values.id_sucursal === "" ? true : false}
                id={Values.id}
              />
            </Col>
            <Col xs={12} md={7} className="py-3">
              <BasicDatePicker
                size="small"
                format="DD/MM/YYYY"
                label="Fecha"
                fecha={fecha}
                setFecha={setFecha}
                fechaMin={fechaMin}
              />
              {
                <HelperError
                  Error={ErrorFecha}
                  Mensaje="Error en la Fecha de la reservación"
                />
              }
            </Col>

            <Col xs={12} md={5} className="py-3">
              {/*<SelectHoarios
                //config={config}
                configData={dataConfig}
                Value={hora}
                fecha={Values.fecha_de_reservacion}
                Error={Errores.hora_de_reserva}
                id_sucursal={Values.id_sucursal}
                handlInputChange={({ target }) => {
                  //console.log(target.value);
                  setHora(target.value);
                }}
                disabled={Values.id_sucursal === "" ? true : false}
              />*/}
              <SelectHorariosColab
                configData={dataConfig}
                Value={hora}
                fecha={Values.fecha_de_reservacion}
                sucursal={Values.id_sucursal}
                Error={Errores.hora_de_reserva}
                id_colaborador={Values.id_colaborador}
                handlInputChange={({ target }) => {
                  //console.log(target.value);
                  setHora(target.value);
                }}
                disabled={Values.id_colaborador === "" ? true : false}
              />
              <HelperError
                Error={ErrorHora}
                Mensaje="Error en la hora de reserva"
              />
            </Col>

            {/*

 <Col xs={12} md={5} className="py-3">
              <BasicTimePicker
                size="small"
                label="Hora"
                hora={Values.hora_de_reserva}
                setTime={setHora}
              />
              {
                <HelperError
                  Error={ErrorHora}
                  Mensaje="Error en la hora de reserva"
                />
              }
            </Col>



              */}

            <Col sm={12} className="py-3">
              <div className="d-flex p-0 m-0 w-100">
                <div className="flex-grow-1 p-0 m-0 pe-3">
                  <ClientesSelect
                    className=""
                    size="small"
                    Value={Values.id_cliente}
                    Error={Errores.id_cliente}
                    handlInputChange={handlInputChange}
                    isEditar={Values.id != "0" ? true : false}
                  />
                </div>
                {Values.id != "0" ? (
                  ""
                ) : (
                  <Button
                    className="btn btn-Kalendar px-4 "
                    variant="contained"
                    onClick={handleShowC}
                  >
                    Añadir
                  </Button>
                )}
              </div>
            </Col>

            <Col sm={12} className="py-3 pt-2">
              <TextField
                name="notas"
                value={Values.notas}
                error={Errores.notas}
                onChange={handlInputChange}
                fullWidth
                label="Notas"
                multiline
                rows={4}
              ></TextField>
            </Col>

            <Stack
              className="p-3"
              spacing={2}
              justifyContent="flex-end"
              direction={{ xs: "column", sm: "row" }}
              style={{}}
            >
              <Button
                onClick={() => {
                  setValue(0);
                }}
                className="btn btn-exp-Excel font-AvenirMedium py-2 px-4 ms-auto"
                variant="text"
              >
                <ArrowBackIosNewIcon style={{ fontSize: "20px" }} />
                Anterior
              </Button>

              <LoadingButton
                loading={IsGuardando}
                loadingPosition="start"
                onClick={() => GuardarReserva()}
                className="btn btn-Kalendar font-AvenirMedium  py-2 px-4  mx-2  mt-2"
                variant="contained"
              >
                <span className={IsGuardando ? "px-4" : "px-2"}>
                  {IsGuardando
                    ? "Guardando..."
                    : Values.id != "0"
                    ? "Guardar"
                    : "Crear"}
                </span>
              </LoadingButton>
            </Stack>
          </Row>
        </Col>
      </Row>
    </>
  );
};

export default Pago;
