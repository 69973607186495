export const IMG = {
    TIPO_COLABORADORES :"colaboradores",
    TIPO_EMPRESAS : "empresas",
    TIPO_SUCURSALES : "sucursales",
    TIPO_USUARIOS : "usuarios",
    TIPO_USUARIOS_SISTEMA: "usuariossistema",
    TIPO_COMPROBANTE_LICENCIA: 'comprobantelicencia'
}

//Listas estaticas para los select del modulo de productos
export const TiposProductos = [
    { label: "Selecciona un tipo de producto", value: "0" },
    { label: "Insumo", value: "1" },
    { label: "Materia prima", value: "2" },
    { label: "Producto terminado", value: "3" },
    { label: "Servicio", value: "4" },
]
export const Monedas = [
    { label: "MXN", value: "MXN" },
    { label: "USD", value: "USD" }
]
export const Impuestos = [
    { label: "I.V.A. 16%", value: "16" },
    { label: "I.V.A. 8%", value: "8" },
    { label: "No genera I.V.A.", value: "0" },
    { label: "Excento de I.V.A.", value: "-1" },
    { label: "Tasa cero", value: "-2" }
]

// Constantes para la duración
export const Horas = ["00", "01", "02", "04", "05", "06", "07", "08", "09", "10",
                      "11", "12", "13", "14", "15", "16", "17", "18", "19", "20",
                      "21", "22", "23"];

export const Minutos = ["00","05", "10", "15", "20", "25", "30", "35", "40", "45", "50", "55"];

//Listas estaticas para los select del modulo de locaciones
export const TiposLocaciones = [
    { ID: "0", DESCRIPCION: "Tipo de locación" },
    { ID: "1", DESCRIPCION: "Hacienda" },
    { ID: "2", DESCRIPCION: "Salón" },
    { ID: "3", DESCRIPCION: "Jardín" },
    { ID: "4", DESCRIPCION: "Playa" },
    { ID: "5", DESCRIPCION: "Bosque" },
    { ID: "6", DESCRIPCION: "Rancho" }
]

//Listas estaticas para los select del modulo de descuentos
export const TipoDescuento = [
    {label: "Porcentaje", value: 1},
    {label: "Neto", value: 2}
]

export const TiposPlanes = {
    MENSUAL: 'Mensual',
    TRIMESTRAL: 'Trimestral',
    ANUAL: 'Anual'
}

export const FormasPago = {
    TARJETA: 'TARJETA',
    TRANSFERENCIA: 'TRANSFERENCIA'
}