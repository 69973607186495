import React, { useState, useEffect } from "react";
import useMediaQuery from "@mui/material/useMediaQuery";
import {
  Alert,
  Grid,
  Menu,
  MenuItem,
  Snackbar,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableFooter from "@mui/material/TableFooter";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import IconButton from "@mui/material/IconButton";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import LastPageIcon from "@mui/icons-material/LastPage";
import { TableHead } from "@mui/material";
import AccEditar from "./../../../assets/AccEditar.svg";
import AccEliminar from "./../../../assets/AccEliminar.svg";
import { Link } from "react-router-dom";
import Loading from "./../../../Includes/Loading";
import { Desactivar, Eliminar } from "../Funciones/Cuponera_Cupones";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import Switch from "./../../../Includes/Switch";
import Tooltip from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";
import { yellow } from "@mui/material/colors";
import MenuIcon from "@mui/icons-material/Menu";
import MoreVert from "@mui/icons-material/MoreVert";
import Fade from "@mui/material/Fade";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import ModalDetallesCuponera from "./ModalDetallesCuponera";

const MySwal = withReactContent(Swal);

function TablePaginationActions(props) {
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowRight />
        ) : (
          <KeyboardArrowLeft />
        )}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowLeft />
        ) : (
          <KeyboardArrowRight />
        )}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </Box>
  );
}

function createData(ID, NOMBRE, calories, fat) {
  return { ID, NOMBRE, calories, fat };
}

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

export default function TableCuponeras({ Lista, IsLoading, setLista }) {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(15);
  const [open, setOpen] = useState(false);
  const [mensaje, setMensaje] = useState("");
  const [severity, setSeverity] = useState("error");
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - Lista.length) : 0;
  const [anchorEl, setAnchorEl] = React.useState(null);
  const openMenu = Boolean(anchorEl);
  const [idRow, setIdRow] = useState();
  const [activoRow, setActivoRow] = useState();
  const [stateRow, setStateRow] = useState();
  const [showModalReserva, setShowModalReserva] = useState(false);
  const [vigencia, setVigencia] = useState("");

  const handleMenuClose = () => {
    setAnchorEl(null);
  };
  const handleClick = (event) => {
    //console.log("productos", event);
    setAnchorEl(event.currentTarget);
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const EliminarRegistro = (id) => {
    new Swal({
      title: "¿Estas seguro de eliminar esta cuponera?",
      text: "esta acción no se puede deshacer",
      icon: "warning",
      showDenyButton: true,
      denyButtonText: "No, cancelar",
      confirmButtonText: "Si, estoy seguro",
      confirmButtonColor: "#3ABE88",
      denyButtonColor: "#65748B",
      reverseButtons: true,
    }).then(function (isConfirm) {
      console.log(isConfirm.isConfirmed);
      if (isConfirm.isConfirmed) {
        if (id != undefined) {
          Eliminar(id)
            .then((resp) => {
              new Swal({
                title: "Exito!",
                text: "Eliminado correctamente",
                icon: "success",
              }).then(function () {
                eliminarProductoDeTabla(id);
              });
            })
            .catch((resp) => {
              alert("error al eliminar");
            });
        }
      }
    });
  };
  // se elimina de la tabla en memoria para no consultar nuevamente toda la lista
  const eliminarProductoDeTabla = (id) => {
    const filteredLibraries = Lista.filter((item) => {
      return item.ID !== id;
    });
    setLista(filteredLibraries);
  };
  const onChange = (checked, id) => {
    setIdRow(id);
    setStateRow(checked);
    ActivarOrDesactivar(id, checked);
  };
  const ActivarOrDesactivar = (id, checked) => {
    if (id != undefined) {
      Desactivar(id, checked)
        .then((resp) => {
          const ListaFiltrado = Lista.filter((item) => {
            if (item.ID == id) {
              item.ACTIVO = checked;
            }
            return item;
          });
          setLista(ListaFiltrado);
          setSeverity("success");
          setMensaje("Cambios guardados correctamente");
          setOpen(true);
        })
        .catch((resp) => {
          setSeverity("error");
          setMensaje("Error al guardar los cambios");
          setOpen(true);
        });
    }
  };
  const StyledSnackbar = styled((props) => <Snackbar {...props} />)(
    ({ theme }) => ({
      "& .MuiSnackbar-root": {
        top: theme.spacing(15),
      },
    })
  );

  return (
    <>
      <StyledSnackbar
        direction="right"
        open={open}
        autoHideDuration={6000}
        onClose={handleClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      >
        <Alert onClose={handleClose} severity={severity} sx={{ width: "100%" }}>
          {mensaje}
        </Alert>
      </StyledSnackbar>

      <TableContainer component={Paper}>
        <Menu
          open={openMenu}
          anchorEl={anchorEl}
          onClose={handleMenuClose}
          anchorOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
          TransitionComponent={Fade}
        >
          {activoRow == 1 ? (
            <>
              <MenuItem>
                {stateRow ? "Desactivar" : "Activar"}
                <Switch
                  checked={stateRow}
                  onChange={onChange}
                  id={idRow}
                  tituloActivo="Desactivar cuponera "
                  tituloInactivo="Activar cuponera"
                  on
                />
              </MenuItem>
              <MenuItem onClick={() => setShowModalReserva(!showModalReserva)}>
                {"Ver detalles"}
                <IconButton>
                  <VisibilityOutlinedIcon
                    style={{ background: "", color: "black" }}
                  />
                </IconButton>
              </MenuItem>
            </>
          ) : (
            <></>
          )}

          {/*<MenuItem
            onClick={() => {
              handleMenuClose();
            }}
            component={Link}
            to={"./edit/" + idRow}
          >
            Editar
            <IconButton>
              <img src={AccEditar} />
            </IconButton>
        </MenuItem>*/}
          <MenuItem
            onClick={() => {
              handleMenuClose();
              EliminarRegistro(idRow);
            }}
          >
            Eliminar
            <IconButton>
              <img src={AccEliminar} />
            </IconButton>
          </MenuItem>
        </Menu>
        <Table aria-label="custom pagination table">
          {showModalReserva ? (
            <ModalDetallesCuponera
              setShowModalReserva={setShowModalReserva}
              id={idRow}
              vigencia={vigencia}
            />
          ) : (
            ""
          )}
          <TableHead>
            <TableRow>
              <TableCell
                className="d-none d-sm-table-cell font-Avenir font-AvenirBold"
                align="left"
              >
                FECHA
              </TableCell>
              <TableCell
                className="d-none d-sm-table-cell font-Avenir font-AvenirBold"
                align="left"
              >
                CATEGORIA
              </TableCell>
              <TableCell
                className="d-none d-sm-table-cell font-Avenir font-AvenirBold"
                align="left"
              >
                CANTIDAD
              </TableCell>
              <TableCell
                className="d-none d-sm-table-cell font-Avenir font-AvenirBold"
                align="left"
              >
                TIPO DE DESCUENTO
              </TableCell>

              <TableCell
                className="d-none d-sm-table-cell font-Avenir font-AvenirBold"
                align="left"
              >
                DESCUENTO
              </TableCell>
              <TableCell
                className="d-none d-sm-table-cell font-Avenir font-AvenirBold"
                align="left"
              >
                VIGENCIA
              </TableCell>
              <TableCell
                className="d-none d-sm-table-cell font-Avenir font-AvenirBold"
                align="left"
              ></TableCell>

              <TableCell
                className="d-none d-sm-table-cell font-Avenir font-AvenirBold"
                align="right"
              >
                ACCIONES
              </TableCell>
            </TableRow>
          </TableHead>

          {IsLoading ? (
            <TableBody>
              <TableRow>
                <TableCell component="th" scope="row" colSpan={6}>
                  <Loading />
                </TableCell>
              </TableRow>
            </TableBody>
          ) : (
            <TableBody>
              {(rowsPerPage > 0
                ? Lista.slice(
                    page * rowsPerPage,
                    page * rowsPerPage + rowsPerPage
                  )
                : Lista
              ).map((row) => (
                <TableRow key={row.ID}>
                  <TableCell
                    component="th"
                    scope="row"
                    className="d-none d-sm-table-cell"
                  >
                    {row.FECHA}
                  </TableCell>

                  <TableCell component="th" scope="row">
                    <div className="d-flex justify-content-start align-items-start flex-column">
                      <span className="text-danger">
                        {row.CUPON_CODIGO ? row.CODIGO : ""}
                      </span>
                      <span>{row.CUPON_CODIGO ? "CODIGO" : "CUPONES"}</span>
                    </div>
                  </TableCell>

                  <TableCell component="th" scope="row">
                    {row.NUMERO_CUPONES}
                  </TableCell>
                  <TableCell
                    component="th"
                    scope="row"
                    className="d-sm-table-cell"
                  >
                    <Grid className="d-none d-sm-block">
                      {row.TIPO_DESCUENTO === 1 ? "Porcentaje" : "Neto"}
                    </Grid>
                    <Grid
                      container
                      direction="column"
                      justifyContent="flex-start"
                      alignItems="flex-start"
                      className="d-xsm-none d-sm-block d-sm-none"
                      style={{
                        background: "",
                        maxWidth: 300,
                      }}
                    >
                      <Grid>
                        <Typography
                          style={{
                            maxWidth: 150,
                            margin: "auto",
                            textOverflow: "ellipsis",
                            textAlign: "left",
                          }}
                          noWrap={true}
                        >
                          {row.TIPO_DESCUENTO === 1 ? "Porcentaje" : "Neto"}
                        </Typography>
                      </Grid>
                    </Grid>
                  </TableCell>

                  {row.TIPO_DESCUENTO === 1 ? (
                    <TableCell component="th" scope="row">
                      <Grid className="d-none d-sm-block">
                        {row.CANTIDAD + "%"}
                      </Grid>
                      <Grid
                        container
                        direction="column"
                        justifyContent="flex-start"
                        alignItems="flex-start"
                        className="d-xsm-none d-sm-block d-sm-none"
                        style={{
                          background: "",
                          maxWidth: 300,
                        }}
                      >
                        <Grid>
                          <Typography
                            style={{
                              maxWidth: 150,
                              margin: "auto",
                              textOverflow: "ellipsis",
                              textAlign: "left",
                            }}
                            noWrap={true}
                          >
                            {row.CANTIDAD + "%"}
                          </Typography>
                        </Grid>
                      </Grid>
                    </TableCell>
                  ) : (
                    <TableCell component="th" scope="row">
                      {row.TIPO_DESCUENTO === 1
                        ? row.CANTIDAD + "%"
                        : "$ " + row.CANTIDAD}
                    </TableCell>
                  )}

                  <TableCell
                    component="th"
                    scope="row"
                    className="d-none d-sm-table-cell"
                  >
                    {row.VIGENCIA}
                  </TableCell>
                  <TableCell>
                    <span
                      className={
                        row.VIGENTE == 1
                          ? "badge rounded-pill bg-success"
                          : "badge rounded-pill bg-danger"
                      }
                    >
                      {row.VIGENTE == 1 ? "Vigente" : "Vencido"}
                    </span>
                  </TableCell>

                  <TableCell align="right">
                    {row.VIGENTE == 1 ? (
                      <Grid key={row.ID} className="d-none d-sm-block">
                        <Switch
                          checked={row.ACTIVO}
                          onChange={onChange}
                          id={row.ID}
                          tituloActivo="Desactivar cuponera"
                          tituloInactivo="Activar cuponera"
                          on
                        />

                        <Tooltip title="Ver detalles" placement="top">
                          <IconButton
                            onClick={() => {
                              setIdRow(row.ID);
                              setVigencia(row.VIGENCIA);
                              setShowModalReserva(!showModalReserva);
                            }}
                          >
                            <VisibilityOutlinedIcon
                              style={{ background: "", color: "black" }}
                            />
                          </IconButton>
                        </Tooltip>

                        <Tooltip title="Eliminar" placement="top">
                          <IconButton onClick={() => EliminarRegistro(row.ID)}>
                            <img src={AccEliminar} />
                          </IconButton>
                        </Tooltip>
                      </Grid>
                    ) : (
                      <Grid key={row.ID} className="d-none d-sm-block">
                        <Tooltip title="Eliminar" placement="top">
                          <IconButton onClick={() => EliminarRegistro(row.ID)}>
                            <img src={AccEliminar} />
                          </IconButton>
                        </Tooltip>
                      </Grid>
                    )}

                    <Grid>
                      <DrawerHeader
                        style={{ backgroundColor: "#FFFFFF" }}
                        className="d-xsm-none d-sm-block d-sm-none"
                        onClick={() => {
                          setIdRow(row.ID);
                          setStateRow(row.ACTIVO);
                          setVigencia(row.VIGENCIA);
                          setActivoRow(row.VIGENTE);
                        }}
                      >
                        <IconButton onClick={handleClick}>
                          <MoreVert />
                        </IconButton>
                      </DrawerHeader>
                    </Grid>
                  </TableCell>
                </TableRow>
              ))}
              {emptyRows > 0 && (
                <TableRow style={{ height: 53 * emptyRows }}>
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          )}
          <TableFooter>
            <TableRow>
              <TablePagination
                rowsPerPageOptions={[
                  15,
                  30,
                  100,
                  { label: "Todos", value: -1 },
                ]}
                count={Lista.length}
                rowsPerPage={rowsPerPage}
                page={page}
                SelectProps={{
                  inputProps: {
                    "aria-label": "Filas por pagína",
                  },
                  native: true,
                }}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                ActionsComponent={TablePaginationActions}
                labelRowsPerPage={"Registros por página"}
                labelDisplayedRows={({ from, to, count, page }) => {
                  return `${from} - ${to} de ${count}`;
                }}
              />
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
    </>
  );
}
