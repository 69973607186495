import React, { useState, useEffect } from "react";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import Card from "@mui/material/Card";
import ExportarDanger from "./../../../assets/ExportarDanger.svg";
import ExportarSucess from "./../../../assets/ExportarSucess.svg";
import { Col, Row } from "react-bootstrap";
import TableCliente from "./Tabla";
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
import SelectOrdenamiento from "../../../Includes/SelectOrdenamiento";
import Buscador from "../../../Includes/Buscador";
import { getLista } from "../Funciones/Clientes";
import { usePermisos } from "../../../Context/PermissionsContext";
import { getLocalStorage, getLocalStorageJWT } from "../../../Context/Storage";
import BACKEND_URL from "../../../Context/backend";
import { GetDate } from "../../../Lib/Funciones Generales/ObtenerFecha";

const Clientes = () => {
  const [ValueSelect, setValueSelect] = useState("1");
  const [ValueBusqueda, setValueBusqueda] = useState("");
  const [IsLoading, setIsLoading] = useState(true);
  const [Lista, setLista] = useState([]);
  const { userPermissions } = usePermisos();

  const getListaCliente = () => {
    setIsLoading(true);
    getLista(0, 0, ValueSelect, ValueBusqueda)
      .then((resp) => {
        let list = JSON.parse(resp.data);
        //console.log(list);
        setLista(list);
        setIsLoading(false);
      })
      .catch((resp) => {
        setLista([]);
        setIsLoading(false);
      });
  };
  function extractFilenameFromResponse(response) {
    console.log(response);
    const contentDispositionHeader = response.headers.get(
      "Content-Disposition"
    );
    console.log(contentDispositionHeader);
    if (contentDispositionHeader) {
      const matches = contentDispositionHeader.match(/filename="(.+)"/);
      if (matches && matches.length > 1) {
        return matches[1];
      }
    }
    return "archivo.xlsx"; // Nombre predeterminado si no se puede extraer del encabezado
  }

  useEffect(() => {
    getListaCliente();
  }, [ValueBusqueda, ValueSelect]);

  useEffect(() => {
    document.title = `Clientes`;
  }, []);

  return (
    <>
      <div className="d-flex justify-content-between flex-wrap my-4">
        <div className="p-2">
          <h4 className="text-left font-AvenirBold">Clientes</h4>
        </div>
        <div className="p-2">
          <Stack spacing={2} direction={{ xs: "column", sm: "row" }}>
            {getLocalStorage("tipo") == "EMPRESA" ||
            userPermissions.includes("Exportar Catalogos") === true ? (
              <>
                <Button
                  className="btn btn-exp-Excel font-AvenirMedium"
                  variant="text"
                  onClick={() => {
                    fetch(`${BACKEND_URL}reportesExcel/catalogo/clientes`, {
                      method: "GET",
                      headers: {
                        Authorization: `Bearer ${getLocalStorageJWT()}`,
                        "Content-Type": "application/json",
                      },
                    })
                      .then((response) => {
                        const filename = `Catálogo de clientes ${GetDate()}`;
                        return response.blob().then((blob) => {
                          const downloadUrl = URL.createObjectURL(blob);
                          const anchor = document.createElement("a");
                          anchor.href = downloadUrl;
                          anchor.download = filename;
                          anchor.click();
                          URL.revokeObjectURL(downloadUrl);
                        });
                      })
                      .catch((error) => {
                        console.error(
                          "Error al descargar el archivo Excel:",
                          error
                        );
                      });
                  }}
                >
                  <img className="me-2" src={ExportarSucess} />
                  Exportar Excel
                </Button>

                <Link
                  target={false ? "_self" : "_blank"}
                  to={`../pdf/catalogo/clientes`}
                  style={{ textDecoration: "none" }}
                >
                  <Button
                    className="btn  btn-exp-PDF  font-AvenirMedium"
                    variant="text"
                  >
                    <img className="me-2" src={ExportarDanger} />
                    Exportar PDF
                  </Button>
                </Link>
              </>
            ) : (
              <></>
            )}
            <Link to="./add" style={{ textDecoration: "none" }}>
              <Button className="btn btn-Kalendar px-4 " variant="contained">
                Crear nuevo
              </Button>
            </Link>
          </Stack>
        </div>
      </div>

      <Card variant="outlined" className="p-0 shadow">
        <Row className="p-3">
          <Col xs={12} md={8}>
            <Buscador
              placeholder="Buscar clientes por alias"
              ValueBusqueda={ValueBusqueda}
              setValueBusqueda={setValueBusqueda}
            />
          </Col>
          <Col xs={12} md={4}>
            <SelectOrdenamiento
              ValueSelect={ValueSelect}
              setValueSelect={setValueSelect}
            />
          </Col>
        </Row>
        <Row>
          <Col sm={12}>
            <TableCliente
              Lista={Lista ?? []}
              IsLoading={IsLoading}
              setLista={setLista}
            ></TableCliente>
          </Col>
        </Row>
      </Card>
    </>
  );
};

export default Clientes;
