import { Paper } from "@mui/material";
import { margin } from "@mui/system";
import React, { useEffect, useRef, useState } from "react";

import {
  convertirNumeroATexto,
  recortarHoraAgregarTexto,
} from "./../../Lib/Funciones Generales/Generales";
const Ticket = ({
  Values,
  productosCarrito,
  config,
  descuento,
  idReserv,
  folioReservacion,
  setFolioReservacion = () => {},
}) => {
  let totalDescuento =
    descuento.tipo == 0
      ? 0
      : descuento.tipo == 1
      ? (Values.subtotal * descuento.descuento) / 100
      : descuento.descuento;

  return (
    <>
      {" "}
      <div className="contectTiket me-3 p-2 pe-3">
        <Paper className="shadow" style={{ width: config.ancho + "mm" }}>
          <div
            id="divTiket"
            className="ticket p-1"
            style={{ width: config.ancho + "mm" }}
          >
            <div
              style={{
                fontSize: config.fuente + "px",
                fontWeight: "500",
                paddingLeft: "2mm",
                paddingRight: "2mm",
                paddingTop: "20px",
                paddingBottom: "40px",
              }}
            >
              <center>
                <img
                  src={Values.img_emp}
                  style={{ maxWidth: "70px", margin: "auto" }}
                />
              </center>
              <br />
              <p style={{ textAlign: "center", margin: "0px" }}>
                {Values.nombre_empresa.toUpperCase()}
              </p>
              <p
                style={{
                  textAlign: "center",
                  margin: "0px",
                  fontWeight: "bold",
                }}
              >
                SUCURSAL {Values.sucursal.toUpperCase()}
              </p>
              <p style={{ textAlign: "center", margin: "0px" }}>
                {Values.direccion.toUpperCase()}
              </p>

              <p
                style={{
                  textAlign: "center",
                  margin: "0px",
                  fontSize: parseInt(config.fuente) + 6 + "px",
                }}
              >
                FOLIO: {folioReservacion.toString().padStart(5, "0")}
              </p>
              <div className="border-dotted">
                -------------------------------------------------------------------------------------------------------
              </div>

              {config.encabezado != "" ? (
                <>
                  <p style={{ textAlign: "center", margin: "0px" }}>
                    {config.encabezado.toUpperCase()}
                  </p>

                  <div className="border-dotted">
                    -------------------------------------------------------------------------------------------------------
                  </div>
                </>
              ) : (
                ""
              )}
              <table style={{ width: "100%" }}>
                <tr>
                  <td>
                    <b style={{ fontSize: config.fuente + "px" }}>CANT.</b>
                  </td>
                  <td>
                    <b style={{ fontSize: config.fuente + "px" }}>CONCEPTO</b>
                  </td>
                  <td align="right">
                    <b style={{ fontSize: config.fuente + "px" }}>IMPORTE</b>
                  </td>
                </tr>

                {productosCarrito.map((row) => (
                  <tr>
                    <td valign="top">{row.CANTIDAD}</td>
                    <td valign="top">{row.DESCRIPCION.toUpperCase()}</td>
                    <td valign="top" align="right">
                      {" "}
                      ${parseFloat(row.CANTIDAD * row.PRECIO).toFixed(2)}
                    </td>
                  </tr>
                ))}
              </table>
              <br />
              <table style={{ width: "100%" }}>
                <tr>
                  <td align="right">
                    <b style={{ fontSize: config.fuente + "px" }}>SUBTOTAL</b>
                  </td>
                  <td align="right">
                    ${parseFloat(Values.subtotal).toFixed(2)}
                  </td>
                </tr>
                <tr>
                  <td align="right">
                    <b style={{ fontSize: config.fuente + "px" }}>DESCUENTOS</b>
                  </td>
                  <td align="right">
                    ${parseFloat(totalDescuento).toFixed(2)}
                  </td>
                </tr>
                <tr>
                  <td align="right">
                    <b style={{ fontSize: config.fuente + "px" }}>TOTAL</b>
                  </td>
                  <td align="right">${parseFloat(Values.total).toFixed(2)}</td>
                </tr>
              </table>
              <div className="border-dotted">
                -------------------------------------------------------------------------------------------------------
              </div>
              <p style={{ textAlign: "center", margin: "0px" }}>
                {convertirNumeroATexto(Values.total).toUpperCase()}
              </p>
              <div className="border-dotted">
                -------------------------------------------------------------------------------------------------------
              </div>
              <br />
              <p style={{ textAlign: "left", margin: "0px" }}>
                {productosCarrito.length} ARTÍCULOS VENDIDOS
              </p>
              <br />

              <table style={{ width: "100%" }}>
                <tr>
                  <td align="left">
                    <b style={{ fontSize: config.fuente + "px" }}>EFECTIVO</b>
                  </td>
                  <td align="right">
                    ${parseFloat(Values.efectivo).toFixed(2)}
                  </td>
                </tr>
                <tr>
                  <td align="left">
                    <b style={{ fontSize: config.fuente + "px" }}>
                      TARJETA CRE./DEB.
                    </b>
                  </td>
                  <td align="right">
                    ${parseFloat(Values.tarjeta).toFixed(2)}
                  </td>
                </tr>

                <tr>
                  <td align="left">
                    <b style={{ fontSize: config.fuente + "px" }}>MONEDERO</b>
                  </td>
                  <td align="right">
                    ${parseFloat(Values.monedero).toFixed(2)}
                  </td>
                </tr>
                <tr>
                  <td align="left">
                    <b style={{ fontSize: config.fuente + "px" }}>
                      OTRAS FORMAS
                    </b>
                  </td>
                  <td align="right">${parseFloat(Values.otros).toFixed(2)}</td>
                </tr>

                <tr>
                  <td align="left">
                    <b style={{ fontSize: config.fuente + "px" }}>
                      TOTAL PAGADO
                    </b>
                  </td>
                  <td align="right">
                    $
                    {parseFloat(
                      Values.otros +
                        Values.monedero +
                        Values.tarjeta +
                        Values.efectivo
                    ).toFixed(2)}
                  </td>
                </tr>
              </table>

              <br />
              <p style={{ textAlign: "left", margin: "0px" }}>
                {productosCarrito.length} DATOS DE LA RESERVACIÓN
              </p>
              <br />
              <table style={{ width: "100%" }}>
                <tr>
                  <td align="left">
                    <b style={{ fontSize: config.fuente + "px" }}>FECHA</b>
                  </td>
                  <td align="right">{Values.fecha_reservacion}</td>
                </tr>
                <tr>
                  <td align="left">
                    <b style={{ fontSize: config.fuente + "px" }}>HORA</b>
                  </td>
                  <td align="right">{recortarHoraAgregarTexto(Values.hora)}</td>
                </tr>
                <tr>
                  <td align="left">
                    <b style={{ fontSize: config.fuente + "px" }}>
                      COLABORADOR
                    </b>
                  </td>
                  <td align="right">{Values.nombre_colaborador}</td>
                </tr>
              </table>

              <br />
              {config.pie != "" ? (
                <>
                  <div className="border-dotted">
                    -------------------------------------------------------------------------------------------------------
                  </div>
                  <p style={{ textAlign: "center", margin: "0px" }}>
                    {config.pie.toUpperCase()}
                  </p>

                  <div className="border-dotted">
                    -------------------------------------------------------------------------------------------------------
                  </div>
                </>
              ) : (
                ""
              )}

              {config.linea_1 != "" ? (
                <>
                  <p style={{ textAlign: "center", margin: "0px" }}>
                    {config.linea_1.toUpperCase()}
                  </p>
                </>
              ) : (
                ""
              )}
            </div>
          </div>
        </Paper>
      </div>
    </>
  );
};

export default Ticket;
