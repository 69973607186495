import { stringify } from "query-string";
import { getData, postdData, postUrl } from "../../../Context/backend";
import validator from "validator";
import { IMG } from "../../../Lib/Funciones Generales/Constantes";


export const getComisiones = async (id, no_pagadas, fechaInicio , fechaFin) => {
    let data = [];
    let queryParamsObj = {
      id_colaborador: id,
      no_pagadas: no_pagadas,
      fecha_inicio: fechaInicio,
      fecha_fin: fechaFin,   
    }; 
    const url = `comisiones/getcomisiones?${stringify(queryParamsObj)}`;
    const res = await getData(url);
    return new Promise((resolve, reject) => {
      if (!res.error) {
        data = JSON.parse(res.data.respuesta.data); 
        resolve(data);
      } else {
        reject({
          mensaje: "Error al conectar a internet, revisa tu conexion a internet",
        });
      }
    });
  };


  export const procesarPagoComisiones = async (idColaborador, reservaciones,  
    totalreservaciones, totalInsentivos) => {
   
    if (parseFloat(totalreservaciones)+parseFloat(totalInsentivos)<=0 ) {
      return new Promise((resolve, reject) => {
        resolve({ mensaje: "El monto a pagar no puede ser 0" });
      });
    }

  if (idColaborador==0 || idColaborador=="" ) {
      return new Promise((resolve, reject) => {
        resolve({ mensaje: "Selecciona un colaborador" });
      });
    } 

 
const stringsArray = reservaciones.map(number => number.toString());
 

      let data = [];
      const body = {
        id_colaborador: idColaborador,
        reservaciones: stringsArray ,
        total_comisiones: totalreservaciones,
        total_insentivos: totalInsentivos, 
      };
      const url = "comisiones/pagarcomisiones" ;
      const res = await postdData(url, body);
      return new Promise((resolve, reject) => {
        if (!res.error) {
          data = res.data;
          resolve(data);
        } else {
          reject(res);
        }
      });
    
  };