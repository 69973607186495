import { Paper } from "@mui/material";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import PlaylistAddCheckIcon from '@mui/icons-material/PlaylistAddCheck';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import PhoneIphoneIcon from '@mui/icons-material/PhoneIphone';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import MessageOutlinedIcon from '@mui/icons-material/MessageOutlined';


const PopHover = ({ event }) => {

  if(!event.extendedProps.productos)
  {
    return
  }

    const array = event.extendedProps.productos.split('|');
    const arrayTransformado = array.map((fruta, indice) => {
        return  <>&#8226;{fruta}<br></br></>;
      });

  // console.log(event);
  return (
    <Paper elevation={6} style={{ width: "250px", zIndex:999 }}  >
      <div className="p-3 mb-2">
        <p
          className="font-AvenirBold my-0"
          style={{ color: "#fd8c27", fontSize: "14px" }}
        >
          {event.title}
        </p> 
        
           <div className=" d-flex justify-content-start align-content-top align-items-top">
          <div>
            <PlaylistAddCheckIcon style={{ width: "17px" }} />
          </div>
          <div className="mt-1">
            <p className="font-AvenirMedium my-0 ps-2"
            style={{ color: "#3f3f3f", fontSize: "14px" }}>
              {arrayTransformado =="" ? 
              event.extendedProps.productos: 
             
              arrayTransformado
          
              }
            </p>
          </div>
        </div>
        <div className=" d-flex justify-content-start align-content-top align-items-top">
          <div>
            <AccessTimeIcon style={{ width: "17px" }} />
          </div>
          <div className="mt-1">
            <p className="font-AvenirMedium my-0 ps-2"
            style={{ color: "#3f3f3f", fontSize: "14px" }}>
              {event.extendedProps.hora}
            </p>
          </div>
        </div>

        <div className=" d-flex justify-content-start align-content-top align-items-top">
          <div>
            <AttachMoneyIcon style={{ width: "19px" }} />
          </div>
          <div className="mt-1">
            <p className="font-AvenirMedium my-0 ps-2"
            style={{ color: "#3f3f3f", fontSize: "14px" }}>
              {event.extendedProps.pagado}
            </p>
          </div>
        </div>
        

  <div className=" d-flex justify-content-start align-content-top align-items-top">
          <div>
            <PhoneIphoneIcon style={{ width: "17px" }} />
          </div>
          <div className="mt-1">
            <p className="font-AvenirMedium my-0 ps-2"
            style={{ color: "#3f3f3f", fontSize: "14px" }}>
              {event.extendedProps.telefono}
            </p>
          </div>
        </div>

 <div className=" d-flex justify-content-start align-content-top align-items-top">
          <div>
            <EmailOutlinedIcon style={{ width: "17px" }} />
          </div>
          <div className="mt-1">
            <p className="font-AvenirMedium my-0 ps-2"
            style={{ color: "#3f3f3f", fontSize: "14px" }}>
              {event.extendedProps.correo}
            </p>
          </div>
        </div>

 <div className=" d-flex justify-content-start align-content-top align-items-top">
          <div>
            <MessageOutlinedIcon style={{ width: "17px" }} />
          </div>
          <div className="mt-1">
            <p className="font-AvenirMedium my-0 ps-2 parrafo2lineastruncate"
            style={{ color: "#3f3f3f", fontSize: "14px" }}>
              {event.extendedProps.notas == "" ? "--" :event.extendedProps.notas }
            </p>
          </div>
        </div>
        

      </div>
    </Paper>
  );
};

export default PopHover;
