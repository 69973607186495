import {  Route, Routes } from "react-router-dom";
import Error404 from "../../Includes/Error404";
import Generales from "./Componentes/Generales";

 const routerConfigGenerales =()=> {
  return (
    <> 
     <Routes>     
        <Route index element={<Generales/>} /> 
        <Route path="*" element={<Error404 />} />
    </Routes>
    </> 
  );
}

export default routerConfigGenerales