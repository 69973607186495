import React from "react";

import {
  Grid,
  Stack,
  Card,
  CardContent,
  TextField,
  Autocomplete,
  Typography,
  Avatar,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  styled,
  T,
} from "@mui/material";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import { Col, Row } from "react-bootstrap";
import {
  avatarLetters,
  firstLeterMayus,
} from "../../../Lib/Funciones Generales/Generales";

export default function ChartTop({
  titulo = "",
  rows = [{ img: "", titulos: "", subtitulos: "" }],
  avatarColor = "#99A2AD",
  namePropios = false,
}) {
  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    "&:last-child td, &:last-child th": {
      border: 0,
    },
  }));
  return (
    <>
      <Card className="shadow-sm" elevation={3} style={{ height: "491px" }}>
        <CardContent className="p-0">
          <div className="p-3">
            <h6 className="mb-0 text-left font-AvenirBold">{titulo}</h6>
          </div>
          <hr
            style={{
              backgroundColor: "#DFDEE0",
              height: "1px",
              opacity: "1",
            }}
            className="m-0 p-0"
          />
          <div lassName="p-3 m-3">
            <Row className="p-0 m-2">
              <TableContainer sx={{ maxHeight: 424 }}>
                <Table sx={{ minWidth: 200 }}>
                  <TableBody>
                    {rows.map((row) => {
                      return (
                        <StyledTableRow className="d-flex" key={row.titulos}>
                          <StyledTableCell component="th" scope="row">
                            {row.img ? (
                              <Avatar
                                sx={{
                                  width: 45,
                                  height: 45,
                                  border: `0.1px solid ${avatarColor}`,
                                }}
                                src={row.img}
                              />
                            ) : (
                              <Avatar
                                sx={{
                                  bgcolor: "#F0F0F0",
                                  color: avatarColor,
                                  border: `0.1px solid ${avatarColor}`,
                                }}
                              >
                                {avatarLetters(row.titulos)}
                              </Avatar>
                            )}
                          </StyledTableCell>
                          <StyledTableCell align="left" className="w-100">
                            <Typography
                              style={{
                                //maxWidth: 150,
                                textAlign: "left",
                              }}
                              noWrap={true}
                            >
                              {namePropios
                                ? firstLeterMayus(row.titulos)
                                : row.titulos}
                            </Typography>
                            <Typography
                              style={{
                                fontSize: "12px",
                                color: "GrayText",
                                //maxWidth: 180,
                                textAlign: "left",
                              }}
                              noWrap={true}
                            >
                              {row.subtitulos}
                            </Typography>
                          </StyledTableCell>
                        </StyledTableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            </Row>
          </div>
        </CardContent>
      </Card>
    </>
  );
}
