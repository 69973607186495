import {
  Alert,
  AlertTitle,
  Avatar,
  Box,
  Button,
  Card,
  CardContent,
  Divider,
  Snackbar,
  Stack,
  Tab,
  Tabs,
  TextField,
  Typography,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useNavigate } from "react-router-dom";
import {
  guardar,
  getInfoEmpleado,
  getProductosByColaborador,
} from "../Funciones/Colaboradores";
import Loading from "../../../Includes/Loading";
import LoadingButton from "@mui/lab/LoadingButton";
import { styled } from "@mui/material/styles";
import { useParams } from "react-router-dom";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import { BasicDatePicker } from "../../../Includes/DatePicker";
import {
  ConvertDate,
  GetDate,
} from "../../../Lib/Funciones Generales/ObtenerFecha";
import ModalCargarImagen from "../../../Lib/Imagen/Componentes/ModalCargarImagen";
import AccEditarImagen from "./../../../assets/EditarImagen.svg";
import { IMG } from "../../../Lib/Funciones Generales/Constantes";
import "../../../Lib/Funciones Generales/Prototipos";
import SelectSucursal from "../../../Configuraciones/Sucursales/Componentes/SelectSucursal";
import Puesto from "../../Puestos/Componentes/SelectPuestos";
import SelectGrupoAcceso from "../../../Configuraciones/gruposacceso/Componentes/SelectGrupoAcceso";
import { getListaTemplateHorario } from "../../../Configuraciones/Sucursales/Componentes/Horarios/funciones/Horarios";
import RepHorarios from "../../../Configuraciones/Sucursales/Componentes/Horarios/componentes/RepHorarios";
import Switch from "../../../Includes/Switch";

const MySwal = withReactContent(Swal);

const Empleado = () => {
  const [Horarios, SetHorarios] = useState([]);

  const [tab, setTab] = React.useState(0);
  const handleChangeTab = (event, newValue) => {
    setTab(newValue);
  };
  const [openModal, setOpenModal] = React.useState(false);
  const handleOpenModal = () => setOpenModal(true);
  const handleCloseModal = () => setOpenModal(false);
  const fnDevolverImagen = (img) => {
    setValues({ ...Values, img: img });
  };

  const { id } = useParams();
  const navigate = useNavigate(); 
  const [IsLoading, setIsLoading] = useState(true);
  const [isAll, setIsAll] = useState(false);
  const [IsGuardando, setIsGuardando] = useState(false);
  const [open, setOpen] = useState(false);
  const [mensaje, setMensaje] = useState("");
  const [fecha, setFecha] = useState(GetDate());
  const [servicios, setServicios] = useState([]); 
  const [Values, setValues] = useState({
    id: id ?? "0",
    nombre: "",
    apellido_paterno: "",
    apellido_materno: "",
    correo: "",
    telefono: "",
    img: AccEditarImagen,
    id_puesto: 0,
    id_grupo: 0,
  });

  const [Errores, setErrores] = useState({
    nombre: false,
    apellido_paterno: false,
    apellido_materno: false,
    correo: false,
    telefono: false,
    id_grupo: false,
  });

  useEffect(() => {
    setValues({ ...Values, fecha_ingreso: ConvertDate(fecha) });
  }, [fecha]);

  const handleClose = () => {
    setOpen(false);
  };

  const handlInputChange = ({ target }) => {
    let Name = target.name;
    let Value = target.value ? target.value : "";
    setValues({
      ...Values,
      [Name]: Value,
    });
    setErrores({
      ...Errores,
      [Name]: false,
    });
  };

  useEffect(() => {
    setIsLoading(true);
    if (id != undefined) {
      getInfoEmpleado(id, AccEditarImagen)
        .then((resp) => {
          if (resp?.err?.length == 0) {
            MySwal.fire({
              title: "Error",
              html: resp.mensaje,
              icon: "error",
              confirmButtonColor: "#3ABE88",
              showConfirmButton: true,
              allowEscapeKey: false,
              allowEnterKey: false,
              allowOutsideClick: false,
            }).then(() => {
              setIsLoading(false);
              navigate(-1);
            });
          } else {
            setValues(resp.data);

            const objHor = getListaTemplateHorario()[0];
            if (resp.horarios.length != 0) {
              const newHorariosArr = resp.horarios.filter((element) => {
                const newObj = Object.assign(objHor, element);
                return newObj;
              });
              SetHorarios(newHorariosArr);
            } else {
              let newHorariosArr = [];
              for (let h = 0; h < 7; h++) {
                const horario = getListaTemplateHorario()[0];
                newHorariosArr.push(horario);
              }
              SetHorarios(newHorariosArr);
            }

            setIsLoading(false);
          }
        })
        .catch((resp) => {
          MySwal.fire({
            title: "Error",
            html: resp.mensaje,
            icon: "error",
            confirmButtonColor: "#3ABE88",
            showConfirmButton: true,
            allowEscapeKey: false,
            allowEnterKey: false,
            allowOutsideClick: false,
          }).then(() => {
            setIsLoading(false);
            navigate(-1);
          });
        });
    } else {
      const newArr = [];
      for (let it = 0; it < 7; it++) {
        const objHor = getListaTemplateHorario()[0];
        newArr.push(objHor);
      }
      SetHorarios(newArr);
      setIsLoading(false);
    }

    getProductosByColaborador(id == undefined ? 0 : id)
      .then((resp) => {
        if (resp?.err?.length == 0) {
          setServicios([]);
        } else {
          setServicios(JSON.parse(resp.data));
        }
      })
      .catch((resp) => {
        setServicios([]);
      });
  }, []);

   // console.log(servicios);
  const GuardarEmpledo = () => {
    setIsGuardando(true);
    guardar(Values, Errores, setErrores, Horarios, SetHorarios, servicios)
      .then((data) => {
        if (data.codigo == "200") {
          setIsGuardando(false);
          MySwal.fire({
            title: "Correcto",
            html: data.mensaje,
            icon: "success",
            confirmButtoColor: "#3ABE88",
            showConfirmButton: false,
            timer: 1700,
          }).then((result) => {
            navigate(-1);
          });
        } else {
          setMensaje(data.mensaje);
          setOpen(true);
          setIsGuardando(false);
        }
      })
      .catch((data) => {
        setMensaje(data.mensaje);
        setOpen(true);
        setIsGuardando(false);
      });
  };

  const StyledSnackbar = styled((props) => <Snackbar {...props} />)(
    ({ theme }) => ({
      "& .MuiSnackbar-root": {
        top: theme.spacing(15),
      },
    })
  );

  const img_url = Values.img == AccEditarImagen ? AccEditarImagen : Values.img;

  return (
    <>
      <StyledSnackbar
        direction="right"
        open={open}
        autoHideDuration={6000}
        onClose={handleClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      >
        <Alert onClose={handleClose} severity="error" sx={{ width: "100%" }}>
          {mensaje}
        </Alert>
      </StyledSnackbar>
      <Button
        onClick={() => navigate(-1)}
        className="btn  font-AvenirBold my-4 text-star ps-0 pe-4  "
        variant="text"
      >
        <ArrowBackIcon className="me-3" />
        Colaboradores
      </Button>

      <Box className=" mb-2">
        <Tabs
          TabIndicatorProps={{
            bgcolor: "#3abe88",
            style: {
              backgroundColor: "#3abe88",
            },
          }}
          value={tab}
          onChange={handleChangeTab}
          variant="scrollable"
          scrollButtons="auto"
          textColor="#212529"
          indicatorColor=""
          aria-label="scrollable auto tabs example"
        >
          <Tab label="Datos generales" className="font-AvenirBold" />
          {/*<Tab label="Horarios" className="font-AvenirBold" />*/}
        </Tabs>
      </Box>

      <Card elevation={3} className="mb-4">
        <CardContent className="p-0">
          <div className="p-3">
            <h6 className="mb-0 text-left font-AvenirBold">
              {id ? "Editar colaborador" : "Nuevo colaborador"}
            </h6>
          </div>
          <hr
            style={{ backgroundColor: "#DFDEE0", height: "1px", opacity: "1" }}
            className="m-0 p-0"
          />

          {IsLoading ? (
            <Row className="px-3">
              <Col sm={12} className="p-3">
                <Loading />
              </Col>
            </Row>
          ) : (
            <Row className="p-3">
              {tab == 0 && (
                <>
                  <Col
                    sm={12}
                    md={12}
                    lg={4}
                    className="d-flex flex-wrap align-items-center justify-content-center"
                  >
                    <Avatar
                      className="shadow"
                      sx={{ width: 120, height: 120 }}
                      src={img_url}
                    />
                    <strong
                      className="w-100 text-center px-0 pt-3"
                      style={{ color: "#3ABE88", cursor: "pointer" }}
                      onClick={handleOpenModal}
                    >
                      Editar foto
                    </strong>
                  </Col>
                  <Col sm={12} md={4} className="p-3 mt-0 mt-md-5">
                    <TextField
                      fullWidth
                      inputProps={{ maxLength: 250 }}
                      name="nombre"
                      label="Nombre"
                      variant="outlined"
                      value={Values.nombre}
                      error={Errores.nombre}
                      helperText={Errores.nombre ? "Nombre no valido" : ""}
                      onChange={handlInputChange}
                    />
                  </Col>
                  <Col sm={12} md={4} className="p-3 mt-0 mt-md-5">
                    <TextField
                      fullWidth
                      inputProps={{ maxLength: 250 }}
                      name="apellido_paterno"
                      label="Apellido paterno"
                      variant="outlined"
                      value={Values.apellido_paterno}
                      error={Errores.apellido_paterno}
                      helperText={
                        Errores.apellido_paterno
                          ? "Apellido paterno no válido"
                          : ""
                      }
                      onChange={handlInputChange}
                    />
                  </Col>
                  <Col sm={12} md={4} className="p-3">
                    <TextField
                      fullWidth
                      inputProps={{ maxLength: 250 }}
                      name="apellido_materno"
                      label="Apellido materno"
                      variant="outlined"
                      value={Values.apellido_materno}
                      error={Errores.apellido_materno}
                      helperText={
                        Errores.apellido_materno
                          ? "Apellido materno no valido"
                          : ""
                      }
                      onChange={handlInputChange}
                    />
                  </Col>

                  <Col sm={12} md={4} className="p-3">
                    <TextField
                      fullWidth
                      inputProps={{ maxLength: 250 }}
                      name="correo"
                      label="Correo electrónico"
                      variant="outlined"
                      helperText={Errores.correo ? "Correo incorrecto" : ""}
                      value={Values.correo}
                      error={Errores.correo}
                      onChange={handlInputChange}
                    />
                  </Col>

                  <Col sm={12} md={4} className="p-3">
                    <TextField
                      fullWidth
                      name="telefono"
                      label="Teléfono"
                      variant="outlined"
                      inputProps={{ maxLength: 10 }}
                      helperText={Errores.telefono ? "Teléfono inválido" : ""}
                      value={Values.telefono}
                      error={Errores.telefono}
                      onChange={handlInputChange}
                    />
                  </Col>

                  <Col sm={12} md={4} className="p-3">
                    <Puesto
                      Value={Values.id_puesto}
                      Error={Errores.id_puesto}
                      handlInputChange={handlInputChange}
                    ></Puesto>
                  </Col>

                  <Col sm={12} md={4} className="p-3">
                    <SelectGrupoAcceso
                      Value={Values.id_grupo}
                      Error={Errores.id_grupo}
                      handlInputChange={handlInputChange}
                    ></SelectGrupoAcceso>
                  </Col>
                  <Col sm={12} className="p-3">
                    {Values.id == 0 ? (
                      <>
                        <Alert severity="info">
                          <AlertTitle>Mensaje</AlertTitle>
                          Se enviará una contraseña temporal vía correo
                          electrónico
                        </Alert>
                      </>
                    ) : (
                      <></>
                    )}
                  </Col>

                  {/* <Col sm={12} md={4} className="p-3">
                <FormGroup>
                <FormControlLabel control={  <Switch
                  checked={Values.acceso_app}
                  defaultChecked
                  name="acceso_app"
                  error={Errores.acceso_app}
                  onChange={onChange}
                  tituloActivo="No acceso a la app"
                  tituloInactivo="Acceso a la app"
                  on
                />} label="Acceso a la app"/>
                </FormGroup>
              </Col> */}
                </>
              )}

              {tab == 1 && (
                <RepHorarios Horarios={Horarios} SetHorarios={SetHorarios} />
              )}
            
              <div className="row">

 <div className="col-12 text-end mb-3">

  <Typography className="w-100 ">
                {"Seleccionar todos"}
                <Switch
                  tituloActivo="Desactivar"
                  tituloInactivo="Activar"
                  id={0}
                  checked={isAll}
                  onChange={(checked) => {
                    const resultado = servicios.map((objeto) => {
                      return { ...objeto, ACTIVO: checked ? 1 : 0 };
                    });
                    setServicios([...resultado]);
                    setIsAll(checked);
                  }}
                />
              </Typography>
              <Divider></Divider>


 </div>


                
                <div className="col-12 col-md-6 col-lg-4">
                  {servicios.map((prod, index) => {
                    if (index % 3 === 0) {
                      return ( 
                        <div className="d-flex justify-content-between border-bottom">
                          <Typography>{prod.DESCRIPCION}</Typography> 
                          <Switch
                            checked={prod.ACTIVO == 1}
                            tituloActivo="Desactivar"
                            tituloInactivo="Activar"
                            id={prod.ID}
                            onChange={(checked) => {
                              const indice = servicios.findIndex(
                                (objeto) => objeto.ID === prod.ID
                              );
                              if (indice !== -1) {
                                servicios[indice].ACTIVO = checked ? 1 : 0;
                                setServicios([...servicios]);
                              }
                            }}
                          />
                        </div>

                      );
                    }
                    return null;
                  })}
                </div>
                <div className="col-12 col-md-6 col-lg-4">
                  {servicios.map((prod, index) => {
                    if (index % 3 === 1) {
                      return (
                        <div className="d-flex justify-content-between border-bottom">
                        <Typography>{prod.DESCRIPCION}</Typography> 
                        <Switch
                          checked={prod.ACTIVO == 1}
                          tituloActivo="Desactivar"
                          tituloInactivo="Activar"
                          id={prod.ID}
                          onChange={(checked) => {
                            const indice = servicios.findIndex(
                              (objeto) => objeto.ID === prod.ID
                            );
                            if (indice !== -1) {
                              servicios[indice].ACTIVO = checked ? 1 : 0;
                              setServicios([...servicios]);
                            }
                          }}
                        />
                      </div>
                      );
                    }
                    return null;
                  })}
                </div>
                <div className="col-12 col-md-6 col-lg-4">
                  {servicios.map((prod, index) => {
                    if (index % 3 === 2) {
                      return (
                        <div className="d-flex justify-content-between border-bottom">
                          <Typography>{prod.DESCRIPCION}</Typography> 
                          <Switch
                            checked={prod.ACTIVO == 1}
                            tituloActivo="Desactivar"
                            tituloInactivo="Activar"
                            id={prod.ID}
                            onChange={(checked) => {
                              const indice = servicios.findIndex(
                                (objeto) => objeto.ID === prod.ID
                              );
                              if (indice !== -1) {
                                servicios[indice].ACTIVO = checked ? 1 : 0;
                                setServicios([...servicios]);
                              }
                            }}
                          />
                        </div>
                      );
                    }
                    return null;
                  })}
                </div>
              </div>
            </Row>
          )}
          <Stack
            className="p-3"
            spacing={2}
            direction={{ xs: "column", sm: "row" }}
            style={{}}
          >
            <LoadingButton
              loading={IsGuardando}
              loadingPosition="start"
              disabled={IsLoading}
              onClick={() => GuardarEmpledo()}
              className="btn btn-Kalendar font-AvenirMedium  py-2 px-4 "
              variant="contained"
              xs={{ with: "100$" }}
            >
              <span className={IsGuardando ? "px-4" : "px-2"}>
                {IsGuardando ? "Guardando..." : id ? "Guardar" : "Crear"}
              </span>
            </LoadingButton>
            <Button
              onClick={() => navigate(-1)}
              className="btn btn-cancelar font-AvenirMedium py-2 px-4"
              variant="outlined"
            >
              Cancelar
            </Button>
          </Stack>
        </CardContent>
      </Card>

      {openModal && (
        <ModalCargarImagen
          id={Values.id}
          tipo={IMG.TIPO_COLABORADORES}
          Titulo="Cargar imagen de empleado"
          mostrar={openModal}
          imgUrl={Values.img}
          fnCerrar={handleCloseModal}
          fnDevolverImagen={fnDevolverImagen}
        />
      )}
    </>
  );
};

export default Empleado;
{
  /* <code>
<pre>
  {
    JSON.stringify(Values, null, 2)
  }
</pre>
</code> */
}
