
import { Autocomplete, TextField } from "@mui/material"
import { useEffect } from "react";
import { useState } from "react";
import { getProductosServicios, getInfoProductoServicio } from "../Funciones/SAT";

const ProductoServicioSat = ({ Value, Error, handlInputChange, Name = "clave_codigo_prod_sat", MinLen = 1 }) => {
    const [ProductoServicio, setProductoServicio] = useState({ cargado: false, lista: [{ CLAVE: 0, DESCRIPCION: "Producto o Servicio SAT" }] });

    // Consultar al momento de buscar por query  con sentencia like
    const consultar = (valor) => {
        getProductosServicios(valor)
            .then((resp) => {
                let list = JSON.parse(resp.data);
                if (list.length != 0) {
                    setProductoServicio({ cargado: true, lista: list });
                }
                else {
                    setProductoServicio({ cargado: true, lista: [{ CLAVE: 0, DESCRIPCION: "Sin claves disponibles" }] });
                }
            })
            .catch((resp) => {
                setProductoServicio({ cargado: true, lista: [{ CLAVE: 0, DESCRIPCION: "Sin claves disponibles" }] });
            });
    }

    //Consultar solo una vez en caso de edición por query con sentencia where clave = 
    useEffect(() => {
        getInfoProductoServicio(Value)
            .then((resp) => {
                clearInterval(window.intervalo)
                let list = JSON.parse(resp.data);
                if (list.length != 0) {
                    const info = list[0];
                    setProductoServicio({ cargado: true, lista: [{ CLAVE: parseInt(info.CLAVE).toString(), DESCRIPCION: info.DESCRIPCION }] });
                }
                else {
                    setProductoServicio({ cargado: true, lista: [{ CLAVE: 0, DESCRIPCION: "Sin claves disponibles" }] });
                }
            })
            .catch((resp) => {
                clearInterval(window.intervalo)
                setProductoServicio({ cargado: true, lista: [{ CLAVE: 0, DESCRIPCION: "Sin claves disponibles" }] });
            });
    }, [])


    const getOpcion = () => {
        const opcion = ProductoServicio.lista.find(x => x.CLAVE == Value)
        if (opcion) {
            if (opcion.CLAVE != 0) {
                return opcion
            } else {
                return null
            }
        } else {
            return null
        }
    }


    return (
        ProductoServicio.cargado ? (
            <Autocomplete
                fullWidth
                defaultValue={getOpcion}
                isOptionEqualToValue={(option, value) => {
                    return option.CLAVE == value.CLAVE
                }}
                onChange={(e, value) => {
                    if (value) {
                        handlInputChange({ target: { name: Name, value: value.CLAVE } })
                    }
                    else {
                        handlInputChange({ target: { name: Name, value: "" } })
                    }
                }}
                onInputChange={(event, newInputValue) => {
                    const titulo = newInputValue.trim().length == 0 ? "Sin claves disponibles" : newInputValue + " - Buscando claves del SAT..."
                    setProductoServicio({ ...ProductoServicio, lista: [{ CLAVE: 0, DESCRIPCION: titulo }] });
                    if (newInputValue.trim().length >= MinLen) {

                        clearInterval(window.intervalo)
                        window.intervalo = setInterval(function () {
                            consultar(newInputValue)
                            clearInterval(window.intervalo)
                        }, 1000)

                    }
                }}
                options={ProductoServicio.lista}
                getOptionLabel={(option) => {
                    return (option.CLAVE != 0 ? (option.CLAVE + ' - ') : "") + option.DESCRIPCION
                }}
                renderInput={(params) => {
                    return (
                        <TextField
                            {...params}
                            error={Error}
                            helperText={Error ? "Selecciona Producto de Serviciod del SAT" : ""}
                            label="Producto o Servicio del SAT"
                        />
                    )
                }}
            />
        ) : (
            <TextField
                fullWidth
                disabled={true}
                value={"Producto o Servicio del SAT"}
            />
        )
    )
}

export default ProductoServicioSat