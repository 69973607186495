import {
  Alert,
  Button,
  Card,
  CardContent,
  Snackbar,
  Stack,
  TextField,
  FormControlLabel,
  FormGroup,
  Divider,
} from "@mui/material";

import React, { useState, useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { styled } from "@mui/material/styles";
import { useParams } from "react-router-dom";
import { guardarConfig, getInfoConfigGeneral } from "../Funciones/Generales";
import Loading from "../../../Includes/Loading";
import LoadingButton from "@mui/lab/LoadingButton";
import withReactContent from "sweetalert2-react-content";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import Swal from "sweetalert2";
import Switch from "./../../../Includes/Switch";
import "../../../Lib/Funciones Generales/Prototipos";

const MySwal = withReactContent(Swal);

const Configuraciones = () => {
  const { id } = useParams();

  const navigate = useNavigate();
  const [IsLoading, setIsLoading] = useState(true);
  const [IsGuardando, setIsGuardando] = useState(false);
  const [open, setOpen] = useState(false);
  const [mensaje, setMensaje] = useState("");
  const [Values, setValues] = useState({
    id: id ?? "0",
    encabezado: "",
    pie: "",
    linea_1: "",
    incrementos_horarios: "",
    ancho: "",
    fuente: "",
    si_cobro: false,
  });
  const [Errores, setErrores] = useState({
    encabezado: false,
    pie: false,
    linea_1: false,
    si_cobro: false,
    incrementos_horarios: false,
    ancho: false,
    fuente: false,
  });

  const handleClose = () => {
    setOpen(false);
  };

  const handlInputChange = ({ target }) => {
    let Name = target.name;
    const Value = target.value;
    let NewValue = {
      ...Values,
      [Name]: Value,
    };

    if (Name == "si_cobro") {
      NewValue = { ...NewValue, si_cobro: Value };
    }

    if (Name == "incrementos_horarios") {
      NewValue = { ...NewValue, incrementos_horarios: Value.toDecimal() };
    }

    if (Name == "ancho") {
      NewValue = { ...NewValue, ancho: Value.toDecimal() };
    }

    if (Name == "fuente") {
      NewValue = { ...NewValue, fuente: Value.toDecimal() };
    }

    setValues(NewValue);

    setErrores({
      ...Errores,
      [Name]: false,
    });
  };

  useEffect(() => {
    setIsLoading(true);
    if (id == undefined) {
      getInfoConfigGeneral(id)
        .then((resp) => {
          //console.log(resp);
          if (resp?.err?.length == 0) {
            MySwal.fire({
              title: "Error",
              html: resp.mensaje,
              icon: "error",
              confirmButtonColor: "#3ABE88",
              showConfirmButton: true,
              allowEscapeKey: false,
              allowEnterKey: false,
              allowOutsideClick: false,
            }).then(() => {
              setIsLoading(false);
              navigate(-1);
            });
          } else {
            setValues(resp.values);
            setIsLoading(false);
          }
        })
        .catch((resp) => {
          MySwal.fire({
            title: "Error",
            html: resp.mensaje,
            icon: "error",
            confirmButtonColor: "#3ABE88",
            showConfirmButton: true,
            allowEscapeKey: false,
            allowEnterKey: false,
            allowOutsideClick: false,
          }).then(() => {
            setIsLoading(false);
            navigate(-1);
          });
          //setIsLoading(false);
        });
    } else {
      setIsLoading(false);
    }
  }, []);

  const GuardarConfig = () => {
    setIsGuardando(true);
    guardarConfig(Values, Errores, setErrores)
      .then((data) => {
        console.log(data);
        if (data.codigo == "200") {
          setIsGuardando(false);
          MySwal.fire({
            title: "Correcto",
            html: data.mensaje,
            icon: "success",
            confirmButtoColor: "#3ABE88",
            showConfirmButton: false,
            timer: 1700,
          }).then((result) => {
            // navigate(-1);
          });
        } else {
          setMensaje(data.mensaje);
          setOpen(true);
          setIsGuardando(false);
        }
      })
      .catch((data) => {
        setMensaje(data.mensaje);
        setOpen(true);
        setIsGuardando(false);
      });
  };

  const StyledSnackbar = styled((props) => <Snackbar {...props} />)(
    ({ theme }) => ({
      "& .MuiSnackbar-root": {
        top: theme.spacing(15),
      },
    })
  );

  useEffect(() => {
    document.title = `Configuraciones`;
  }, []);

  return (
    <>
      <div className="p-3 w-100"></div>

      <StyledSnackbar
        direction="right"
        open={open}
        autoHideDuration={6000}
        onClose={handleClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      >
        <Alert onClose={handleClose} severity="error" sx={{ width: "100%" }}>
          {mensaje}
        </Alert>
      </StyledSnackbar>

      <Card elevation={3}>
        <CardContent className="p-0">
          <div className="p-3">
            <h6 className="mb-0 text-left font-AvenirBold">
              Configuraciones generales
            </h6>
          </div>
          <hr
            style={{
              backgroundColor: "#DFDEE0",
              height: "1px",
              opacity: "1",
            }}
            className="m-0 p-0"
          />

          {IsLoading ? (
            <Row className="px-3">
              <Col sm={12} className="p-3">
                <Loading />
              </Col>
            </Row>
          ) : (
            <Row className="p-3">
              <Col sm={12} className="p-3 d-flex justify-content-between">
                <div className="w-100">
                  <h6 className="font-AvenirMedium m-0 p-0">
                    Utilizar funciones de punto de venta
                  </h6>
                  <h6
                    lassName="font-Avenir mt-0 p-0"
                    style={{ fontSize: "13px" }}
                  >
                    Con ella podrás realizar cobros con diferentes formas de
                    pago
                  </h6>
                </div>

                <FormGroup>
                  <div className="d-flex justify-content-center">
                    <p
                      className="my-0 algin-self-center font-Avenir mt-2 text-center"
                      style={{ fontSize: "13px" }}
                    >
                      {Values.si_cobro ? "Activo" : "Desactivado"}
                    </p>

                    <Switch
                      className="algin-self-center"
                      checked={Values.si_cobro}
                      name="si_cobro"
                      error={Errores.si_cobro}
                      onChange={(checked) => {
                        handlInputChange({
                          target: {
                            name: "si_cobro",
                            value: checked,
                          },
                        });
                      }}
                    />
                  </div>
                </FormGroup>
              </Col>
              <Divider light />

              <div className="w-100 mt-3">
                <h6 className="font-AvenirMedium m-0 p-0">
                  Configuraciones de impresión de ticket
                </h6>
                <h6
                  lassName="font-Avenir mt-0 p-0"
                  style={{ fontSize: "13px" }}
                >
                  Esta es la información que se muestra en el ticket
                </h6>
              </div>

              <Col sm={12} md={6} className="p-3">
                <TextField
                  fullWidth
                  name="encabezado"
                  label="Encabezado del ticket"
                  variant="outlined"
                  helperText={Errores.encabezado ? "Encabezado incorrecto" : ""}
                  value={Values.encabezado}
                  error={Errores.encabezado}
                  onChange={handlInputChange}
                  inputProps={{ maxLength: 500 }}
                  multiline
                />
              </Col>

              <Col sm={12} md={6} className="p-3">
                <TextField
                  fullWidth
                  name="pie"
                  label="Pie del ticket"
                  variant="outlined"
                  helperText={Errores.pie ? "Pie incorrecto" : ""}
                  value={Values.pie}
                  error={Errores.pie}
                  onChange={handlInputChange}
                  inputProps={{ maxLength: 500 }}
                  multiline
                />
              </Col>

              <Col sm={12} md={4} className="p-3">
                <TextField
                  fullWidth
                  name="linea_1"
                  label="Linea del ticket"
                  variant="outlined"
                  helperText={Errores.linea_1 ? "Linea incorrecta" : ""}
                  value={Values.linea_1}
                  error={Errores.linea_1}
                  onChange={handlInputChange}
                  inputProps={{ maxLength: 500 }}
                  multiline
                />
              </Col>

              <Col sm={12} md={4} className="p-3">
                <TextField
                  fullWidth
                  name="ancho"
                  label="Ancho del ticket (mm)"
                  variant="outlined"
                  helperText={Errores.ancho ? "Ancho incorrecta" : ""}
                  value={Values.ancho}
                  error={Errores.ancho}
                  onChange={handlInputChange}
                  inputProps={{ maxLength: 500 }}
                  multiline
                />
              </Col>

              <Col sm={12} md={4} className="p-3">
                <TextField
                  fullWidth
                  name="fuente"
                  label="Fuente del ticket (px)"
                  variant="outlined"
                  helperText={Errores.fuente ? "Fuente incorrecta" : ""}
                  value={Values.fuente}
                  error={Errores.fuente}
                  onChange={handlInputChange}
                  inputProps={{ maxLength: 500 }}
                  multiline
                />
              </Col>

              <Divider light />

              <div className="w-100 mt-3">
                <h6 className="font-AvenirMedium m-0 p-0">
                  Otras Configuraciones
                </h6>
                <h6
                  lassName="font-Avenir mt-0 p-0"
                  style={{ fontSize: "13px" }}
                ></h6>
              </div>

              <Col sm={12} md={3} className="p-3">
                <TextField
                  fullWidth
                  name="incrementos_horarios"
                  label="Periodicidad de reservaciones (minutos)"
                  variant="outlined"
                  helperText={
                    Errores.incrementos_horarios ? "Incremento incorrecto" : ""
                  }
                  value={Values.incrementos_horarios}
                  error={Errores.incrementos_horarios}
                  onChange={handlInputChange}
                />
              </Col>
            </Row>
          )}
          <Stack
            className="p-3"
            spacing={2}
            direction={{ xs: "column", sm: "row" }}
            style={{}}
          >
            <LoadingButton
              loading={IsGuardando}
              loadingPosition="start"
              disabled={IsLoading}
              onClick={() => GuardarConfig()}
              className="btn btn-Kalendar font-AvenirMedium  py-2 px-4 "
              variant="contained"
              xs={{ with: "100$" }}
            >
              <span className={IsGuardando ? "px-4" : "px-2"}>
                {IsGuardando ? "Guardando..." : id ? "Guardar" : "Guardar"}
              </span>
            </LoadingButton>
            {/* <Button
                            onClick={() => navigate(-1)}
                            className="btn btn-cancelar font-AvenirMedium py-2 px-4"
                            variant="outlined"
                        >
                            Cancelar
                        </Button> */}
          </Stack>
        </CardContent>
      </Card>
    </>
  );
};

export default Configuraciones;
