import { Route, Routes } from "react-router-dom";
import Error404 from "../../Includes/Error404";
import Descuento from "./Componentes/Descuentos";
import RepDescuento from "./Componentes/RepDescuentos";

const routerDescuentos = () => {
  return (
    <>
      <Routes>
        <Route index element={<RepDescuento />} />
        <Route path="/add" element={<Descuento />} />
        <Route path="/edit/:id" element={<Descuento />} />
        <Route path="*" element={<Error404 />} />
      </Routes>
    </>
  );
}

export default routerDescuentos