import React, { useEffect, useState } from "react";
import FullCalendar from "@fullcalendar/react"; // must go before plugins
import resourceTimeGridPlugin from "@fullcalendar/resource-timegrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import resourceTimelinePlugin from "@fullcalendar/resource-timeline";
import interactionPlugin from "@fullcalendar/interaction";
import adaptivePlugin from "@fullcalendar/adaptive";
import dayGridPlugin from "@fullcalendar/daygrid";
import dayjs from "dayjs";
import { getSucursalesandColabora } from "../../Catalogos/Colaboradores/Funciones/Colaboradores";

import { useRef } from "react";
import { Button, Divider } from "@mui/material";
import { Card } from "react-bootstrap";
import DialogSetHJorario from "../Dialogos/DialogSetHJorario";
import {
  Eliminar,
  getHorariosSuc,
  guardarHorario,
} from "../Funciones/Horarios";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import $ from "jquery";
import { getInfoConfigGeneral } from "../../Configuraciones/Generales/Funciones/Generales";
const MySwal = withReactContent(Swal);

export default function RepHorarioSemanal({
  id_colaborador = 0,
  title = "Horario semanal de colaboradores",
  resourceAreaHeaderContent = "COLABORADORES",
  titlePage = "Horario semanal",
  isReverseAll = false,
  isVista = false,
}) {
  const calendarRef = useRef(null);
  const [horarios, setHorarios] = useState([]);
  const [isOpenDialogoHorario, setIsOpenDialogoHorario] = useState(false);
  const [rangoSelec, setRangoSelec] = useState([]);

  const [colaboradores, setColaboradores] = useState([]);
  const [sucursales, setSucursales] = useState([]);
  const [resources, setResources] = useState([]);
  const [IsLoading, setIsLoading] = useState(true);
  const [eventClickInfoM, setEventClickInfoM] = useState(null);

  const [rango, setRango] = useState({
    inicio: "09:00:00",
    fin: "23:59:59",
  });

  const getColaboradores = () => {
    setIsLoading(true);
    getSucursalesandColabora(Number(id_colaborador))
      .then((resp) => {
        let list = JSON.parse(resp.data);
        let sucursales = JSON.parse(resp.sucursales);
        if (list.length != 0) {
          const filteredResources = list.map((resource) => {
            return {
              id: resource.ID.toString(),
              title: resource.DESCRIPCION,
            };
          });
          const filteredResources_ = sucursales.map((resource) => {
            return {
              id: resource.ID.toString(),
              title: resource.DESCRIPCION,
            };
          });

          if (id_colaborador > 0) {
            setColaboradores(filteredResources_);
            setSucursales(filteredResources);
          } else {
            setColaboradores(filteredResources);
            setSucursales(filteredResources_);
          }
        } else {
          setColaboradores([]);
        }
      })
      .catch((resp) => {
        console.log(resp);
        setColaboradores([]);
      });
  };

  const getHorarios = () => {
    setIsLoading(true);
    getHorariosSuc(Number(id_colaborador))
      .then((resp) => {
        let list = JSON.parse(resp.data);
        if (list.length != 0) {
          const filteredResources = list.map((resource) => {
            return {
              id: resource.ID.toString(),
              resourceId: resource.ID_SUCURSAL + "|" + resource.ID_COLABORADOR,
              daysOfWeek: [resource.DIA],
              startTime: resource.INICIO,
              endTime: resource.FIN,
              title: resource.INICIO + " - " + resource.FIN + " Hrs",
              backgroundColor: "#FD8C27",
              borderColor: "#FD8C27",
            };
          });
          setHorarios(filteredResources);
          //console.log(filteredResources);
          //console.log(filteredResources);

          /*
           id: "1",
                resourceId: "32|32",
                daysOfWeek: [1], // Lunes
                startTime: "10:00",
                endTime: "13:00",
                title: "10 - 13 hrs",
                color: "red",
          
          */
        } else {
          setHorarios([]);
        }
      })
      .catch((resp) => {
        console.log(resp);
        setColaboradores([]);
      });
  };

  //console.log(horarios);

  const filtrarColaboradores = () => {
    if (isReverseAll) {
      const RES = colaboradores.map((col) => {
        return sucursales.map((suc) => {
          return {
            id: suc.id.toString() + "|" + col.id.toString(),
            title: suc.title,
            building: col.title,
          };
        });
      });
      setResources(RES.flat());
      //console.log(RES.flat());
    } else {
      const RES = sucursales.map((suc) => {
        return colaboradores.map((col) => {
          //console.log(col);
          //console.log(horarios);
          return {
            /*id:
            id_colaborador == 0
              ? suc.id.toString() + "|" + col.id.toString()
              : col.id.toString() + "|" + suc.id.toString(),*/
            id:
              id_colaborador == 0
                ? suc.id.toString() + "|" + col.id.toString()
                : col.id.toString() + "|" + suc.id.toString(),
            title: col.title,
            building: suc.title,
          };
        });
      });
      if (isVista) {
        const filteredTotal = RES.flat().filter((data) => {
          const elemento = horarios.find((e) => e.resourceId == data.id);
          if (elemento != undefined) {
            console.log(elemento);
            return data;
          }
        });
        //console.log("filteredTotal", filteredTotal);
        //console.log("horarios", horarios); //43
        //console.log("RES.flat()", RES.flat()); //54
        setResources(filteredTotal);
      } else {
        setResources(RES.flat());
      }
    }
  };

  useEffect(() => {
    getColaboradores();
    getHorarios();
    if (Number(id_colaborador) != 0) {
      $(".fc-media-screen").attr(
        "style",
        "height: 50vh !important; max-height: 65vh !important;"
      ); 
    } else {
      $(".fc-media-screen").attr("style", "max-height: 85vh !important;");
    }
    //console.log($);
    //console.log($(".fc-media-screen"));
    getInfoConfigGeneral()
      .then((data) => {
        //console.log(data.other.INICIO_H);
        //console.log(data.other.FIN_H);
        setRango({
          ...rango,
          inicio: data.other.INICIO_H,
          fin: data.other.FIN_H,
        });
      })
      .catch((data) => {
        console.log(data);
      });
    document.title = titlePage;
  }, []);

  useEffect(() => {
    filtrarColaboradores();
  }, [sucursales]);

  const handleDateSelection = (selectInfo) => {
    const { start, end, view } = selectInfo;

    if (view.type === "resourceTimelineTenDay" && !isSameDay(start, end)) {
      calendarRef.current.getApi().unselect();
      return false;
    }
    //console.log(selectInfo);
    //console.log(selectInfo.resource._resource.id);
    const startDate = selectInfo.start;
    const endDate = selectInfo.end;

    //console.log("Fecha de inicio:", startDate);

    /*
  DialogSetHJorario({
    id,
    sucursal,
    colaborador,
    inicio  ,
    fin  , 
*/

    // Obtener el día de la semana
    const daysOfWeek = [
      "Domingo",
      "Lunes",
      "Martes",
      "Miércoles",
      "Jueves",
      "Viernes",
      "Sábado",
    ];
    const dayOfWeek = start.getDay(); // Retorna un número del 0 (Domingo) al 6 (Sábado)
    const dayOfWeekend = end.getDay(); // Retorna un número del 0 (Domingo) al 6 (Sábado)

    const selectedDayOfWeek = daysOfWeek[dayOfWeek];
    const selectedDayOfWeekEnd = daysOfWeek[dayOfWeekend];

    // Obtener la hora en formato local
    const selectedTime = start.toLocaleTimeString([], {
      hour: "2-digit",
      minute: "2-digit",
    });
    const selectedTimeEnd = end.toLocaleTimeString([], {
      hour: "2-digit",
      minute: "2-digit",
    });

    setRangoSelec({
      id: 0,
      resource_id: selectInfo.resource._resource.id,
      sucursal: selectInfo.resource._resource.extendedProps.building,
      colaborador: selectInfo.resource._resource.title,
      inicio: selectedTime,
      fin: selectedTimeEnd,
      dia: dayOfWeek,
      diaName: selectedDayOfWeek,
    });
    setIsOpenDialogoHorario(true);
  };

  const isSameDay = (date1, date2) => {
    return (
      date1.getFullYear() === date2.getFullYear() &&
      date1.getMonth() === date2.getMonth() &&
      date1.getDate() === date2.getDate()
    );
  };

  const handleEventClick = (eventClickInfo) => {
    const event = eventClickInfo.event;
    const eventId = event.id;
    const title = event.title;
    let id = eventId;
    if (id != undefined) {
      setRangoSelec({
        id: id,
      });
      setEventClickInfoM(eventClickInfo);
      setIsOpenDialogoHorario(true);
    }
  };

  const eventResizeFullCalendar = ({
    el,
    endDelta,
    event,
    jsEvent,
    oldEvent,
    relatedEvents,
    revert,
    startDelta,
    view,
  }) => {
    //console.log(event);
    MySwal.fire({
      title: "¿Estas seguro de actualizar este horario?",
      icon: "warning",
      showDenyButton: true,
      denyButtonText: "No, cancelar",
      confirmButtonText: "Si, estoy seguro",
      confirmButtonColor: "#3ABE88",
      denyButtonColor: "#65748B",
      reverseButtons: true,
    }).then((result) => {
      if (result.isConfirmed) {
        const data = {
          id: event._def.publicId,
          resource_id: event._def.resourceIds[0],
          inicio: event.start.toLocaleTimeString([], {
            hour: "2-digit",
            minute: "2-digit",
          }),
          fin: event.end.toLocaleTimeString([], {
            hour: "2-digit",
            minute: "2-digit",
          }),
          dia: event.start.getDay(),
        };

        guardarHorario(data)
          .then((data) => {
            if (data.codigo == "200") {
              Swal.fire({
                title: "Correcto",
                html: data.mensaje,
                icon: "success",
                confirmButtoColor: "#3ABE88",
                showConfirmButton: false,
                timer: 1700,
              }).then((result) => {
                getHorarios();
              });
            } else {
              Swal.fire({
                title: "Error",
                html: data.mensaje,
                icon: "info",
                confirmButtoColor: "#3ABE88",
              }).then((result) => {
                //setRangoSelec(null);
                //setIsOpenDialogoHorario(false);
                revert();
              });
            }
            //console.log(data);
            //getColaboradores();
            //getHorarios();
          })
          .catch((data) => {
            Swal.fire({
              title: "Error",
              html: data.mensaje,
              icon: "info",
              confirmButtoColor: "#3ABE88",
            }).then((result) => {
              revert();
            });
          });
        //revert();
      } else if (result.isDenied) {
        revert();
      }
    });
  };

  //console.log(resources);
  return (
    <>
      <div>
        <div className="p-0">
          <div
            style={{ userSelect: "none", backgroundColor: "#f9fafc" }}
            onContextMenu={(e) => {
              //  e.preventDefault();
            }}
          >
            <h5 className="font-AvenirBold m-2">{title}</h5>
            <div style={{ backgroundColor: "white" }}>
              <FullCalendar
                editable={isVista ? false : true}
                eventResize={isVista ? null : eventResizeFullCalendar}
                eventStartEditable={false} //arrastre de eventos
                eventResourceEditable={false} //Determina si el usuario puede arrastrar eventos entre recursos.
                eventDurationEditable={isVista ? false : true} //editar desde las orillas, estirarlo
                selectable={isVista ? false : true}
                select={handleDateSelection}
                eventClick={isVista ? null : handleEventClick}
                ref={calendarRef}
                //selectMirror={true}
                //aspectRatio={50}
                //dayMaxEvents={true}
                handleWindowResize={true}
                plugins={[
                  resourceTimelinePlugin,
                  dayGridPlugin,
                  interactionPlugin,
                ]}
                initialView="resourceTimelineTenDay"
                views={{
                  resourceTimelineTenDay: {
                    type: "resourceTimeline",
                    buttonText: "Por semana",
                    duration: { days: 7 },
                    slotDuration: { days: 1 },
                    slotMinTime: rango.inicio,
                    slotMaxTime: rango.fin,
                    slotLabelContent: (arg) => {
                      if (arg.text.includes("/")) {
                        const dayName = arg.date.toLocaleString("default", {
                          weekday: "long",
                        });
                        return dayName;
                      } else {
                        return arg.text;
                      }
                    },
                    slotLabelInterval: { hour: 2 },
                  },
                }}
                headerToolbar={null}
                locales={"es"}
                resourceGroupField="building"
                resourceAreaHeaderContent={resourceAreaHeaderContent}
                resources={resources}
                events={horarios}
                //slotMinTime={"05:00:00"}
                //slotMaxTime={"23:00:00"}
                //scrollTime={"08:00"}
              />
            </div>
          </div>
        </div>
      </div>
      {isOpenDialogoHorario && (
        <DialogSetHJorario
          dataI={rangoSelec}
          getHorarios={getHorarios}
          setRangoSelec={setRangoSelec}
          setIsOpenDialogoHorario={setIsOpenDialogoHorario}
          eventClickInfo={eventClickInfoM}
        />
      )}
    </>
  );
}
