import React, { useEffect, useState } from "react";
import {
  Grid,
  Card,
  CardContent,
  TextField,
  Autocomplete,
  Typography,
  Tooltip,
} from "@mui/material";
import { Col, Row } from "react-bootstrap";
import Box from "@mui/material/Box";
import LinearProgress from "@mui/material/LinearProgress";
import { updateRadial } from "../../Funciones/Dashboard";
import {
  avatarLetters,
  formatMoneda,
} from "../../../Lib/Funciones Generales/Generales";
import SelectSucursal from "../../../Configuraciones/Sucursales/Componentes/SelectSucursal";

const getDateByNumber = (moth4Number, year) => {
  let moth;
  switch (moth4Number) {
    case 1:
      moth = "Enero";
      break;
    case 2:
      moth = "Febrero";
      break;
    case 3:
      moth = "Marzo";
      break;
    case 4:
      moth = "Abril";
      break;
    case 5:
      moth = "Mayo";
      break;
    case 6:
      moth = "Junio";
      break;
    case 7:
      moth = "Julio";
      break;
    case 8:
      moth = "Agosto";
      break;
    case 9:
      moth = "Septiembre";
      break;
    case 10:
      moth = "Octubre";
      break;
    case 11:
      moth = "Noviembre";
      break;
    case 12:
      moth = "Diciembre";
      break;
  }

  return moth + " - " + year;
};

var currentMoth = new Date();
currentMoth.setMonth(currentMoth.getMonth());
var oneMothAgo = new Date();
oneMothAgo.setMonth(oneMothAgo.getMonth() - 1);
var twoMothAgo = new Date();
twoMothAgo.setMonth(twoMothAgo.getMonth() - 2);
var treeMothAgo = new Date();
treeMothAgo.setMonth(treeMothAgo.getMonth() - 3);
var fourMothAgo = new Date();
fourMothAgo.setMonth(fourMothAgo.getMonth() - 4);
var fiveMothAgo = new Date();
fiveMothAgo.setMonth(fiveMothAgo.getMonth() - 5);
var sixMothAgo = new Date();
sixMothAgo.setMonth(sixMothAgo.getMonth() - 6);

const options = [
  getDateByNumber(currentMoth.getMonth() + 1, currentMoth.getFullYear()),
  getDateByNumber(oneMothAgo.getMonth() + 1, oneMothAgo.getFullYear()),
  getDateByNumber(twoMothAgo.getMonth() + 1, twoMothAgo.getFullYear()),
  getDateByNumber(treeMothAgo.getMonth() + 1, treeMothAgo.getFullYear()),
  getDateByNumber(fourMothAgo.getMonth() + 1, fourMothAgo.getFullYear()),
  getDateByNumber(fiveMothAgo.getMonth() + 1, fiveMothAgo.getFullYear()),
  getDateByNumber(sixMothAgo.getMonth() + 1, sixMothAgo.getFullYear()),
];
export default function ChartBar2({
  titulo = "",
  color = "#005bd6",
  porcentajes = [],
  nombreBarras = { N1: "", N2: "", N3: "", N4: "" },
  nombreBarrasResponsive = { NR1: "", NR2: "", NR3: "", NR4: "" },
  type,
}) {
  const [Values, setValues] = useState({ id_sucursal: "0" });
  const [data, setData] = useState(porcentajes.length == 0 ? [] : porcentajes);
  const [porcentajesData, setPorcentajesData] = useState([
    { valor: 0, porcentaje: 0 },
    { valor: 0, porcentaje: 0 },
    { valor: 0, porcentaje: 0 },
    { valor: 0, porcentaje: 0 },
  ]);
  const [valueFecha, setValueFecha] = useState(options[0]);
  const [inputValue, setInputValue] = useState("");

  const updateDataProcess = (d = []) => {
    const filtered = d.map((item, i) => {
      return {
        nombre: type == 1 ? nombreBarras[`N${i + 1}`] : item.NOMBRE,
        total: item.TOTAL,
      };
    });

    setData(filtered);

    const a = filtered.map((fil) => {
      return fil.total;
    });

    const MaxVal = Math.max(...a);

    const newP = a.map((valor) => {
      const porcentaje = (valor / MaxVal) * 100;
      return { valor: valor, porcentaje: porcentaje ? porcentaje : 0 };
    });
    setPorcentajesData(newP);
  };

  useEffect(() => {
    updateDataProcess(porcentajes);
  }, []);

  useEffect(() => {
    updateRadial(type, type == 1 ? Values.id_sucursal : valueFecha).then(
      (resp) => {
        if (resp?.err?.length == 0) {
        } else {
          let datos = JSON.parse(resp.data);
          updateDataProcess(datos);
        }
      }
    );
  }, [Values.id_sucursal, valueFecha]);

  const handlInputChange = ({ target }) => {
    let Name = target.name;
    let Value = target.value;

    setValues({
      ...Values,
      [Name]: Value,
    });
  };

  const style = {
    fontSize: "11px",
  };
  return (
    <>
      <>
        <Card className="shadow-sm" elevation={3} style={{ height: "491px" }}>
          <CardContent className="p-0">
            <div className="p-3">
              <h6 className="mb-0 text-left font-AvenirBold">{titulo}</h6>
            </div>
            <hr
              style={{
                backgroundColor: "#DFDEE0",
                height: "1px",
                opacity: "1",
              }}
              className="m-0 p-0"
            />
            <div lassName="p-3 m-3">
              <Row className="p-1 m-2">
                <Col>
                  <Row xs={4} sm={4} md={4}>
                    <Grid
                      container
                      direction="row"
                      justifyContent="center"
                      alignItems="center"
                      rowSpacing={0}
                      //columnSpacing={{ xs: 0, sm: 2, md: 3 }}
                      style={{ background: "" }}
                    >
                      {data.map((item, i) => {
                        return (
                          <Tooltip
                            placement="right"
                            title={
                              type == 1
                                ? data[i].total
                                : formatMoneda(data[i].total)
                            }
                          >
                            <div className="barcontainer">
                              <div
                                className="bar"
                                style={{
                                  height: `${porcentajesData[i].porcentaje}%`,
                                  backgroundColor: color,
                                  maxHeight: "100%",
                                }}
                              ></div>
                            </div>
                          </Tooltip>
                        );
                      })}
                    </Grid>
                  </Row>
                  <Row xs={3} sm={3} md={3}>
                    <Grid
                      container
                      direction="row"
                      justifyContent="center"
                      alignItems="center"
                      rowSpacing={0}
                      style={{
                        background: "",
                      }}
                    >
                      {data.map((item, i) => {
                        return (
                          <Typography
                            className="  justify-content-center ms-auto me-auto"
                            style={style}
                          >
                            {type == 1
                              ? data[i].nombre
                              : avatarLetters(data[i].nombre)}
                          </Typography>
                        );
                      })}
                    </Grid>
                  </Row>
                </Col>
              </Row>
              <Row className="p-1 m-2">
                <Col>
                  <Grid
                    container
                    direction="column"
                    justifyContent="center"
                    alignItems="center"
                  >
                    {type === 1 ? (
                      <SelectSucursal
                        size="medium"
                        Value={Values.id_sucursal}
                        Error={null}
                        handlInputChange={handlInputChange}
                        showTodos={true}
                        disabledClearable
                      />
                    ) : (
                      <Autocomplete
                        value={valueFecha}
                        onChange={(event, newValue) => {
                          setValueFecha(newValue);
                        }}
                        inputValue={inputValue}
                        onInputChange={(event, newInputValue) => {
                          setInputValue(newInputValue);
                        }}
                        id="controllable-states-demo"
                        options={options}
                        sx={{ width: "100%" }}
                        renderInput={(params) => (
                          <TextField {...params} label="Rango de Fechas" />
                        )}
                        disableClearable={true}
                      />
                    )}
                  </Grid>
                </Col>
              </Row>
            </div>
          </CardContent>
        </Card>
      </>
    </>
  );
}
