import { stringify } from "query-string";
import { getData, postdData } from "../../../Context/backend";
import { TiposPlanes } from "../../../Lib/Funciones Generales/Constantes";


///////////////////
/* FUNCIONES GET */
///////////////////
export const getLista = async (iTake, iSkip, Order, query) => {
  //onsole.log(query)
  if (Order != "1" && query != "") {
    Order = "1";
  }
  let data = [];
  let queryParamsObj = {
    iTake: iTake,
    iSkip: iSkip,
    Order: Order,
    query: query,
    siManager: 0
  };

  //console.log(queryParamsObj);
  const url = `empresa/ListarPlanes?${stringify(queryParamsObj)}`;
  const res = await getData(url);
  //console.log("res: ", res);
  return new Promise((resolve, reject) => {
    if (!res.error) {
      data = res.data.respuesta;
      resolve(data);
    } else {
      reject(res);
    }
  });
};





export const getInfoPlanEmpresa = async (id_plan, initValues, siExtra = false) => {
  let data = [];
  let queryParamsObj = {
    id_plan: id_plan,
  };

  let url = '';
  if (!siExtra) {
    url = `empresa/ConsultarPlanInfo?${stringify(queryParamsObj)}`;
  } else {
    url = `empresa/ConsultarPlanInfoExtra?${stringify(queryParamsObj)}`;
  }
  const res = await getData(url);
  return new Promise((resolve, reject) => {
    if (!res.error) {
      data = JSON.parse(res.data.respuesta.data);
      const fecha_empresa = res.data.respuesta.fecha_empresa
      const contador_sucursales = res.data.respuesta.contador_sucursales
      const contador_colaboradores = res.data.respuesta.contador_colaboradores
      let Values = { ...initValues, fecha: fecha_empresa, contador_sucursales, contador_colaboradores }
      if (data.length > 0) {
        data = data[0]
        Values = {
          ...Values,
          id_licencia: data.ID,
          fecha_corte: data.FECHA_CORTE,
          activo: data.ACTIVO,
          total: data.TOTAL,
          id_empresa: data.ID_EMPRESA,
          id_plan: data.ID_PLAN,
          nombre_plan: data.NOMBRE_PLAN,
          periodo_de_plan: data.PERIODO_DE_PLAN,
          total_sucursales_ad: data.TOTAL_SUCURSALES_AD,
          total_colaboradores_ad: data.TOTAL_COLABORADORES_AD,
          numero_de_sucursales: data.NUMERO_DE_SUCURSALES,
          numero_de_colaboradores: data.NUMERO_DE_COLABORADORES,
          numero_de_sucursales_gratis: data.NUMERO_DE_SUCURSALES_GRATIS,
          numero_de_colaboradores_gratis: data.NUMERO_DE_COLABORADORES_GRATIS,
          precio_mensual: data.PRECIO_MENSUAL,
          precio_trimestral: data.PRECIO_TRIMESTRAL,
          precio_anual: data.PRECIO_ANUAL,
          precio_por_colaborador: data.PRECIO_POR_COLABORADOR,
          precio_por_sucursal: data.PRECIO_POR_SUCURSAL,
          max_sucursales: data.MAX_SUCURSALES,
          max_colaboradores: data.MAX_COLABORADORES,
          color: data.COLOR,
        };
      }

      if (siExtra) {
        Values ={
          ...Values,
          precio_mensual: 0,
          max_sucursales: Values.max_sucursales,
          max_colaboradores: Values.max_colaboradores,
          numero_de_sucursales_gratis:  Values.numero_de_sucursales,
          numero_de_colaboradores_gratis: Values.numero_de_colaboradores
        }
      }


      resolve(Values);
    } else {
      reject({
        mensaje: "Error al conectar a internet, revisa tu conexion a internet",
      });
    }
  });
};

////////////////////
/* FUNCIONES POST */
////////////////////
export const guardarInfoPlan = async (Values, Tarjeta, forma_de_pago, siExtra=false) => {
  let data = [];
  const body = { ...Values, ...Tarjeta, forma_de_pago, manager: 0, };
  const url = !siExtra ? "empresa/ComprarPlan" : "empresa/ComprarPlanExtra";
  const res = await postdData(url, body);

  return new Promise((resolve, reject) => {
    if (!res.error) {
      data = res.data;
      resolve(data);
    } else {
      reject(res);
    }
  });
};


export const CalcularPrecio = (Values, numero_de_sucursales, numero_de_colaboradores, periodo_de_plan) => {
  const {
    precio_mensual,
    precio_trimestral,
    precio_anual,
    precio_por_colaborador,
    precio_por_sucursal,
    numero_de_colaboradores_gratis,
    numero_de_sucursales_gratis,
  } = Values;

  let numero_meses_plan = 0;
  let precioPeriodo = 0;
  let sucursalesAd = numero_de_sucursales - numero_de_sucursales_gratis;
  let colaboradoresAd = numero_de_colaboradores - numero_de_colaboradores_gratis;

  switch (periodo_de_plan) {
    case TiposPlanes.MENSUAL:
      precioPeriodo = precio_mensual
      numero_meses_plan = 1;
      break;

    case TiposPlanes.TRIMESTRAL:
      precioPeriodo = precio_trimestral
      numero_meses_plan = 3;
      break;

    case TiposPlanes.ANUAL:
      precioPeriodo = precio_anual
      numero_meses_plan = 12;
      break;
  }

  let total_sucursales_ad = sucursalesAd * precio_por_sucursal * numero_meses_plan;
  let total_colaboradores_ad = colaboradoresAd * precio_por_colaborador * numero_meses_plan;

  const plan = {
    total: precioPeriodo + total_sucursales_ad + total_colaboradores_ad,
    total_colaboradores_ad,
    total_sucursales_ad,
  }

  return plan;

};



export const getInfoComprobante = async (id_licencia) => {
  let queryParamsObj = {
    id_licencia: id_licencia,
  };
  const url = `empresa/ComprobantePago?${stringify(queryParamsObj)}`;
  const res = await getData(url);
  return new Promise((resolve, reject) => {
    if (!res.error) {
      const data = JSON.parse(res.data.respuesta.data)
      resolve(data[0]);
    } else {
      reject({});
    }
  });
};

export const guardarInfoComprobante = async (Values, Errores, setErrores) => {
  let data = [];

  if (Values.img == "") {
    Errores.forma_de_pago = true;
    return new Promise((resolve, reject) => {
      resolve({ mensaje: 'Falta seleccionar el archivo (901)' });
    })
  }

  if (Values.forma_de_pago == -1) {
    return new Promise((resolve, reject) => {
      resolve({ mensaje: 'Falta seleccionar la forma de pago (901)' });
    })
  }

  if (Values.fecha == null) {
    return new Promise((resolve, reject) => {
      resolve({ mensaje: 'Confirma la fecha de pago (901)' });
    })
  }


  let body = { ...Values }
  const url = `empresa/AgregarComprobante`;
  const res = await postdData(url, body);
  return new Promise((resolve, reject) => {
    if (!res.error) {
      data = res.data;
      resolve(data);
    } else {
      reject(res);
    }
  });

};