
const Deposito = () => {
    return (
        <>
            <div className="">
                <h6 className=" text-star mb-1 mt-3 fw-bolder font-PlayfairDisplay">
                    Cuenta bancaria para deposito o transferencia
                </h6>
                <div
                    className="px-3 py-2  mt-3 "
                    style={{
                        backgroundColor: "#F7F7F7",
                        cursor: "pointer",
                    }}
                >
                    <div className=" w-100 mt-2 mt-md-0">
                        <small>
                            <p className="font-OxygenBold my-1">Banco Banorte</p>
                        </small>

                        <small>
                            <p className="font-Oxygen my-1">
                                A nombre de: OPERADORA FLEXOR INFRAESTRUCTURA SA DE CV
                                <br />
                                Cuenta: 70115713882
                                <br />
                                CLABE Interbancaria: 072320004978120496
                                <br />
                                Moneda: Pesos Mexicanos
                            </p>
                        </small>
                    </div>
                </div>

                <h6 className=" text-star mb-1 mt-3 fw-bolder font-PlayfairDisplay">
                    Pasos a Seguir
                </h6>
                <p className="font-Oxygen my-1">
                    1. Realiza tu pago.
                </p>
                <p className="font-Oxygen my-1">
                    2. Carga tu comprobante de pago en la sección licencias
                </p>
                <p className="font-Oxygen my-1">
                    3. Activa tu licencia
                </p>
                <p className="font-Oxygen my-1">
                    Mientras mas rápido hagas tu pago, mas rapido confirmaremos tu solicitud.
                </p>
            </div>
        </>
    );
};

export default Deposito;
