import React, { useState, useEffect } from "react";
import { Alert, Snackbar } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableFooter from "@mui/material/TableFooter";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import IconButton from "@mui/material/IconButton";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import LastPageIcon from "@mui/icons-material/LastPage";
import UploadIcon from '@mui/icons-material/Upload';
import PostAddIcon from '@mui/icons-material/PostAdd';

import {
  TableHead,
  Tooltip,
  Grid,
  MenuItem,
  Menu
} from "@mui/material";
import Loading from "../../../Includes/Loading";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
//import { Tooltip } from "@mui/material";
import { styled } from "@mui/material/styles";
import Fade from "@mui/material/Fade";
import MoreVert from "@mui/icons-material/MoreVert";
import { formatMoneda } from "../../../Lib/Funciones Generales/Generales";
import ActLicencia from "../../../assets/ActLicencia.svg";
import Facturar from "./../../../assets/Facturar.svg";
import { FormasPago } from "../../../Lib/Funciones Generales/Constantes";
import { formatDateBackDeFront } from "../../../Lib/Funciones Generales/ObtenerFecha";
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';


Date.prototype.addDays = function (days) {
  var date = new Date(this.valueOf());
  date.setDate(date.getDate() + days);
  return date;
}

export const ESTATUS = {
  EXPIRADO: 0,
  ACTIVO: 1,
  POR_EXPIRAR: 2,
  POR_ACTIVAR: 3,
  SIN_AUTORIZAR: 4
}

const MySwal = withReactContent(Swal);

function TablePaginationActions({
  count
  , page
  , rowsPerPage
  , onPageChange

}) {
  const theme = useTheme();

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };


  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowRight />
        ) : (
          <KeyboardArrowLeft />
        )}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowLeft />
        ) : (
          <KeyboardArrowRight />
        )}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </Box>
  );
}

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

export default function TableLicencia({
  Lista
  , IsLoading
  , setLista
  , setLicenciaSeleccionada
  , setShowDialogLicencia
  , setShowDialogLicenciaExtra
  , setShowDialogVerComprobante
  , setShowDialogoCargarComprobante
  , fechaActual
  , btnNuevaLicencia
}) {

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  const [open, setOpen] = useState(false);
  const [mensaje, setMensaje] = useState("");
  const [severity, setSeverity] = useState("error");
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - Lista.length) : 0;

  const [anchorEl, setAnchorEl] = React.useState(null);
  const openMenu = Boolean(anchorEl);
  const [idRow, setIdRow] = useState();
  const [stateRow, setStateRow] = useState();

  const handleMenuClose = () => {
    setAnchorEl(null);
  };
  const handleClick = (event) => {
    //console.log("productos", event);
    setAnchorEl(event.currentTarget);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    console.log(page);
  };

  const handleClose = () => {
    setOpen(false);
  };


  const getEstatus = (row, index) => {

    if (!row.PAGADO || row.PAGADO == 0) {
      return ESTATUS.SIN_AUTORIZAR
    }

    const FI = new Date(formatDateBackDeFront(row.FECHA_INICIO) + 'T00:00:00.000');
    const FF = new Date(formatDateBackDeFront(row.FECHA_CORTE) + 'T23:59:59.997');
    if (fechaActual.addDays(7).getTime() >= FF.getTime()) {
      return ESTATUS.POR_EXPIRAR
    }
    // if (fechaActual.addDays(7).getTime() > FI.getTime()) {
    //   return ESTATUS.POR_ACTIVAR
    // }
    if (fechaActual.getTime() > FF.getTime()) {
      return ESTATUS.EXPIRADO
    }
    if (FI.getTime() <= fechaActual.getTime() && fechaActual.getTime() <= FF.getTime()) {
      return ESTATUS.ACTIVO
    }
  }

  const showEstatus = (row) => {
    const status = getEstatus(row)

    if (status == ESTATUS.SIN_AUTORIZAR) {
      return (
        <div className="p-1 px-2  text-white text-center bg-warning" style={{ borderRadius: 25 }}>
          No autorizado
        </div>
      )
    }

    if (status == ESTATUS.POR_EXPIRAR) {
      return (
        <div className="p-1 px-2  text-white text-center" style={{ borderRadius: 25, backgroundColor: '#fd8c27' }}>
          Por expirar
        </div>
      )
    }
    if (status == ESTATUS.POR_ACTIVAR) {
      return (
        <div className="p-1 px-2  text-white text-center  bg-warning" style={{ borderRadius: 25 }}>
          Por activar
        </div>
      )
    }

    if (status == ESTATUS.EXPIRADO) {
      return (
        <div className="p-1 px-2  text-white text-center" style={{ borderRadius: 25, backgroundColor: '#d32f2f' }}>
          Expirada
        </div>
      )
    }

    if (status == ESTATUS.ACTIVO) {
      return (
        <div className="p-1 px-2  text-white text-center" style={{ borderRadius: 25, backgroundColor: '#2e7d32' }}>
          Activo
        </div>
      )
    }

    return (
      <div className="p-1 px-2  text-white text-center  bg-warning" style={{ borderRadius: 25 }}>
        Por activar
      </div>
    )
  }


  const StyledSnackbar = styled((props) => <Snackbar {...props} />)(
    ({ theme }) => ({
      "& .MuiSnackbar-root": {
        top: theme.spacing(15),
      },
    })
  );

  return (
    <>
      <StyledSnackbar
        direction="right"
        open={open}
        autoHideDuration={6000}
        onClose={handleClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      >
        <Alert onClose={handleClose} severity={severity} sx={{ width: "100%" }}>
          {mensaje}
        </Alert>
      </StyledSnackbar>
      <TableContainer component={Paper}>
        <Menu
          open={openMenu}
          anchorEl={anchorEl}
          onClose={handleMenuClose}
          anchorOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
          TransitionComponent={Fade}
        >
          <MenuItem
            onClick={() => {

            }}
          >
            Facturar
            <IconButton>
              <img src={Facturar} />
            </IconButton>
          </MenuItem>
        </Menu>
        <Table aria-label="custom pagination table">
          <TableHead>
            <TableRow>
              <TableCell
                className="d-none d-sm-table-cell font-Avenir font-AvenirBold"
                align="left"
              >
                PLAN
              </TableCell>

              <TableCell
                className="d-none d-sm-table-cell font-Avenir font-AvenirBold"
                align="left"
              >
                PERIODO
              </TableCell>
              <TableCell
                className="d-none d-sm-table-cell font-Avenir font-AvenirBold"
                align="left"
              >
                SUCURSALES
              </TableCell>
              <TableCell
                className="d-none d-sm-table-cell font-Avenir font-AvenirBold"
                align="left"
              >
                COLABORADORES
              </TableCell>

              <TableCell
                className="d-none d-sm-table-cell font-Avenir font-AvenirBold"
                align="center"
              >
                ESTATUS
              </TableCell>

              <TableCell
                className="d-none d-sm-table-cell font-Avenir font-AvenirBold"
                align="center"
              >
                FORMA DE PAGO
              </TableCell>
              <TableCell
                className="d-none d-sm-table-cell font-Avenir font-AvenirBold"
                align="center"
              >
                PAGADO
              </TableCell>
              <TableCell
                className="d-none d-sm-table-cell font-Avenir font-AvenirBold"
                align="right"
              >
                TOTAL
              </TableCell>
              <TableCell
                className="d-none d-sm-table-cell font-Avenir font-AvenirBold"
                align="right"
              >
                ACCIONES
              </TableCell>
            </TableRow>
          </TableHead>

          {IsLoading ? (
            <TableBody>
              <TableRow>
                <TableCell component="th" scope="row" colSpan={9}>
                  <Loading />
                </TableCell>
              </TableRow>
            </TableBody>
          ) : (
            <TableBody>
              {(rowsPerPage > 0
                ? Lista.slice(
                  page * rowsPerPage,
                  page * rowsPerPage + rowsPerPage
                )
                : Lista
              ).map((row) => (
                <TableRow key={row.ID}>

                  <TableCell
                    component="th"
                    scope="row"
                    className="d-none d-sm-table-cell"
                  >

                    {row.NOMBRE_PLAN}

                  </TableCell>


                  <TableCell
                    component="th"
                    scope="row"
                    className="d-none d-sm-table-cell"
                  >
                    {row.FECHA_INICIO + ' - ' + row.FECHA_CORTE}
                  </TableCell>

                  <TableCell
                    component="th"
                    scope="row"
                    className="d-none d-sm-table-cell"
                  >
                    {row.NUMERO_DE_SUCURSALES}
                  </TableCell>

                  <TableCell
                    component="th"
                    scope="row"
                    className="d-none d-sm-table-cell"
                  >
                    {row.NUMERO_DE_COLABORADORES}
                  </TableCell>



                  <TableCell
                    component="th"
                    scope="row"
                    className="d-none d-sm-table-cell"
                  >
                    {
                      showEstatus(row)
                    }
                  </TableCell>

                  <TableCell
                    align="center"
                    component="th"
                    scope="row"
                    className="d-none d-sm-table-cell"
                  >
                    {row.FORMA_DE_PAGO}
                  </TableCell>

                  <TableCell
                    align="center"
                    component="th"
                    scope="row"
                    className="d-none d-sm-table-cell"
                  >
                    {row.PAGADO == 1 ? 'Si' : 'No'}
                  </TableCell>

                  <TableCell
                    align="right"
                    component="th"
                    scope="row"
                    className="d-none d-sm-table-cell"
                  >
                    {formatMoneda(row.TOTAL)}
                  </TableCell>

                  <TableCell align="right">
                    <Grid key={row.ID} className="d-none d-sm-block">



                      {/* ACCIONES PARA LAS LICENCIAS COMPRADAS */}


                      {
                        row.FORMA_DE_PAGO == FormasPago.TRANSFERENCIA && !row.PAGADO && row.COMPROBANTE == "" && (
                          <Tooltip title="Cargar comprobante" placement="top">
                            <IconButton onClick={() => {
                              setShowDialogoCargarComprobante(true)
                              setLicenciaSeleccionada(row.ID)
                            }}>
                              <UploadIcon />
                            </IconButton>
                          </Tooltip>
                        )
                      }

                      {
                        row.FORMA_DE_PAGO == FormasPago.TRANSFERENCIA && row.COMPROBANTE != "" && (
                          <Tooltip title="Ver comprobante" placement="top">
                            <IconButton onClick={() => {
                              setShowDialogVerComprobante(true)
                              setLicenciaSeleccionada(row.ID)
                            }}>
                              <RemoveRedEyeIcon />
                            </IconButton>
                          </Tooltip>
                        )
                      }



                      <Tooltip title="Ver información" placement="top">
                        <IconButton onClick={() => {
                          setShowDialogLicencia(true)
                          setLicenciaSeleccionada(row.ID)
                        }}>
                          <img src={ActLicencia} />
                        </IconButton>
                      </Tooltip>

                      {
                        (ESTATUS.ACTIVO == getEstatus(row) || ESTATUS.POR_EXPIRAR == getEstatus(row)) && (
                          <Tooltip title="Comprar sucursales y colaboradores" placement="top">
                            <IconButton onClick={() => {
                              setShowDialogLicenciaExtra(true)
                              setLicenciaSeleccionada(row.ID)
                            }}>
                              <PostAddIcon />
                            </IconButton>
                          </Tooltip>
                        )
                      }





                      <Tooltip title="Facturar" placement="top">
                        <IconButton>
                          <img src={Facturar} />
                        </IconButton>
                      </Tooltip>



                    </Grid>
                    <Grid>
                      <DrawerHeader
                        style={{ backgroundColor: "#FFFFFF" }}
                        className="d-xsm-none d-sm-block d-sm-none"
                        onClick={() => {
                          setIdRow(row.ID);
                          setStateRow(row.ACTIVO);
                        }}
                      >
                        <IconButton onClick={handleClick}>
                          <MoreVert />
                        </IconButton>
                      </DrawerHeader>
                    </Grid>
                  </TableCell>
                </TableRow>
              ))}
              {emptyRows > 0 && (
                <TableRow style={{ height: 53 * emptyRows }}>
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          )}
          <TableFooter>
            <TableRow>
              <TablePagination
                rowsPerPageOptions={[
                  15,
                  30,
                  100,
                  { label: "Todos", value: -1 },
                ]}
                count={Lista.length}
                rowsPerPage={rowsPerPage}
                page={page}
                SelectProps={{
                  inputProps: {
                    "aria-label": "Filas por pagína",
                  },
                  native: true,
                }}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                ActionsComponent={TablePaginationActions}
                labelRowsPerPage={"Registros por página"}
                labelDisplayedRows={({ from, to, count, page }) => {
                  return `${from} - ${to} de ${count}`;
                }}
              />
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
    </>
  );
}
