import React from "react";
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
import Error404 from "../Includes/Error404";
import AsistenciaConfirm from "./Components/AsistenciaConfirm";
import AsistenciaUnConfirm from "./Components/AsistenciaUnConfirm";

export default function Asistencia() {
  return (
    <Routes>
      <Route path="s/:uuid" element={<AsistenciaConfirm />} />
      <Route path="n/:uuid" element={<AsistenciaUnConfirm />} />
      <Route path="/*" element={<Error404 />} />
    </Routes>
  );
}
