import React from "react";
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
import Error404 from "../Includes/Error404";
import View from "./View";

export default function AppMovil() {
  return (
    <Routes>
      <Route path="/:id" element={<View />} />
      <Route path="/*" element={<Error404 />} />
    </Routes>
  );
}
