import React, { useState, useEffect } from "react";
import {
  Alert,
  Avatar,
  Button,
  Card,
  CardContent,
  Snackbar,
  Stack,
  TextField,
} from "@mui/material";

import { Col, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { guardar, getInfoPerfil } from "../Funciones/Perfiles";
import Loading from "../../../Includes/Loading";
import LoadingButton from "@mui/lab/LoadingButton";
import { styled } from "@mui/material/styles";
import { useParams } from "react-router-dom";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";

export const Colaborador = ({
  Values,
  Errores,
  handlInputChange,
  img_url,
  handleOpenModal,
}) => {
  return (
    <Row className="p-3">
      <Col
        sm={12}
        md={12}
        lg={4}
        className="d-flex flex-wrap align-items-center justify-content-center"
      >
        <Avatar
          className="shadow"
          sx={{ width: 120, height: 120 }}
          src={img_url}
        />
        <strong
          className="w-100 text-center px-0 pt-3"
          style={{ color: "#3ABE88", cursor: "pointer" }}
          onClick={handleOpenModal}
        >
          Editar
        </strong>
      </Col>
      <Col sm={12} md={4} className="p-3">
        <TextField
          fullWidth
          name="nombre"
          label="Nombre"
          variant="outlined"
          value={Values.nombre}
          error={Errores.nombre}
          onChange={handlInputChange}
        />
      </Col>
      <Col sm={12} md={4} className="p-3">
        <TextField
          fullWidth
          name="apellido_paterno"
          label="Apellido paterno"
          variant="outlined"
          value={Values.apellido_paterno}
          error={Errores.apellido_paterno}
          onChange={handlInputChange}
        />
      </Col>
      <Col sm={12} md={4} className="p-3">
        <TextField
          fullWidth
          name="apellido_materno"
          label="Apellido materno"
          variant="outlined"
          value={Values.apellido_materno}
          onChange={handlInputChange}
          error={Errores.apellido_materno}
        />
      </Col>
      <Col sm={12} md={4} className="p-3">
        <TextField
          fullWidth
          name="correo"
          label="Correo electrónico"
          variant="outlined"
          helperText={Errores.correo ? "Correo incorrecto" : ""}
          value={Values.correo}
          onChange={handlInputChange}
        />
      </Col>

      <Col sm={12} md={4} className="p-3">
        <TextField
          fullWidth
          name="telefono"
          label="Teléfono"
          variant="outlined"
          inputProps={{ maxLength: 10 }}
          helperText={Errores.telefono ? "Teléfono inválido" : ""}
          value={Values.telefono == 0 ? "" : Values.telefono}
          onChange={handlInputChange}
        />
      </Col>
    </Row>
  );
};
