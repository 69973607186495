const ConfigLateral = ({ color }) => {
    return (
        <svg width="23px" height="24px" viewBox="0 0 23 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
            <title>1.9 ConfigLateralON</title>
            <g id="Michelle-Pourroy-MAnager" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g id="1.1-Dashboard" transform="translate(-36.000000, -560.000000)" stroke={color} strokeWidth="1.5">
                    <g id="1-copy-4" transform="translate(0.000000, 542.000000)">
                        <g id="1.9-ConfigLateralON" transform="translate(37.000000, 19.000000)">
                            <path d="M11.1980221,0 C12.058883,1.17413006e-15 12.8231606,0.55086019 13.0953887,1.36754447 L13.704928,3.19520886 C14.2326573,3.43289896 14.7319414,3.72254057 15.1962647,4.0576179 L17.086901,3.67204516 C17.9302844,3.49945947 18.7894822,3.88591327 19.2199126,4.6314406 L20.2228386,6.3685594 C20.653269,7.11408673 20.5583489,8.05140072 19.9871936,8.6954993 L18.7078721,10.1390603 C18.7361642,10.4222012 18.7506517,10.7094087 18.7506517,11 C18.7506517,11.2905913 18.7361642,11.5777988 18.7078721,11.8609397 L19.9871936,13.3045007 C20.5583489,13.9485993 20.653269,14.8859133 20.2228386,15.6314406 L19.2199126,17.3685594 C18.7894822,18.1140867 17.9302844,18.5005405 17.086901,18.3279548 L15.1962647,17.9423821 C14.7319414,18.2774594 14.2326573,18.567101 13.704928,18.8047911 L13.0953887,20.6324555 C12.8231606,21.4491398 12.058883,22 11.1980221,22 L9.19217014,22 C8.33130932,22 7.56703163,21.4491398 7.29480354,20.6324555 L6.68482406,18.8045928 C6.15725733,18.5669399 5.65812172,18.2773663 5.19392761,17.9423821 L3.30329125,18.3279548 C2.45990787,18.5005405 1.60071011,18.1140867 1.1702797,17.3685594 L0.167353697,15.6314406 C-0.263076711,14.8859133 -0.168156636,13.9485993 0.402998649,13.3045007 L1.68232013,11.8609397 C1.65402806,11.5777988 1.63954059,11.2905913 1.63954059,11 C1.63954059,10.7094087 1.65402806,10.4222012 1.68232013,10.1390603 L0.402998649,8.6954993 C-0.168156636,8.05140072 -0.263076711,7.11408673 0.167353697,6.3685594 L1.1702797,4.6314406 C1.60071011,3.88591327 2.45990787,3.49945947 3.30329125,3.67204516 L5.19392761,4.0576179 C5.65812172,3.72263375 6.15725733,3.43306005 6.68482406,3.19540717 L7.29480354,1.36754447 C7.56703163,0.55086019 8.33130932,1.04631599e-15 9.19217014,0 L11.1980221,0 Z" id="Combined-Shape"></path>
                            <circle id="Oval" cx="10.1950961" cy="11" r="1.69444444"></circle>
                        </g>
                    </g>
                </g>
            </g>
        </svg>
    )
}

export default ConfigLateral