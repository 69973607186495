function RFCValidator(rfcStr) {
	var strCorrecta
	if (rfcStr.length == 12)
	{
		strCorrecta = ' ' + rfcStr;
	}
	else
	{
		strCorrecta = rfcStr;
	}
	var valid = '^(([A-Z]|[a-z]|\s){1})(([A-Z]|[a-z]){3})([0-9]{6})((([A-Z]|[a-z]|[0-9]){3}))';
	var validRfc=new RegExp(valid);
	var matchArray=strCorrecta.match(validRfc);
	if (matchArray==null) {
		//alert('Cadena:' + strCorrecta);
		return false;
	}
	else
	{
		// alert('Cadena:' + strCorrecta);
		return true;
	}
}

export default RFCValidator;
