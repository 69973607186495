import { stringify } from "query-string";
import validator from "validator";

import { getData, postdData } from "../../../Context/backend";

export const guardarConfig = async (Values, Errores, setErrores) => {
  let error = false;

  if (!Values.encabezado || Values.encabezado == "") {
    Errores.encabezado = true;
    setErrores(Errores);
    error = true;
  }

  if (!Values.pie || Values.pie == "") {
    Errores.pie = true;
    setErrores(Errores);
    error = true;
  }

  if (!Values.linea_1 || Values.linea_1 == "") {
    Errores.linea_1 = true;
    setErrores(Errores);
    error = true;
  }

  if (!Values.incrementos_horarios || Values.incrementos_horarios == "") {
    Errores.incrementos_horarios = true;
    setErrores(Errores);
    error = true;
  } else {
    if (Values.incrementos_horarios < 5 || Values.incrementos_horarios > 1400) {
      Errores.incrementos_horarios = true;
      setErrores(Errores);
      error = true;
    }
  }

  if (!error) {
    let data = [];
    const url =
      Values.id == undefined || Values.id == "" || Values.id == 0
        ? "configGenerales/Editar"
        : "configGenerales/Editar";

    const body = Values;
    const res = await postdData(url, body);

    return new Promise((resolve, reject) => {
      if (!res.error) {
        data = res.data;
        resolve(data);
      } else {
        reject(res);
      }
    });
  } else {
    return new Promise((resolve, reject) => {
      resolve({ mensaje: "Completa todos los campos requeridos" });
    });
  }
};

export const getInfoConfigGeneral = async () => {
  let data = [];
  const url = `configGenerales/Consultar`;
  const res = await getData(url);
  return new Promise((resolve, reject) => {
    if (!res.error) {
      data = JSON.parse(res.data.respuesta.data);
      //  console.log("datos", data)
      if (data.length == 0) {
        resolve({
          err: [],
          mensaje: "No se encontró información.",
        });
      } else {
        data = JSON.parse(res.data.respuesta.data)[0];
        let Value = {
          id: String(data.ID),
          encabezado: String(data.ENCABEZADO),
          pie: String(data.PIE),
          linea_1: String(data.LINEA_1),
          incrementos_horarios: String(data.INCREMENTOS_HORARIOS),
          si_cobro: data.SI_COBRO,
          ancho: String(data.ANCHO),
          fuente: String(data.FUENTE),
        };

        resolve({
          values: Value,
          other: JSON.parse(res.data.respuesta.maxMin)[0],
        });
      }
    } else {
      reject({
        mensaje: "Error al conectar a internet, revisa tu conexion a internet",
      });
    }
  });
};
