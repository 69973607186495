import { stringify } from "query-string";
import { getData, postdData, postUrl } from "../../../Context/backend";
import validator from "validator";
import { IMG } from "../../../Lib/Funciones Generales/Constantes";


export const getPermisosFaltas = async (id, si_descontado_nomina, fechaInicio , fechaFin) => {
    let data = [];
    let queryParamsObj = {
      id_colaborador: id,
      si_descontado_nomina: si_descontado_nomina,
      fecha_inicio: fechaInicio,
      fecha_fin: fechaFin,   
    }; 
    const url = `colaboradores/getPermisosFaltas?${stringify(queryParamsObj)}`;
    const res = await getData(url);
    return new Promise((resolve, reject) => {
      if (!res.error) {
        data = JSON.parse(res.data.respuesta.data); 
        resolve(data);
      } else {
        reject({
          mensaje: "Error al conectar a internet, revisa tu conexion a internet",
        });
      }
    });
  };

  export const guardarPermisoFalta = async (Values, Errores, setErrores) => {
    let error = false;
    console.log("PERMISO_FALTA", Values)
    if (!Values.id_colaborador || Values.id_colaborador == 0 || Values.id_colaborador == "" || Values.id_colaborador == "0") {
      Errores.id_colaborador = true;
      setErrores(Errores);
      error = true;
    }

    if (!Values.id_tipo_permiso_falta || Values.id_tipo_permiso_falta == 0 || Values.id_tipo_permiso_falta == "" || Values.id_tipo_permiso_falta == "0") {
      Errores.id_tipo_permiso_falta = true;
      setErrores(Errores);
      error = true;
    }
  
  
    if (!error) {
      let data = [];
  
      let queryParamsObj = {};
      const url =
        Values.id == undefined || Values.id == "" || Values.id == 0
          ? "colaboradores/InsertarPermisoFalta"
          : "colaboradores/EditarPermisoFalta";
  
      let { fecha_falta, id_tipo_permiso_falta } = Values
      fecha_falta = fecha_falta.toISOString()
      id_tipo_permiso_falta = parseInt(id_tipo_permiso_falta)
      const body = {
        ...Values,
        fecha_falta,
        id_tipo_permiso_falta,
      };

     
      const res = await postdData(url, body);
  
      return new Promise((resolve, reject) => {
        if (!res.error) {
          data = res.data;
          resolve(data);
        } else {
          reject(res);
        }
      });
    } else {
      return new Promise((resolve, reject) => {
        resolve({ mensaje: "Completa todos los campos requeridos" });
      });
    }
  };

  export const Eliminar = async (id) => {
    if (id != undefined) {
      let data = [];
      let queryParamsObj = {
        id: id,
      };
      const url = `colaboradores/EliminarPermisoFalta?${stringify(queryParamsObj)}`;
      const res = await postUrl(url);
      return new Promise((resolve, reject) => {
        if (!res.error) {
          data = res.data;
          resolve(data);
        } else {
          reject(res);
        }
      });
    } else {
      return new Promise((resolve, reject) => {
        resolve({ mensaje: "No se pudo obtener el identificador" });
      });
    }
  };