import React from "react";
import { useParams } from "react-router-dom";
import IMG from "../assets/LogoTextoNegro.svg";
import { useEffect } from "react";

export default function View() {
  const { id } = useParams();
  useEffect(() => {
    console.log(`File: View.jsx ~ 9 -->`, id);
  }, []);
  return (
    <>
      <div className="container-fluid pt-5">
        <div className="d-flex justify-content-center align-items-center">
          <img src={IMG} alt="" style={{ width: "400px", height: "400px" }} />
        </div>
      </div>
    </>
  );
}
