import React, { useState, useEffect } from "react";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import Card from "@mui/material/Card";
import ExportarDanger from "./../../../assets/ExportarDanger.svg";
import ExportarSucess from "./../../../assets/ExportarSucess.svg";
import { Col, Row } from "react-bootstrap";
import TableProductos from "./Tabla";
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
import SelectOrdenamiento from "../../../Includes/SelectOrdenamiento";
import Buscador from "../../../Includes/Buscador";
import { getLista } from "../Funciones/Productos";
import { usePermisos } from "../../../Context/PermissionsContext";
import { getLocalStorage, getLocalStorageJWT } from "../../../Context/Storage";
import BACKEND_URL from "../../../Context/backend";

const Productos = () => {
  console.log("Renderizando...");

  const [ValueSelect, setValueSelect] = useState("1");
  const [ValueBusqueda, setValueBusqueda] = useState("");
  const [IsLoading, setIsLoading] = useState(true);
  const [Lista, setLista] = useState([]);
  const { userPermissions } = usePermisos();
  useEffect(() => {
    getListaProductos();
  }, [ValueBusqueda, ValueSelect]);

  const getListaProductos = () => {
    setIsLoading(true);
    getLista(0, 0, ValueSelect, ValueBusqueda)
      .then((resp) => {
        let list = JSON.parse(resp.data);
        setLista(list);
        setIsLoading(false);
      })
      .catch((resp) => {
        setLista([]);
        setIsLoading(false);
      });
  };

  useEffect(() => {
    document.title = `Productos y servicios`;
  }, []);

  return (
    <>
      <div className="d-flex justify-content-between flex-wrap my-4">
        <div className="p-2">
          <h4 className="text-left font-AvenirBold">Productos y servicios</h4>
        </div>
        <div className="p-2">
          <Stack spacing={2} direction={{ xs: "column", sm: "row" }}>
            {getLocalStorage("tipo") == "EMPRESA" ||
              (userPermissions.includes("Exportar Catalogos") === true && (
                <>
                  <Button
                    className="btn btn-exp-Excel font-AvenirMedium"
                    variant="text"
                    onClick={() => {
                      fetch(`${BACKEND_URL}reportesExcel/catalogo/productos`, {
                        method: "GET",
                        headers: {
                          Authorization: `Bearer ${getLocalStorageJWT()}`,
                          "Content-Type": "application/json",
                        },
                      })
                        .then((response) => {
                          console.log(response);
                          return response.blob();
                        })
                        .then((blob) => {
                          const downloadUrl = URL.createObjectURL(blob);
                          const newWindow = window.open(downloadUrl, "_blank");
                          newWindow.opener = null;
                          newWindow.focus();
                        })
                        .catch((error) => {
                          console.error(
                            "Error al descargar el archivo Excel:",
                            error
                          );
                        });
                    }}
                  >
                    <img className="me-2" src={ExportarSucess} />
                    Exportar Excel
                  </Button>
                  <Link
                    target={false ? "_self" : "_blank"}
                    to={`../pdf/catalogo/productos`}
                    style={{ textDecoration: "none" }}
                  >
                    <Button
                      className="btn  btn-exp-PDF  font-AvenirMedium"
                      variant="text"
                    >
                      <img className="me-2" src={ExportarDanger} />
                      Exportar PDF
                    </Button>
                  </Link>
                </>
              ))}
            <Link to="./add" style={{ textDecoration: "none" }}>
              <Button className="btn btn-Kalendar px-4 " variant="contained">
                Crear nuevo
              </Button>
            </Link>
          </Stack>
        </div>
      </div>

      <Card variant="outlined" className="p-0 shadow">
        <Row className="p-3">
          <Col xs={12} md={8}>
            <Buscador
              placeholder="Buscar productos por código o nombre"
              ValueBusqueda={ValueBusqueda}
              setValueBusqueda={setValueBusqueda}
            />
          </Col>
          <Col xs={12} md={4}>
            <SelectOrdenamiento
              ValueSelect={ValueSelect}
              setValueSelect={setValueSelect}
            />
          </Col>
        </Row>
        <Row>
          <Col sm={12}>
            <TableProductos
              Lista={Lista ?? []}
              IsLoading={IsLoading}
              setLista={setLista}
            ></TableProductos>
          </Col>
        </Row>
      </Card>
    </>
  );
};

export default Productos;
