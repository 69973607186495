import React from "react";
import HorarioSemanal from "../../../HorarioSemanal/Components/HorarioSemanal";
import { useParams, useNavigate } from "react-router-dom";
import { Alert, Snackbar, Button, styled, Box } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useState } from "react";

export default function HorariosColab() {
  const { id } = useParams();
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [mensaje, setMensaje] = useState("");

  const handleClose = () => {
    setOpen(false);
  };

  const StyledSnackbar = styled((props) => <Snackbar {...props} />)(
    ({ theme }) => ({
      "& .MuiSnackbar-root": {
        top: theme.spacing(15),
      },
    })
  );
  return (
    <>
      <StyledSnackbar
        direction="right"
        open={open}
        autoHideDuration={6000}
        onClose={handleClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      >
        <Alert onClose={handleClose} severity="error" sx={{ width: "100%" }}>
          {mensaje}
        </Alert>
      </StyledSnackbar>
      <Button
        onClick={() => navigate(-1)}
        className="btn  font-AvenirBold my-4 text-star ps-0 pe-4  "
        variant="text"
      >
        <ArrowBackIcon className="me-3" />
        Colaboradores
      </Button>
      <Box
        className=" mb-2"
        //style={{ maxHeight: "400px", background: "red" }}
      >
        <HorarioSemanal
          id_colaborador={id}
          title="Horario Semanal de Colaborador"
          resourceAreaHeaderContent="COLABORADOR"
          titlePage="Colaboradores"
        />
      </Box>
    </>
  );
}
