import React, { useState, useEffect, useRef } from "react";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import Card from "@mui/material/Card";
import ExportarDanger from "./../../../assets/ExportarDanger.svg";
import ExportarSucess from "./../../../assets/ExportarSucess.svg";
import { Col, Row, Table } from "react-bootstrap";
import TableGrupo from "./Tabla";
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
import SelectOrdenamiento from "../../../Includes/SelectOrdenamiento";
import Buscador from "../../../Includes/Buscador";
import { getLista } from "../Funciones/gruposacceso";

const RepGrupos = () => {
  const [ValueSelect, setValueSelect] = useState("1");
  const [ValueBusqueda, setValueBusqueda] = useState("");
  const [IsLoading, setIsLoading] = useState(true);
  const [Lista, setLista] = useState([]);

  useEffect(() => {
    getListaColaboradores();
  }, [ValueBusqueda, ValueSelect]);

  const getListaColaboradores = () => {
    setIsLoading(true);
    getLista(0, 0, ValueSelect, ValueBusqueda)
      .then((resp) => {
        let list = JSON.parse(resp.data);
        const ls = list.filter((item) => {
          item.MENU = false;
          return item;
        });
        setLista(ls);
        setIsLoading(false);
      })
      .catch((resp) => {
        setLista([]);
        setIsLoading(false);
      });
  };

  useEffect(() => {
    document.title = ` Grupos de acceso`;
  }, []);

  return (
    <>
      <div className="d-flex justify-content-between flex-wrap my-4">
        <div className="p-2">
          <h4 className="text-left font-AvenirBold"> Grupos de acceso</h4>
        </div>
        <div className="p-2">
          <Stack spacing={2} direction={{ xs: "column", sm: "row" }}>
           
            <Link to="./add" style={{ textDecoration: "none" }}>
              <Button className="btn btn-Kalendar px-4 " variant="contained">
                Crear nuevo
              </Button>
            </Link>
          </Stack>
        </div>
      </div>

      <Card variant="outlined" className="p-0 shadow">
        <Row className="p-3">
          <Col xs={12} md={8}>
            <Buscador
              placeholder="Buscar grupo por nombre"
              ValueBusqueda={ValueBusqueda}
              setValueBusqueda={setValueBusqueda}
            />
          </Col>
          <Col xs={12} md={4}>
            <SelectOrdenamiento
              ValueSelect={ValueSelect}
              setValueSelect={setValueSelect}
            />
          </Col>
        </Row>

        <Row>
          <Col sm={12}>
            <TableGrupo
              Lista={Lista ?? []}
              IsLoading={IsLoading}
              setLista={setLista}
              ValueBusqueda={ValueBusqueda}
            ></TableGrupo>
          </Col>
        </Row>
      </Card>
    </>
  );
};

export default RepGrupos;
