import {  Route, Routes } from "react-router-dom";
import Error404 from "../../Includes/Error404";
import Cliente from "./Componentes/Cliente";
import RepCliente from "./Componentes/RepClientes";
 


 const routerClientes =()=> {
  console.log("renderizando router"); 
  return (
    <> 
     <Routes>     
        <Route index element={<RepCliente/>} /> 
        <Route path="/add" element={<Cliente/>} /> 
        <Route path="/edit/:id" element={<Cliente />} /> 
        <Route path="*" element={<Error404 />} />
    </Routes>
    </> 
  );
}

export default routerClientes;