import * as React from "react";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import LogoutIcon from "@mui/icons-material/Logout";
import ManageAccountsOutlinedIcon from "@mui/icons-material/ManageAccountsOutlined";
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
import { clearStorageJWT } from "../Context/Storage";
import { getInfoPerfil } from "../Catalogos/Perfil/Funciones/Perfiles";
import AccEditarImagen from "../assets/AvatarMenu.svg";
import { useParams } from "react-router-dom";
import { useState, useEffect } from "react";
import { Avatar } from "@mui/material";
import { useContext } from "react";
import { IconContext } from "./menu";

import LicenciaBasico from '../assets/LicenciaBasico.svg'

export default function AccountMenu() {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [IsLoading, setIsLoading] = useState(true);
  const open = Boolean(anchorEl);
  const avatar = useContext(IconContext);

  const [Values, setValues] = useState({
    img: AccEditarImagen,
  });

  useEffect(() => {
    setIsLoading(true);

    getInfoPerfil(AccEditarImagen, true)
      .then((resp) => {
        setValues(resp.Values);
        setIsLoading(false);
      })
      .catch((resp) => {
        setIsLoading(false);
      });

    setIsLoading(false);
  }, []);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const img_url = Values.img == AccEditarImagen ? AccEditarImagen : Values.img;
  avatar.setUrl(img_url);
  return (
    <React.Fragment>
      <Tooltip title="Configuración de la cuenta">
        <IconButton>
          <Avatar
            sx={{ width: 25, height: 25 }}
            src={avatar.url}
            onClick={handleClick}
            aria-controls={open ? "account-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
          ></Avatar>
        </IconButton>
      </Tooltip>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: 1.5,
            "& .MuiAvatar-root": {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            "&:before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: "background.paper",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        <Link to="perfil/" style={{ textDecoration: "none", color: "black" }}>
          <MenuItem>
          <ListItemIcon>
              <ManageAccountsOutlinedIcon />
            </ListItemIcon>
            Mi perfil
          </MenuItem>
        </Link>
        <Link to="licencias/" style={{ textDecoration: "none", color: "black" }}>
          <MenuItem>
           
            <ListItemIcon>
              <img src={LicenciaBasico} width={20} />
            </ListItemIcon>
            Mis licencias
          </MenuItem>
        </Link>
        <Link
          onClick={clearStorageJWT}
          style={{ textDecoration: "none", color: "black" }}
        >
          <MenuItem>
            <ListItemIcon>
              <LogoutIcon />
            </ListItemIcon>
            Cerrar sesión
          </MenuItem>
        </Link>
      </Menu>
    </React.Fragment>
  );
}
