import { Grid, Typography } from "@mui/material";
import React from "react";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import DoneAllIcon from "@mui/icons-material/DoneAll";
import BlockIcon from "@mui/icons-material/Block";

export default function BarAsistencia({ isConfirado }) {
  return (
    <>
      {isConfirado ? (
        <Grid
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
          style={{
            width: "100%",
            height: "50px",
            background: "#D5F5E0",
          }}
        >
          <DoneAllIcon
            style={{ color: "#3ABE88" }}
            sx={{ width: 40, height: 40, padding: "5px" }}
          />
          <Typography style={{ marginLeft: "13px" }}>CONFIRMADA</Typography>
        </Grid>
      ) : (
        <Grid
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
          style={{
            width: "100%",
            height: "50px",
            background: "#F5D5D5",
          }}
        >
          <BlockIcon
            style={{ color: "#D14343" }}
            sx={{ width: 40, height: 40, padding: "5px" }}
          />
          <Typography style={{ marginLeft: "13px" }}>NO CONFIRMADA</Typography>
        </Grid>
      )}
    </>
  );
}
