import { stringify } from "query-string";
import { getData, postdData, postUrl } from "../../../Context/backend";
import validator from "validator";
import { IMG } from "../../../Lib/Funciones Generales/Constantes";

///////////////////
/* FUNCIONES GET */
///////////////////
export const getLista = async (iTake, iSkip, Order, query) => {
  //onsole.log(query)
  let data = [];
  let queryParamsObj = {
    iTake: iTake,
    iSkip: iSkip,
    Order: Order,
    query: query,
  };
  const url = `sucursales/Listar?${stringify(queryParamsObj)}`;
  const res = await getData(url);
  return new Promise((resolve, reject) => {
    if (!res.error) {
      data = res.data.respuesta;
      resolve(data);
    } else {
      reject(res);
    }
  });
};

export const getOpciones = async (query = "") => {
  let data = [];
  let queryParamsObj = {
    query: query,
  };
  const url = `sucursales/getOpcionesv2?${stringify(queryParamsObj)}`;
  const res = await getData(url);
  return new Promise((resolve, reject) => {
    if (!res.error) {
      data = res.data.respuesta;
      // console.log(data);
      resolve(data);
    } else {
      reject(res);
    }
  });
};

export const getHorario = async (id_sucursal,fecha ) => {
  let data = [];
  let queryParamsObj = {
    id_sucursal: id_sucursal,
    fecha: fecha,
  };
  const url = `sucursales/getHorario?${stringify(queryParamsObj)}`;
  const res = await getData(url);
  return new Promise((resolve, reject) => {
    if (!res.error) {
      data = res.data.respuesta;
      // console.log(data);
      resolve(data);
    } else {
      reject(res);
    }
  });
};


export const Desactivar = async (id, checked) => {
  if (id != undefined) {
    let data = [];
    let queryParamsObj = {
      id: id,
      activo: checked,
    };
    const url = `sucursales/Desactivar?${stringify(queryParamsObj)}`;
    const res = await postUrl(url);
    return new Promise((resolve, reject) => {
      if (!res.error) {
        data = res.data;
        resolve(data);
      } else {
        reject(res);
      }
    });
  } else {
    return new Promise((resolve, reject) => {
      resolve({ mensaje: "No se pudo obtener el identificador" });
    });
  }
};

export const Eliminar = async (id) => {
  if (id != undefined) {
    let data = [];
    let queryParamsObj = {
      id: id,
    };
    const url = `sucursales/Eliminar?${stringify(queryParamsObj)}`;
    const res = await postUrl(url);
    return new Promise((resolve, reject) => {
      if (!res.error) {
        data = res.data;
        resolve(data);
      } else {
        reject(res);
      }
    });
  } else {
    return new Promise((resolve, reject) => {
      resolve({ mensaje: "No se pudo obtener el identificador" });
    });
  }
};

export const Guardar = async (Values, Errores, setErrores, Puntos) => {
  let error = false;
  //console.log("entro");

  if (!Values.nombre || Values.nombre == "") {
    let Errores2 = Errores;
    Errores2.nombre = true;
    setErrores(Errores2);
    error = true;
  }

  if (!Values.direccion || Values.direccion == "") {
    let Errores2 = Errores;
    Errores2.direccion = true;
    setErrores(Errores2);
    error = true;
  }

  if (!Values.telefono || Values.telefono == "") {
    Errores.telefono = true;
    setErrores(Errores);
    error = true;
  } else {
    if (!validator.isNumeric(Values.telefono)) {
      Errores.telefono = true;
      setErrores(Errores);
      error = true;
    } else if (Values.telefono.length != 10) {
      Errores.telefono = true;
      setErrores(Errores);
      error = true;
    }
  }

  if (!Values.whatsapp || Values.whatsapp == "") {
    Errores.whatsapp = true;
    setErrores(Errores);
    error = true;
  } else {
    if (!validator.isNumeric(Values.whatsapp)) {
      Errores.whatsapp = true;
      setErrores(Errores);
      error = true;
    } else if (Values.whatsapp.length != 10) {
      Errores.whatsapp = true;
      setErrores(Errores);
      error = true;
    }
  }

  Values.horarios.forEach((element) => {
    if (element.ERROR_FIN || element.ERROR_INICIO) {
      if (element.ACTIVO) {
        error = true;
      }
    }
  });

  if (!error) {
    let imgs = [];
    let horarios = [];
    let data = [];

    let lat = 0, lng = 0;
    lat = Array.isArray(Puntos) && Puntos[0] ? Puntos[0].lat : 0
    lng = Array.isArray(Puntos) && Puntos[0] ? Puntos[0].lng : 0

    try {
      Values.galeriaImg?.forEach((element) => {
        //console.log(element.IMG);
        if (element.IMG?.includes("base64")) {
          const newElement = JSON.parse(JSON.stringify(element));
          newElement.IMG = element.IMG?.split("base64,")[1];
          const d = {};
          d.id = newElement.ID;
          d.id_sucursal = Values.id;
          d.url = newElement.IMG;
          d.principal = newElement.PRINCIPAL;
          imgs.push(d);
        } else {
          const d = {};
          d.id = element.ID;
          d.id_sucursal = Values.id;
          d.url = element.IMG;
          d.principal = element.PRINCIPAL;
          imgs.push(d);
        }
        ///console.log("ok");
      });
      Values.horarios.forEach((element, index) => {
        const newObject = {};
        newObject.id = element.ID;
        newObject.id_sucursal = Values.id;
        newObject.dia = index;
        newObject.inicio = element.INICIO;
        newObject.fin = element.FIN;
        newObject.activo = element.ACTIVO;
        horarios.push(newObject);
      });
    } catch (e) {
      console.log(e);
    }

    const body = {
      id: Values.id,
      nombre: Values.nombre,
      direccion: Values.direccion,
      telefono: Values.telefono,
      whatsapp: Values.whatsapp,
      informacion: Values.informacion,
      horarios: horarios,
      galeriA_IMGS: imgs,
      ubicacion: { lat, lng }
    };

    const url =
      Values.id == undefined || Values.id == "" || Values.id == 0
        ? "sucursales/Insertar"
        : "sucursales/Editar";
    const res = await postdData(url, body);
    return new Promise((resolve, reject) => {
      if (!res.error) {
        data = res.data;
        //console.log(data);
        resolve(data);
      } else {
        //res.mensaje = "asdas";
        reject(res);
      }
    });
  } else {
    return new Promise((resolve, reject) => {
      resolve({ mensaje: "Completa todos los campos requeridos" });
    });
  }
};

export const getInfoSucursal = async (id) => {
  let data = [];
  let queryParamsObj = {
    id: id,
  };
  const url = `sucursales/Consultar?${stringify(queryParamsObj)}`;
  const res = await getData(url);
  return new Promise((resolve, reject) => {
    if (!res.error) {
      //console.log(JSON.parse(res.data.respuesta));
      data = JSON.parse(res.data.respuesta.data);
      //console.log(data);
      if (data.length == 0) {
        resolve({
          err: [],
          mensaje: "No se encontró información de esta sucursal",
        });
      } else {
        data = JSON.parse(res.data.respuesta.data)[0];
        let imgs = JSON.parse(res.data.respuesta.imagenes);
        let horarios = JSON.parse(res.data.respuesta.horarios);
        //console.log(data);
        let Values = {
          data: {
            id: String(data.ID),
            img: String(data.IMG),
            nombre: String(data.NOMBRE),
            direccion: String(data.DIRECCION),
            telefono: String(data.TELEFONO),
            whatsapp: String(data.WHATSAPP),
            informacion: String(data.INFORMACION),            
          },
          horarios: horarios,
          galeriaImg: imgs,
          ubicacion: { lat: data.LAT, lng: data.LNG }
        };
        resolve(Values);
      }
    } else {
      reject({
        mensaje: "Error al conectar a internet, revisa tu conexion a internet",
      });
    }
  });
};

export const ActualizarImgPrincipal = async (id, urlIMG) => {
  let data = [];
  let queryParamsObj = {
    id_suc: id,
    url: urlIMG,
  };
  const url = `sucursales/ActualizarImgPrincipal?${stringify(queryParamsObj)}`;
  const res = await postUrl(url);
  return new Promise((resolve, reject) => {
    if (!res.error) {
      data = res.data;
      //console.log(data);
      resolve(data);
    } else {
      //res.mensaje = "asdas";
      reject(res);
    }
  });
};
