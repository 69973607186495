import { Autocomplete, TextField } from "@mui/material";
import { useEffect, useState } from "react";
import { getOpciones } from "../Funciones/Sucursales";

const SelectSucursal = ({
  Value,
  Error,
  handlInputChange,
  Name = "id_sucursal",
  Opciones = [],
  size = "",
  showTodos = false,
  label = "Sucursal",
  disabledClearable = false,
}) => {
  const opcionInicial = { ID: 0, DESCRIPCION: "Categoría" };
  const [filtro, setFiltro] = useState();
  const [sucusales, setSucusales] = useState({
    cargado: false,
    lista: [opcionInicial],
  });

  useEffect(() => {
    getOpciones()
      .then((resp) => {
        let list = JSON.parse(resp.data);
        if (list.length != 0) {
          if (showTodos) {
            list.unshift({ ID: 0, DESCRIPCION: "Todas las sucursales" });
          }
          setSucusales({ cargado: true, lista: [...Opciones, ...list] });
          if (list.length == 1) {
            handlInputChange({ target: { name: Name, value: list[0].ID } });
          }
        } else {
          setSucusales({
            cargado: true,
            lista: [{ ID: 0, DESCRIPCION: "Sin sucursales disponibles" }],
          });
        }
      })
      .catch((resp) => {
        setSucusales({
          cargado: true,
          lista: [{ ID: 0, DESCRIPCION: "Sin sucursales disponibles" }],
        });
      });
  }, []);

  const getOpcion = () => {
    const opcion = sucusales.lista.find((x) => x.ID == Value);
    if (opcion) {
      return opcion;
    } else {
      return null;
    }
  };
  /*useEffect(() => {
    console.log(sucusales);
  }, [sucusales]);*/

  return sucusales.cargado ? (
    <Autocomplete
      fullWidth
      size={size}
      defaultValue={getOpcion}
      isOptionEqualToValue={(option, value) => {
        return option.ID == value.ID;
      }}
      inputValue={filtro}
      onChange={(e, value) => {
        if (value) {
          handlInputChange({ target: { name: Name, value: value.ID } });
        } else {
          handlInputChange({ target: { name: Name, value: "0" } });
        }
      }}
      onInputChange={(event, newInputValue) => {
        setFiltro(newInputValue);
      }}
      options={sucusales.lista}
      getOptionLabel={(option) => {
        return option.DESCRIPCION;
      }}
      renderInput={(params) => {
        return (
          <TextField
            {...params}
            error={Error}
            helperText={Error ? "Selecciona una sucursal" : ""}
            label="Sucursal"
          />
        );
      }}
      disableClearable={disabledClearable}
    />
  ) : (
    <TextField fullWidth disabled={true} value={"Sucursales"} />
  );
};

export default SelectSucursal;
