import React, { useCallback } from "react";
import { useDropzone } from "react-dropzone";
import { Alert, Backdrop, CircularProgress, Snackbar } from "@mui/material";
import AccEditarImagen from "./../../../assets/EditarImagen.svg";
import { Col, Row } from "react-bootstrap";
import { useState } from "react";
import { useEffect } from "react";
import Modal from "../../../Includes/Modal";
import {
  CargarImagen,
  EliminarImagen,
} from "../../../Lib/Imagen/Funciones/CargarImagen";
import { IMG } from "../../../Lib/Funciones Generales/Constantes";
import { styled } from "@mui/material/styles";

import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
const MySwal = withReactContent(Swal);
const StyledSnackbar = styled((props) => <Snackbar {...props} />)(
  ({ theme }) => ({
    "& .MuiSnackbar-root": {
      top: theme.spacing(15),
    },
  })
);

const ModalCargarImagen = ({
  id = 0,
  tipo = "",
  Titulo = "Cargar imagen",
  Subtitulo = "la imagen",
  requiereConfirmar = false,
  mostrar = false,
  imgUrl = AccEditarImagen,
  fnCerrar = () => {},
  fnDevolverImagen = (src) => {},
  imgDefault = false,
}) => {
  const [error, setError] = useState({
    mostrar: false,
    mensaje: "",
    severity: "error",
  });

  const [{ img, loading }, setImg] = useState({
    img: imgUrl,
    type: "",
    loading: false,
  });

  const ConfirmarSubirImagen = (img_64, img_data) => {
    setImg({ img: img_data, loading: false });

    new Swal({
      title: "¿Estas seguro de guardar " + Subtitulo + "?",
      text: "Esta acción no se puede deshacer",
      icon: "warning",
      showDenyButton: true,
      denyButtonText: "No, cancelar",
      confirmButtonText: "Si, estoy seguro",
      confirmButtonColor: "#3ABE88",
      denyButtonColor: "#65748B",
      reverseButtons: true,
    }).then(function (isConfirm) {
      //console.log(isConfirm.isConfirmed);
      if (isConfirm.isConfirmed) {
        SubirImagen(img_64);
      } else {
        fnCerrar();
      }
    });
  };

  const SubirImagen = (img_64, img_data) => {
    if (requiereConfirmar) {
      setImg({ img: img_data, loading: true });
    }

    if (tipo != IMG.TIPO_COMPROBANTE_LICENCIA) {
      EliminarImagen(id, tipo, img_64)
        .then((resp) => {
          //console.log("complete");
          console.log(resp);
        })
        .catch((e) => {
          //console.log("error");
          console.log(e);
        });
    }

    CargarImagen(id.toString(), tipo, img_64)
      .then((resp) => {
        if (resp.respuesta.includes("http")) {
          setError({
            mostrar: true,
            mensaje: resp.mensaje,
            severity: "success",
          });
          setImg({ img: img, loading: false });
          setTimeout(() => {
            fnDevolverImagen(resp.respuesta);
            fnCerrar();
          }, 1500);
        }
      })
      .catch((resp) => {
        // console.log("ERROR: ", resp)
        setError({
          mostrar: true,
          mensaje: "Problemas al actualizar la imagen",
          severity: "error",
        });
        setImg({ img: imgUrl, loading: false });
      });
  };

  const onDrop = useCallback((files) => {
    setImg({ img: img, loading: true });

    if (files) {
      if (files.length > 0) {
        const reader = new FileReader();
        reader.onloadend = () => {
          setTimeout(() => {
            setImg({ img: reader.result, loading: true });
          }, 1000);
        };
        reader.readAsDataURL(files[0]);
      } else {
        setImg({ img: imgUrl, loading: false });
      }
    } else {
      setImg({ img: imgUrl, loading: false });
    }
  }, []);

  // console.log("ID: ", id, " TIPO: ", tipo)

  useEffect(() => {
    if (AccEditarImagen != img && img != imgUrl) {
      setTimeout(async () => {
        const img_data = img.split("base64,");
        const img_type = img_data[0];
        const img_64 = img_data[1];

        if (id && id != 0) {
          let validarTipo = false;
          Object.keys(IMG).map((tipoImagen) => {
            if (IMG[tipoImagen] == tipo) {
              validarTipo = true;
            }
          });

          //Locaciones / Empleados / Planners
          if (validarTipo) {
            if (!requiereConfirmar) {
              SubirImagen(img_64, img);
            } else {
              ConfirmarSubirImagen(img_64, img);
            }
          } else {
            setImg({ img: img, loading: false });
            setError({
              mostrar: true,
              mensaje: "Falta asignar el tipo de imagen",
              severity: "error",
            });
          }
        } else {
          setImg({ img: img, loading: false });
          fnDevolverImagen(img);
          fnCerrar();
          // setError({ mostrar: true, mensaje: "Falta asignar el id de la imagen", severity: "error" })
        }

        // fnDevolverImagen(img)
        // fnCerrar();
      }, 0);
    }
  }, [img]);

  const { fileRejections, getRootProps, getInputProps } = useDropzone({
    onDrop: onDrop,
    accept: {
      "image/webp": [],
      "image/jpg": [],
      "image/jpeg": [],
      "image/png": [],
    },
  });

  const StyledSnackbar = styled((props) => <Snackbar {...props} />)(
    ({ theme }) => ({
      "& .MuiSnackbar-root": {
        top: theme.spacing(15),
      },
    })
  );

  return (
    <>
      <Modal
        Titulo={Titulo}
        mostrar={mostrar}
        fnCerrar={fnCerrar}
        maxWidth="md"
        textoGuardar=""
        textoCancelar=""
      >
        <div
          {...getRootProps()}
          style={{ border: "1px dotted lightgray" }}
          className="p-3 mt-0 mb-4 mx-4"
        >
          <input {...getInputProps()} />
          {
            <Row className="p-4 w-100 h-100">
              <Col sm={6} md={4} className="d-flex justify-content-center">
                <img
                  id={"imgAzure"}
                  src={imgDefault ? AccEditarImagen : img}
                  className="img-fluid"
                />
              </Col>
              <Col sm={6} md={8}>
                <div className="w-100 h-100 d-flex">
                  <div className="m-auto">
                    <strong> Selecciona {Subtitulo} </strong>
                    <p className="text-mutted mb-0">
                      Arrastra {Subtitulo} o{" "}
                      <strong style={{ color: "#3ABE88" }}> selecciona</strong>{" "}
                      <br />
                      desde tu ordenador
                    </p>
                  </div>
                </div>
              </Col>
            </Row>
          }
        </div>
        {fileRejections && fileRejections.length != 0 && (
          <div className="alert alert-danger py-4 mx-4" role="alert">
            <strong> Error al cargar: </strong> Solo se aceptan archivos JPG /
            JPGE / PNG / WEBP /
          </div>
        )}
      </Modal>

      <Backdrop sx={{ color: "#fff", zIndex: 1000000 }} open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>

      <StyledSnackbar
        direction="right"
        open={error.mostrar}
        autoHideDuration={6000}
        onClose={() => setError({ ...error, mostrar: false })}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      >
        <Alert
          onClose={() => setError({ ...error, mostrar: false })}
          severity={error.severity}
          sx={{ width: "100%" }}
        >
          {error.mensaje}
        </Alert>
      </StyledSnackbar>
    </>
  );
};

export default ModalCargarImagen;
