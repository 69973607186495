import {
  Avatar,
  FormControl,
  IconButton,
  InputLabel,
  OutlinedInput,
  TextField,
  Alert,
  Snackbar,
  styled,
} from "@mui/material";
import * as React from "react";
import { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import { getDescuentosVigentes } from "../../Descuentos/Descuentos/Funciones/Descuento";
import Loading from "../../Includes/Loading";
import { avatarLetters } from "../../Lib/Funciones Generales/Generales";
import iconodescuento from "./../../assets/iconodescuento.svg";
import { getInfoByCodigo } from "../../Catalogos/Cuponera/Funciones/Cuponera_Cupones";
const Descuentos = ({ setDescuento, descuento, codigoDesc, setCodigoDesc }) => {
  const [descuentos, setDescuentos] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [codigoDecuento, setCodigoDescuento] = useState("");
  const [show, setShow] = useState(false);
  const [open, setOpen] = useState(true);
  const [openAlert, setOpenAlert] = useState(true);
  const [mensaje, setMensaje] = useState("");

  const handleClose = () => {
    setOpenAlert(false);
  };

  const handleInputChangeCode = ({ target }) => {
    const Name = target.name;
    const Value = target.value;

    setCodigoDescuento(Value);
  };

  const getInfoCupon = (codigo) => {
    getInfoByCodigo(codigo)
      .then((data) => {
        if (data.codigo == "200") {
          //console.log(data);
          const row = JSON.parse(data.respuesta);
          setDescuento({
            id: row[0].ID,
            descuento: row[0].VALOR,
            tipo: row[0].TIPO_DESCUENTO,
            max: 1000,
          });
          //console.log("Descuento: ", descuento);
          //console.log("Row: ", row[0]);
          setCodigoDesc(codigo);
        } else {
          setMensaje(data.mensaje);
          setOpenAlert(true);
        }
      })
      .catch((data) => {
        //console.log(data);
        setMensaje(data.mensaje);
        setOpenAlert(true);
      });
  };

  useEffect(() => {
    getDescuentosVigentes()
      .then((resp) => {
        let list = JSON.parse(resp.data);
        //console.log(list);
        if (list.length != 0) {
          setDescuentos(list);
        } else {
          setDescuentos(list);
        }
        setIsLoading(false);
      })
      .catch((resp) => {
        setDescuentos([]);
        setIsLoading(false);
      });
  }, []);

  const StyledSnackbar = styled((props) => <Snackbar {...props} />)(
    ({ theme }) => ({
      "& .MuiSnackbar-root": {
        top: theme.spacing(15),
      },
    })
  );

  return (
    <>
      <Col xs={12} style={{ maxHeight: "50vh", overflowY: "scroll" }}>
        {isLoading ? (
          <Loading />
        ) : (
          <div className="w-100 h-100 d-flex justify-content-start flex-wrap flex-row">
            <StyledSnackbar
              direction="right"
              open={openAlert}
              autoHideDuration={6000}
              onClose={handleClose}
              anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
            >
              <Alert
                onClose={handleClose}
                severity="error"
                sx={{ width: "100%" }}
              >
                {mensaje}
              </Alert>
            </StyledSnackbar>
            {descuentos.map((row) => (
              <div
                className=""
                onClick={() => {
                  setDescuento({
                    id: row.ID,
                    descuento: row.CANTIDAD,
                    tipo: row.TIPO_DE_DESCUENTO,
                    max: row.DESCUENTO_MAX,
                  });
                  console.log(row);
                }}
              >
                <div
                  style={{}}
                  className={
                    " card-producto card p-3 m-3 ms-0 mt-1 mb-3 shadow-sm d-flex justify-content-center  flex-column " +
                    (descuento.id == row.ID ? "card-producto-active" : "")
                  }
                >
                  <center>
                    <img
                      src={iconodescuento}
                      className="img-fluid"
                      style={{ maxWidth: "35px" }}
                    />
                  </center>
                  <h3 className="font-AvenirBold parrafo2lineastruncate text-center my-1 ">
                    {row.TIPO_DE_DESCUENTO == 1
                      ? row.CANTIDAD + " %"
                      : "$ " + row.CANTIDAD}
                  </h3>
                  <p
                    style={{ fontSize: "12px" }}
                    className="font-AvenirBold  parrafo2lineastruncate text-center my-1"
                  >
                    DESCUENTO
                  </p>
                  {row.TIPO_DE_DESCUENTO == 1 ? (
                    <p
                      style={{ fontSize: "11px" }}
                      className="font-Avenir  parrafo2lineastruncate text-center my-1"
                    >
                      Max. -$ {row.DESCUENTO_MAX}
                    </p>
                  ) : (
                    <></>
                  )}
                </div>
              </div>
            ))}
          </div>
        )}

        <div
          className="d-flex flex-column justify-content-end mt-3 "
          style={{ maxWidth: "500px" }}
        >
          <TextField
            className="flex-grow-1"
            id="outlined-basic"
            label="Código de descuento"
            placeholder="Ingresa el codigo de descuento"
            fullWidth
            variant="outlined"
            size="small"
            onChange={handleInputChangeCode}
            value={codigoDecuento}
          />
          <div className="align-self-end mt-3">
            <Link
              className=" font-AvenirMedium "
              variant="text"
              href="#"
              underline="none"
              style={{ color: "#fd8c27", textAlign: "end" }}
              onClick={() => {
                getInfoCupon(codigoDecuento);
              }}
            >
              Aplicar código
            </Link>
          </div>
        </div>
      </Col>
    </>
  );
};

export default Descuentos;
