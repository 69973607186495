import * as React from "react";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Collapse from "@mui/material/Collapse";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import EventAvailableIcon from "@mui/icons-material/EventAvailable";
import { Link, NavLink, useLocation } from "react-router-dom";
import { getLocalStorage } from "../Context/Storage";

import logo from "./../assets/LogoKalendar.svg";
import DashboardLateral from "../assets/Iconos/DashboardLateral";
import DescuentosLateral from "../assets/Iconos/DescuentosLateral";
import ReportesLateral from "../assets/Iconos/ReportesLateral";
import ConfigLateral from "../assets/Iconos/ConfigLateral";
import CatalogosLateral from "../assets/Iconos/CatalogosLateral";
import EventNoteIcon from "@mui/icons-material/EventNote";

// import DashboardLateral from "./../assets/DashboardLateral.svg";
// import SeriesLateral from "./../assets/SeriesLateral.svg";
// import SettingsIcon from "@mui/icons-material/Settings";
// import DescuentosLateral from "./../assets/DescuentosLateralOFF.svg";
// import ReportesLateral from "./../assets/ReportesLateralOFF.svg";

const color = {
  focus: "#FE8D27",
  neutral: "#FFFFFF",
};

const pathInHijos = (location, hijos) => {
  let activarMenu = false;
  hijos.map((hijo) => {
    if (location.pathname.includes(hijo)) {
      activarMenu = true;
    }
  });
  return activarMenu;
};

const getColor = (location, path1, path2, hijos = [], esHijo = false) => {
  // console.log(location.pathname, path1, path2, hijos)
  if (esHijo) {
    return location.pathname.includes(path2) ? color.focus : color.neutral;
  }

  return location.pathname == path1 ||
    location.pathname == path2 ||
    pathInHijos(location, hijos)
    ? color.focus
    : color.neutral;
};

const hijosCatalogos = [
  "/clientes/",
  "/colaboradores/",
  "/productos/",
  "/categorias/",
  "/puestos/",
];

const hijosDescuentos = ["/descuentos/", "/cupones/"];

const hijosReportes = ["/represervaciones/", "/repcomisiones/"];

const hijosConfiguraciones = [
  // "/configuraciones/",
  // "/usuarios/",
  // "/sucursales/",
  // "/gruposacceso/",
  // "/formaspago/",

  "/Generales/",
  "/Sucursales/",
  "/Grupos de acceso/",
  "/Formas de pago/",
  "/Horario del colaborador/",
  "/Horario semanal/",
  "/Usuarios/",
];

export default function Sidebar({ openMenu, permisosMenu }) {
  const location = useLocation();
  const [open, setOpen] = React.useState(pathInHijos(location, hijosCatalogos));
  const [openDescuentos, setOpenDescuentos] = React.useState(
    pathInHijos(location, hijosDescuentos)
  );
  const [openReport, setOpenReport] = React.useState(
    pathInHijos(location, hijosReportes)
  );
  const [openConfig, setOpenConfig] = React.useState(
    pathInHijos(location, hijosConfiguraciones)
  );
  const [activeItem, setActiveItem] = React.useState(null);

  console.log(permisosMenu);

  const getContienePermisos = (menu) => {
    const contieneCadena = permisosMenu.some((cadena) => menu.includes(cadena));
    console.log(contieneCadena);
    if (contieneCadena) {
      console.log("El menú contiene al menos una de las cadenas buscadas.");
    } else {
      console.log("El menú no contiene ninguna de las cadenas buscadas.");
    }
    return contieneCadena;
  };

  const handleClickCatalogos = () => {
    if (activeItem === "Catalogos") {
      setActiveItem(null);
    } else {
      setActiveItem("Catalogos");
    }
  };

  const handleClickDescuentos = () => {
    if (activeItem === "Descuentos") {
      setActiveItem(null);
    } else {
      setActiveItem("Descuentos");
    }
  };

  const handleClickReportes = () => {
    if (activeItem === "Reportes") {
      setActiveItem(null);
    } else {
      setActiveItem("Reportes");
    }
  };

  const handleClickConfiguraciones = () => {
    if (activeItem === "Configuraciones") {
      setActiveItem(null);
    } else {
      setActiveItem("Configuraciones");
    }
  };

  // const handleClick = () => {
  //   setOpen(!open);
  // };

  // const handleClickConfig = () => {
  //   setOpenConfig(!openConfig);
  // };

  // const handleClickDescuentos = () => {
  //   setOpenDescuentos(!openDescuentos);
  // };

  // const handleClickReports = () => {
  //   setOpenReport(!openReport);
  // };

  return (
    <>
      <div
        className=" d-flex flex-column justify-content-between h-100  "
        style={{
          backgroundColor: "#100D1C",
        }}
      >
        <List
          sx={{ width: "100%", maxWidth: 360 }}
          style={{
            backgroundColor: "#100D1C",
            height: "100%",
            color: "white",
            zIndex: 1,
            paddingTop: openMenu ? "10px" : "50px",
          }}
          component="nav"
          aria-labelledby="nested-list-subheader"
        >
          {openMenu ? (
            <center>
              <Link to="inicio/" style={{ textDecoration: "none" }}>
                <img className="mb-4" src={logo} style={{ margin: "auto" }} />
              </Link>
            </center>
          ) : (
            <></>
          )}

          <Link
            to="inicio/"
            style={{ textDecoration: "none", color: "white" }}
            className="mb-1 mt-5"
          >
            <ListItemButton className="ps-2">
              <ListItemIcon className="justify-content-center">
                <DashboardLateral color={getColor(location, "/", "/inicio/")} />
              </ListItemIcon>
              <ListItemText
                primary="Dashboard"
                style={{ color: getColor(location, "/", "/inicio/") }}
              />
            </ListItemButton>
          </Link>

          {getLocalStorage("tipo") == "EMPRESA" ||
          permisosMenu.includes("Reservaciones") ? (
            <Link
              to="reservaciones/"
              style={{ textDecoration: "none", color: "white" }}
              className="mb-1 mt-5"
            >
              <ListItemButton className="ps-2">
                <ListItemIcon className="justify-content-center">
                  <EventAvailableIcon
                    width={20}
                    style={{
                      margin: "auto",
                      color: getColor(location, "", "/reservaciones/"),
                    }}
                  />
                </ListItemIcon>
                <ListItemText
                  primary="Reservaciones"
                  style={{ color: getColor(location, "", "/reservaciones/") }}
                />
              </ListItemButton>
            </Link>
          ) : (
            ""
          )}
          {getLocalStorage("tipo") == "EMPRESA" ||
          getContienePermisos([
            "Clientes",
            "Colaboradores",
            "Productos",
            "Categorías",
            "Puestos",
          ]) ? (
            <ListItemButton className="ps-2" onClick={handleClickCatalogos}>
              <ListItemIcon className="justify-content-center">
                <CatalogosLateral
                  color={getColor(location, "", "", hijosCatalogos)}
                />
              </ListItemIcon>
              <ListItemText
                primary="Catálogos"
                style={{ color: getColor(location, "", "", hijosCatalogos) }}
              />
              {activeItem === "Catalogos" ? <ExpandLess /> : <ExpandMore />}
            </ListItemButton>
          ) : (
            ""
          )}
          <Collapse
            in={activeItem === "Catalogos"}
            timeout="auto"
            unmountOnExit
          >
            <List component="div" disablePadding>
              {getLocalStorage("tipo") == "EMPRESA" ||
              permisosMenu.includes("Clientes") ? (
                <Link
                  to="clientes/"
                  style={{ textDecoration: "none", color: "white" }}
                >
                  <ListItemButton className="ps-3">
                    <ListItemIcon></ListItemIcon>
                    <ListItemText
                      primary="Clientes"
                      style={{
                        color: getColor(location, "", "/clientes/", [], true),
                      }}
                    />
                  </ListItemButton>
                </Link>
              ) : (
                ""
              )}
              {getLocalStorage("tipo") == "EMPRESA" ||
              permisosMenu.includes("Colaboradores") ? (
                <Link
                  to="colaboradores/"
                  style={{ textDecoration: "none", color: "white" }}
                >
                  <ListItemButton className="ps-3">
                    <ListItemIcon></ListItemIcon>
                    <ListItemText
                      primary="Colaboradores"
                      style={{
                        color: getColor(
                          location,
                          "",
                          "/colaboradores/",
                          [],
                          true
                        ),
                      }}
                    />
                  </ListItemButton>
                </Link>
              ) : (
                ""
              )}

              {getLocalStorage("tipo") == "EMPRESA" ||
              permisosMenu.includes("Productos") ? (
                <Link
                  to="productos/"
                  style={{ textDecoration: "none", color: "white" }}
                >
                  <ListItemButton className="ps-3">
                    <ListItemIcon></ListItemIcon>
                    <ListItemText
                      primary="Productos"
                      style={{
                        color: getColor(location, "", "/productos/", [], true),
                      }}
                    />
                  </ListItemButton>
                </Link>
              ) : (
                ""
              )}
              {getLocalStorage("tipo") == "EMPRESA" ||
              permisosMenu.includes("Categorías") ? (
                <Link
                  to="categorias/"
                  style={{ textDecoration: "none", color: "white" }}
                >
                  <ListItemButton className="ps-3">
                    <ListItemIcon></ListItemIcon>
                    <ListItemText
                      primary="Categorías"
                      style={{
                        color: getColor(location, "", "/categorias/", [], true),
                      }}
                    />
                  </ListItemButton>
                </Link>
              ) : (
                ""
              )}
              {getLocalStorage("tipo") == "EMPRESA" ||
              permisosMenu.includes("Puestos") ? (
                <Link
                  to="puestos/"
                  style={{ textDecoration: "none", color: "white" }}
                >
                  <ListItemButton className="ps-3">
                    <ListItemIcon></ListItemIcon>
                    <ListItemText
                      primary="Puestos"
                      style={{
                        color: getColor(location, "", "/puestos/", [], true),
                      }}
                    />
                  </ListItemButton>
                </Link>
              ) : (
                ""
              )}
            </List>
          </Collapse>

          {/*-------------------------------------------------------------------------------------------------------------*/}

          {getLocalStorage("tipo") == "EMPRESA" ||
          getContienePermisos(["Descuentos", "Cupones"]) ? (
            <ListItemButton className="ps-2" onClick={handleClickDescuentos}>
              <ListItemIcon className="justify-content-center">
                <DescuentosLateral
                  color={getColor(location, "", "", hijosDescuentos)}
                />
              </ListItemIcon>
              <ListItemText
                primary="Descuentos"
                style={{ color: getColor(location, "", "", hijosDescuentos) }}
              />
              {activeItem === "Descuentos" ? <ExpandLess /> : <ExpandMore />}
            </ListItemButton>
          ) : (
            ""
          )}

          <Collapse
            in={activeItem === "Descuentos"}
            timeout="auto"
            unmountOnExit
          >
            <List component="div" disablePadding>
              {getLocalStorage("tipo") == "EMPRESA" ||
              permisosMenu.includes("Descuentos") ? (
                <Link
                  to="descuentos/"
                  style={{ textDecoration: "none", color: "white" }}
                >
                  <ListItemButton className="ps-3">
                    <ListItemIcon></ListItemIcon>
                    <ListItemText
                      primary="Descuentos"
                      style={{
                        color: getColor(location, "", "/descuentos/", [], true),
                      }}
                    />
                  </ListItemButton>
                </Link>
              ) : (
                ""
              )}
              {getLocalStorage("tipo") == "EMPRESA" ||
              permisosMenu.includes("Cupones") ? (
                <Link
                  to="cupones/"
                  style={{ textDecoration: "none", color: "white" }}
                >
                  <ListItemButton className="ps-3">
                    <ListItemIcon></ListItemIcon>
                    <ListItemText
                      primary="Cupones"
                      style={{
                        color: getColor(location, "", "/cupones/", [], true),
                      }}
                    />
                  </ListItemButton>
                </Link>
              ) : (
                ""
              )}
            </List>
          </Collapse>

          {/*-------------------------------------------------------------------------------------------------------------*/}

          {getLocalStorage("tipo") == "EMPRESA" ||
          getContienePermisos([
            "Reporte Reservaciones",
            "Reporte Comisiones",
          ]) ? (
            <ListItemButton className="ps-2" onClick={handleClickReportes}>
              <ListItemIcon className="justify-content-center">
                <ReportesLateral
                  color={getColor(location, "", "", hijosReportes)}
                />
              </ListItemIcon>
              <ListItemText
                primary="Reportes"
                style={{ color: getColor(location, "", "", hijosReportes) }}
              />
              {activeItem === "Reportes" ? <ExpandLess /> : <ExpandMore />}
            </ListItemButton>
          ) : (
            ""
          )}

          <Collapse in={activeItem === "Reportes"} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              {getLocalStorage("tipo") == "EMPRESA" ||
              permisosMenu.includes("Reporte Reservaciones") ? (
                <Link
                  to="represervaciones/"
                  style={{ textDecoration: "none", color: "white" }}
                >
                  <ListItemButton className="ps-3">
                    <ListItemIcon></ListItemIcon>
                    <ListItemText
                      primary="Reservaciones"
                      style={{
                        color: getColor(
                          location,
                          "",
                          "/represervaciones/",
                          [],
                          true
                        ),
                      }}
                    />
                  </ListItemButton>
                </Link>
              ) : (
                ""
              )}
              {getLocalStorage("tipo") == "EMPRESA" ||
              permisosMenu.includes("Reporte Comisiones") ? (
                <Link
                  to="repcomisiones/"
                  style={{ textDecoration: "none", color: "white" }}
                >
                  <ListItemButton className="ps-3">
                    <ListItemIcon></ListItemIcon>
                    <ListItemText
                      primary="Comisiones"
                      style={{
                        color: getColor(
                          location,
                          "",
                          "/repcomisiones/",
                          [],
                          true
                        ),
                      }}
                    />
                  </ListItemButton>
                </Link>
              ) : (
                ""
              )}
            </List>
          </Collapse>

          {/*-------------------------------------------------------------------------------------------------------------*/}

          {getLocalStorage("tipo") == "EMPRESA" ||
          getContienePermisos([
            "Generales",
            "Sucursales",
            "Grupos de acceso",
            "Formas de pago",
            "Horario del colaborador",
            "Horario semanal",
            "Usuarios",
          ]) ? (
            <ListItemButton
              className="ps-2"
              onClick={handleClickConfiguraciones}
            >
              <ListItemIcon>
                <ListItemIcon className="justify-content-center">
                  <ConfigLateral
                    color={getColor(location, "", "", hijosConfiguraciones)}
                  />
                </ListItemIcon>
              </ListItemIcon>
              <ListItemText
                primary="Configuraciones"
                style={{
                  color: getColor(location, "", "", hijosConfiguraciones),
                }}
              />
              {activeItem === "Configuraciones" ? (
                <ExpandLess />
              ) : (
                <ExpandMore />
              )}
            </ListItemButton>
          ) : (
            ""
          )}

          <Collapse
            in={activeItem === "Configuraciones"}
            timeout="auto"
            unmountOnExit
          >
            <List component="div" disablePadding>
              {getLocalStorage("tipo") == "EMPRESA" ||
              permisosMenu.includes("Formas de pago") ? (
                <Link
                  to="formaspago/"
                  style={{ textDecoration: "none", color: "white" }}
                >
                  <ListItemButton className="ps-3">
                    <ListItemIcon></ListItemIcon>
                    <ListItemText
                      primary="Formas de pago"
                      style={{
                        color: getColor(location, "", "/formaspago/", [], true),
                      }}
                    />
                  </ListItemButton>
                </Link>
              ) : (
                ""
              )}

              {getLocalStorage("tipo") == "EMPRESA" ||
              permisosMenu.includes("Generales") ? (
                <Link
                  to="configuraciones/"
                  style={{ textDecoration: "none", color: "white" }}
                >
                  <ListItemButton className="ps-3">
                    <ListItemIcon></ListItemIcon>
                    <ListItemText
                      primary="Generales"
                      style={{
                        color: getColor(
                          location,
                          "",
                          "/configuraciones/",
                          [],
                          true
                        ),
                      }}
                    />
                  </ListItemButton>
                </Link>
              ) : (
                ""
              )}

              {getLocalStorage("tipo") == "EMPRESA" ||
              permisosMenu.includes("Grupos de acceso") ? (
                <Link
                  to="gruposacceso/"
                  style={{ textDecoration: "none", color: "white" }}
                >
                  <ListItemButton className="ps-3">
                    <ListItemIcon></ListItemIcon>
                    <ListItemText
                      primary="Grupos de acceso"
                      style={{
                        color: getColor(
                          location,
                          "",
                          "/gruposacceso/",
                          [],
                          true
                        ),
                      }}
                    />
                  </ListItemButton>
                </Link>
              ) : (
                ""
              )}
              {getLocalStorage("tipo") == "EMPRESA" ||
              permisosMenu.includes("Horario del colaborador") ? (
                <Link
                  to="horario_colaborador/"
                  style={{ textDecoration: "none", color: "white" }}
                >
                  <ListItemButton className="ps-3">
                    <ListItemIcon></ListItemIcon>
                    <ListItemText
                      primary="Horarios colaboradores"
                      style={{
                        color: getColor(
                          location,
                          "",
                          "/horario_colaborador/",
                          [],
                          true
                        ),
                      }}
                    />
                  </ListItemButton>
                </Link>
              ) : (
                ""
              )}
              {getLocalStorage("tipo") == "EMPRESA" ||
              permisosMenu.includes("Horario semanal") ? (
                <Link
                  to="horario_semanal/"
                  style={{ textDecoration: "none", color: "white" }}
                >
                  <ListItemButton className="ps-3">
                    <ListItemIcon></ListItemIcon>
                    <ListItemText
                      primary="Horario semanal"
                      style={{
                        color: getColor(
                          location,
                          "",
                          "/horario_semanal/",
                          [],
                          true
                        ),
                      }}
                    />
                  </ListItemButton>
                </Link>
              ) : (
                ""
              )}
              {getLocalStorage("tipo") == "EMPRESA" ||
              permisosMenu.includes("Sucursales") ? (
                <Link
                  to="sucursales/"
                  style={{ textDecoration: "none", color: "white" }}
                >
                  <ListItemButton className="ps-3">
                    <ListItemIcon></ListItemIcon>
                    <ListItemText
                      primary="Sucursales"
                      style={{
                        color: getColor(location, "", "/sucursales/", [], true),
                      }}
                    />
                  </ListItemButton>
                </Link>
              ) : (
                ""
              )}

              {getLocalStorage("tipo") == "EMPRESA" ||
              permisosMenu.includes("Usuarios") ? (
                <Link
                  to="usuarios/"
                  style={{ textDecoration: "none", color: "white" }}
                >
                  <ListItemButton className="ps-3">
                    <ListItemIcon></ListItemIcon>
                    <ListItemText
                      primary="Usuarios"
                      style={{
                        color: getColor(location, "", "/usuarios/", [], true),
                      }}
                    />
                  </ListItemButton>
                </Link>
              ) : (
                ""
              )}
            </List>
          </Collapse>
        </List>
      </div>
    </>
  );
}
