import {
  Avatar,
  FormControl,
  IconButton,
  InputLabel,
  OutlinedInput,
} from "@mui/material";
import { useEffect, useState } from "react";
import SearchIcon from "@mui/icons-material/Search";
import InputAdornment from "@mui/material/InputAdornment";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import Loading from "./../../Includes/Loading";

import { getListaByCategoria } from "./../../Catalogos/Productos/Funciones/Productos";

import {
  avatarLetters,
  getTiempoFromHorasToMinutos,
} from "../../Lib/Funciones Generales/Generales";
import { Button, Col, Row } from "react-bootstrap";

export default function Productos({
  categoria,
  valueBusqueda,
  limpairBusqueda,
  productosCarrito,
  setProductosCarrito,
  setCategoria,
}) {
  const [productos, setProductos] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    getListaByCategoria(categoria, valueBusqueda)
      .then((resp) => {
        let list = JSON.parse(resp.data);
        //console.log(resp)
        if (list.length != 0) {
          setProductos(list);
        } else {
          setProductos(list);
        }
        setIsLoading(false);
      })
      .catch((resp) => {
        setProductos([]);
        setIsLoading(false);
      });
  }, [valueBusqueda]);

  const addItem = (newItem) => {
    const items = [...productosCarrito];
    const itemIndex = Array.isArray(items)
      ? items.findIndex((item) => item.ID === newItem.ID)
      : -1;
    if (itemIndex !== -1) {
      items[itemIndex].CANTIDAD += 1;
    } else {
      items.push(newItem);
    }
    setProductosCarrito(items);
  };

  return isLoading ? (
    <Loading />
  ) : (
    <>
      <Row>
        <Col xs={12}>
          <Button
            onClick={() => {
              setCategoria(0);
              limpairBusqueda("");
            }}
            className="btn  font-AvenirBold my-1 text-star ps-0 pe-4  "
            variant="text"
          >
            <ArrowBackIcon className="me-3" />
            Regresar
          </Button>
        </Col>
        <Col xs={12} style={{ maxHeight: "70vh", overflowY: "scroll" }}>
          {productos.length == 0 ? (
            <>
              <h2 className="my-4 font-font-AvenirBold text-center">
                Sin resultados
              </h2>
            </>
          ) : (
            <div className="w-100 h-100 d-flex justify-content-start flex-wrap flex-row">
              {productos.map((row) => (
                <div
                  className=""
                  onClick={() =>
                    addItem({
                      ID: row.ID,
                      DESCRIPCION: row.DESCRIPCION,
                      PRECIO: row.PRECIO,
                      DURACION: getTiempoFromHorasToMinutos(row.DURACION),
                      CANTIDAD: 1,
                    })
                  }
                >
                  <div
                    style={{}}
                    className=" card-producto card p-3 m-3 shadow-sm d-flex justify-content-center  flex-column "
                  >
                    <center>
                      <Avatar sx={{ bgcolor: "#fd8c27" }}>
                        {avatarLetters(row.DESCRIPCION)}
                      </Avatar>
                    </center>
                    <p className="font-Avenir fw-bold parrafo2lineastruncate text-center my-1">
                      {row.DESCRIPCION}
                    </p>
                    <p className="font-font-AvenirMedium fw-bold text-truncate text-center my-2">
                      ${row.PRECIO} MXN
                    </p>
                  </div>
                </div>
              ))}
            </div>
          )}
        </Col>
      </Row>
    </>
  );
}
