import * as React from "react";
import { DataGrid, GridColDef, GridValueGetterParams } from "@mui/x-data-grid";
import { formatMoneda } from "../../../Lib/Funciones Generales/Generales";

const columns = [
  { field: "id", headerName: "Folio", align: "left" },
  { field: "fecha", headerName: "Fecha", align: "left", width: 200 },
  { field: "total", headerName: "Total", align: "left" },
  {
    field: "tarjeta",
    headerName: "Tarjeta",
    align: "left",
  },
  {
    field: "efectivo",
    headerName: "Efectivo",
    align: "left",
  },

  {
    field: "otros",
    headerName: "Otros",
    align: "left",
  },

  {
    field: "pagada",
    headerName: "Pagada",
    align: "left",
  },
];

export default function RepComisiones({ comisiones }) {
  const [selectedRows, setSelectedRows] = React.useState([]);

  let rows = [];

  let TotalApagar = 0;
  let TotalPagado = 0;
  let TotalNoPagado = 0;
  let TotalIncentivos = 0;

  comisiones.map((row) => {
    rows.push({
      id: parseInt(row.FOLIO),
      fecha: row.FECHA + " " + row.HORA,
      total: formatMoneda(row.TOTAL_RESERVACION),
      comision: formatMoneda(row.COMISION),
      incentivos: formatMoneda(row.INCENTIVOS),
      pagada: row.COMISION_PAGADA == 1 ? "SI" : "NO",
      tarjeta: formatMoneda(row.TARJETA),
      efectivo: formatMoneda(row.EFECTIVO),
      otros: formatMoneda(row.OTROS)
    });
    TotalPagado +=
      row.COMISION_PAGADA == 0
        ? parseFloat(row.COMISION) + parseFloat(row.INCENTIVOS)
        : 0;
    TotalNoPagado += row.COMISION_PAGADA == 0 ? parseFloat(row.COMISION) : 0;
    TotalIncentivos +=
      row.COMISION_PAGADA == 0 ? parseFloat(row.INCENTIVOS) : 0;
  });

  comisiones.map((row) => {
    const indice = selectedRows.indexOf(row.FOLIO);
    if (indice != -1) {
      TotalApagar += parseFloat(row.COMISION) + parseFloat(row.INCENTIVOS);
    }
  });

  return (
    <div className="row">
      <div className="col-12" style={{ height: 500, width: "100%" }}>
        <DataGrid
          responsive={true}
          rows={rows}
          columns={columns}
          pageSize={5}
          rowsPerPageOptions={[5]}
          checkboxSelection
          keepNonExistentRowsSelected
          isRowSelectable={(params) => params.row.pagada == "NO"}
          onRowSelectionModelChange={(newRowSelectionModel) => {
            setSelectedRows(newRowSelectionModel);
          }}
        />
      </div>
      <div className="col-12 col-md-6  d-flex flex-column justify-content-end w-100">
        <div className="p-1 mt-5">
          <h5 className="font-AvenirMedium text-end mb-2">
            Pagadas: {formatMoneda(TotalPagado)} MXN
          </h5>
          <h5 className="font-AvenirMedium text-end mb-2">
            Comisiones por pagar: {formatMoneda(TotalNoPagado)} MXN
          </h5>
          <h5 className="font-AvenirMedium text-end mb-2">
            Incentivos: {formatMoneda(TotalIncentivos)} MXN
          </h5>
          <h5 className="font-AvenirBold text-end mb-0">
            Total a pagar: <span className="font-AvenirBold">{formatMoneda(TotalApagar)} MXN</span> 
          </h5>
        </div>
      </div>
    </div>
  );
}
