import {
  Alert,
  AlertTitle,
  Avatar,
  Button,
  Card,
  CardContent,
  Checkbox,
  Divider,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  Snackbar,
  Stack, 
  TextField,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  guardar,
  getInfoEmpleado,
  getAccesos,
  getInfoGrupo,
} from "../Funciones/gruposacceso";
import { styled } from "@mui/material/styles";
import { useParams } from "react-router-dom";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import AccEditarImagen from "./../../../assets/EditarImagen.svg";
import "../../../Lib/Funciones Generales/Prototipos";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { Col, Row } from "react-bootstrap";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import Loading from "../../../Includes/Loading";
import { LoadingButton } from "@mui/lab";
import Switch from "../../../Includes/Switch";

const MySwal = withReactContent(Swal);

const Empleado = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [IsLoading, setIsLoading] = useState(true);
  const [IsGuardando, setIsGuardando] = useState(false);
  const [open, setOpen] = useState(false);
  const [mensaje, setMensaje] = useState("");
  const [permisos, setPermisos] = useState([]);

  const [Values, setValues] = useState({
    id: id ?? "0",
    permisos: [],
  });

  const [Errores, setErrores] = useState({
    nombre: false,
  });

  let cont = 0;
  let cont2 = 0;

  const handlInputChange = ({ target }) => 
  {
    let Name = target.name;
    let Value = target.value ? target.value : "";

    setValues({
      ...Values,
      [Name]: Value,
    });
    setErrores({
      ...Errores,
      [Name]: false,
    });
  };

 
 
  
  useEffect(() => {
    setIsLoading(true);
    if (id != undefined) {
      getInfoGrupo(id, AccEditarImagen)
        .then((resp) => {
          if (resp?.err?.length == 0) {
            MySwal.fire({
              title: "Error",
              html: resp.mensaje,
              icon: "error",
              confirmButtonColor: "#3ABE88",
              showConfirmButton: true,
              allowEscapeKey: false,
              allowEnterKey: false,
              allowOutsideClick: false,
            }).then(() => {
              setIsLoading(false);
              navigate(-1);
            });
          } else {
            setValues(resp);
            setPermisos(resp.permisos);
            setIsLoading(false);
          }
        })
        .catch((resp) => {
          MySwal.fire({
            title: "Error",
            html: resp.mensaje,
            icon: "error",
            confirmButtonColor: "#3ABE88",
            showConfirmButton: true,
            allowEscapeKey: false,
            allowEnterKey: false,
            allowOutsideClick: false,
          }).then(() => {
            setIsLoading(false);
            navigate(-1);
          });
          //setIsLoading(false);
        });
    } else {
      getAccesos()
        .then((resp) => {
          if (resp?.err?.length == 0) {
            MySwal.fire({
              title: "Error",
              html: resp.mensaje,
              icon: "error",
              confirmButtonColor: "#3ABE88",
              showConfirmButton: true,
              allowEscapeKey: false,
              allowEnterKey: false,
              allowOutsideClick: false,
            }).then(() => {
              setIsLoading(false);
            });
          } else { 
            setPermisos(resp);
            setIsLoading(false);
          }
        })
        .catch((resp) => {
          MySwal.fire({
            title: "Error",
            html: resp.mensaje,
            icon: "error",
            confirmButtonColor: "#3ABE88",
            showConfirmButton: true,
            allowEscapeKey: false,
            allowEnterKey: false,
            allowOutsideClick: false,
          }).then(() => {
            setIsLoading(false);
            navigate(-1);
          });
        });
    }
  }, []);

  const GuardarGrupo = () => {
    setIsGuardando(true);
    guardar(Values, Errores, setErrores, permisos)
      .then((data) => {
        if (data.codigo == "200") {
          setIsGuardando(false);
          MySwal.fire({
            title: "Correcto",
            html: data.mensaje,
            icon: "success",
            confirmButtoColor: "#3ABE88",
            showConfirmButton: false,
            timer: 1700,
          }).then((result) => {
            navigate(-1);
          });
        } else {
          setMensaje(data.mensaje);
          setOpen(true);
          setIsGuardando(false);
        }
      })
      .catch((data) => {
        // alert(data.mensaje);
        setMensaje(data.mensaje);
        setOpen(true);
        setIsGuardando(false);
      });
  };

  const StyledSnackbar = styled((props) => <Snackbar {...props} />)(
    ({ theme }) => ({
      "& .MuiSnackbar-root": {
        top: theme.spacing(15),
      },
    })
  );

  function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`vertical-tabpanel-${index}`}
        aria-labelledby={`vertical-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }
  function a11yProps(index) {
    return {
      id: `vertical-tab-${index}`,
      "aria-controls": `vertical-tabpanel-${index}`,
    };
  }
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
 

const onChangeSwitch= (nombrePadre, nombreHijo)=>
{                         
    const per = permisos.findIndex((p) => p.nombre == nombrePadre );
    console.log(per)
    if(per!== -1)
    { 
     const per2 =  permisos[per].permisos.findIndex((p) => p.nombre == nombreHijo );
     console.log(per2)
     if (per2 !== -1)
     {
       permisos[per].permisos[per2].tienepermiso =   !permisos[per].permisos[per2].tienepermiso;
       setPermisos([...permisos]);
     }
    }   

}

  return (
    <>
      <StyledSnackbar
        direction="right"
        open={open}
        autoHideDuration={6000}
        onClose={() => {
          setOpen(false);
        }}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      >
        <Alert
          onClose={() => {
            setOpen(false);
          }}
          severity="error"
          sx={{ width: "100%" }}
        >
          {mensaje}
        </Alert>
      </StyledSnackbar>
      <Button
        onClick={() => navigate(-1)}
        className="btn  font-AvenirBold my-4 text-star ps-0 pe-4  "
        variant="text"
      >
        <ArrowBackIcon className="me-3" />
        Grupos de acceso
      </Button>
      <Card elevation={3} className="mb-4">
        <CardContent className="p-0">
          <div className="p-3">
            <h6 className="mb-0 text-left font-AvenirBold">
              {id ? "Editar grupo de acceso" : "Nuevo grupo de acceso"}
            </h6>
          </div>
          <hr
            style={{ backgroundColor: "#DFDEE0", height: "1px", opacity: "1" }}
            className="m-0 p-0"
          /> 
          {IsLoading ? (
            <Row className="px-3">
              <Col sm={12} className="p-3">
                <Loading />
              </Col>
            </Row>
          ) : (

            <Row>    
                <Col sm={12} md={7}   className="p-3 mt-0"> 
            <Row className="p-3"> 
              <Col sm={12}   className="p-3 mt-0">
                <TextField
                  fullWidth
                  name="nombre"
                  label="Nombre del grupo"
                  inputProps={{ maxLength: 250 }}
                  variant="outlined"
                  value={Values.nombre}
                  error={Errores.nombre}
                  helperText={Errores.nombre ? "Nombre no valido" : ""}
                  onChange={handlInputChange}
                />
              </Col> 
              <Col sm={12}   className="p-3 mt-0">
                <Box
                  sx={{
                    flexGrow: 1,
                    bgcolor: "background.paper",
                    display: "flex",
                    height: 500,
                  }}
                >
                  <Tabs
                    orientation="vertical"
                    variant="scrollable"
                    value={value}
                    onChange={handleChange}
                    aria-label="Vertical tabs example"
                    sx={{ borderRight: 1, borderColor: "divider" }}
                  >
                    {permisos.map((row) => (
                      <Tab lassName="font-AvenirBold" label={row.nombre} {...a11yProps(cont++)} />
                    ))}
                  </Tabs> 
                  {permisos.map((row) => (
                    <TabPanel
                      value={value}
                      index={cont2++}
                      className="p-0 w-100"
                    >
                      {row.permisos.map((row1) => (
                        <div className="mb-3" > 
                        <div 
                        onClick={()=>{onChangeSwitch(row.nombre, row1.nombre)}}
                        style={{cursor:"pointer"}}
                        className="w-100 d-flex justify-content-between align-content-center align-items-center">
                          <div  style={{cursor:"pointer"}}>
                            <label className="font-Avenir"  style={{cursor:"pointer"}}>{row1.nombre}</label>
                          </div>
                          <div>
                            <Switch
                              checked={row1.tienepermiso}
                              
                              id={null}
                              tituloActivo="Desactivar"
                              tituloInactivo="Activar" 
                            />
                          </div>
                        </div> 
                        <Divider  style={{backgroundColor:"#e1e1e1"}} />
                        </div>
                       
                      ))}
                    </TabPanel>
                  ))}
                </Box>
              </Col> 
            </Row>
            </Col>

            <Col sm={12} md={5}  className="p-4 p-md-3 mt-0">
            <h6 className="mb-0 mt-3 text-left font-AvenirBold">
  ¿Cómo funciona?
</h6>
<p>
 Crea un grupo de accesos para administrar a que funciones del sistema tienen acceso los colaboradores  
</p>
<p>
Asigna a tus colaboradores un grupo de acceso
</p>

</Col>
            </Row>
          )}
          <Stack
            className="p-3"
            spacing={2}
            direction={{ xs: "column", sm: "row" }}
            style={{}}
          >
            <LoadingButton
              loading={IsGuardando}
              loadingPosition="start"
              disabled={IsLoading}
              onClick={() => GuardarGrupo()}
              className="btn btn-Kalendar font-AvenirMedium  py-2 px-4 "
              variant="contained"
              xs={{ with: "100$" }}
            >
              <span className={IsGuardando ? "px-4" : "px-2"}>
                {IsGuardando ? "Guardando..." : id ? "Guardar" : "Crear"}
              </span>
            </LoadingButton>
            <Button
              onClick={() => navigate(-1)}
              className="btn btn-cancelar font-AvenirMedium py-2 px-4"
              variant="outlined"
            >
              Cancelar
            </Button>
          </Stack>
        </CardContent>
      </Card>
    </>
  );
};

export default Empleado;
{
  /* <code>
<pre>
  {
    JSON.stringify(Values, null, 2)
  }
</pre>
</code> */
}
