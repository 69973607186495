import React, { useState, useEffect } from "react";

import Chart from "react-apexcharts";
import { Grid, Card, CardContent } from "@mui/material";
import { Col, Row } from "react-bootstrap";
import SelectSucursal from "../../../Configuraciones/Sucursales/Componentes/SelectSucursal";
import { getServicios4Sucursal } from "../../Funciones/Dashboard";

const RadilBarServiceSuc = ({ val = 0, label = "" }) => {
  const [Values, setValues] = useState({
    id_sucursal: "0",
    total: val,
  });

  const [IsLoading, setIsLoading] = useState(true);

  const handlInputChange = ({ target }) => {
    let Name = target.name;
    let Value = target.value;
    //console.log(Value);
    setValues({
      ...Values,
      [Name]: Value,
    });
  };

  useEffect(() => {
    setIsLoading(true);
    getServicios4Sucursal(Values.id_sucursal)
      .then((resp) => {
        if (resp?.err?.length == 0) {
          setIsLoading(false);
        } else {
          setIsLoading(false);
          setValues({
            ...Values,
            total: resp.data,
          });
        }
      })
      .catch((resp) => {
        console.log(resp);
        setIsLoading(false);
      });
  }, [Values.id_sucursal]);

  var options = {
    chart: {
      height: 280,
      type: "radialBar",
    },

    series: [Values.total === "0" ? "0" : "100"],
    color: ["#025CCF"],
    plotOptions: {
      radialBar: {
        hollow: {
          margin: 15,
          size: "73%",
        },

        dataLabels: {
          showOn: "always",
          name: {
            offsetY: 40,
            show: true,
            color: "#888",
            fontSize: "20px",
            fontWeight: 500,
          },
          value: {
            offsetY: -20,
            formatter: function (param) {
              //return parseInt(123);
              return Values.total;
            },
            color: "#025CCF",
            fontSize: "50px",
            show: true,
            fontWeight: 600,
          },
        },
      },
    },

    stroke: {
      lineCap: "butt",
    },
    labels: [label],
  };

  return (
    <>
      <Card className="shadow-sm" elevation={3} style={{ height: "491px" }}>
        <CardContent className="p-0">
          <div className="p-3">
            <h6 className="mb-0 text-left font-AvenirBold">
              Total de servicios atendidos
            </h6>
          </div>
          <hr
            style={{
              backgroundColor: "#DFDEE0",
              height: "1px",
              opacity: "1",
            }}
            className="m-0 p-0"
          />
          {IsLoading ? (
            <>
              <center>
                <h1 className="parpadea">...</h1>
              </center>
            </>
          ) : (
            <div lassName="p-3 m-3">
              <Row className="p-1 m-2">
                <Col>
                  <Chart
                    options={options}
                    series={options.series}
                    type="radialBar"
                    height={300}
                  />
                </Col>
              </Row>
              <Row
                className="d-flex align-items-end p-1 m-2"
                style={{ background: "", height: "100px" }}
              >
                <Col>
                  <Grid
                    container
                    direction="row"
                    justifyContent="center"
                    alignItems="flex-end"
                  >
                    <SelectSucursal
                      Value={Values.id_sucursal}
                      handlInputChange={handlInputChange}
                    />
                  </Grid>
                </Col>
                {/*<button
              onClick={() => {
                setCont(cont + 4);
              }}
            >
              +1
            </button>
            <button
              onClick={() => {
                setCont(0);
              }}
            >
              Reset
            </button>*/}
              </Row>
            </div>
          )}
        </CardContent>
      </Card>
    </>
  );
};
export default RadilBarServiceSuc;
