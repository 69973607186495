const CatalogosLateral = ({ color }) => {
    return (
        <svg width="18px" height="16px" viewBox="0 0 18 16" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
            <title>1.5 CatalogosLateralON</title>
            <g id="Michelle-Pourroy-MAnager" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g id="1.1-Dashboard" transform="translate(-35.000000, -325.000000)">
                    <g id="1-copy-5" transform="translate(0.000000, 303.000000)">
                        <g id="1.5-CatalogosLateralON" transform="translate(35.000000, 22.000000)">
                            <g id="Group" transform="translate(6.602564, 0.000000)" stroke={color} strokeLinecap="round" strokeWidth="1.5">
                                <line x1="0" y1="1.32051283" x2="10.5641027" y2="1.32051283" id="Path-2"></line>
                                <line x1="0" y1="7.923077" x2="10.5641027" y2="7.923077" id="Path-2"></line>
                                <line x1="0" y1="14.5256412" x2="10.5641027" y2="14.5256412" id="Path-2"></line>
                            </g>
                            <circle id="Oval" fill={color} cx="1" cy="1.32051283" r="1"></circle>
                            <circle id="Oval" fill={color} cx="1" cy="7.923077" r="1"></circle>
                            <circle id="Oval" fill={color} cx="1" cy="14.5256412" r="1"></circle>
                        </g>
                    </g>
                </g>
            </g>
        </svg>
    )
}

export default CatalogosLateral
