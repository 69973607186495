import React, { useState, useEffect } from "react";
import {
  Alert,
  Avatar,
  Button,
  Card,
  CardContent,
  Divider,
  Snackbar,
  Stack,
  TextField,
} from "@mui/material";

import { Col, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { guardar, getInfoPerfil } from "../Funciones/Perfiles";
import Loading from "../../../Includes/Loading";
import LoadingButton from "@mui/lab/LoadingButton";
import { styled } from "@mui/material/styles";
import { useParams } from "react-router-dom";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import RegimenFiscal from "../../../Lib/SAT/Componentes/RegimenFiscal";

export const Empresa = ({
  Values,
  Errores,
  handlInputChange,
  img_url,
  handleOpenModal,
}) => {
  return (
    <Row className="p-3">
      <Col
        sm={12}
        md={12}
        lg={4}
        className="d-flex flex-wrap align-items-center justify-content-center"
      >
        <Avatar
          className="shadow"
          sx={{ width: 120, height: 120 }}
          src={img_url}
        />
        <strong
          className="w-100 text-center px-0 pt-3"
          style={{ color: "#3ABE88", cursor: "pointer" }}
          onClick={handleOpenModal}
        >
          Editar
        </strong>
      </Col>
      <Col sm={12} md={4} className="p-3">
        <TextField
          fullWidth
          name="nombre"
          label="Nombre"
          variant="outlined"
          value={Values.nombre}
          error={Errores.nombre}
          onChange={handlInputChange}
        />
      </Col>
      <Col sm={12} md={4} className="p-3">
        <TextField
          fullWidth
          name="razon_social"
          label="Razon Social"
          variant="outlined"
          value={Values.razon_social}
          error={Errores.razon_social}
          onChange={handlInputChange}
        />
      </Col>
      <Col sm={12} md={4} className="p-3">
        <RegimenFiscal
          Value={Values.regimen_fiscal}
          Error={Errores.regimen_fiscal}
          handlInputChange={handlInputChange}
        />
      </Col>
      <Col sm={12} md={4} className="p-3">
        <TextField
          fullWidth
          name="rfc"
          label="RFC"
          variant="outlined"
          //helperText={Errores.correo ? "Correo incorrecto" : ""}
          value={Values.rfc}
          onChange={handlInputChange}
        />
      </Col>

      <Col sm={12} md={4} className="p-3">
        <TextField
          fullWidth
          name="direccion"
          label="Direccion"
          variant="outlined"
          //inputProps={{ maxLength: 10 }}
          //helperText={Errores.direccion}
          value={Values.direccion}
          onChange={handlInputChange}
        />
      </Col>
      <Col sm={12} md={4} className="p-3">
        <TextField
          fullWidth
          name="cp"
          label="CP"
          variant="outlined"
          //inputProps={{ maxLength: 10 }}
          //helperText={Errores.telefono ? "Teléfono inválido" : ""}
          value={Values.cp}
          error={Errores.cp}
          onChange={handlInputChange}
        />
      </Col>
      <Col sm={12} md={4} className="p-3">
        <TextField
          fullWidth
          name="correo"
          label="Correo"
          variant="outlined"
          //inputProps={{ maxLength: 10 }}
          //helperText={Errores.telefono ? "Teléfono inválido" : ""}
          error={Errores.correo}
          value={Values.correo}
          onChange={handlInputChange}
        />
      </Col>
      <Col sm={12} md={4} className="p-3">
        <TextField
          fullWidth
          name="telefono"
          label="Telefono"
          variant="outlined"
          inputProps={{ maxLength: 10 }}
          helperText={Errores.telefono ? "Teléfono inválido" : ""}
          value={Values.telefono == 0 ? "" : Values.telefono}
          onChange={handlInputChange}
        />
      </Col>

      <Divider />

      <Col sm={12} md={4} className="p-3">
        <TextField
          fullWidth
          name="facebook"
          label="Facebook"
          variant="outlined"
          inputProps={{ maxLength: 10 }}
          helperText={Errores.facebook ? "Facebook inválido" : ""}
          value={Values.facebook == 0 ? "" : Values.facebook}
          onChange={handlInputChange}
        />
      </Col>

      <Col sm={12} md={4} className="p-3">
        <TextField
          fullWidth
          name="youtube"
          label="Youtube"
          variant="outlined"
          helperText={Errores.youtube ? "Youtube inválido" : ""}
          value={Values.youtube == 0 ? "" : Values.youtube}
          onChange={handlInputChange}
        />
      </Col>

      <Col sm={12} md={4} className="p-3">
        <TextField
          fullWidth
          name="instagram"
          label="Instagram"
          variant="outlined"
          helperText={Errores.instagram ? "Instagram inválido" : ""}
          value={Values.instagram == 0 ? "" : Values.instagram}
          onChange={handlInputChange}
        />
      </Col>

    </Row>
  );
};
