import {
  Alert,
  Button,
  Card,
  CardContent,
  Snackbar,
  Stack,
  TextField,
  Tabs,
  Box,
  Tab,
  TextareaAutosize,
} from "@mui/material";

import React, { useState, useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useNavigate } from "react-router-dom";

import Loading from "../../../Includes/Loading";
import LoadingButton from "@mui/lab/LoadingButton";
import { styled } from "@mui/material/styles";
import { useParams } from "react-router-dom";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";

import { GetDate } from "../../../Lib/Funciones Generales/ObtenerFecha";
import "../../../Lib/Funciones Generales/Prototipos";
import RepHorarios from "./Horarios/componentes/RepHorarios";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ModalCargarImagen from "../../../Lib/Imagen/Componentes/ModalCargarImagen";
import ModalCargarMultImagen from "../../../Lib/Imagen/Componentes/ModalCargarMultImagen";
import { getListaTemplateHorario } from "./Horarios/funciones/Horarios";
import AccEditarImagen from "./../../../assets/EditarImagen.svg";
import { Guardar, getInfoSucursal } from "../Funciones/Sucursales";
import { IMG } from "../../../Lib/Funciones Generales/Constantes";
import StandardImageList from "./ImageList";
import logo from "../../../assets/logo.png";

import MyLocationIcon from '@mui/icons-material/MyLocation';
import Mapa from "../../../Lib/MapaReact/Funciones/Mapa";
import { GoogleMap, Marker } from "@react-google-maps/api";

const MySwal = withReactContent(Swal);
let i = 0;

const containerStyle = {
  width: '100%',
  height: '30rem'
};

const Sucursal = () => {
  const [fecha_evento, setFechaEvento] = useState(GetDate());
  //Eventos del formulario
  const [tab, setTab] = React.useState(0);
  const handleChangeTab = (event, newValue) => {
    setTab(newValue);
  };

  const { id } = useParams();
  const navigate = useNavigate();
  const [IsLoading, setIsLoading] = useState(false);
  const [IsGuardando, setIsGuardando] = useState(false);
  const [open, setOpen] = useState(false);
  const [mensaje, setMensaje] = useState("");
  const [tamanioActual, setTamanioActual] = useState(0);

  const [Values, SetValues] = useState({
    id: id ?? "0",
    nombre: "",
    direccion: "",
    telefono: "",
    whatsapp: "",
    informacion: "",
    calificacion: 5,
    horarios: [],
    galeriaImg: [],
  });

  const [Errores, setErrores] = useState({
    nombre: false,
    direccion: false,
    telefono: false,
    whatsapp: false,
    informacion: false,
  });

  const [Horarios, SetHorarios] = useState([]);

  const [imagenes, setImagenes] = useState([]);

  // Definicion de variables para la carga de imagenes
  const [openModal, setOpenModal] = React.useState(false);
  const handleOpenModal = () => setOpenModal(true);
  const handleCloseModal = () => setOpenModal(false);



  const fnDevolverImagen = (img) => {
    let random = "";
    if (Values.id != "0" && Values.id != 0) {
      random = "?" + Math.floor(Math.random() * 1000000);
    }
    let newData = imagenes.filter((item) => {
      return item;
    });
    //console.log(img);
    img.filter((item) => {
      //console.log(item);
      i = i + 1;
      const status = newData.length > 0 ? false : true;
      if (newData.length + 1 <= 10) {
        newData.push({
          //ID: i,
          ID: item.hasOwnProperty("IMG") ? item.ID : i,
          IMG: item.hasOwnProperty("IMG") ? item.IMG : item,
          ID_EMPRESA: i,
          ID_SUCURSAL: id,
          PRINCIPAL: status,
        });
      }
    });
    setImagenes(newData);
    setTamanioActual(imagenes.length);
    SetValues({ ...Values, galeriaImg: imagenes });
  };

  const getTotalImgs = () => {
    return Array.isArray(Values.galeriaImg) ? Values.galeriaImg.length : 0
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handlInputChange = ({ target }) => {
    const Name = target.name;
    const Value = target.value;
    console.log("Name: ", Name, " Value: ", Value);
    let NewValue = {
      ...Values,
      [Name]: Value,
    };
    if (Name == "whatsapp" || Name == "telefono") {
      NewValue = { ...NewValue, [Name]: Value.toNumber() };
    }
    SetValues(NewValue);
    setErrores({
      ...Errores,
      [Name]: false,
    });
  };

  useEffect(() => {
    setIsLoading(true);

    if (id != undefined) {
      getInfoSucursal(id)
        .then((resp) => {
          //console.log("respuestaSucursalByID: ", resp);
          if (resp?.err?.length == 0) {
            MySwal.fire({
              title: "Error",
              html: resp.mensaje,
              icon: "error",
              confirmButtonColor: "#3ABE88",
              showConfirmButton: true,
              allowEscapeKey: false,
              allowEnterKey: false,
              allowOutsideClick: false,
            }).then(() => {
              setIsLoading(false);
              navigate(-1);
            });
          } else {
            SetValues(resp.data);


            if (resp.ubicacion.lat != 0 && resp.ubicacion.lng != 0) {
              setListaPuntos([{ ...resp.ubicacion }])
            }

            const objHor = getListaTemplateHorario()[0];
            if (resp.horarios.length != 0) {
              const newHorariosArr = resp.horarios.filter((element) => {
                const newObj = Object.assign(objHor, element);
                return newObj;
              });
              SetHorarios(newHorariosArr);
            } else {
              let newHorariosArr = [];
              for (let h = 0; h < 7; h++) {
                const horario = getListaTemplateHorario()[0];
                newHorariosArr.push(horario);
              }
              SetHorarios(newHorariosArr);
            }


            const filteredLibraries = resp.galeriaImg.filter((item) => {
              if (item.IMG == resp.data.img) {
                item.PRINCIPAL = true;
              } else {
                item.PRINCIPAL = false;
              }
              return item;
            });
            setImagenes(filteredLibraries);


            setIsLoading(false);
          }
        })
        .catch((resp) => {
          console.log(resp)
          MySwal.fire({
            title: "Error",
            html: resp.mensaje,
            icon: "error",
            confirmButtonColor: "#3ABE88",
            showConfirmButton: true,
            allowEscapeKey: false,
            allowEnterKey: false,
            allowOutsideClick: false,
          }).then(() => {
            setIsLoading(false);
            navigate(-1);
          });
        });
    } else {
      setIsLoading(false);
      const newArr = [];
      for (let it = 0; it < 7; it++) {
        const objHor = getListaTemplateHorario()[0];
        ///console.log(objHor);
        newArr.push(objHor);
      }
      SetHorarios(newArr);
    }
  }, []);

  useEffect(() => {
    SetValues({
      ...Values,
      horarios: Horarios,
      galeriaImg: imagenes,
    });
  }, [Horarios, imagenes]);

  const GuardarEvento = () => {
    //console.log(imagenes);

    /////////////////////////
    setIsGuardando(true);
    Guardar(Values, Errores, setErrores, ListaPuntos)
      .then((data) => {
        if (data.codigo == "200") {
          setIsGuardando(false);
          MySwal.fire({
            title: "Correcto",
            html: data.mensaje,
            icon: "success",
            confirmButtoColor: "#3ABE88",
            showConfirmButton: false,
            timer: 1700,
          }).then((result) => {
            navigate(-1);
            /*if (window.location.href.includes("add")) {
              window.location.href = "../eventos";
            } else if (window.location.href.includes("edit")) {
              window.location.href = "../";
            }*/
          });
        } else {
          setMensaje(data.mensaje);
          setOpen(true);
          setIsGuardando(false);
        }
      })
      .catch((data) => {
        setMensaje(data.mensaje);
        setOpen(true);
        setIsGuardando(false);
      });
  };




  /*
   * --- Inicio mapa ---
   */
  const zoomMap = 13
  const [ListaPuntos, setListaPuntos] = useState([]);

  const obtenerPointByAddress = (lat, lng) => {
    if (lat != 0 && lng != 0) {
      actualizarMarker(0, lat, lng);
      moverCamera(lat, lng)
    }
  };
  const clickMapa = (lat, lng) => {
    actualizarMarker(0, lat, lng)
  }
  const moverMarker = (marker, index) => {
    const { lat, lng } = marker.latLng
    actualizarMarker(index, lat(), lng())
  }
  const {
    onLoad,
    onUnmount,
    onMapClick,
    actualizarMarker,
    buscarAddress,
    moverCamera,
    isLoaded,
    Zoom,
    Map,
    ZoomInit
  } = Mapa({ clickMapa, obtenerPointByAddress, zoomMap, ListaPuntos, setListaPuntos })
  /*
   *  --- Fin mapa ----
   */



  const StyledSnackbar = styled((props) => <Snackbar {...props} />)(
    ({ theme }) => ({
      "& .MuiSnackbar-root": {
        top: theme.spacing(15),
      },
    })
  );

  useEffect(() => {
    document.title = `Sucursales`;
  }, []);

  useEffect(() => {
    if (ZoomInit && Map) {
      if (ListaPuntos) {
        if (ListaPuntos.length > 0) {
          moverCamera(ListaPuntos[0].lat, ListaPuntos[0].lng)

        }
      }
    }
  }, [ZoomInit, Map])

  return (
    <>
      <StyledSnackbar
        direction="right"
        open={open}
        autoHideDuration={6000}
        onClose={handleClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      >
        <Alert onClose={handleClose} severity="error" sx={{ width: "100%" }}>
          {mensaje}
        </Alert>
      </StyledSnackbar>
      <Button
        onClick={() => navigate(-1)}
        className="btn  font-AvenirBold my-4 text-star ps-0 pe-4  "
        variant="text"
      >
        <ArrowBackIcon className="me-3" />
        Sucursales
      </Button>

      <Box className=" mb-2">
        <Tabs
          TabIndicatorProps={{
            bgcolor: "#3abe88",
            style: {
              backgroundColor: "#3abe88",
            },
          }}
          value={tab}
          onChange={handleChangeTab}
          variant="scrollable"
          scrollButtons="auto"
          textColor="#212529"
          indicatorColor=""
          aria-label="scrollable auto tabs example"
        >
          <Tab label="Datos generales" className="font-AvenirBold" />
          <Tab label="Horarios" className="font-AvenirBold" />
          <Tab label="Galería de imágenes" className="font-AvenirBold" />
          <Tab label="Ubicación" className="font-AvenirBold" />
          <Tab label="Información" className="font-AvenirBold" />
        </Tabs>
      </Box>

      <Card elevation={3} className="mb-4">
        <CardContent className="p-0">
          <div className="p-3">
            <h6 className="mb-0 text-left font-AvenirBold">
              {id ? "Editar sucursal" : "Nueva sucursal"}
            </h6>
          </div>
          <hr
            style={{ backgroundColor: "#DFDEE0", height: "1px", opacity: "1" }}
            className="m-0 p-0"
          />

          {IsLoading ? (
            <Row className="px-3">
              <Col sm={12} className="p-3">
                <Loading />
              </Col>
            </Row>
          ) : (
            <>
              <Row className="p-3">
                {tab == 0 ? (
                  <>
                    <Col sm={12} md={6} className="p-3">
                      <TextField
                        fullWidth
                        inputProps={{ maxLength: 150 }}
                        name="nombre"
                        label="Nombre de la sucursal"
                        variant="outlined"
                        value={Values.nombre}
                        error={Errores.nombre}
                        helperText={
                          Errores.nombre
                            ? "Nombre de la sucursal no valido"
                            : ""
                        }
                        onChange={handlInputChange}
                      />
                    </Col>



                    <Col sm={12} md={6} className="p-3">
                      <TextField
                        fullWidth
                        name="telefono"
                        label="Teléfono fijo"
                        variant="outlined"
                        inputProps={{ maxLength: 10 }}
                        helperText={
                          Errores?.telefono ? "Teléfono inválido" : ""
                        }
                        value={Values.telefono}
                        error={Errores.telefono}
                        //type="number"
                        onChange={handlInputChange}
                      />
                    </Col>

                    <Col sm={12} md={6} className="p-3">
                      <TextField
                        fullWidth
                        name="whatsapp"
                        label="WhatsApp"
                        variant="outlined"
                        inputProps={{ maxLength: 10 }}
                        helperText={
                          Errores?.whatsapp ? "WhatsApp inválido" : ""
                        }
                        value={Values.whatsapp}
                        error={Errores.whatsapp}
                        //type="number"
                        onChange={handlInputChange}
                      />
                    </Col>
                  </>
                ) : tab == 1 ? (
                  <RepHorarios Horarios={Horarios} SetHorarios={SetHorarios} />
                ) : tab == 2 ? (
                  <Col
                    sm={12}
                    md={12}
                    lg={12}
                    xl={12}
                    className="d-flex flex-wrap align-items-center justify-content-center"
                    style={
                      {
                        //background: "blue",
                        //color: "blue"
                      }
                    }
                  >
                    <StandardImageList
                      id={Values.id}
                      logo={logo}
                      openModal={openModal}
                      setOpenModal={setOpenModal}
                      imagenes={imagenes}
                      setImagenes={setImagenes}
                    />

                    {
                      openModal && (
                        <ModalCargarMultImagen
                          id={Values.id}
                          tipo={IMG.TIPO_SUCURSALES}
                          Titulo="Cargar imagen de sucursal"
                          mostrar={openModal}
                          fnCerrar={handleCloseModal}
                          fnDevolverImagen={fnDevolverImagen}
                          imgDefault={true}
                          getTotalImgs={getTotalImgs}
                        />
                      )
                    }

                  </Col>
                ) : tab == 3 ? (


                  isLoaded && (

                    <Row className="p-3">
                      <Col sm={9} className="p-3">
                        <TextField
                          multiline
                          fullWidth
                          name="direccion"
                          label="Dirección"
                          maxRows={3}
                          inputProps={{ maxLength: 249 }}
                          value={Values.direccion}
                          error={Errores.direccion}
                          placeholder="Dirección"
                          helperText={Errores.direccion ? "Direccion no valida" : ""}
                          onChange={handlInputChange}
                        />
                      </Col>
                      <Col sm={3} className="p-3 d-flex">
                        <Button
                          onClick={() => buscarAddress(Values.direccion)}
                          className="btn btn-Kalendar font-AvenirMedium px-4 py-2 my-auto"
                          variant="contained"
                        >
                          <MyLocationIcon className="me-2" /> Localizar
                        </Button>
                      </Col>
                      <Col sm={12} className="p-0">
                        {
                          isLoaded && (
                            <GoogleMap
                              mapContainerStyle={containerStyle}
                              onLoad={onLoad}
                              onUnmount={onUnmount}
                              onClick={onMapClick}
                              zoom={Zoom}
                            >
                              {
                                ZoomInit && Map && (
                                  <>
                                    {
                                      ListaPuntos.map((punto, index) => (
                                        <Marker
                                          onDragEnd={marker => moverMarker(marker, index)}
                                          draggable={true}
                                          position={{
                                            lat: punto.lat,
                                            lng: punto.lng,
                                          }}
                                        />
                                      ))
                                    }

                                  </>
                                )
                              }





                            </GoogleMap>
                          )
                        }
                      </Col>
                    </Row>
                  )

                ) : (
                  <TextField
                    name="informacion"
                    placeholder="Información de la sucursal"
                    label="Información"
                    value={Values.informacion}
                    onChange={handlInputChange}
                    className="form-control"
                    multiline
                    rows={6}
                  />
                )}
              </Row>
            </>
          )}
          <Stack
            className="p-3"
            spacing={2}
            direction={{ xs: "column", sm: "row" }}
            style={{}}
          >
            <LoadingButton
              loading={IsGuardando}
              loadingPosition="start"
              disabled={IsLoading}
              onClick={() => GuardarEvento()}
              className="btn btn-Kalendar font-AvenirMedium  py-2 px-4 "
              variant="contained"
              xs={{ with: "100$" }}
            >
              <span className={IsGuardando ? "px-4" : "px-2"}>
                {IsGuardando ? "Guardando..." : id ? "Guardar" : "Crear"}
              </span>
            </LoadingButton>
            <Button
              onClick={() => navigate(-1)}
              className="btn btn-cancelar font-AvenirMedium py-2 px-4"
              variant="outlined"
            >
              Cancelar
            </Button>

            <Button
              onClick={() => {
                const nextTab = tab - 1 < 0 ? 0 : tab - 1;
                setTab(nextTab);
              }}
              className="btn btn-exp-Excel font-AvenirMedium py-2 px-4 ms-auto"
              variant="text"
            >
              <ArrowBackIosNewIcon style={{ fontSize: "20px" }} />
              Anterior
            </Button>

            <Button
              onClick={() => {
                const nextTab = tab + 1 > 3 ? 3 : tab + 1;
                setTab(nextTab);
              }}
              className="btn btn-exp-Excel font-AvenirMedium py-2 px-4 ms-n2"
              variant="text"
            >
              Siguiente
              <ArrowForwardIosIcon style={{ fontSize: "20px" }} />
            </Button>
          </Stack>
        </CardContent>
      </Card>
    </>
  );
};

export default Sucursal;
