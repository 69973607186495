import { stringify } from "query-string";
import { getData, postdData, postUrl } from "../../../Context/backend";

export const getComisiones = async (id, fechaInicio , fechaFin) => {
  let data = [];
  let queryParamsObj = {
    id_cliente: id,
    fecha_inicio: fechaInicio,
    fecha_fin: fechaFin,   
  }; 
  const url = `clientes/getReservas4Cliente?${stringify(queryParamsObj)}`;
  const res = await getData(url);
  return new Promise((resolve, reject) => {
    if (!res.error) {
      data = JSON.parse(res.data.respuesta.data); 
      resolve(data);
    } else {
      reject({
        mensaje: "Error al conectar a internet, revisa tu conexion a internet",
      });
    }
  });
};