import React, { useState, useEffect } from "react";
import {
  DialogContent,
  Button,
  TextField,
  Card,
  Avatar,
  Grid,
  Typography,
  Snackbar,
  Alert,
} from "@mui/material";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

import Efectivo from "./../../assets/Efectivo.svg";
import Monedero from "./../../assets/Monedero.svg";
import Otros from "./../../assets/Otro.svg";
import Tarjeta from "./../../assets/Tarjeta.svg";
import Cita from "../../assets/Cita.svg";
import Carrito from "./carrito";
import { LoadingButton } from "@mui/lab";
import { pagarReservacion } from "../Funciones/Reservaciones";
import { styled } from "@mui/material/styles";

const MySwal = withReactContent(Swal);
export default function PagoReservacion({
  productosCarrito,
  setShowModalPago,
  totalReserva,
  idReserv,
  descuento,
  getInfo,
  folioReservacion,
  setFolioReservacion = () => {},
}) {
  const [IsGuardando, setIsGuardando] = useState(false);
  const [mensaje, setMensaje] = useState("");
  const [openAlert, setOpenAlert] = useState(false);

  const [formasPago, setFormasPago] = useState({
    efectivo: { active: false, monto: 0 },
    tarjeta: { active: false, monto: 0 },
    monedero: { active: false, monto: 0 },
    otros: { active: false, monto: 0 },
    incentivo: { active: false, monto: 0 },
  });

  let cambio = 0;

  let Total = parseFloat(totalReserva); // - descuento.descuento;
  let efectivo =
    formasPago.efectivo.monto == "" || formasPago.efectivo.monto == undefined
      ? 0
      : parseFloat(formasPago.efectivo.monto);
  let tarjeta =
    formasPago.tarjeta.monto == "" || formasPago.tarjeta.monto == undefined
      ? 0
      : parseFloat(formasPago.tarjeta.monto);
  let monedero =
    formasPago.monedero.monto == "" || formasPago.monedero.monto == undefined
      ? 0
      : parseFloat(formasPago.monedero.monto);
  let otros =
    formasPago.otros.monto == "" || formasPago.otros.monto == undefined
      ? 0
      : parseFloat(formasPago.otros.monto);

  let TotalPagado = efectivo + tarjeta + monedero + otros;

  if (formasPago.efectivo.monto != 0) {
    cambio = TotalPagado - Total;
  }

  const setForma = (tipo, active, monto) => {
    const Value = { active: active, monto: monto };
    let NewValue = {
      ...formasPago,
      [tipo]: Value,
    };
    setFormasPago(NewValue);
  };

  const GuardarReserva = () => {
    setIsGuardando(true);
    // alert("Guardando")
    pagarReservacion(idReserv, formasPago, TotalPagado, totalReserva, cambio)
      .then((data) => {
        if (data.codigo == "200") {
          setIsGuardando(false);
          MySwal.fire({
            title: "Correcto",
            html: data.mensaje,
            icon: "success",
            confirmButtoColor: "#3ABE88",
            showConfirmButton: false,
            timer: 1700,
          }).then((result) => {
            getInfo();
            setShowModalPago(false);
          });
        } else {
          setMensaje(data.mensaje);
          setOpenAlert(true);
          setIsGuardando(false);
        }
      })
      .catch((data) => {
        setMensaje(data.mensaje);
        setOpenAlert(true);
        setIsGuardando(false);
      });
  };

  const StyledSnackbar = styled((props) => <Snackbar {...props} />)(
    ({ theme }) => ({
      "& .MuiSnackbar-root": {
        top: theme.spacing(15),
      },
    })
  );

  const handleClose = () => {
    setOpenAlert(false);
  };

  return (
    <>
      <StyledSnackbar
        direction="right"
        open={openAlert}
        autoHideDuration={6000}
        onClose={handleClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      >
        <Alert onClose={handleClose} severity="error" sx={{ width: "100%" }}>
          {mensaje}
        </Alert>
      </StyledSnackbar>

      <DialogContent>
        <Button
          onClick={() => setShowModalPago(false)}
          className="btn  font-AvenirBold my-4 text-star ps-0 pe-4  "
          variant="text"
        >
          <ArrowBackIcon className="me-3" />
          Rgresar a detalle
        </Button>

        <div className="row " style={{ minHeight: "70vh" }}>
          <div className="col-12 col-md-6 p-3 pt-0">
            <h6 className="font-AvenirBold mb-3" style={{ color: "black" }}>
              Resumen
            </h6>

            <div className="d-flex justify-content-start mb-3 ps-0 p-2 align-items-center mt-0">
              <div>
                <Avatar
                  className="shadow"
                  sx={{ width: 50, height: 50 }}
                  src={Cita}
                />
              </div>
              <div>
                <Grid style={{ marginLeft: "20px" }}>
                  <h6
                    className="font-AvenirBold mb-0"
                    style={{ color: "black" }}
                  >
                    Número de reservación
                  </h6>
                  <Typography className="font-Avenir">
                    {folioReservacion.toString().padStart(5, "0")}
                  </Typography>
                </Grid>
              </div>
            </div>

            <Carrito
              productosCarrito={productosCarrito}
              descuento={descuento}
              cambio={cambio}
              isVista
            />
          </div>
          <div className="col-12 col-md-6 p-3  ">
            <div style={{ maxWidth: "500_" }}>
              <h6 className="font-AvenirBold mb-3" style={{ color: "black" }}>
                Formas de pago
              </h6>

              <div className="mt-0 d-flex justify-content-between mt-3">
                <div className="flex-grow-1">
                  <Card
                    sx={{ width: "100%" }}
                    className={
                      "card-descuento" +
                      (formasPago.efectivo.active
                        ? " card-descuento-active "
                        : "")
                    }
                    onClick={() =>
                      setForma("efectivo", !formasPago.efectivo.active, 0)
                    }
                  >
                    <div className="d-flex p-2 align-content-center align-items-center">
                      <div className="pe-2">
                        <img
                          src={Efectivo}
                          className="img-fluid"
                          style={{ maxWidth: "40px" }}
                        />
                      </div>
                      <div className="">
                        <h6 className="font-AvenirBold parrafo2lineastruncate text-center my-0 ">
                          Efectivo
                        </h6>
                      </div>
                    </div>
                  </Card>
                </div>
                <div className="ms-3" style={{ width: "150px" }}>
                  <TextField
                    id="outlined-basic"
                    label="Efectivo"
                    fullWidth
                    variant="outlined"
                    size="small"
                    disabled={!formasPago.efectivo.active}
                    value={formasPago.efectivo.monto}
                    onChange={(event) => {
                      setForma("efectivo", true, event.target.value.toNumber());
                    }}
                  />
                </div>
              </div>
              <div className="mt-3 d-flex justify-content-between">
                <div className="flex-grow-1">
                  <Card
                    sx={{ width: "100%" }}
                    className={
                      "card-descuento" +
                      (formasPago.tarjeta.active
                        ? " card-descuento-active "
                        : "")
                    }
                    onClick={() =>
                      setForma("tarjeta", !formasPago.tarjeta.active, 0)
                    }
                  >
                    <div className="d-flex p-2 align-content-center align-items-center">
                      <div className="pe-2">
                        <img
                          src={Tarjeta}
                          className="img-fluid"
                          style={{ maxWidth: "40px" }}
                        />
                      </div>
                      <div className="">
                        <h6 className="font-AvenirBold parrafo2lineastruncate text-center my-0 ">
                          Tarjeta de Cred / Deb
                        </h6>
                      </div>
                    </div>
                  </Card>
                </div>
                <div className="ms-3" style={{ width: "150px" }}>
                  <TextField
                    id="outlined-basic"
                    label="Tarjeta"
                    fullWidth
                    size="small"
                    variant="outlined"
                    disabled={!formasPago.tarjeta.active}
                    value={formasPago.tarjeta.monto}
                    onChange={(event) => {
                      setForma("tarjeta", true, event.target.value.toNumber());
                    }}
                  />
                </div>
              </div>
              <div className="mt-3 d-flex justify-content-between">
                <div className="flex-grow-1">
                  <Card
                    sx={{ width: "100%" }}
                    className={
                      "card-descuento" +
                      (formasPago.monedero.active
                        ? " card-descuento-active "
                        : "")
                    }
                    onClick={() =>
                      setForma("monedero", !formasPago.monedero.active, 0)
                    }
                  >
                    <div className="d-flex p-2 align-content-center align-items-center">
                      <div className="pe-2">
                        <img
                          src={Monedero}
                          className="img-fluid"
                          style={{ maxWidth: "40px" }}
                        />
                      </div>
                      <div className="">
                        <h6 className="font-AvenirBold parrafo2lineastruncate text-center my-0 ">
                          Monedero electronico
                        </h6>
                      </div>
                    </div>
                  </Card>
                </div>
                <div className="ms-3" style={{ width: "150px" }}>
                  <TextField
                    id="outlined-basic"
                    label="Monedero"
                    fullWidth
                    size="small"
                    variant="outlined"
                    disabled={!formasPago.monedero.active}
                    value={formasPago.monedero.monto}
                    onChange={(event) => {
                      setForma("monedero", true, event.target.value.toNumber());
                    }}
                  />
                </div>
              </div>
              <div className="mt-3 d-flex justify-content-between">
                <div className="flex-grow-1">
                  <Card
                    sx={{ width: "100%" }}
                    className={
                      "card-descuento" +
                      (formasPago.otros.active ? " card-descuento-active " : "")
                    }
                    onClick={() =>
                      setForma("otros", !formasPago.otros.active, 0)
                    }
                  >
                    <div className="d-flex p-2 align-content-center align-items-center">
                      <div className="pe-2">
                        <img
                          src={Otros}
                          className="img-fluid"
                          style={{ maxWidth: "40px" }}
                        />
                      </div>
                      <div className="">
                        <h6 className="font-AvenirBold parrafo2lineastruncate text-center my-0 ">
                          Otro
                        </h6>
                      </div>
                    </div>
                  </Card>
                </div>
                <div className="ms-3" style={{ width: "150px" }}>
                  <TextField
                    id="outlined-basic"
                    label="Otro"
                    fullWidth
                    size="small"
                    variant="outlined"
                    disabled={!formasPago.otros.active}
                    value={formasPago.otros.monto}
                    onChange={(event) => {
                      setForma("otros", true, event.target.value.toNumber());
                    }}
                  />
                </div>
              </div>

              <div className="mt-3 d-flex justify-content-between">
                <div className="flex-grow-1">
                  <Card
                    sx={{ width: "100%" }}
                    className={
                      "card-descuento" +
                      (formasPago.incentivo.active
                        ? " card-descuento-active "
                        : "")
                    }
                    onClick={() =>
                      setForma("incentivo", !formasPago.incentivo.active, 0)
                    }
                  >
                    <div className="d-flex p-2 align-content-center align-items-center">
                      <div className="pe-2">
                        <img
                          src={Otros}
                          className="img-fluid"
                          style={{ maxWidth: "40px" }}
                        />
                      </div>
                      <div className="">
                        <h6 className="font-AvenirBold parrafo2lineastruncate text-center my-0 ">
                          Incentivo
                        </h6>
                      </div>
                    </div>
                  </Card>
                </div>
                <div className="ms-3" style={{ width: "150px" }}>
                  <TextField
                    id="outlined-basic"
                    label="Incentivo"
                    fullWidth
                    size="small"
                    variant="outlined"
                    disabled={!formasPago.incentivo.active}
                    value={formasPago.incentivo.monto}
                    onChange={(event) => {
                      setForma(
                        "incentivo",
                        true,
                        event.target.value.toNumber()
                      );
                    }}
                  />
                </div>
              </div>
            </div>

            <div className="d-flex justify-content-end mt-5">
              <Button
                onClick={() => setShowModalPago(false)}
                className="btn btn-cancelar font-AvenirMedium py-2 px-4 mx-2 me-sm-0  "
                variant="outlined"
              >
                Cancelar
              </Button>
              <LoadingButton
                loading={IsGuardando}
                loadingPosition="start"
                onClick={() => GuardarReserva()}
                className="btn btn-Kalendar font-AvenirMedium  py-2 px-4  ms-3 "
                variant="contained"
              >
                <span className={IsGuardando ? "px-4" : "px-2"}>
                  {IsGuardando ? "Guardando..." : "Pagar"}
                </span>
              </LoadingButton>
            </div>
          </div>
        </div>
      </DialogContent>
    </>
  );
}
