const DashboardLateral = ({ color }) => {
    return (
        <svg width="20px" height="22px" viewBox="0 0 20 22" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
            <title>1.1 DashboardLateralON</title>
            <g id="Michelle-Pourroy-MAnager" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g id="1.1-Dashboard" transform="translate(-32.000000, -83.000000)" stroke={color} strokeWidth="1.5">
                    <g id="1" transform="translate(0.000000, 64.000000)">
                        <g id="1.1-DashboardLateralON" transform="translate(32.000000, 19.000000)">
                            <rect id="Rectangle" x="6.75" y="12.75" width="6.5" height="8.5" rx="2"></rect>
                            <path d="M10,1.0090218 L19.25,9.3340218 L19.25,20 C19.25,20.4166667 19.1851521,20.7541877 18.9696699,20.9696699 C18.7541877,21.1851521 18.4166667,21.25 18,21.25 L18,21.25 L2,21.25 C1.58333333,21.25 1.24581229,21.1851521 1.03033009,20.9696699 C0.814847883,20.7541877 0.75,20.4166667 0.75,20 L0.75,20 L0.75,9.3340218 L10,1.0090218 Z" id="Path-6"></path>
                        </g>
                    </g>
                </g>
            </g>
        </svg>
    )
}

export default DashboardLateral
