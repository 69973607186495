import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { TimePicker } from "@mui/x-date-pickers";
import { GetDate } from "../Lib/Funciones Generales/ObtenerFecha";

import "dayjs/locale/es-mx";
import dayjs from "dayjs";
import TextField from "@mui/material/TextField";
import { useEffect, useState } from "react";

export const BasicTimePicker = ({
  hora,
  setTime,
  label,
  format = "HH:mm",
  size = "small",
  disabledd = false,
}) => {
  const [Value, setValue] = useState();
  useEffect(() => {
    //console.log(GetDate());
    if (!hora) {
      //console.log(dayjs().hour());
      //console.log(dayjs().minute());
      hora = String(dayjs().hour() + ":" + dayjs().minute());
      //console.log(hora);
      //console.log(dayjs(GetDate() + "T" + hora));
      setValue(dayjs(GetDate() + "T" + hora));
    } else {
      setValue(dayjs(GetDate() + "T" + hora));
    }
  }, [hora]);

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="es-MX">
      <TimePicker    
        disabled={disabledd}
        ampm={false}
        orientation="landscape"
        label={label}
        //value={dayjs(GetDate() + "T" + hora)}
        value={Value}
        inputFormat={format}
        onChange={setTime}
        renderInput={(params) => (
          <TextField
            {...params}
            size={size}
            inputProps={{ ...params.inputProps, readOnly: true }}
          />
        )}
      />
    </LocalizationProvider>
  );
};
