
const LicenciaIcono = ({ color, size = '44px', className = "" }) => {
    return (
        <svg className={className} width={size} height={size} viewBox="0 0 44 44" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
            <title>4.3LicenciaBasico</title>
            <g id="MANAGER" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g id="4.3LicenciaBasico" transform="translate(0.000000, 0.000000)" fill={color}>
                    <path d="M13,39.2600272 L15.0793162,41.339842 C18.6253433,44.8867193 24.3746567,44.8867193 27.9206838,41.339842 L30,39.2600272 L23.6195662,32.8780635 C22.4490956,31.7073122 20.5517094,31.7073122 19.3812388,32.8780635 L13,39.2600272 Z" id="Fill-1"></path>
                    <path d="M22,25.7188713 L22.0007989,25.7188713 C22.8947218,25.7188713 23.7343224,26.062677 24.3662197,26.6857757 L33.8247076,36 L36,33.8569181 L23.6128959,21.6577153 C23.1815121,21.233662 22.6087304,21 22,21 C21.3904708,21 20.817689,21.233662 20.3871041,21.6577153 L8,33.8569181 L10.1752924,36 L19.635378,26.6842022 C20.2664765,26.0618903 21.106077,25.7188713 22,25.7188713" id="Fill-3"></path>
                    <path d="M30.0451858,9.28734402 L43.9124015,23 C44.2893016,20.3327966 43.4510375,17.5308542 41.3768915,15.479826 L28.3551114,2.60318262 C24.8450795,-0.867727541 19.1541266,-0.867727541 15.6448915,2.60318262 L2.62231462,15.479826 C0.548965473,17.5308542 -0.289298671,20.3327966 0.0876014624,23 L13.9548171,9.28734402 C18.3907644,4.90162643 25.6092386,4.90005053 30.0451858,9.28734402" id="Fill-5"></path>
                    <path d="M4.46218301,31 L16.9543779,18.3626732 C18.3022942,16.999185 20.0941447,16.2485738 21.9995972,16.2485738 C23.9058553,16.2485738 25.6977058,16.999185 27.0448164,18.3626732 L39.537817,31 L41.5923218,28.9209454 C41.7373457,28.7750611 41.8670615,28.6193969 42,28.4653627 C41.8984833,28.3480033 41.7977723,28.2290139 41.687393,28.1165444 L26.7169013,12.9731051 C25.4165206,11.6585167 23.7084617,11 22.0004028,11 C20.2923439,11 18.5834794,11.6585167 17.2830987,12.9731051 L2.31260701,28.1165444 C2.20222773,28.2290139 2.10151671,28.3480033 2,28.4653627 C2.13293855,28.6193969 2.26265434,28.7750611 2.40767821,28.9209454 L4.46218301,31 Z" id="Fill-8"></path>
                </g>
            </g>
        </svg>
    )
}

export default LicenciaIcono