import FullCalendar from "@fullcalendar/react"; // must go before plugins

import timeGridPlugin from "@fullcalendar/resource-timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import { useState } from "react";
import dayjs from "dayjs";
import { useRef } from "react";
import { useEffect } from "react";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import {
  actualizarHoraReserva,
  eliminarHorarioBloqueado,
  getListaColaboradoresBloqueadosByHorario,
  getListaDiasBloqueados,
  guardarHorarioBloqueado,
} from "../Funciones/Reservaciones";
import PopHover from "./PopHover";
import { Alert, Snackbar, styled } from "@mui/material";
import { Horas } from "../../Lib/Funciones Generales/Constantes";
import { Minutos } from "../../Lib/Funciones Generales/Constantes";
import {
  getOpciones,
  getOpcionesv2,
} from "../../Catalogos/Colaboradores/Funciones/Colaboradores";
import resourceTimeGridPlugin from "@fullcalendar/resource-timegrid";
import ModalBloqueoHorario from "./Dialogos/ModalBloqueoHorario";
import ModalDiaSeleccionado from "./Dialogos/ModalDiaSeleccionado";
import $ from "jquery";

import resourceTimelinePlugin from "@fullcalendar/resource-timeline";

let todayStr = new Date().toISOString().replace(/T.*$/, ""); // YYYY-MM-DD of today
const MySwal = withReactContent(Swal);

const StyledSnackbar = styled((props) => <Snackbar {...props} />)(
  ({ theme }) => ({
    "& .MuiSnackbar-root": {
      top: theme.spacing(15),
    },
  })
);

const FullCalendarApp = ({
  reservaciones,
  idReserv,
  setIdReserv,
  folioReservacion,
  setFolioReservacion = () => {},
  setShowReservacion,
  startDate,
  viewStartDate,
  setViewStartDate,
  viewEndDate,
  setFechaReserva = () => {},
  setViewEndDate,
  setShowModalReserva = () => {},
  idColaborador = 0,
  idSucursal = 0,
  config,
  intervalo,
  getReservaciones = () => {},
  slotLabelInterval,
  colaboradores,
  rango = { inicio: "09:00:00", fin: "23:00:00" },
}) => {
  const calendarRef = useRef(null);

  //#region States
  const [event, setEvent] = useState(null);
  const [position, setPosition] = useState({
    top: 0,
    left: 0,
    width: 0,
    height: 0,
  });

  const [banderaSelectColaboradores, setBanderaSelectColaboradores] =
    useState(false);
  const [selectColaboradores, setSelectColaboradores] = useState([]);
  const [opcColaboradores, setOpcColaboradores] = useState([]);

  const initFechaBloqueo = {
    init: false,
    id: 0,
    fecha_inicio: null,
    fecha_fin: null,
    hora_inicio: "00:00",
    hora_fin: "00:00",
    id_sucursal: idSucursal == "" ? 0 : idSucursal,
    id_colaborador: idColaborador == "" ? 0 : idColaborador,
    motivo_etiqueta: "",
  };
  const [IsGuardando, setIsGuardando] = useState(false);
  const [open, setOpen] = useState(false);
  const [mensaje, setMensaje] = useState("");
  const [showModalBloquearHorario, setShowModalBloquearHorario] =
    useState(false);
  const [selectedRange, setSelectedRange] = useState(null);
  const [diasBloqueados, setDiasBloqueados] = useState({
    init: false,
    lista: [],
  });
  const [valuesFechaBloqueo, setValuesFechaBloqueo] =
    useState(initFechaBloqueo);
  const [errorFechaBloqueo, setErrorFechaBloqueo] = useState({
    motivo_etiqueta: false,
    id_colaborador: false,
    id_sucursal: false,
  });
  const [Reservaciones, setReservaciones] = useState([]);

  //#endregion

  //#region Handles

  const handlInputChange = ({ target }) => {
    setValuesFechaBloqueo({
      ...valuesFechaBloqueo,
      [target.name]: target.value,
    });
  };

  const handleEventMouseEnter = (info) => {
    if (event == null) {
      const eventElement = info.el;
      const eventWidth = eventElement.offsetWidth;
      const eventHeight = eventElement.offsetHeight;

      const boundingClientRect = eventElement.getBoundingClientRect();
      const eventPosition = {
        x: boundingClientRect.left + window.pageXOffset,
        y: boundingClientRect.top + window.pageYOffset,
      };

      let X = 0;
      let Y = 0;
      var windowWidth =
        window.innerWidth || document.documentElement.clientWidth;
      var windowHeight =
        window.innerHeight || document.documentElement.clientHeight;
      if (windowWidth < eventPosition.x + 250) {
        X = eventPosition.x - 200;
      } else {
        X = eventPosition.x;
      }

      if (windowHeight < eventPosition.y + 500) {
        Y = eventPosition.y - 200;
      } else {
        Y = eventPosition.y;
      }
      setPosition({
        top: Y,
        left: X,
        width: eventWidth,
        height: eventHeight,
      });
      setEvent(info.event);
    }
  };

  const handleEventMouseLeave = (info) => {
    if (event != null) {
      setEvent(null);
    }
  };

  const handleDatesSet = (info) => {
    setViewStartDate(dayjs(info.view.activeStart).format("YYYY-MM-DD"));
    setViewEndDate(dayjs(info.view.activeEnd).format("YYYY-MM-DD"));
  };

  function handleSelect(rangeInfo) {
    const { start, end } = rangeInfo;

    let diaInicio = new Intl.DateTimeFormat("en", { day: "2-digit" }).format(
      start
    );
    let diaFin = new Intl.DateTimeFormat("en", { day: "2-digit" }).format(end);

    if (diaInicio == diaFin) {
      setShowModalReserva(false);
      setSelectedRange(rangeInfo); // aqui esta el objeto
      setFechaReserva(rangeInfo.start);
    }
  }

  const handleEventDragStart = (arg) => {
    setEvent(null);
  };
  //#endregion

  //#region Funciones
  const getColaboradores = () => {
    if (
      valuesFechaBloqueo.id_sucursal != "" ||
      valuesFechaBloqueo.id_sucursal != 0
    ) {
      //getOpciones(valuesFechaBloqueo.id_sucursal)
      getOpcionesv2(valuesFechaBloqueo.id_sucursal)
        .then((resp) => {
          let list = JSON.parse(resp.data);
          if (list.length != 0) {
            setOpcColaboradores([{ ID: -1, DESCRIPCION: "Todos" }, ...list]);
          } else {
            setOpcColaboradores([{ ID: 0, DESCRIPCION: "Sin colaboradores" }]);
          }
        })
        .catch((resp) => {
          setOpcColaboradores([{ ID: 0, DESCRIPCION: "Sin colaboradores" }]);
        });
    }
  };

  const getDiasBloqueados = () => {
    getListaDiasBloqueados(idColaborador, idSucursal).then((resp) => {
      let list = JSON.parse(resp.data);
      setDiasBloqueados({ init: true, lista: list });
    });
  };

  const MostrarHorarioBloqueo = (id) => {
    let DiaBloqueado =
      diasBloqueados &&
      diasBloqueados.lista &&
      Array.isArray(diasBloqueados.lista) &&
      diasBloqueados.lista.filter((x) => x.id == id);

    if (DiaBloqueado) {
      DiaBloqueado = DiaBloqueado[0];

      const hora_inicio = DiaBloqueado.start.substring(11, 16);
      const hora_fin = DiaBloqueado.end.substring(11, 16);
      setValuesFechaBloqueo({
        ...valuesFechaBloqueo,
        id: DiaBloqueado.id,
        id_sucursal: DiaBloqueado.id_sucursal,
        fecha_inicio: DiaBloqueado.start,
        fecha_fin: DiaBloqueado.end,
        motivo_etiqueta: DiaBloqueado.title,
        hora_inicio,
        hora_fin,
      });
      setShowModalBloquearHorario(true);

      getListaColaboradoresBloqueadosByHorario(
        DiaBloqueado.id_sucursal,
        DiaBloqueado.id
      ).then((resp) => {
        let data = JSON.parse(resp.data);
        console.log("COLABORADORES: ", data);
        let COLABORADORES_BLOQUEADOS = data.COLABORADORES_BLOQUEADOS.map(
          (colaborador) => {
            return colaborador.ID_COLABORADOR;
          }
        );
        if (data.COLABORADORES_SUCURSAL.length != 0) {
          setOpcColaboradores([
            { ID: -1, DESCRIPCION: "Todos" },
            ...data.COLABORADORES_SUCURSAL,
          ]);
        } else {
          setOpcColaboradores([{ ID: 0, DESCRIPCION: "Sin colaboradores" }]);
        }
        setSelectColaboradores(COLABORADORES_BLOQUEADOS);
      });
    }
  };

  const EliminarHorarioBloqueado = (id) => {
    MySwal.fire({
      title: "¿Estas seguro de eliminar este horario bloqueado?",
      icon: "warning",
      showDenyButton: true,
      denyButtonText: "No, cancelar",
      confirmButtonText: "Si, estoy seguro",
      confirmButtonColor: "#3ABE88",
      denyButtonColor: "#65748B",
      reverseButtons: true,
    }).then((result) => {
      if (result.isConfirmed) {
        eliminarHorarioBloqueado(id).then((data) => {
          if (data.codigo === "200") {
            MySwal.fire({
              title: "Correcto",
              html: data.mensaje,
              icon: "success",
              confirmButtoColor: "#3ABE88",
              showConfirmButton: false,
              timer: 1700,
            });

            setShowModalBloquearHorario(false);
            setValuesFechaBloqueo({ ...initFechaBloqueo });
            setSelectColaboradores([]);
            getDiasBloqueados();
          } else {
            MySwal.fire({
              title: "Error",
              html: data.mensaje,
              icon: "error",
              confirmButtoColor: "#3ABE88",
              showConfirmButton: false,
              timer: 1700,
            });
          }
        });
      } else if (result.isDenied) {
      }
    });
  };

  const GuardarHorarioBloqueado = () => {
    setIsGuardando(true);
    guardarHorarioBloqueado(
      valuesFechaBloqueo,
      selectColaboradores,
      banderaSelectColaboradores,
      errorFechaBloqueo,
      setErrorFechaBloqueo
    )
      .then((data) => {
        if (data.codigo == "200") {
          setIsGuardando(false);
          setOpen(true);
          setMensaje(data.mensaje);
          setSelectedRange(null);
          setShowModalBloquearHorario(false);
          setValuesFechaBloqueo({ ...initFechaBloqueo });
          setSelectColaboradores([]);
          getDiasBloqueados();
        } else {
          setMensaje(data.mensaje);
          setOpen(true);
          setIsGuardando(false);
        }
      })
      .catch((data) => {
        setMensaje(data.mensaje);
        setOpen(true);
        setIsGuardando(false);
      });
  };

  const eventClickFullCalendar = (e) => {
    // console.log("EVENTO CLICK: ", JSON.stringify(e.event, null, 2))
    if (e.event.extendedProps.bloqueado == 1) {
      MostrarHorarioBloqueo(e.event._def.publicId);
      return;
    }

    setEvent(null);
    setIdReserv(e.event._def.publicId);
    //console.log(e.event._def.extendedProps.folio);
    setFolioReservacion(e.event._def.extendedProps.folio);
    setShowReservacion(true);
  };

  const eventDropFullCalendar = ({ event, revert, oldEvent, view }) => {
    // console.log("EVENTO DRAGABLE: ", JSON.stringify(event, null, 2));
    //console.log(event.id);
    //console.log("Actual: ", event._def.resourceIds[0]);
    //console.log("Anterior: ", oldEvent._def.resourceIds[0]);
    if (event.extendedProps.bloqueado == 1 || event.bloqueado == 1) {
      revert();
      return;
    }

    setEvent(null);
    MySwal.fire({
      title: "¿Estas seguro de actualizar esta reservación?",
      icon: "warning",
      showDenyButton: true,
      denyButtonText: "No, cancelar",
      confirmButtonText: "Si, estoy seguro",
      confirmButtonColor: "#3ABE88",
      denyButtonColor: "#65748B",
      reverseButtons: true,
    }).then((result) => {
      if (result.isConfirmed) {
        const fecha = new Date(event.start).toISOString();
        actualizarHoraReserva(event.id, fecha, event._def.resourceIds[0])
          .then((data) => {
            if (data.codigo === "200") {
              MySwal.fire({
                title: "Correcto",
                html: data.mensaje,
                icon: "success",
                confirmButtoColor: "#3ABE88",
                showConfirmButton: false,
                timer: 1700,
              }).then(() => {
                //Reservaciones = [];
                getReservaciones();
              });
            } else {
              MySwal.fire({
                title: "Error",
                html: data.mensaje,
                icon: "error",
                confirmButtoColor: "#3ABE88",
                showConfirmButton: true,
                timer: 5700,
              }).then(() => {
                revert();
              });
            }
          })
          .catch((data) => {
            MySwal.fire({
              title: "Error",
              html: data.mensaje,
              icon: "error",
              confirmButtoColor: "#3ABE88",
              showConfirmButton: true,
              timer: 5700,
            }).then(() => {
              revert();
            });
          });
      } else if (result.isDenied) {
        //alert("OK");
        revert();
      }
    });
  };
  //#endregion

  //#region Effects()

  useEffect(() => {
    let FilteredData = reservaciones.map((row) => {
      return {
        id: row.ID,
        title: row.NOMBRE,
        start: row.FECHA_DE_RESERVACION,
        end: row.FECHA_FIN,
        hora: row.HORA,
        productos: row.PRODUCTOS,
        correo: row.CORREO,
        telefono: row.TELEFONO,
        notas: row.NOTAS,
        pagado: row.PAGADO,
        resourceId: row.ID_COLABORADOR,
        folio: row.FOLIO,
        estatus: row.ESTATUS,
        resourceEditable: true,
        backgroundColor:
          row.ESTATUS == 0
            ? "#FE8D27"
            : row.ESTATUS == 1
            ? "#3ABE88"
            : row.ESTATUS == 2
            ? "#D04646"
            : row.ESTATUS == 3
            ? "#C5BE02"
            : "#3788D8",
        borderColor:
          row.ESTATUS == 0
            ? "#FE8D27"
            : row.ESTATUS == 1
            ? "#3ABE88"
            : row.ESTATUS == 2
            ? "#D04646"
            : row.ESTATUS == 3
            ? "#C5BE02"
            : "#3788D8",
        //textColor:""
      };
    });
    FilteredData = [...FilteredData, ...diasBloqueados.lista];
    setReservaciones(FilteredData);
    // console.log(`File: ~ 459 -->`, "render");
  }, [reservaciones, diasBloqueados]);

  useEffect(() => {
    if (calendarRef.current) {
      calendarRef.current.getApi().gotoDate(startDate);
    }
  }, [startDate]);

  useEffect(() => {
    getDiasBloqueados();
    const handleDocumentClick = (e) => {
      setEvent(null);
    };
    document.addEventListener("click", handleDocumentClick);
    return () => {
      document.removeEventListener("click", handleDocumentClick);
    };
  }, []);

  useEffect(() => {
    $(".fc-media-screen").attr(
      "style",
      "height: 50vh !important; max-height: 80vh !important;"
    );
  }, []);

  useEffect(() => {
    getColaboradores();
  }, [valuesFechaBloqueo.id_sucursal]);

  useEffect(() => {
    setValuesFechaBloqueo({
      ...valuesFechaBloqueo,
      id_sucursal: idSucursal,
    });
    if (diasBloqueados.init) {
      getDiasBloqueados();
    }
  }, [idSucursal]);

  useEffect(() => {
    setValuesFechaBloqueo({
      ...valuesFechaBloqueo,
      id_colaborador: idColaborador,
    });
    setSelectColaboradores([idColaborador]);
    if (diasBloqueados.init) {
      getDiasBloqueados();
    }
  }, [idColaborador]);
  //#endregion
  const resources = [
    { id: "33", title: "<h3>HOLAAAA</h3>" },
    { id: "2", title: "<strong>Recurso 1</strong>" },
    { id: "3", title: '<span style="color: red;">Recurso 3</span>' },
  ];

  const resourceRenderCallback = (arg) => {
    const node = document.createElement("div");
    node.innerHTML = arg.resource.title;
    arg.el.querySelector(".fc-cell-text").appendChild(node);
  };

  return (
    <>
      <div
        style={{
          userSelect: "none",
        }}
      >
        {event != null && (
          <div
            className="event-container"
            id="event-container"
            style={{
              zIndex: "99",
              top: position.top + 25,
              left: position.left + 25,
            }}
          >
            <PopHover event={event} />
          </div>
        )}

        <FullCalendar
          slotDuration={intervalo}
          slotLabelInterval={slotLabelInterval}
          slotMinTime={rango.inicio}
          slotMaxTime={rango.fin}
          slotLabelFormat={{
            hour: "numeric",
            minute: "2-digit",
            omitZeroMinute: false,
          }}
          ref={calendarRef}
          select={handleSelect}
          aspectRatio={50}
          handleWindowResize={true}
          plugins={[
            resourceTimeGridPlugin,
            timeGridPlugin,
            interactionPlugin,
            resourceTimelinePlugin,
          ]}
          headerToolbar={{
            left: "prev,next today",
            center: "title",
            right: "timeGridWeek,resourceTimeGridDay",
          }}
          locales={"es"}
          initialView="timeGridWeek"
          initialDate={new Date()}
          editable={true}
          eventDurationEditable={false} //editar desde las orillas, estirarlo
          eventResourceEditable={true}
          eventStartEditable={true} //arrastre de eventos
          selectable={true}
          selectMirror={true}
          dayMaxEvents={true}
          buttonText={{ today: "HOY", week: "Sem", month: "Mes", day: "Día" }}
          allDayText={"Día"}
          events={Reservaciones}
          resources={colaboradores}
          resourceRender={function (renderInfo) {
            renderInfo.el.style.backgroundColor = "blue";
          }}
          datesSet={handleDatesSet}
          eventDragStart={handleEventDragStart}
          eventMouseEnter={handleEventMouseEnter}
          eventMouseLeave={handleEventMouseLeave}
          eventClick={eventClickFullCalendar}
          eventDrop={eventDropFullCalendar}
        />

        <StyledSnackbar
          direction="right"
          open={open}
          autoHideDuration={6000}
          onClose={() => setOpen(false)}
          anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        >
          <Alert
            onClose={() => setOpen(false)}
            severity={
              mensaje && mensaje.includes("correctamente") ? "success" : "error"
            }
            sx={{ width: "100%" }}
          >
            {mensaje}
          </Alert>
        </StyledSnackbar>

        {showModalBloquearHorario && (
          <ModalBloqueoHorario
            valuesFechaBloqueo={valuesFechaBloqueo}
            EliminarHorarioBloqueado={EliminarHorarioBloqueado}
            setShowModalBloquearHorario={setShowModalBloquearHorario}
            setValuesFechaBloqueo={setValuesFechaBloqueo}
            initFechaBloqueo={initFechaBloqueo}
            Horas={Horas}
            IsGuardando={IsGuardando}
            GuardarHorarioBloqueado={GuardarHorarioBloqueado}
            handlInputChange={handlInputChange}
            opcColaboradores={opcColaboradores}
            Minutos={Minutos}
            errorFechaBloqueo={errorFechaBloqueo}
            setBanderaSelectColaboradores={setBanderaSelectColaboradores}
            selectColaboradores={selectColaboradores}
            setSelectColaboradores={setSelectColaboradores}
          />
        )}
        {selectedRange != null && !showModalBloquearHorario && (
          <ModalDiaSeleccionado
            selectedRange={selectedRange}
            setSelectedRange={setSelectedRange}
            setShowModalReserva={setShowModalReserva}
            setShowModalBloquearHorario={setShowModalBloquearHorario}
            setValuesFechaBloqueo={setValuesFechaBloqueo}
            setFechaReserva={setFechaReserva}
            valuesFechaBloqueo={valuesFechaBloqueo}
          />
        )}
      </div>
    </>
  );
};

export default FullCalendarApp;
