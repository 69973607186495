import { Avatar } from "@mui/material";
import { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { getOpciones } from "../../Catalogos/Categorias/Funciones/Categorias";
import Loading from "./../../Includes/Loading";
import { avatarLetters } from "../../Lib/Funciones Generales/Generales";

export default function Categorias({ setCategoria, categoria }) {
  const [Categorias, setCategorias] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    getOpciones()
      .then((resp) => {
        let list = JSON.parse(resp.data);
        //console.log(resp)
        if (list.length != 0) {
          setCategorias(list);
        } else {
          setCategorias(list);
        }
        setIsLoading(false);
      })
      .catch((resp) => {
        setCategorias([]);
        setIsLoading(false);
      });
  }, [categoria]);

  return isLoading ? (
    <Loading />
  ) : (
    <>
      <Row>
        <Col xs={12} style={{ maxHeight: "65vh", overflowY: "scroll" }}>
          <div className="w-100 h-100 d-flex justify-content-start flex-wrap flex-row">
            {Categorias.map((row) => (
              <div className="" onClick={() => setCategoria(row.ID)}>
                <div
                  style={{}}
                  className=" card-producto card p-3 m-3 shadow-sm d-flex justify-content-center  flex-column "
                >
                  <center>
                    <Avatar sx={{ bgcolor: "#fd8c27" }}>
                      {avatarLetters(row.DESCRIPCION)}
                    </Avatar>
                  </center>
                  <p className="font-Avenir fw-bold parrafo2lineastruncate text-center">
                    {row.DESCRIPCION}
                  </p>
                </div>
              </div>
            ))}
          </div>
        </Col>
      </Row>
    </>
  );
}
