import { Autocomplete, TextField } from "@mui/material"
import { useEffect, useState } from "react";
import { getOpciones } from "../Funciones/Planes";
import { CalcularPrecio } from "../../Licencias/Funciones/Licencias";
import { TiposPlanes } from "../../../Lib/Funciones Generales/Constantes";
import { GetDate, formatDateFrontDeBack } from "../../../Lib/Funciones Generales/ObtenerFecha";


const Planes = ({
    Value
    , Error
    , handlInputChange
    , setValues
    , Name = "id_plan"
    , disabled = false
}) => {
    const opcionInicial = { ID: 0, DESCRIPCION: "Plan" }
    const [ValorFiltrado, setFiltro] = useState()
    const [Plan, setPlan] = useState({ cargado: false, lista: [opcionInicial] });

    const SingularMayus = "Plan"
    const SingularMinus = "plan";
    const PluralMinus = "planes"

    useEffect(() => {
        getOpciones()
            .then((resp) => {
                let list = JSON.parse(resp.data);
                if (list.length != 0) {
                    setPlan({ cargado: true, lista: list });

                }
                else {
                    setPlan({ cargado: true, lista: [{ ID: 0, DESCRIPCION: "Sin " + PluralMinus + " disponibles" }] });
                }
            })
            .catch((resp) => {
                setPlan({ cargado: true, lista: [{ ID: 0, DESCRIPCION: "Sin " + PluralMinus + " disponibles" }] });
            });
    }, [])

    const updatePlan = (value) => {

        const Plan = {
            ...Value,
            id_plan: value.ID,
            total: value.PRECIO_MENSUAL,
            total_colaboradores_ad: 0,
            total_sucursales_ad: 0,
            nombre_plan: value.DESCRIPCION,
            descripcion: value.DESCRIPCION_LARGA,
            numero_de_colaboradores: value.NUMERO_DE_COLABORADORES_GRATIS < Value.contador_colaboradores ? Value.contador_colaboradores : value.NUMERO_DE_COLABORADORES_GRATIS,
            numero_de_sucursales: value.NUMERO_DE_SUCURSALES_GRATIS < Value.contador_sucursales ? Value.contador_sucursales : value.NUMERO_DE_SUCURSALES_GRATIS,
            color: value.COLOR,
            max_sucursales: value.MAX_SUCURSALES,
            max_colaboradores: value.MAX_COLABORADORES,
            precio_mensual: value.PRECIO_MENSUAL,
            precio_trimestral: value.PRECIO_TRIMESTRAL,
            precio_anual: value.PRECIO_ANUAL,
            precio_por_colaborador: value.PRECIO_POR_COLABORADORES,
            precio_por_sucursal: value.PRECIO_POR_SUCURSAL,
            numero_de_colaboradores_gratis: value.NUMERO_DE_COLABORADORES_GRATIS,
            numero_de_sucursales_gratis: value.NUMERO_DE_SUCURSALES_GRATIS,
            periodo_de_plan: TiposPlanes.MENSUAL,
            fecha_corte: formatDateFrontDeBack(GetDate(0, 1))            
        }

        const {
            total, 
            total_sucursales_ad, 
            total_colaboradores_ad
        } = CalcularPrecio(Plan, Plan.numero_de_sucursales, Plan.numero_de_colaboradores, TiposPlanes.MENSUAL)


        setValues({
            ...Plan,
            total,
            total_sucursales_ad,
            total_colaboradores_ad
        })
    }

    const getOpcion = () => {
        // console.log("Empleado: ", Value)
        const opcion = Plan.lista.find(x => x.ID == Value.id_plan)

        if (opcion) {
            return opcion
        } else {
            return null
        }
    }

    return (
        Plan.cargado ? (
            <Autocomplete
                fullWidth
                disabled={disabled}
                defaultValue={getOpcion}
                isOptionEqualToValue={(option, value) => {
                    return option.ID == value.ID
                }}
                inputValue={ValorFiltrado}
                onChange={(e, value) => {
                    console.log("PLAN: ", value)
                    if (value) {
                        updatePlan(value)
                    }
                    else {
                        handlInputChange({ target: { name: Name, value: "" } })
                    }
                }}
                onInputChange={(event, newInputValue) => {
                    setFiltro(newInputValue);
                }}
                options={Plan.lista}
                getOptionLabel={(option) => {
                    return option.DESCRIPCION
                }}
                renderInput={(params) => {
                    return (
                        <TextField
                            {...params}
                            error={Error}
                            helperText={Error ? "Selecciona un " + SingularMinus + "" : ""}
                            label={SingularMayus}
                        />
                    )
                }}
            />
        ) : (
            <TextField
                fullWidth
                disabled={true}
                value={SingularMayus}
            />
        )
    )
}

export default Planes