import {  Route, Routes } from "react-router-dom";
import Error404 from "../../Includes/Error404";
import RepFormaPago from "./Componentes/RepFormaPago";
 


 const routerFormaPago =()=> {
  console.log("renderizando router"); 
  return (
    <> 
     <Routes>     
        <Route index element={<RepFormaPago/>} /> 
        <Route path="*" element={<Error404 />} />
    </Routes>
    </> 
  );
}

export default  routerFormaPago