import { useState, useEffect } from "react";

import IconButton from "@mui/material/IconButton";
import { Col, Row } from "react-bootstrap";

import Tarjetas from "../../../../assets/Tarjetas.svg";
import Transferencia from "../../../../assets/Transferencia.svg";

import DetalleDePlanProfesionales from "../../../../assets/DetalleDePlanProfesionales.svg";
import DetalleDePlanSucursales from "../../../../assets/DetalleDePlanSucursales.svg";
import CloseIcon from "@mui/icons-material/Close";
import HelperError from "../../../../Includes/HelperError";

import {
  Radio,
  FormControlLabel,
  RadioGroup,
  Slider,
  Card,
  FormControl,
  Alert,
  Dialog,
  Snackbar,
  DialogTitle,
  DialogContent,
  Button,
  Tabs,
  Tab
} from "@mui/material";
import { CalcularPrecio, getInfoPlanEmpresa, guardarInfoPlan } from "../../Funciones/Licencias";
import { GetDate, formatDateFrontDeBack } from "../../../../Lib/Funciones Generales/ObtenerFecha";
import { TiposPlanes } from "../../../../Lib/Funciones Generales/Constantes";
import { formatMoneda } from "../../../../Lib/Funciones Generales/Generales";
import { styled } from "@mui/material/styles";

import SelectPlanes from "../../../Planes/Componentes/SelectPlanes";
import LoadingButton from "@mui/lab/LoadingButton";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import LicenciaIcono from "../Assets/LicenciaIcono";
import Loading from "../../../../Includes/Loading";
import CreditCardIcon from '@mui/icons-material/CreditCard';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import SelectTarjetas from "../../../../Configuraciones/FormaPago/Componentes/SelectTarjetas";
import Deposito from "../Deposito";
import DoneIcon from "@mui/icons-material/Done";

const stylePlan = {
  basico: {
    color: '#8F8F8F'
  },
  intermedio: {
    color: '#83E5F2'
  },
  premium: {
    color: '#FE8D27'
  },
  colores: {
    detalle: '#919bac'
  },
  textoTitulo: {
    fontSize: 12,
    fontWeight: 900
  },
  textoDescripcion: {
    fontSize: 12,
    fontWeight: 500
  },
  textoPrecio: {
    fontSize: 10,
    fontWeight: 900
  }
}
const styleUI = {
  radio: {
    color: '#d2d2d2',
    '&.Mui-checked': {
      color: '#fe8d27',
    },
    fontWeight: 'bold'
  }
}

const MySwal = withReactContent(Swal);
const StyledSnackbar = styled((props) => <Snackbar {...props} />)(
  ({ theme }) => ({
    "& .MuiSnackbar-root": {
      top: theme.spacing(15),
    },
  })
);

const ModalLicencia = ({
  licenciaSeleccionada
  , setLicenciaSeleccionada
  , setShowDialogLicencia
  , getListaLicencia
}) => {

  const [tab, setTab] = useState(0);
  const handleChange = (event, newValue) => {
    setTab(newValue);
  };
  const [IsLoading, setIsLoading] = useState(true)
  const [IsGuardando, setIsGuardando] = useState(false)
  const [mensaje, setMensaje] = useState("");
  const [openAlert, setOpenAlert] = useState(false);

  const [paymentMethod, setPaymentMethod] = useState("TARJETA")
  const [tarjeta, setTarjeta] = useState({
    id_tarjeta: "0",
    numero_tarjeta: "",
  })

  const initValues = {
    id_licencia: licenciaSeleccionada,
    total: 0,
    id_empresa: 0,
    activo: 0,
    id_plan: 0,
    fecha: '',
    fecha_corte: '',
    nombre_plan: '',
    descripcion: '',
    periodo_de_plan: "Mensual",
    total_sucursales_ad: 0,
    total_colaboradores_ad: 0,
    numero_de_colaboradores: 0,
    numero_de_sucursales: 0,
    numero_de_colaboradores_gratis: 0,
    numero_de_sucursales_gratis: 0,
    precio_mensual: 0,
    precio_trimestral: 0,
    precio_anual: 0,
    precio_por_colaborador: 0,
    precio_por_sucursal: 0,
    max_sucursales: 0,
    max_colaboradores: 0,
    contador_sucursales: 0,
    contador_colaboradores: 0,
    color: ''
  }

  const initErrores = {
    numero_de_sucursales: { error: false, mensaje: '' },
    numero_de_colaboradores: { error: false, mensaje: '' },
  }

  const [Errores, setErrores] = useState(initErrores)
  const [Values, setValues] = useState(initValues);

  const CalcularPrecioPlan = (target, numero_de_sucursales, numero_de_colaboradores, periodo) => {

    const {
      total,
      total_colaboradores_ad,
      total_sucursales_ad
    } = CalcularPrecio(Values, numero_de_sucursales, numero_de_colaboradores, periodo);

    setValues({
      ...Values,
      [target.name]: target.value,
      total,
      total_colaboradores_ad,
      total_sucursales_ad
    });
  }

  const handlInputChange = ({ target }) => {
    setValues({
      ...Values,
      [target.name]: target.value,
    });
  };

  const handleSliderChange = ({ target }) => {
    setErrores({ ...Errores, [target.name]: initErrores[target.name] })

    const numero_de_sucursales = target.name == "numero_de_sucursales" ? target.value : Values.numero_de_sucursales
    const numero_de_colaboradores = target.name == "numero_de_colaboradores" ? target.value : Values.numero_de_colaboradores

    if (target.name == "numero_de_sucursales") {
      if (numero_de_sucursales < Values.contador_sucursales) {
        setErrores({
          ...Errores,
          numero_de_sucursales: {
            error: true,
            mensaje: 'El número actual de sucursales es de ' + Values.contador_sucursales
          }
        })
        return;
      }
    } else {
      if (numero_de_colaboradores < Values.contador_colaboradores) {
        setErrores({
          ...Errores,
          numero_de_colaboradores: {
            error: true,
            mensaje: 'El número actual de profesionales es de ' + Values.contador_colaboradores
          }
        })
        return;
      }
    }

    CalcularPrecioPlan(
      target,
      numero_de_sucursales,
      numero_de_colaboradores,
      Values.periodo_de_plan
    )
  }

  const handlRadioChange = ({ target }) => {

    let fecha_corte = "";
    switch (target.value) {
      case TiposPlanes.MENSUAL: fecha_corte = GetDate(0, 1); break;
      case TiposPlanes.TRIMESTRAL: fecha_corte = GetDate(0, 3); break;
      case TiposPlanes.ANUAL: fecha_corte = GetDate(0, 12); break;
    }

    fecha_corte = formatDateFrontDeBack(fecha_corte)
    setValues({ ...Values, fecha_corte, [target.name]: target.value })

  }

  useEffect(() => {
    if (!IsLoading) {
      CalcularPrecioPlan(
        { target: { periodo_de_plan: Values.periodo_de_plan } },
        Values.numero_de_sucursales,
        Values.numero_de_colaboradores,
        Values.periodo_de_plan
      )
    }
  }, [Values.fecha_corte])




  const GuardarPlan = () => {
    new Swal({
      title: "¿Estas seguro de comprar la licencia?",
      text: "Esta acción no se puede deshacer",
      icon: "warning",
      showDenyButton: true,
      denyButtonText: "No, cancelar",
      confirmButtonText: "Si, estoy seguro",
      confirmButtonColor: "#3ABE88",
      denyButtonColor: "#65748B",
      reverseButtons: true,
    }).then(function (isConfirm) {
      console.log(isConfirm.isConfirmed);
      if (isConfirm.isConfirmed) {
        setIsGuardando(true)
        guardarInfoPlan(Values, tarjeta, paymentMethod)
          .then((data) => {
            if (data.codigo == "200") {
              MySwal.fire({
                title: "Correcto",
                html: data.mensaje,
                icon: "success",
                confirmButtoColor: "#3ABE88",
                showConfirmButton: false,
                timer: 1700,
              }).then((result) => {
                getListaLicencia();
                setLicenciaSeleccionada(-1);
                setShowDialogLicencia(false)
                setIsGuardando(false);
              });
            } else {
              setMensaje(data.mensaje);
              setOpenAlert(true);
            }
          })
          .catch((data) => {
            setMensaje(data.mensaje);
            setOpenAlert(true);
            setIsGuardando(false);
          });
      }
    });
  }

  const widthPlan = '60px';
  const widthPrecio = '75px';


  useEffect(() => {
    getInfoPlanEmpresa(licenciaSeleccionada, initValues).then(plan => {

      const {
        total,
        total_colaboradores_ad,
        total_sucursales_ad
      } = CalcularPrecio(plan, plan.numero_de_sucursales, plan.numero_de_colaboradores, plan.periodo_de_plan);

      setValues({
        ...plan,
        total,
        total_colaboradores_ad,
        total_sucursales_ad
      });

      setIsLoading(false)

    }).catch(error => {

      console.log(error)

    })
  }, [])

  console.log("PAYMENT: ", paymentMethod, " TARJETA: ", tarjeta.id_tarjeta)


  return (
    <>
      <StyledSnackbar
        direction="right"
        open={openAlert}
        autoHideDuration={6000}
        onClose={() => setOpenAlert(false)}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      >
        <Alert onClose={() => setOpenAlert(false)} severity="error" sx={{ width: "100%" }}>
          {mensaje}
        </Alert>
      </StyledSnackbar>

      <Dialog
        fullWidth
        maxWidth={(tab == 1 || tab == 2) ? "sm" : "md"}
        // maxWidth={(tab == 1) ? "sm" : "md"}
        open={true}
        PaperProps={{ elevation: 0 }}
      >
        <IconButton
          aria-label="Close"
          className="m-3 mt-2"
          style={{ position: "absolute", right: "0%" }}
          onClick={() => {
            setShowDialogLicencia(false);
            setLicenciaSeleccionada(false);
            setValues({ ...Values, id_plan: 0 })
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogTitle>
          <h6 className="fw-bolder f-12 mt-2">
            {licenciaSeleccionada != 0 ? "Información de licencia" : "Nueva licencia"}
          </h6>
        </DialogTitle>
        <DialogContent>
          <Row>
            {
              IsLoading && Values.id_licencia != 0 ? (
                <Col sm={12} className="py-4">
                  <Loading />
                </Col>
              ) : (
                <>


                  {
                    licenciaSeleccionada != 0 && (
                      <Col sm={12} className="px-3 mb-3">
                        <div className="d-flex  justify-content-between p-3"
                          style={{ backgroundColor: '#f9fafc', borderRadius: 10 }}>
                          <div className="w-50 d-flex flex-wrap">
                            <p className="w-100 m-0"> <strong> CLIENTE DESDE </strong> </p>
                            <p className="w-100 m-0"> {Values.fecha} </p>
                          </div>
                          <div className="w-50 d-flex flex-wrap">
                            <p className="w-100 m-0 text-end"> <strong> FECHA DE CORTE </strong> </p>
                            <p className="w-100 m-0 text-end">  {Values.fecha_corte}  </p>
                          </div>
                        </div>
                      </Col>
                    )
                  }

                  {
                    licenciaSeleccionada == 0 && (
                      <Tabs
                        value={tab}
                        onChange={handleChange}
                        className="mb-3 px-3 py-1"
                        style={{ backgroundColor: '#f9fafc', borderRadius: 10 }}>
                        <Tab
                          label="Licencia"
                          disabled={Values.id_plan == 0}
                          icon={<LicenciaIcono className="mb-2" size="20px" color={tab == 0 ? '#1976d2' : '#666'} />}
                        />
                        <Tab icon={<CreditCardIcon />} label="Forma de pago" disabled={Values.id_plan == 0} />
                        <Tab icon={<CheckCircleIcon />} label="Confirmar y pagar" disabled={Values.id_plan == 0 || tarjeta.id == 0} />
                      </Tabs>
                    )
                  }


                  {

                    tab == 0 && (
                      <>
                        <Col sm={12} md={6} className="p-3">

                          <Col sm={12} className="mb-4">
                            <SelectPlanes
                              Value={Values}
                              handlInputChange={handlInputChange}
                              setValues={setValues}
                              disabled={licenciaSeleccionada != 0}
                            />
                          </Col>

                          <Col sm={12}>
                            <FormControl>
                              <RadioGroup
                                name="periodo_de_plan"
                                value={Values.periodo_de_plan}
                                onChange={handlRadioChange}
                              >
                                <FormControlLabel
                                  value="Mensual"
                                  control={<Radio disabled={Values.id_plan == 0 || licenciaSeleccionada != 0} sx={styleUI.radio} />}
                                  label="Mensual"
                                />

                                <FormControlLabel
                                  className="mt-1"
                                  value="Trimestral"
                                  control={<Radio disabled={Values.id_plan == 0 || licenciaSeleccionada != 0} sx={styleUI.radio} />}
                                  label="Trimestral"
                                />

                                <FormControlLabel
                                  className="mt-1"
                                  value="Anual"
                                  control={<Radio disabled={Values.id_plan == 0 || licenciaSeleccionada != 0} sx={styleUI.radio} />}
                                  label="Anual"
                                />
                              </RadioGroup>
                            </FormControl>
                          </Col>



                          <Col sm={12}>
                            <p>Selecciona la cantidad de sucursales que necesites</p>
                            <Slider
                              disabled={Values.id_plan == 0 || licenciaSeleccionada != 0}
                              name="numero_de_sucursales"
                              value={Values.numero_de_sucursales}
                              aria-label="Sucursales"
                              valueLabelDisplay="auto"
                              min={Values.numero_de_sucursales_gratis}
                              max={Values.max_sucursales}
                              onChange={handleSliderChange}
                              style={{ color: "#FD8C27" }}
                            />
                            <div className="w-100 d-flex justify-content-between">
                              <p className="m-0">
                                {/* {Values.numero_de_sucursales} */}
                                {Values.numero_de_sucursales_gratis}
                              </p>
                              <p className="m-0">
                                {Values.max_sucursales}
                              </p>
                            </div>
                            <HelperError
                              Error={Errores.numero_de_sucursales.error}
                              Mensaje={Errores.numero_de_sucursales.mensaje}
                            />
                          </Col>

                          <Col sm={12}>
                            <p>Selecciona el número de colaboradores que requieres</p>
                            <Slider
                              disabled={Values.id_plan == 0 || licenciaSeleccionada != 0}
                              name="numero_de_colaboradores"
                              value={Values.numero_de_colaboradores}
                              aria-label="Profesionales"
                              valueLabelDisplay="auto"
                              min={Values.numero_de_colaboradores_gratis}
                              max={Values.max_colaboradores}
                              onChange={handleSliderChange}
                              style={{ color: "#FD8C27" }}
                            />
                            <div className="w-100 d-flex justify-content-between">
                              <p className="m-0">
                                {/* {Values.numero_de_colaboradores} */}
                                {Values.numero_de_colaboradores_gratis}
                              </p>
                              <p className="m-0">
                                {Values.max_colaboradores}
                              </p>
                            </div>
                            <HelperError
                              Error={Errores.numero_de_colaboradores.error}
                              Mensaje={Errores.numero_de_colaboradores.mensaje}
                            />
                          </Col>
                        </Col>

                        <Col sm={12} md={6}>
                          <Card className="px-4 pb-3" variant="outlined" style={{ border: '3px solid ' + (Values.color == "" ? '#8F8F8F' : Values.color) }}>
                            <Row className="mx-0">
                              <Col sm={12}>

                                <div className="mt-4">
                                  <LicenciaIcono color={(Values.color == "" ? '#8F8F8F' : Values.color)} />
                                </div>

                                <h5 className="mt-4 pt-2 mb-0">
                                  <strong>{Values.nombre_plan}</strong>
                                </h5>
                                <div className="d-flex flex-wrap align-items-center">
                                  <h4 className="m-0 mt-1">
                                    <strong>{formatMoneda(Values.total - Values.total_sucursales_ad - Values.total_colaboradores_ad)} MXN </strong>
                                  </h4>
                                  <p className="text-mutted m-0 ms-2 mt-1" style={{ fontSize: 13 }}>
                                    / {Values.periodo_de_plan}
                                  </p>

                                </div>

                                <p className="mt-4 pt-2 mb-0">
                                  <strong style={{ color: stylePlan.colores.detalle }}>Detalles del plan </strong>
                                </p>
                                <hr style={{ height: 0.5 }} />
                              </Col>

                              <Col sm={12} className="mt-2">
                                <div className="d-flex flex-wrap justify-content-between align-items-center">
                                  <div style={{ width: 55 }} className="d-flex my-auto">
                                    <img className="m-auto" src={DetalleDePlanSucursales} />
                                  </div>
                                  <div className="ps-0 ps-lg-2" style={{ width: 'calc(100% - ' + widthPlan + ')' }}>
                                    {/* <p className="my-auto fw-bold" style={stylePlan.textoTitulo}>
                                {Values.periodo_de_plan}
                              </p> */}
                                    <div className="d-flex flex-wrap align-items-center w-100 my-auto">
                                      <p className="m-0 text-end me-2" style={{ ...stylePlan.textoDescripcion, width: 15 }}>
                                        {Values.numero_de_sucursales_gratis}
                                      </p>
                                      <p className="m-0 me-auto" style={stylePlan.textoDescripcion}>
                                        Sucursales
                                      </p>
                                      <p className="m-0" style={stylePlan.textoPrecio}>
                                        Incluido
                                      </p>
                                    </div>
                                    <div className="d-flex flex-wrap justify-content-between align-items-center w-100 my-auto">
                                      <p className="m-0 text-end me-2" style={{ ...stylePlan.textoDescripcion, width: 15 }}>
                                        {Values.numero_de_sucursales - Values.numero_de_sucursales_gratis}
                                      </p>
                                      <p className="m-0 me-auto" style={stylePlan.textoDescripcion}>
                                        Sucursales adicionales
                                      </p>
                                      <p className="m-0" style={stylePlan.textoPrecio}>
                                        {formatMoneda(Values.total_sucursales_ad)} MXN
                                      </p>
                                    </div>

                                  </div>
                                  {/* <div style={{ width: widthPrecio }} className="d-flex justify-content-end my-auto fw-bold">
                              <p className="m-0 mt-2" style={stylePlan.textoPrecio}>
                                {formatMoneda(Values.total_sucursales_ad)}
                              </p>
                            </div> */}
                                </div>
                              </Col>

                              <Col sm={12} className="mt-2">
                                <hr style={{ height: 0.5 }} />
                                <div className="d-flex flex-wrap justify-content-between align-items-center">
                                  <div style={{ width: 55 }} className="d-flex my-auto">
                                    <img className="m-auto" src={DetalleDePlanProfesionales} />
                                  </div>
                                  <div className="ps-0 ps-lg-2" style={{ width: 'calc(100% - ' + widthPlan + ')' }}>
                                    {/* <p className="my-auto fw-bold" style={stylePlan.textoTitulo}>
                                {Values.periodo_de_plan}
                              </p> */}
                                    <div className="d-flex flex-wrap align-items-center w-100 my-auto">
                                      <p className="m-0 text-end me-2" style={{ ...stylePlan.textoDescripcion, width: 15 }}>
                                        {Values.numero_de_colaboradores_gratis}
                                      </p>
                                      <p className="m-0 me-auto" style={stylePlan.textoDescripcion}>
                                        Profesionales
                                      </p>
                                      <p className="m-0" style={stylePlan.textoPrecio}>
                                        Incluido
                                      </p>
                                    </div>
                                    <div className="d-flex flex-wrap justify-content-between align-items-center w-100 my-auto">
                                      <p className="m-0 text-end me-2" style={{ ...stylePlan.textoDescripcion, width: 15 }}>
                                        {Values.numero_de_colaboradores - Values.numero_de_colaboradores_gratis}
                                      </p>
                                      <p className="m-0 me-auto" style={stylePlan.textoDescripcion}>
                                        Profesionales adicionalos
                                      </p>
                                      <p className="m-0" style={stylePlan.textoPrecio}>
                                        {formatMoneda(Values.total_colaboradores_ad)} MXN
                                      </p>
                                    </div>

                                  </div>
                                  {/* <div style={{ width: widthPrecio }} className="d-flex justify-content-end my-auto fw-bold">
                              <p className="m-0 mt-2" style={stylePlan.textoPrecio}>
                                {formatMoneda(Values.total_colaboradores_ad)}
                              </p>
                            </div> */}
                                </div>
                              </Col>

                              <div className="p-2"></div>
                            </Row>
                          </Card>

                          <Col sm={12} className="mt-3  w-100 d-flex" >
                            {
                              licenciaSeleccionada == 0 ? (
                                <h4 className="m-0 ms-3 me-auto" style={{ fontWeight: 600 }}>Total a pagar</h4>
                              ) : (
                                <h4 className="m-0 ms-3 me-auto" style={{ fontWeight: 600 }}>Pagado</h4>
                              )
                            }

                            <h4 className="m-0 me-3" style={{ fontWeight: 600 }}>
                              {formatMoneda(Values.total)} MXN
                            </h4>
                          </Col>
                        </Col>

                      </>
                    )
                  }

                  {
                    tab == 1 && (
                      <Col sm={12} className="mb-5 pb-5" >
                        {/* <SelectTarjetas
                          paymentMethod={''}
                          setTarjeta={setTarjeta}
                          tarjeta={tarjeta}
                        /> */}

                        <div className="pt-3">
                          <h6 className=" text-star mb-1 mt-3 fw-bolder font-PlayfairDisplay">
                            1. Tarjeta
                          </h6>
                          <Button
                            onClick={(e) => setPaymentMethod("TARJETA")}
                            className={
                              "btn shadow-none btn-outline-black d-flex  mt-4 w-100 font-OxygenMedium text-star  py-3 px-4 " +
                              (paymentMethod == "TARJETA" ? " btn-michelle " : "")
                            }
                            variant="contained"
                          >
                            <div className="d-flex justify-content-star w-100">
                              <img src={Tarjetas} className="img-fluid me-3" />
                              <span>Pagar con tarjeta</span>
                              <div className="flex-grow-1 text-end">
                                {paymentMethod == "TARJETA" && <DoneIcon className="" />}
                              </div>
                            </div>
                          </Button>
                          {paymentMethod == "TARJETA" && (
                            <SelectTarjetas
                              paymentMethod={''}
                              tarjeta={tarjeta}
                              setTarjeta={setTarjeta}
                            />
                          )}

                          <h6 className=" text-star mb-1 mt-3 fw-bolder font-PlayfairDisplay">
                            2. Transferencia
                          </h6>
                          <Button
                            onClick={(e) => {
                              setPaymentMethod("TRANSFERENCIA")
                              setTarjeta({ id_tarjeta: "0", numero_tarjeta: "" })
                            }}
                            className={
                              "btn shadow-none btn-outline-black d-flex  mt-3 w-100 font-OxygenMedium text-star  py-3 px-4 " +
                              (paymentMethod == "TRANSFERENCIA" ? " btn-michelle " : "")
                            }
                            variant="contained"
                          >
                            <div className="d-flex justify-content-star w-100">
                              <img src={Transferencia} className="img-fluid me-3" />
                              <span>Transferencia electrónica</span>
                              <div className="flex-grow-1 text-end">
                                {paymentMethod == "TRANSFERENCIA" && <DoneIcon className="" />}
                              </div>
                            </div>
                          </Button>


                          {paymentMethod == "TRANSFERENCIA" && (
                            <>  <Deposito /></>
                          )}

                        </div>

                      </Col>
                    )
                  }

                  {
                    tab == 2 && (
                      <Col sm={12} className="mb-5 pb-5">

                        <div className="d-flex justify-content-between align-items-center pt-3 pb-n5">
                          {/* <LicenciaIcono className="me-3" color={(Values.color == "" ? '#8F8F8F' : Values.color)} /> */}

                          <div>
                            <h5 className="m-0">
                              <strong>{Values.nombre_plan}</strong>
                            </h5>
                            <div className="d-flex flex-wrap align-items-center">
                              <h4 className="m-0 mt-1">
                                <strong>{formatMoneda(Values.total - Values.total_sucursales_ad - Values.total_colaboradores_ad)} MXN </strong>
                              </h4>
                              <p className="text-mutted m-0 ms-2 mt-1" style={{ fontSize: 13 }}>
                                / {Values.periodo_de_plan}
                              </p>
                            </div>
                          </div>

                          <LicenciaIcono color={(Values.color == "" ? '#8F8F8F' : Values.color)} />

                        </div>
                        <p className="m-0 w-100" style={stylePlan.textoDescripcion}>
                          {Values.descripcion}
                        </p>
                        <p className="my-auto" style={stylePlan.textoDescripcion}>
                          {Values.numero_de_sucursales_gratis} Sucursales incluidas
                        </p>
                        <p className="my-auto" style={stylePlan.textoDescripcion}>
                          {Values.numero_de_colaboradores_gratis} Profesionales incluidos
                        </p>
                        <hr />
                        <div className="d-flex justify-content-between align-content-end">
                          <p className="m-0">
                            Precio {Values.periodo_de_plan}
                          </p>
                          <p className="m-0 mt-auto text-end" style={{ width: 200 }}>
                            {formatMoneda((Values.total - Values.total_sucursales_ad - Values.total_colaboradores_ad) / 1.16)} MXN
                          </p>
                        </div>
                        <div className="d-flex justify-content-between align-content-end">
                          <p className="m-0">
                            {Values.numero_de_sucursales - Values.numero_de_sucursales_gratis} Sucursales adicionales
                          </p>
                          <p className="m-0 mt-auto text-end" style={{ width: 200 }}>
                            {formatMoneda(Values.total_sucursales_ad / 1.16)} MXN
                          </p>
                        </div>
                        <div className="d-flex justify-content-between align-content-end">
                          <p className="m-0">
                            {Values.numero_de_colaboradores - Values.numero_de_colaboradores_gratis} Profesionales adicionales
                          </p>
                          <p className="m-0 mt-auto text-end" style={{ width: 200 }}>
                            {formatMoneda(Values.total_colaboradores_ad / 1.16)} MXN
                          </p>
                        </div>
                        <div className="d-flex justify-content-between align-content-end">
                          <p className="m-0">
                            Subtotal
                          </p>
                          <p className="m-0 mt-auto text-end" style={{ width: 200 }}>
                            {formatMoneda(Values.total / 1.16)} MXN
                          </p>
                        </div>
                        <div className="d-flex justify-content-between align-content-end">
                          <p className="m-0">
                            IVA 16%
                          </p>
                          <p className="m-0 mt-auto text-end " style={{ width: 200 }}>
                            {formatMoneda(Values.total - (Values.total / 1.16))} MXN
                          </p>
                        </div>
                        <div className="d-flex justify-content-between align-content-end">
                          <p className="m-0">
                            <strong>
                              Total
                            </strong>
                          </p>
                          <p className="m-0 mt-auto text-end " style={{ width: 200 }}>
                            <strong >
                              {formatMoneda(Values.total)} MXN
                            </strong>
                          </p>
                        </div>
                        <hr />
                        <p className="m-0 w-100">
                          <strong className=""> Vigencia: </strong> {Values.fecha_corte}
                        </p>
                        <hr style={{ height: 0.5 }} />
                      </Col>

                    )
                  }


                  <Col sm={12} className="d-flex justify-content-end">
                    {
                      tab == 0 && (
                        <Button
                          onClick={() => {
                            setShowDialogLicencia(false)
                            setLicenciaSeleccionada(-1)
                          }}
                          className="btn btn-cancelar font-AvenirMedium py-2 px-4"
                          variant="outlined"
                        >
                          Cancelar
                        </Button>
                      )
                    }

                    {
                      (tab == 1 || tab == 2) && licenciaSeleccionada == 0 && (
                        <Button
                          onClick={() => {
                            setTab(tab - 1)
                          }}
                          className="btn font-AvenirMedium py-2 px-4 text-white me-2"
                          variant="contained"
                          style={{ backgroundColor: '#65748b', borderRadius: 10 }}
                        >
                          <ArrowBackIosNewIcon className="me-1" /> Anterior
                        </Button>
                      )
                    }

                    {
                      (tab == 0 || tab == 1) && licenciaSeleccionada == 0 && (
                        <Button
                          onClick={() => {
                            setTab(tab + 1)
                          }}
                          disabled={Values.id_plan == 0 || (tab == 1 ? (paymentMethod == "TARJETA" ? tarjeta.id_tarjeta == "0" : false) : false)}
                          className="btn font-AvenirMedium py-2 px-4 text-white ms-3"
                          variant="contained"
                          style={{ backgroundColor: '#65748b', borderRadius: 10 }}
                        >
                          Siguiente <ArrowForwardIosIcon className="ms-1" />
                        </Button>
                      )
                    }

                    {
                      tab == 2 && (
                        licenciaSeleccionada == 0 && (
                          <LoadingButton
                            loading={IsGuardando}
                            disabled={licenciaSeleccionada != 0}
                            loadingPosition="start"
                            onClick={() => GuardarPlan()}
                            className="btn btn-Kalendar font-AvenirMedium  py-2 px-4 ms-2 text-white"
                            variant="contained"
                            xs={{ with: "100$" }}
                          >
                            <span className={IsGuardando ? "px-4" : "px-2"}>
                              {!IsGuardando && <MonetizationOnIcon className="me-1" />}
                              {IsGuardando ? "Guardando..." : "Pagar licencia"}
                            </span>
                          </LoadingButton>
                        )
                      )
                    }
                  </Col>


                </>
              )
            }
          </Row>

        </DialogContent >

      </Dialog >

    </>
  );
};

export default ModalLicencia;
