import { Route, Routes } from "react-router-dom";
import Error404 from "../../Includes/Error404";
import RepLicencias from "./Componentes/RepLicencias";

const routerLicencias = () => {
  console.log("renderizando router");
  return (
    <>
      <Routes>
        <Route index element={<RepLicencias />} />
        <Route path="*" element={<Error404 />} />
      </Routes>
    </>
  );
};

export default routerLicencias;
