import {
  Alert,
  Avatar,
  Button,
  Card,
  CardContent,
  Snackbar,
  Stack,
  TextField,
} from "@mui/material";

import React, { useState, useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import { useNavigate, Link } from "react-router-dom";
import { guardar, getInfoPerfil } from "../Funciones/Perfiles";
import Loading from "../../../Includes/Loading";
import LoadingButton from "@mui/lab/LoadingButton";
import { styled } from "@mui/material/styles";
import { useParams } from "react-router-dom";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";

import ModalCargarImagen from "../../../Lib/Imagen/Componentes/ModalCargarImagen";
import AccEditarImagen from "./../../../assets/EditarImagen.svg";
import { IMG } from "../../../Lib/Funciones Generales/Constantes";
import RegimenFiscal from "../../../Lib/SAT/Componentes/RegimenFiscal";
import { Empresa } from "./Empresa";
import { Colaborador } from "./Colaborador";
import { getLocalStorage } from "../../../Context/Storage";
import { useContext } from "react";
import { IconContext } from "../../../Includes/menu";

const MySwal = withReactContent(Swal);

const Perfil = () => {
  // Definicion de variables para la carga de imagenes
  const [openModal, setOpenModal] = React.useState(false);
  const avatar = useContext(IconContext);
  const handleOpenModal = () => setOpenModal(true);
  const handleCloseModal = () => setOpenModal(false);
  const fnDevolverImagen = (img) => {
    let random = "";
    if (perfilType) {
      if (Values.id != "0" && Values.id != 0) {
        random = "?" + Math.floor(Math.random() * 1000000);
      }
      setValues({ ...Values, img: img + random });
    } else {
      if (ValuesCOLAB.id != "0" && ValuesCOLAB.id != 0) {
        random = "?" + Math.floor(Math.random() * 1000000);
      }
      setValuesCOLAB({ ...ValuesCOLAB, img: img + random });
    }
  };

  const { id } = useParams();
  const navigate = useNavigate();
  const [IsLoading, setIsLoading] = useState(true);
  const [IsGuardando, setIsGuardando] = useState(false);
  const [open, setOpen] = useState(false);
  const [mensaje, setMensaje] = useState("");
  const [perfilType, setPerfilType] = useState(true);
  const [Values, setValues] = useState({
    id: id ?? "0",
    nombre: "",
    razon_social: "",
    regimen_fiscal: "",
    rfc: "",
    direccion: "",
    cp: "",
    correo: "",
    telefono: "",
    contrasena_actual: "",
    nueva_contrasena: "",
    repetir_contrasena: "",
    apellido_paterno: "",
    apellido_materno: "",
    facebook: "",
    youtube: "",
    instagram: "",
    img: AccEditarImagen,
  });

  const [ValuesCOLAB, setValuesCOLAB] = useState({
    id: id ?? "0",
    nombre: "",
    apellido_paterno: "",
    apellido_materno: "",
    correo: "",
    telefono: "",
    contrasena_actual: "",
    nueva_contrasena: "",
    repetir_contrasena: "",
    img: AccEditarImagen,
  });

  const [Errores, setErrores] = useState({
    nombre: false,
    razon_social: false,
    regimen_fiscal: false,
    rfc: false,
    direccion: false,
    cp: false,
    correo: false,
    telefono: false,
    contrasena_actual: false,
    nueva_contrasena: false,
    repetir_contrasena: false,
    apellido_paterno: false,
    apellido_materno: false,
  });

  const [ErroresCOLAB, setErroresCOLAB] = useState({
    nombre: false,
    apellido_paterno: false,
    apellido_materno: false,
    correo: false,
    telefono: false,
    contrasena_actual: false,
    nueva_contrasena: false,
    repetir_contrasena: false,
  });

  const handleClose = () => {
    setOpen(false);
  };

  const handlInputChange = ({ target, buscador }) => {
    const Name = target ? target.name : buscador.name;
    const Value = target ? target.value : buscador.value;
    let NewValue = {
      ...Values,
      [Name]: Value,
    };

    setValues(NewValue);
    setErrores({
      ...Errores,
      [Name]: false,
    });
  };

  const handlInputChangeColab = ({ target, buscador }) => {
    const Name = target ? target.name : buscador.name;
    const Value = target ? target.value : buscador.value;
    let NewValue = {
      ...ValuesCOLAB,
      [Name]: Value,
    };

    setValuesCOLAB(NewValue);
    setErroresCOLAB({
      ...ErroresCOLAB,
      [Name]: false,
    });
  };

  useEffect(() => {
    setIsLoading(true);
    document.title = "Perfil";
    getInfoPerfil(AccEditarImagen)
      .then((resp) => {
        if (resp.Values.type) {
          setValues(resp.Values);
          setIsLoading(false);
          setPerfilType(resp.Values.type);
        } else {
          setValuesCOLAB(resp.Values);
          setIsLoading(false);
          setPerfilType(resp.Values.type);
          //console.log(resp);
        }
      })
      .catch((resp) => {
        setIsLoading(false);
      });
  }, []);

  const GuardarCliente = () => {
    //setPerfilType(!perfilType);
    setIsGuardando(true);
    guardar(
      perfilType ? Values : ValuesCOLAB,
      perfilType ? Errores : ErroresCOLAB,
      perfilType ? setErrores : setErroresCOLAB,
      perfilType
    )
      .then((data) => {
        if (data.codigo == "200") {
          setIsGuardando(false);
          MySwal.fire({
            title: "Correcto",
            html: data.mensaje,
            icon: "success",
            confirmButtoColor: "#3ABE88",
            showConfirmButton: false,
            timer: 1700,
          }).then((result) => {
            console.log("I was closed by the timer");
            navigate(-1);
          });
        } else {
          setMensaje(data.mensaje);
          setOpen(true);
          setIsGuardando(false);
        }
      })
      .catch((data) => {
        // alert(data.mensaje);
        setMensaje(data.mensaje);
        setOpen(true);
        setIsGuardando(false);
      });
  };

  const StyledSnackbar = styled((props) => <Snackbar {...props} />)(
    ({ theme }) => ({
      "& .MuiSnackbar-root": {
        top: theme.spacing(15),
      },
    })
  );

  const img_url =
    (perfilType ? Values.img : ValuesCOLAB.img) == AccEditarImagen
      ? AccEditarImagen
      : perfilType
      ? Values.img
      : ValuesCOLAB.img;

  avatar.setUrl(
    (perfilType ? Values.img : ValuesCOLAB.img) == AccEditarImagen
      ? AccEditarImagen
      : perfilType
      ? Values.img
      : ValuesCOLAB.img
  );

  return (
    <>
      <StyledSnackbar
        direction="right"
        open={open}
        autoHideDuration={6000}
        onClose={handleClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      >
        <Alert onClose={handleClose} severity="error" sx={{ width: "100%" }}>
          {mensaje}
        </Alert>
      </StyledSnackbar>
      <Card elevation={3}>
        <CardContent className="p-0">
          <div className="p-3">
            <h6 className="mb-0 text-left font-AvenirBold">Mi perfil</h6>
          </div>
          <hr
            style={{ backgroundColor: "#DFDEE0", height: "1px", opacity: "1" }}
            className="m-0 p-0"
          />

          {IsLoading ? (
            <Row className="px-3">
              <Col sm={12} className="p-3">
                <Loading />
              </Col>
            </Row>
          ) : perfilType ? (
            <Empresa
              Values={Values}
              Errores={Errores}
              handlInputChange={handlInputChange}
              img_url={img_url}
              handleOpenModal={handleOpenModal}
            />
          ) : (
            <Colaborador
              Values={ValuesCOLAB}
              Errores={Errores}
              handlInputChange={handlInputChangeColab}
              img_url={img_url}
              handleOpenModal={handleOpenModal}
            />
          )}

          <Row>
            <Col sm={12} md={6} className="p-3 d-flex">
              <Stack
                className="p-3"
                spacing={2}
                direction={{ xs: "column", sm: "row" }}
                style={{}}
              >
                <LoadingButton
                  loading={IsGuardando}
                  loadingPosition="start"
                  disabled={IsLoading}
                  onClick={() => GuardarCliente()}
                  className="btn btn-Kalendar font-AvenirMedium  py-2 px-4 "
                  variant="contained"
                  xs={{ with: "100$" }}
                >
                  <span className={IsGuardando ? "px-4" : "px-2"}>
                    Actualizar datos
                  </span>
                </LoadingButton>
                <Button
                  onClick={() => navigate(-1)}
                  className="btn btn-cancelar font-AvenirMedium py-2 px-4"
                  variant="outlined"
                >
                  Regresar
                </Button>
              </Stack>
            </Col>

            <Col
              sm={12}
              md={6}
              className="p-3 text-end d-flex flex-column justify-content-center"
            >
              <Link to={"update/"} style={{ textDecorationLine: "none" }}>
                <strong
                  className="w-100 text-center px-0 me-4 "
                  style={{ color: "#FD8C27", cursor: "pointer" }}
                >
                  Cambiar contraseña
                </strong>
              </Link>
            </Col>
          </Row>
        </CardContent>
      </Card>
      {openModal && (
        <ModalCargarImagen
          id={getLocalStorage("tipo") == "EMPRESA" ? Values.id : ValuesCOLAB.id}
          tipo={
            getLocalStorage("tipo") == "EMPRESA"
              ? IMG.TIPO_EMPRESAS
              : getLocalStorage("tipo") == "USER_SYSTEM"
              ? IMG.TIPO_USUARIOS_SISTEMA
              : IMG.TIPO_COLABORADORES
          }
          Titulo="Cargar imagen de perfil"
          mostrar={openModal}
          imgUrl={Values.img}
          fnCerrar={handleCloseModal}
          fnDevolverImagen={fnDevolverImagen}
        />
      )}
    </>
  );
};

export default Perfil;
