import { stringify } from "query-string";
import { getData, postdData, postUrl } from "../../../Context/backend";
import validator from "validator";
import { IMG } from "../../../Lib/Funciones Generales/Constantes";

///////////////////
/* FUNCIONES GET */
///////////////////
export const getLista = async (iTake, iSkip, Order, query) => {
  //onsole.log(query)
  let data = [];
  let queryParamsObj = {
    iTake: iTake,
    iSkip: iSkip,
    Order: Order,
    query: query,
  };
  const url = `configUsuarios/Listar?${stringify(queryParamsObj)}`;
  const res = await getData(url);
  return new Promise((resolve, reject) => {
    if (!res.error) {
      data = res.data.respuesta;
      resolve(data);
    } else {
      reject(res);
    }
  });
};

export const getInfoUsuario = async (id, imgDefault) => {
  let data = [];
  let queryParamsObj = {
    id: id,
  };

  const url = `configUsuarios/Consultar?${stringify(queryParamsObj)}`;
  const res = await getData(url);
  return new Promise((resolve, reject) => {
    if (!res.error) {
      data = JSON.parse(res.data.respuesta.data);
      //console.log(data);
      if (data.length == 0) {
        resolve({
          err: [],
          mensaje: "No se encontró información de este empleado",
        });
      } else {
        data = JSON.parse(res.data.respuesta.data)[0];
        let Values = {
          id: String(data.ID),
          activo: String(data.ACTIVO),
          nombre: String(data.NOMBRE),
          apellido_paterno: String(data.APELLIDO_PATERNO),
          apellido_materno: String(data.APELLIDO_MATERNO),
          id_sucursal: String(data.ID_SUCURSAL),
          correo: String(data.CORREO),
          telefono: String(data.TELEFONO),
          id_grupo: String(data.ID_GRUPO),
          img: String(data.IMG) == "" ? imgDefault : String(data.IMG),
        };
        resolve(Values);
      }
    } else {
      reject({
        mensaje: "Error al conectar a internet, revisa tu conexion a internet",
      });
    }
  });
};

export const getSucursales = async (ID) => {
  let data = [];
  let queryParamsObj = {
    ID_USURARIO: ID,
  };
  const url = `configUsuarios/getSucurslesRelacion?${stringify(
    queryParamsObj
  )}`;
  const res = await getData(url);
  return new Promise((resolve, reject) => {
    if (!res.error) {
      data = res.data.respuesta;
      resolve(data);
    } else {
      reject(res);
    }
  });
};

// export const getOpciones = async (ID) => {
//   let data = [];
//   let queryParamsObj = {
//     ID_SUCURSAL: ID,
//   };
//   const url = `colaboradores/getOpciones?${stringify(queryParamsObj)}`;
//   const res = await getData(url);
//   return new Promise((resolve, reject) => {
//     if (!res.error) {
//       data = res.data.respuesta;
//       // console.log(data);
//       resolve(data);
//     } else {
//       reject(res);
//     }
//   });
// };

////////////////////
/* FUNCIONES POST */
////////////////////
export const guardar = async (Values, Errores, setErrores, sucursales) => {
  let error = false;

  if (!Values.nombre || Values.nombre == "") {
    let Errores2 = Errores;
    Errores2.nombre = true;
    setErrores(Errores2);
    error = true;
  }
  if (!Values.apellido_paterno || Values.apellido_paterno == "") {
    Errores.apellido_paterno = true;
    setErrores(Errores);
    error = true;
  }
  if (!Values.apellido_materno || Values.apellido_materno == "") {
    Errores.apellido_materno = true;
    setErrores(Errores);
    error = true;
  }

  /*

  if (
    !Values.id_sucursal ||
    Values.id_sucursal == "" ||
    Values.id_sucursal == "0" ||
    Values.id_sucursal == null ||
    Values.id_sucursal == "null"
  ) {
    Errores.id_sucursal = true;
    setErrores(Errores);
    error = true;
  }
  */
  if (!Values.correo || Values.correo == "") {
    Errores.correo = true;
    setErrores(Errores);
    error = true;
  } else {
    if (!validator.isEmail(Values.correo)) {
      Errores.correo = true;
      setErrores(Errores);
      error = true;
    }
  }

  if (!Values.telefono || Values.telefono == "") {
    Errores.telefono = true;
    setErrores(Errores);
    error = true;
  } else {
    if (!validator.isNumeric(Values.telefono)) {
      Errores.telefono = true;
      setErrores(Errores);
      error = true;
    } else if (Values.telefono.length != 10) {
      Errores.telefono = true;
      setErrores(Errores);
      error = true;
    }
  }

  //  if (
  //     !Values.id_grupo ||
  //     Values.id_grupo == "" ||
  //     Values.id_grupo == "0" ||
  //     Values.id_grupo == null ||
  //     Values.id_grupo == "null"
  //   ) {
  //     Errores.id_grupo = true;
  //     setErrores(Errores);
  //     error = true;
  //   }

  const relacion = sucursales
    .filter((objeto) => objeto.ACTIVO === 1)
    .map((objeto) => objeto.ID);

  if (!error) {
    let imagen = "";
    if (Values.img.includes("base64")) {
      imagen = Values.img.split("base64,")[1];
    } else {
      imagen = "";
    }

    if (
      Values.id == "0" ||
      Values.id == 0 ||
      (Values.id == "" && Values.img != "")
    ) {
      Values.img_nueva = {
        id: "",
        tipo: IMG.TIPO_USUARIOS_SISTEMA,
        img64: imagen,
      };
      //Values.img = "";
    }
    console.log("el tipo", IMG.TIPO_USUARIOS_SISTEMA);

    let data = [];
    const body = {
      id: Values.id,
      nombre: Values.nombre,
      apellido_paterno: Values.apellido_paterno,
      apellido_materno: Values.apellido_materno,
      correo: Values.correo,
      telefono: Values.telefono,
      img_nueva: Values.img_nueva,
      img: imagen,
      id_sucursal: 0, // Values.id_sucursal,
      id_grupo: Values.id_grupo,
      relacion: relacion,
    };
    console.log("Datos", body);
    const url =
      Values.id == undefined || Values.id == "" || Values.id == 0
        ? "configUsuarios/Insertar"
        : "configUsuarios/Editar";
    const res = await postdData(url, body);
    return new Promise((resolve, reject) => {
      if (!res.error) {
        data = res.data;
        resolve(data);
      } else {
        reject(res);
      }
    });
  } else {
    return new Promise((resolve, reject) => {
      resolve({ mensaje: "Completa todos los campos requeridos" });
    });
  }
};

export const enviarAcceso = async (id) => {
  if (id != undefined) {
    let data = [];
    let queryParamsObj = {
      id: id,
    };
    const url = `configUsuarios/EnviarAcceso?${stringify(queryParamsObj)}`;
    const res = await postUrl(url);
    return new Promise((resolve, reject) => {
      if (!res.error) {
        data = res.data;
        resolve(data);
      } else {
        reject(res);
      }
    });
  } else {
    return new Promise((resolve, reject) => {
      resolve({ mensaje: "No se pudo obtener el identificador" });
    });
  }
};
export const Eliminar = async (id) => {
  if (id != undefined) {
    let data = [];
    let queryParamsObj = {
      id: id,
    };
    const url = `configUsuarios/Eliminar?${stringify(queryParamsObj)}`;
    const res = await postUrl(url);
    return new Promise((resolve, reject) => {
      if (!res.error) {
        data = res.data;
        resolve(data);
      } else {
        reject(res);
      }
    });
  } else {
    return new Promise((resolve, reject) => {
      resolve({ mensaje: "No se pudo obtener el identificador" });
    });
  }
};

export const Desactivar = async (id, checked) => {
  if (id != undefined) {
    let data = [];
    let queryParamsObj = {
      id: id,
      activo: checked,
    };
    const url = `configUsuarios/Desactivar?${stringify(queryParamsObj)}`;
    const res = await postUrl(url);
    return new Promise((resolve, reject) => {
      if (!res.error) {
        data = res.data;
        resolve(data);
      } else {
        reject(res);
      }
    });
  } else {
    return new Promise((resolve, reject) => {
      resolve({ mensaje: "No se pudo obtener el identificador" });
    });
  }
};

export const updateSuc = async (idColab, idSuc) => {
  if (idColab != undefined) {
    let data = [];
    let queryParamsObj = {
      idUser: idColab,
      idSuc: idSuc,
    };
    const url = `configUsuarios/EditarSuc?${stringify(queryParamsObj)}`;
    const res = await postUrl(url);
    return new Promise((resolve, reject) => {
      if (!res.error) {
        data = res.data;
        resolve(data);
      } else {
        reject(res);
      }
    });
  } else {
    return new Promise((resolve, reject) => {
      resolve({ mensaje: "No se pudo obtener el identificador" });
    });
  }
};
