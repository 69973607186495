import React from "react";
import { Route, Routes } from "react-router-dom";
import Error404 from "../../Includes/Error404";
//import Producto from "./Componentes/Producto";
//import RepProductos from "./Componentes/RepProductos";
import RepCuponera from "./Componentes/RepCuponera";
import Cuponera from "./Componentes/Cuponera";

const routerPro = () => {
  return (
    <>
      <Routes>
        <Route index element={<RepCuponera />} />
        <Route path="/add" element={<Cuponera />} />
        <Route path="*" element={<Error404 />} />
        {/*<Route index element={<RepProductos />} />
        <Route path="/add" element={<Producto />} />
        <Route path="/edit/:id" element={<Producto />} />
        <Route path="*" element={<Error404 />} />*/}
      </Routes>
    </>
  );
};

export default routerPro;
