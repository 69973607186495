import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { Button, Card, CardContent, Stack } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { Link } from "react-router-dom";
import { GetDate } from "../../../Lib/Funciones Generales/ObtenerFecha";
import { getOpciones } from "../../../Reservaciones/Funciones/Reservaciones";
import { BasicDatePicker } from "../../../Includes/DatePicker";
import { getOpcionesCola } from "./Funciones/PDFComisiones";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";

const MySwal = withReactContent(Swal);
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const ReporteComisiones = () => {
  const [fechaInicio, setFechaInicio] = useState(GetDate(-7));
  const [fechaFin, setFechaFin] = useState(GetDate());
  useEffect(() => {
    document.title = `Reporte de comisiones`;
  }, []);
  const theme = useTheme();
  const [sucursales, setSucursales] = useState([]);
  const [colaboradores, setColaborador] = useState([]);
  const [seleccionados, setSeleccionados] = useState([]);
  const [selecionadocol, setSelecionadocol] = useState("");
  const todasLasSucursales = { ID: -1, DESCRIPCION: "Seleccionar todas" };
  const opcionesSucursales = [...sucursales, todasLasSucursales];
  const [banderaSeleccion, setBanderaSeleccion] = useState(false);

  useEffect(() => {
    getOpciones()
      .then((resp) => {
        const opciones = JSON.parse(resp.data);
        setSucursales(opciones);
      })
      .catch((err) => console.error(err));
  }, []);

  useEffect(() => {
    getOpcionesCola()
      .then((resp) => {
        const opciones = JSON.parse(resp.data);
        setColaborador(opciones);
      })
      .catch((err) => console.error(err));
  }, []);

  // const handleChange = (event) => {
  //     setSeleccionados(event.target.value);
  // };

  const handleChanges = (event) => {
    setSelecionadocol(event.target.value);
  };

  const handleSelectAll = (event) => {
    if (event.target.value.includes(-1)) {
      setSeleccionados(
        opcionesSucursales.filter((s) => s.ID !== -1).map((s) => s.ID)
      );
      setBanderaSeleccion(true); // actualizar el estado de la bandera
    } else {
      setSeleccionados([]);
      setBanderaSeleccion(false); // actualizar el estado de la bandera
    }
  };

  const handleChangeFechaIn = (event) => {
    // const startDate = new Date(event);
    // const endDate = new Date(fechaFin);
    // const daysDifference = Math.floor((endDate - startDate) / (1000 * 60 * 60 * 24));
    // if (daysDifference >= 0 && daysDifference <= 31) {
    //     //var fecha = new Date(event);
    //     startDate.setDate(startDate.getDate());
    //     const year = startDate.getFullYear();
    //     const month = startDate.getMonth() + 1;
    //     const day = startDate.getDate();
    //     let FECHA = year + "-" + (month < 10 ? "0" + month : month) + "-" + (day < 10 ? "0" + day : day);
    //     setFechaInicio(FECHA);
    // }
    // else {
    //     MySwal.fire({
    //         title: "Advertencia",
    //         html: "El rango minimo para consultar informacion es de 1 mes, intenta con un rango mas corto",
    //         icon: "warning",
    //         confirmButtonColor: "#FD8C27",
    //         showConfirmButton: true,
    //         allowEscapeKey: false,
    //         allowEnterKey: false,
    //         allowOutsideClick: false,
    //     })
    // }
    const startDate = new Date(event);
    const endDate = new Date(fechaFin);
    startDate.setDate(startDate.getDate());
    const year = startDate.getFullYear();
    const month = startDate.getMonth() + 1;
    const day = startDate.getDate();
    let FECHA =
      year +
      "-" +
      (month < 10 ? "0" + month : month) +
      "-" +
      (day < 10 ? "0" + day : day);
    setFechaInicio(FECHA);
  };

  const handleChangeFechaFIn = (event) => {
    // const startDate = new Date(fechaInicio);
    // const endDate = new Date(event);
    // const daysDifference = Math.floor((endDate - startDate) / (1000 * 60 * 60 * 24));
    // if (daysDifference >= 0 && daysDifference <= 31) {
    //     //var fecha = new Date(event);
    //     endDate.setDate(endDate.getDate());
    //     const year = endDate.getFullYear();
    //     const month = endDate.getMonth() + 1;
    //     const day = endDate.getDate();
    //     let FECHA = year + "-" + (month < 10 ? "0" + month : month) + "-" + (day < 10 ? "0" + day : day);
    //     setFechaFin(FECHA);
    // }
    // else {
    //     MySwal.fire({
    //         title: "Advertencia",
    //         html: "El rango maximo para consultar informacion es de 1 mes, intenta con un rango mas corto",
    //         icon: "warning",
    //         confirmButtonColor: "#FD8C27",
    //         showConfirmButton: true,
    //         allowEscapeKey: false,
    //         allowEnterKey: false,
    //         allowOutsideClick: false,
    //     })
    // }

    const startDate = new Date(fechaInicio);
    const endDate = new Date(event);
    endDate.setDate(endDate.getDate());
    const year = endDate.getFullYear();
    const month = endDate.getMonth() + 1;
    const day = endDate.getDate();
    let FECHA =
      year +
      "-" +
      (month < 10 ? "0" + month : month) +
      "-" +
      (day < 10 ? "0" + day : day);
    setFechaFin(FECHA);
  };
  // console.log("El estado de la bandera es:", banderaSeleccion);
  // console.log("SUCURSALES: ", seleccionados)
  // console.log("cOLABORADORES: ", selecionadocol)

  const generarReporte = () => {
    const startDate = new Date(fechaInicio);
    const endDate = new Date(fechaFin);
    const daysDifference = Math.floor(
      (endDate - startDate) / (1000 * 60 * 60 * 24)
    );
    if (daysDifference >= 31) {
      MySwal.fire({
        title: "Advertencia",
        html: "El rango maximo para consultar informacion es de 1 mes, intenta con un rango mas corto",
        icon: "warning",
        confirmButtonColor: "#FD8C27",
        showConfirmButton: true,
        allowEscapeKey: false,
        allowEnterKey: false,
        allowOutsideClick: false,
      });
      return;
    }
    window.open(
      `../pdf/comisiones/${
        banderaSeleccion ? "TODAS" : seleccionados
      }/${selecionadocol}/${fechaInicio}/${fechaFin}`,
      "_blank"
    );
  };

  return (
    <>
      <Card elevation={3} style={{ marginTop: "150px" }}>
        <CardContent className="p-3">
          <div className="p-3">
            <h6 className="mb-0 text-left font-AvenirBold">
              Reporte de comisiones
            </h6>
          </div>
          <hr
            style={{ backgroundColor: "#DFDEE0", height: "1px", opacity: "1" }}
            className="m-0 p-0"
          />
          <Row>
            <Col xs={12} md={4} style={{ marginTop: "15px" }}>
              <BasicDatePicker
                fullWidth
                format="DD/MM/YYYY"
                label="Fecha de inicio"
                fecha={fechaInicio}
                setFecha={handleChangeFechaIn}
              />
            </Col>
            <Col xs={12} md={4} style={{ marginTop: "15px" }}>
              <BasicDatePicker
                fullWidth
                format="DD/MM/YYYY"
                label="Fecha de fin"
                fecha={fechaFin}
                setFecha={handleChangeFechaFIn}
              />
            </Col>
            <Col xs={12} md={4} style={{ marginTop: "15px" }}>
              <div>
                <FormControl fullWidth>
                  <InputLabel id="demo-multiple-sucursal-label">
                    Sucursales
                  </InputLabel>
                  <Select
                    fullWidth
                    labelId="demo-multiple-sucursal-label"
                    id="demo-multiple-sucursal"
                    multiple
                    value={seleccionados}
                    onChange={(e) => {
                      if (e.target.value.includes(-1)) {
                        handleSelectAll(e);
                      } else {
                        setSeleccionados(e.target.value);
                        console.log(e.target.value);
                        setBanderaSeleccion(false); // actualizar el estado de la bandera
                      }
                    }}
                    input={<OutlinedInput label="Sucursales" />}
                  >
                    {opcionesSucursales.map((sucursal) => (
                      <MenuItem
                        key={sucursal.ID}
                        value={sucursal.ID}
                        style={getStyles(
                          sucursal.DESCRIPCION,
                          opcionesSucursales,
                          theme
                        )}
                      >
                        {sucursal.DESCRIPCION}{" "}
                        {seleccionados.includes(sucursal.ID) && ""}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </div>
            </Col>
            <Col xs={12} md={4} style={{ marginTop: "15px" }}>
              <div>
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">
                    Colaborador
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={selecionadocol}
                    onChange={handleChanges}
                    input={<OutlinedInput label="Colaborador" />}
                  >
                    {colaboradores.map((colaborador) => (
                      <MenuItem key={colaborador.ID} value={colaborador.ID}>
                        {colaborador.DESCRIPCION}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </div>
            </Col>
          </Row>
          <Stack
            className="py-4"
            spacing={2}
            direction={{ xs: "column", sm: "row" }}
            style={{ float: "right" }}
          >
            {/* <Link
                            target={seleccionados.length == 0 ? "_self" : "_blank"}
                            to={
                                (seleccionados.length == 0 || selecionadocol == "")
                                    ? "#"
                                    : `../pdf/comisiones/${banderaSeleccion ? "TODAS" : seleccionados}/${selecionadocol}/${fechaInicio}/${fechaFin}`
                            }
                            style={{ textDecoration: "none" }}
                        >
                            <Button
                                disabled={selecionadocol == "" || seleccionados.length == 0 }
                                className="font-AvenirMedium  py-2 px-4 "
                                variant="contained"
                                color="error"
                            >
                                Generar Reporte
                            </Button>
                        </Link> */}

            <Button
              onClick={generarReporte}
              disabled={selecionadocol == "" || seleccionados.length == 0}
              className="font-AvenirMedium  py-2 px-4 "
              variant="contained"
              color="error"
            >
              Generar Reporte
            </Button>
          </Stack>
        </CardContent>
      </Card>
    </>
  );
};
function getStyles(name, sucursales, theme) {
  return {
    fontWeight:
      sucursales.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}
export default ReporteComisiones;
