import * as React from "react";
import {
  getTiempoFromHorasToMinutos,
  getTiempoFromMinutos,
} from "../../Lib/Funciones Generales/Generales";
import {
  Divider,
  FormControlLabel,
  FormGroup,
  IconButton,
  Link,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import Menos from "./../../assets/Menos.svg";
import Mas from "./../../assets/Mas.svg";
import Descuento from "./../../assets/Descuento.svg";
import EliminarRojo from "./../../assets/EliminarRojo.svg";
import { color } from "@mui/system";
import { Col } from "react-bootstrap";
import Switch from "../../Includes/Switch";
import { useEffect } from "react";

export default function Carrito({
  productosCarrito,
  setProductosCarrito,
  descuento,
  setDescuento,
  cambio,
  isVista = false,
}) {
  let totalTiempo = 0;
  let total = 0;
  {
    productosCarrito.length > 0
      ? productosCarrito.map((row) => {
          total += parseFloat(row.PRECIO) * row.CANTIDAD;
          totalTiempo += row.DURACION * row.CANTIDAD;
          row.DESCUENTO = 0;
          row.TIPO_DESCUENTO = 0;
        })
      : (total = 0);
  }
  let totalDescuento =
    descuento.tipo == 0
      ? 0
      : descuento.tipo == 1
      ? (total * descuento.descuento) / 100 > descuento.max
        ? descuento.max
        : (total * descuento.descuento) / 100
      : descuento.descuento;
  const addItem = (newItem) => {
    const items = [...productosCarrito];
    const itemIndex = Array.isArray(items)
      ? items.findIndex((item) => item.ID === newItem.ID)
      : -1;
    if (itemIndex !== -1) {
      items[itemIndex].CANTIDAD += 1;
    } else {
      items.push(newItem);
    }
    setProductosCarrito(items);
  };

  const disminuirItem = (newItem) => {
    const items = [...productosCarrito];
    const itemIndex = Array.isArray(items)
      ? items.findIndex((item) => item.ID === newItem.ID)
      : -1;
    if (itemIndex !== -1) {
      items[itemIndex].CANTIDAD -= 1;
      if (items[itemIndex].CANTIDAD <= 0) {
        items.splice(itemIndex, 1);
      }
    } else {
      items.push(newItem);
    }
    setProductosCarrito(items);
  };

  const deleteItem = (newItem) => {
    const items = [...productosCarrito];
    const itemIndex = Array.isArray(items)
      ? items.findIndex((item) => item.ID === newItem.ID)
      : -1;
    if (itemIndex !== -1) {
      items.splice(itemIndex, 1);
    } else {
    }
    setProductosCarrito(items);
  };

  return (
    <>
      <div className="row">
        <div className="col-12">
          <table className="w-100">
            {isVista && (
              <tr>
                <td className="p-1 ps-0">
                  <h6 className="font-AvenirBold">Concepto</h6>
                </td>
                <td className="p-1 text-center">
                  <h6 className="font-AvenirBold tex-end">Cantidad</h6>
                </td>
                <td className="p-2 pe-0 text-end">
                  <h6 className="font-AvenirBold">Precio</h6>
                </td>
              </tr>
            )}

            {productosCarrito.map((row) => (
              <>
                <tr>
                  <td className="p-1 ps-0">
                    <div className="flex-grow-1 w-100">
                      <p
                        className="my-0  font-AvenirMedium"
                        style={{ fontSize: "13px", color: "#808c9f" }}
                      >
                        {row.DESCRIPCION}
                      </p>
                      <p
                        className="my-0 font-Avenir"
                        style={{ fontSize: "11px", color: "#808c9f" }}
                      >
                        {getTiempoFromMinutos(row.DURACION)}
                      </p>
                    </div>
                  </td>
                  <td className="p-1 text-center">
                    <div
                      className={
                        !isVista ? "d-flex justify-content-between " : ""
                      }
                    >
                      {!isVista && (
                        <div>
                          <IconButton
                            className="   mx-2"
                            onClick={() => addItem(row)}
                          >
                            <img src={Mas} style={{ width: "15px" }} />
                          </IconButton>
                        </div>
                      )}
                      {isVista ? (
                        <div
                          className={
                            "d-flex justify-content-center flex-column"
                          }
                          style={{ maxWidth: "150px" }}
                        >
                          <p className="my-0">{row.CANTIDAD}</p>
                        </div>
                      ) : (
                        <div
                          className="d-flex justify-content-center flex-column mx-2 "
                          style={{ width: "30px" }}
                        >
                          <p
                            className="my-0 mb-3 algin-self-center font-Avenir mb-1 text-center"
                            style={{ fontSize: "134x", color: "#808c9f" }}
                          >
                            {row.CANTIDAD}
                          </p>
                        </div>
                      )}

                      {!isVista && (
                        <div>
                          <IconButton
                            aria-label="delete"
                            className="   mx-2"
                            onClick={() => disminuirItem(row)}
                          >
                            <img src={Menos} style={{ width: "15px" }} />
                          </IconButton>
                        </div>
                      )}
                      {!isVista && (
                        <div>
                          <IconButton
                            aria-label="delete"
                            className="   mx-1"
                            onClick={() => deleteItem(row)}
                          >
                            <img src={EliminarRojo} style={{ width: "15px" }} />
                          </IconButton>
                        </div>
                      )}
                    </div>
                  </td>
                  <td className="p-2 pe-0 text-end">
                    <div
                      className="d-flex justify-content-center flex-column mx-2 text-end"
                      style={{ minWidth: "90px" }}
                    >
                      <p
                        className="my-0 algin-self-center  font-AvenirMedium text-nowrap"
                        style={{ fontSize: "13px", color: "#808c9f" }}
                      >
                        {" "}
                        ${row.PRECIO} MXN{" "}
                      </p>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td colSpan={3}>
                    <Divider style={{ background: "#e2e2e2" }} />
                  </td>
                </tr>
              </>
            ))}
          </table>
        </div>

        {totalDescuento != 0 && !isVista ? (
          <>
            <div className="col-12 mt-3 text-end">
              <Link
                className=" font-AvenirMedium font-carrito"
                variant="text"
                href="#"
                underline="none"
                onClick={() => {
                  setDescuento({ id: 0, descuento: 0, tipo: 0 });
                }}
                style={{ color: "#d14343", textAlign: "end" }}
              >
                Eliminar descuentos
              </Link>
            </div>
          </>
        ) : (
          ""
        )}

        <div className="col-12 mt-3 d-flex justify-content-end">
          <table className="w-100">
            <tr>
              <td className="text-end pe-5">
                <h6 className=" font-gray font-carrito text-end font-AvenirMedium">
                  TOTAL DURACIÓN
                </h6>
              </td>
              <td className="text-end">
                <h6 className=" font-gray font-carrito text-end font-AvenirMedium">
                  {getTiempoFromMinutos(totalTiempo)}
                </h6>
              </td>
            </tr>

            <tr>
              <td className="text-end pe-5">
                <h6 className=" font-gray font-carrito text-end font-AvenirMedium">
                  SUBTOTAL
                </h6>
              </td>
              <td className="text-end">
                <h6 className=" font-gray font-carrito text-end font-AvenirMedium">
                  ${total} MXN
                </h6>
              </td>
            </tr>

            <tr>
              <td className="text-end pe-5">
                <h6 className=" font-gray font-carrito text-end font-AvenirMedium">
                  DESCUENTO
                </h6>
              </td>
              <td className="text-end">
                <h6 className=" font-gray font-carrito text-end font-AvenirMedium">
                  ${totalDescuento} MXN
                </h6>
              </td>
            </tr>

            <tr>
              <td className="text-end pe-5">
                <h6 className=" font-gray font-carrito text-end font-AvenirMedium">
                  TOTAL
                </h6>
              </td>
              <td className="text-end">
                <h6 className=" font-gray font-carrito text-end font-AvenirMedium">
                  ${" "}
                  {!isVista
                    ? total - totalDescuento
                    : total - totalDescuento < 0
                    ? 0
                    : total - totalDescuento}{" "}
                  MXN
                </h6>
              </td>
              <td className="text-end"></td>
            </tr>
            {cambio > 0 ? (
              <tr>
                <td className="text-end">
                  <h6 className=" font-gray font-carrito text-end font-AvenirMedium">
                    CAMBIO
                  </h6>
                </td>
                <td className="text-end">
                  <h6
                    className="font-carrito text-end font-AvenirMedium"
                    style={{ color: "#3abe88" }}
                  >
                    $ {cambio} MXN
                  </h6>
                </td>
              </tr>
            ) : (
              ""
            )}
          </table>
        </div>

        <div>
          <div className="row"></div>
        </div>
      </div>

      <Col sm={12} className="d-flex justify-content-end d-none">
        <div
          className="d-flex justify-content-center flex-column me-3 "
          style={{ width: "30px" }}
        >
          <p
            className="my-0 algin-self-center font-AvenirBold mb-1 text-center"
            style={{ fontSize: "13px" }}
          >
            Pagado{" "}
          </p>
        </div>
        {/*<Switch
          checked={Values.pagado}
          name="pagado"
          error={Errores.pagado}
          onChange={(checked) => {
            handlInputChange({
              target: {
                name: "pagado",
                value: checked,
              },
            });
          }}
          tituloActivo="Si Pagado"
          tituloInactivo="No pagado"
        />*/}
      </Col>
    </>
  );
}
