import { stringify } from "query-string";
import { getData, postdData, postUrl } from "../../Context/backend";
function validarFormatoHora(cadena) {
  var formatoHora = /^([01]\d|2[0-3]):([0-5]\d)$/;
  return formatoHora.test(cadena);
}

///////////////////
/* FUNCIONES GET */
///////////////////
export const getHorariosSuc = async (id = 0) => {
  let data = [];
  let queryParamsObj = {
    ID_COLAB: id,
  };

  const url = `sucursales/getHorariosColaboraSuc?${stringify(queryParamsObj)}`;
  const res = await getData(url);
  return new Promise((resolve, reject) => {
    if (!res.error) {
      data = res.data.respuesta;
      resolve(data);
    } else {
      reject(res);
    }
  });
};

export const getHorarioById = async (id) => {
  let data = [];
  let queryParamsObj = {
    ID_HORARIO: id,
  };
  const url = `horarios/getHorariosColaboradorByIdHorario?${stringify(
    queryParamsObj
  )}`;
  const res = await getData(url);
  return new Promise((resolve, reject) => {
    if (!res.error) {
      data = res.data.respuesta;
      resolve(data);
    } else {
      reject(res);
    }
  });
};

///////////////////
/* FUNCIONES POST */
///////////////////
export const guardarHorario = async (datos, Errores, setErrores) => {
  let error = false;
  let data = [];
  let format;

  if (
    datos.inicio == "" ||
    datos.inicio.length < 5 ||
    datos.inicio.length > 5
  ) {
    error = true;
    Errores.inicio = true;
    setErrores(Errores);
  } else {
    format = validarFormatoHora(datos.inicio);
  }

  if (!format) {
    error = true;
    Errores.inicio = true;
    setErrores(Errores);
  }
  format = undefined;

  if (datos.fin == "" || datos.fin.length < 5 || datos.fin.length > 5) {
    error = true;
    Errores.fin = true;
    setErrores(Errores);
  } else {
    format = validarFormatoHora(datos.fin);
  }

  if (!format) {
    error = true;
    Errores.fin = true;
    setErrores(Errores);
  }

  if (!error) {
    let queryParamsObj = {};
    const url = "sucursales/actualizarHorarioColaborador";
    //console.log(datos);
    const cadena = datos.resource_id;
    const valores = cadena.split("|");

    const sucursal = valores[0];
    const coabora = valores[1];

    const body = {
      id: datos.id,
      dia: datos.dia,
      id_sucursal: sucursal,
      id_Colaborador: coabora,
      inicio: datos.inicio,
      fin: datos.fin,
    };

    //console.log(body);

    const res = await postdData(url, body);
    //const res = { error: false, data: { codigo: "201", mensaje: "okey" } };
    return new Promise((resolve, reject) => {
      if (!res.error) {
        data = res.data;
        resolve(data);
      } else {
        reject(res);
      }
    });
  } else {
    return new Promise((resolve, reject) => {
      resolve({ mensaje: "Completa todos los campos requeridos" });
    });
  }
};

export const Eliminar = async (datos) => {
  if (datos.id != undefined) {
    let data = [];
    /*let queryParamsObj = {
      id: id,
    };*/
    const cadena = datos.resource_id;
    const valores = cadena.split("|");

    const sucursal = valores[0];
    const coabora = valores[1];

    const body = {
      id: datos.id,
      dia: datos.dia,
      id_sucursal: sucursal,
      id_Colaborador: coabora,
      inicio: datos.inicio,
      fin: datos.fin,
    };
    //const url = `sucursales/EliminarHorario?${stringify(queryParamsObj)}`;
    const url = `sucursales/EliminarHorario`;
    const res = await postdData(url, body);
    return new Promise((resolve, reject) => {
      if (!res.error) {
        data = res.data;
        resolve(data);
      } else {
        reject(res);
      }
    });
  } else {
    return new Promise((resolve, reject) => {
      resolve({ mensaje: "No se pudo obtener el identificador" });
    });
  }
};
