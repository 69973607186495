import React, { useState, useEffect, useContext } from "react";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import Card from "@mui/material/Card";
import FullCalendarApp from "./FullCalendarApp";
import Reservacion from "./Reservacion";
import { getLista } from "./../Funciones/Reservaciones";
import { Alert, IconButton, Snackbar, styled } from "@mui/material";
import DetallesReservacion from "./DetallesReservacion";
import SelectSucursal from "../../Configuraciones/Sucursales/Componentes/SelectSucursal";
import Colaboradores from "../../Catalogos/Colaboradores/Componentes/SelectColaboradores";
import { getOpcionesv2 } from "../../Catalogos/Colaboradores/Funciones/Colaboradores";
import dayjs from "dayjs";
import { CalendarPicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import DateFnsUtils from "@date-io/date-fns";
//import esLocale from "date-fns/locale/es";
import { GetDate } from "../../Lib/Funciones Generales/ObtenerFecha";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import Offcanvas from "react-bootstrap/Offcanvas";
import CloseIcon from "@mui/icons-material/Close";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";

import { IconContext } from "../../Includes/menu";
import { getInfoConfigGeneral } from "../../Configuraciones/Generales/Funciones/Generales";
import { convertirTiempo } from "../../Lib/Funciones Generales/Generales";

const RepReservaciones = () => {
  const context = useContext(IconContext);
  const [mensaje, setMensaje] = useState("");
  const [open, setOpen] = useState(false);
  const [IsLoading, setIsLoading] = useState(true);
  const [showModalReserva, setShowModalReserva] = useState(false);
  const [showReservacion, setShowReservacion] = useState(false);
  const [idReserv, setIdReserv] = useState("0");
  const [folioReservacion, setFolioReservacion] = useState("0");
  const [startDate, setStartDate] = React.useState(
    dayjs(GetDate()).format("YYYY-MM-DD")
  );
  const [viewStartDate, setViewStartDate] = useState(null);
  const [viewEndDate, setViewEndDate] = useState(null);
  const handleCloseSnack = () => setOpen(false);
  const [date, setDate] = React.useState(dayjs(GetDate()));

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [closeFilters, setCloseFilters] = useState(false);
  const [colaboradores, setColaboradores] = useState([]);

  const [fechaReserva, setFechaReserva] = useState(null);

  const [rango, setRango] = useState({ inicio: "09:00:00", fin: "23:00:00" });

  const [Values, setValues] = useState({
    fecha_inicio: "",
    fecha_final: "",
    id_colaborador: "",
    id_sucursal: "",
  });
  const [config, setConfig] = useState({
    id: 0,
    encabezado: "",
    pie: "",
    linea_1: "",
    incrementos_horarios: "",
    si_cobro: false,
    ancho: 80,
    fuente: 11,
  });

  const [reservaciones, setReservaciones] = useState([]);
  const [intervalo, setIntervalo] = useState("00:15:00");
  const [slotLabelInterval, setSlotLabelInterval] = useState({
    hours: 0,
    minutes: 15,
    seconds: 0,
  });

  const StyledSnackbar = styled((props) => <Snackbar {...props} />)(
    ({ theme }) => ({
      "& .MuiSnackbar-root": {
        top: theme.spacing(15),
      },
    })
  );

  const getReservaciones = () => {
    setIsLoading(true);
    if (viewStartDate != null) {
      getLista(
        dayjs(viewStartDate).format("YYYY-MM-DD"),
        dayjs(viewEndDate).format("YYYY-MM-DD"),
        Values.id_colaborador,
        Values.id_sucursal
      )
        .then((resp) => {
          let list = JSON.parse(resp.data);
          setReservaciones(list);
          setIsLoading(false);
        })
        .catch((resp) => {
          setReservaciones([]);
          setIsLoading(false);
        });
    }
  };

  const getColaboradores = () => {
    setIsLoading(true);
    if (viewStartDate != null) {
      getOpcionesv2(Number(Values.id_sucursal))
        .then((resp) => {
          let list = JSON.parse(resp.data);
          if (list.length != 0) {
            const filteredResources = list.map((resource) => {
              return {
                id: resource.ID,
                title: resource.DESCRIPCION,
              };
            });
            setColaboradores(filteredResources);
          } else {
            setColaboradores([]);
          }
        })
        .catch((resp) => {
          console.log(resp);
          setColaboradores([]);
        });
    }
  };

  const handlInputChange = ({ target }) => {
    let Name = target.name;
    let Value = target.value;
    if (Name == "precio") {
      Value = Value.toDecimal();
    }
    setValues({
      ...Values,
      [Name]: Value,
    });
  };

  useEffect(() => {
    getReservaciones();
  }, [Values.id_colaborador, viewStartDate, Values.id_sucursal]);

  useEffect(() => {
    getColaboradores();
  }, [viewStartDate, Values.id_sucursal]);

  useEffect(() => {
    document.title = `Reservaciones`;
    context.setOpen(false);
  }, []);
  useEffect(() => {
    getInfoConfigGeneral()
      .then((resp) => {
        if (resp?.err?.length != 0) {
          setConfig(resp.values);
          setIntervalo(convertirTiempo(resp.values.incrementos_horarios));
          const ARR_TIME = convertirTiempo(
            resp.values.incrementos_horarios
          ).split(":");
          const h = ARR_TIME[0] ? Number(ARR_TIME[0]) : 0;
          const m = ARR_TIME[1] ? Number(ARR_TIME[1]) : 15;
          const s = ARR_TIME[2] ? Number(ARR_TIME[2]) : 0;
          setSlotLabelInterval({
            ...slotLabelInterval,
            hours: h,
            minutes: m,
            seconds: s,
          });

          setRango({
            ...rango,
            inicio: resp.other.INICIO_H ? resp.other.INICIO_H : "09:00:00",
            fin: resp.other.FIN_H ? resp.other.FIN_H : "23:00:00",
          });
        }
      })
      .catch((resp) => {});
  }, []);

  return (
    <>
      <Offcanvas show={show} onHide={handleClose}>
        <Offcanvas.Header closeButton>
          <h5 className="font-AvenirBold">Filtrar reservaciones</h5>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <Card variant="outlined" className="p-1 shadow h-100">
            <div className="p-2">
              <SelectSucursal
                size="small"
                Value={Values.id_sucursal}
                Error={null}
                handlInputChange={handlInputChange}
                showTodos={true}
              />
            </div>
            <div className="p-2">
              <Colaboradores
                size="small"
                Value={Values.id_colaborador}
                Error={null}
                id_sucursal={Values.id_sucursal}
                handlInputChange={handlInputChange}
                disabled={Values.id_sucursal === "" ? true : false}
                showTodos={true}
              />
            </div>

            <div className="pt-2">
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <CalendarPicker
                  date={date}
                  onChange={(newDate) => {
                    setStartDate(dayjs(newDate).format("YYYY-MM-DD"));
                    setDate(newDate);
                  }}
                  dayClassName={"dayClass"}
                />
              </LocalizationProvider>
            </div>
          </Card>
        </Offcanvas.Body>
      </Offcanvas>

      <StyledSnackbar direction="right">
        <Alert
          onClose={handleCloseSnack}
          severity="error"
          sx={{ width: "100%" }}
        >
          {mensaje}
        </Alert>
      </StyledSnackbar>
      <div className="d-flex justify-content-between flex-wrap my-4">
        <div className="p-2 d-block d-md-none">
          <Button
            onClick={() => handleShow()}
            className="btn  font-AvenirBold  text-star ps-0 "
            variant="text"
          >
            <FilterAltIcon className="me-3" />
            Filtros
          </Button>
        </div>
        <div
          style={{
            display: closeFilters ? "block" : "none",
          }}
        >
          <div className="p-1 d-none d-md-block">
            <Button
              onClick={() => {
                setCloseFilters(false);
              }}
              className="btn  font-AvenirBold  text-star ps-0 "
              variant="text"
            >
              <FilterAltIcon className="me-3" />
              Filtros
            </Button>
          </div>
        </div>
        <div className="p-2">
          <h4 className="text-left font-AvenirBold">Reservaciones</h4>
        </div>
        <div className="p-2">
          <Stack spacing={2} direction={{ xs: "column", sm: "row" }}>
            <Button
              onClick={() => {
                setFechaReserva(null);
                setShowModalReserva(true);
              }}
              className="btn btn-Kalendar px-4 "
              variant="contained"
            >
              Agregar reserva
            </Button>
            {showModalReserva ? (
              <Reservacion
                fechaReserva={fechaReserva}
                setShowModalReserva={setShowModalReserva}
                setMensaje={setMensaje}
                getReservaciones={getReservaciones}
                idReserv={idReserv}
                setIdReserv={setIdReserv}
                folioReservacion={folioReservacion}
                setFolioReservacion={setFolioReservacion}
                config={config}
                setConfig={setConfig}
                id_suc={Values.id_sucursal}
              />
            ) : (
              ""
            )}
            {showReservacion ? (
              <DetallesReservacion
                setShowReservacion={setShowReservacion}
                setShowModalReserva={setShowModalReserva}
                showModalReserva={showModalReserva}
                idReserv={idReserv}
                setIdReserv={setIdReserv}
                folioReservacion={folioReservacion}
                setFolioReservacion={setFolioReservacion}
                getReservaciones={getReservaciones}
              />
            ) : (
              ""
            )}
          </Stack>
        </div>
      </div>

      <div className="d-flex w-100 flex-wrap flex-md-nowrap">
        <div
          //className={"p-1 " + closeFilters ? " d-none d-md-block" : " d-none"}
          className={"p-1 d-none d-md-block"}
        >
          <Card
            variant="outlined"
            className="p-1 shadow h-100"
            style={{ display: closeFilters ? "none" : "block" }}
          >
            <div className="p-2 text-end">
              <IconButton
                aria-label="delete"
                onClick={() => {
                  setCloseFilters(true);
                }}
              >
                <CloseIcon size="lg" />
              </IconButton>
            </div>
            <div className="p-2">
              <SelectSucursal
                size="small"
                Value={Values.id_sucursal}
                Error={null}
                handlInputChange={handlInputChange}
                showTodos={true}
              />
            </div>
            <div className="p-2">
              <Colaboradores
                size="small"
                Value={Values.id_colaborador}
                Error={null}
                id_sucursal={Values.id_sucursal}
                handlInputChange={handlInputChange}
                disabled={Values.id_sucursal === "" ? true : false}
                showTodos={true}
              />
            </div>

            <div className="pt-2">
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <CalendarPicker
                  date={date}
                  onChange={(newDate) => {
                    setStartDate(dayjs(newDate).format("YYYY-MM-DD"));
                    setDate(newDate);
                  }}
                  dayClassName={"dayClass"}
                />
              </LocalizationProvider>
            </div>
          </Card>
        </div>
        <div className="p-1 flex-grow-1">
          <Card variant="outlined" className="p-1 shadow">
            <div className="p-2">
              <FullCalendarApp
                setFechaReserva={setFechaReserva}
                setShowModalReserva={setShowModalReserva}
                reservaciones={reservaciones}
                idReserv={idReserv}
                idColaborador={Values.id_colaborador}
                idSucursal={Values.id_sucursal}
                setIdReserv={setIdReserv}
                setShowReservacion={setShowReservacion}
                startDate={startDate}
                viewStartDate={viewStartDate}
                setViewStartDate={setViewStartDate}
                viewEndDate={viewEndDate}
                setViewEndDate={setViewEndDate}
                config={config}
                intervalo={intervalo}
                setFolioReservacion={setFolioReservacion}
                getReservaciones={getReservaciones}
                slotLabelInterval={slotLabelInterval}
                colaboradores={colaboradores}
                rango={rango}
              />
            </div>
          </Card>
        </div>
      </div>
    </>
  );
};

export default RepReservaciones;
