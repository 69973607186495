import * as React from "react";
import { DataGrid, GridColDef, GridValueGetterParams } from "@mui/x-data-grid";
import { formatMoneda } from "../../../Lib/Funciones Generales/Generales";
import { Button } from "react-bootstrap";
import { useState } from "react";
import { procesarPagoComisiones } from "../Funciones/Comisiones";
import withReactContent from "sweetalert2-react-content";
import { styled } from "@mui/material/styles";
import Swal from "sweetalert2";
import { Alert, Snackbar } from "@mui/material";
import { LoadingButton } from "@mui/lab";
const MySwal = withReactContent(Swal);

const columns = [
  { field: "id", headerName: "Folio", align: "left" },
  { field: "fecha", headerName: "Fecha", align: "left", width: 200 },
  { field: "total", headerName: "Total", align: "left" },
  {
    field: "comision",
    headerName: "Comisión",
    align: "left",
  },

  {
    field: "incentivos",
    headerName: "Incentivos",
    align: "left",
  },
  {
    field: "pagada",
    headerName: "Pagada",
    align: "left",
  },
];

 
export default function RepComisiones({ comisiones, colaborador, IsLoading , getComisionesByColabora, 
  setComisiones}) {
  const [selectedRows, setSelectedRows] = useState([]);
  const [IsGuardando, setIsGuardando] = useState(false); 
  const [open, setOpen] = useState(false);
  const [mensaje, setMensaje] = useState(""); 
  
 
  let rows = [];

  let TotalApagar = 0;
  let TotalPagado = 0;
  let TotalNoPagado = 0;
  let TotalIncentivos = 0;

  let TotalIncenttivosApagar = 0;
  let TotalReservacionesApagar = 0;

  comisiones.map((row) => {
    rows.push({
      id: parseInt(row.FOLIO),
      fecha: row.FECHA + " " + row.HORA,
      total: formatMoneda(row.TOTAL_RESERVACION),
      comision: formatMoneda(row.COMISION),
      incentivos: formatMoneda(row.INCENTIVOS),
      pagada: row.COMISION_PAGADA == 1 ? "SI" : "NO",
    });
    TotalPagado +=
      row.COMISION_PAGADA == 0
        ? parseFloat(row.COMISION) + parseFloat(row.INCENTIVOS)
        : 0;
    TotalNoPagado += row.COMISION_PAGADA == 0 ? parseFloat(row.COMISION) : 0;
    TotalIncentivos +=
      row.COMISION_PAGADA == 0 ? parseFloat(row.INCENTIVOS) : 0;
  });

  comisiones.map((row) => {
    const indice = selectedRows.indexOf(row.FOLIO);
    if (indice != -1) {
      TotalApagar += parseFloat(row.COMISION) + parseFloat(row.INCENTIVOS);
      TotalIncenttivosApagar +=   parseFloat(row.INCENTIVOS);
      TotalReservacionesApagar += parseFloat(row.COMISION)  ;
    }
  });

 
    const GuardarPagoComisiones = () => {
      setIsGuardando(true);
      procesarPagoComisiones(colaborador.id, 
        selectedRows, 
        TotalReservacionesApagar, 
        TotalIncenttivosApagar)

        .then((data) => {
          if (data.codigo == "200") {
            setIsGuardando(false);
            MySwal.fire({
              title: "Correcto",
              html: data.mensaje,
              icon: "success",
              confirmButtoColor: "#3ABE88",
              showConfirmButton: false,
              timer: 1700,
            }).then((result) => { 
              setComisiones([]);
              getComisionesByColabora()
            });
          } else {
            setMensaje(data.mensaje);
            setOpen(true);
            setIsGuardando(false);
          }
        })
        .catch((data) => {
          // alert(data.mensaje);
          setMensaje(data.mensaje);
          setOpen(true);
          setIsGuardando(false);
        });
    };
    const handleClose = () => {
      setOpen(false);
    };
  
    
  const StyledSnackbar = styled((props) => <Snackbar {...props} />)(
    ({ theme }) => ({
      "& .MuiSnackbar-root": {
        top: theme.spacing(15),
      },
    })
  );


  return (
    <>
    
    <StyledSnackbar
        direction="right"
        open={open}
        autoHideDuration={6000}
        onClose={handleClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      >
        <Alert onClose={handleClose} severity="error" sx={{ width: "100%" }}>
          {mensaje}
        </Alert>
      </StyledSnackbar>

    <div className="row">
      <div className="col-12" style={{ height: 500, width: "100%" }}>
        <DataGrid
          responsive={true}
          rows={rows}
          columns={columns}
          pageSize={5}
          rowsPerPageOptions={[5]}
          checkboxSelection 
          isRowSelectable={(params) => params.row.pagada == "NO"}
          onRowSelectionModelChange={(newRowSelectionModel) => {
            setSelectedRows(newRowSelectionModel);
          }}
          selectionModel={selectedRows} 
        />
      </div>
      <div className="col-12 col-md-6  d-flex flex-column justify-content-end w-100">
        <div className="p-1 mt-5">
          <h5 className="font-AvenirMedium text-end mb-2">
            Pagadas: {formatMoneda(TotalPagado)} MXN
          </h5>
          <h5 className="font-AvenirMedium text-end mb-2">
            Comisiones por pagar: {formatMoneda(TotalNoPagado)} MXN
          </h5>
          <h5 className="font-AvenirMedium text-end mb-2">
            Incentivos: {formatMoneda(TotalIncentivos)} MXN
          </h5>
          <h5 className="font-AvenirBold text-end mb-0">
            Total a pagar: <span className="font-AvenirBold">{formatMoneda(TotalApagar)} MXN</span> 
          </h5>
        </div>
        <div className="p-1 mt-2 text-end"> 
          <LoadingButton
              loading={IsGuardando}
              loadingPosition="start"
              disabled={IsLoading}
              onClick={() => {  
                GuardarPagoComisiones()
              }}
              className="btn btn-Kalendar font-AvenirMedium  py-2 px-4 "
              variant="contained"
              xs={{ with: "100%" }}
            >
              <span className={IsGuardando ? "px-4" : "px-2"}>
                {IsGuardando ? "Guardando..." : "Pagar comisiones" }
              </span>
            </LoadingButton>
        </div>
      </div>
    </div>
    </>
  );
}
