import React, { useState, useEffect, useRef } from "react";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import Card from "@mui/material/Card";
import { Col, Row } from "react-bootstrap";
import TableLicencia from "./Tabla";
import SelectOrdenamiento from "../../../Includes/SelectOrdenamiento";
import Buscador from "../../../Includes/Buscador";
import { getLista } from "../Funciones/Licencias";
import ModalLicencia from "./Dialogos/ModalLicencias";
import ModalComprobanteCargar from "./Dialogos/ModalComprobanteCargar";
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import ModalComprobante from "./Dialogos/ModalComprobante";
import ModalLicenciasExtra from "./Dialogos/ModalLicenciasExtras";

const Licencias = () => {

  const btnNuevaLicencia = useRef(null)

  const [ValueSelect, setValueSelect] = useState("1");
  const [ValueBusqueda, setValueBusqueda] = useState("");
  const [IsLoading, setIsLoading] = useState(true);
  const [Lista, setLista] = useState([]);

  const [fechaActual, setFechaActual] = useState(null)
  const [showDialogComprobanteVer, setShowDialogVerComprobante] = useState(false)
  const [showDialogComprobanteCargar, setShowDialogoCargarComprobante] = useState(false)
  const [licenciaSeleccionada, setLicenciaSeleccionada] = useState(-1);
  const [showDialogLicencia, setShowDialogLicencia] = useState(false)
  const [showDialogLicenciaExtra, setShowDialogLicenciaExtra] = useState(false)


  useEffect(() => {
    getListaLicencia();
  }, [ValueBusqueda, ValueSelect]);

  const getListaLicencia = () => {
    setIsLoading(true);
    getLista(0, 0, ValueSelect, ValueBusqueda)
      .then((resp) => {
        let list = JSON.parse(resp.data);
        //console.log(list);
        setLista(list);
        setFechaActual(new Date(resp.fecha_actual + 'T00:00:00.000'))
        setIsLoading(false);
      })
      .catch((resp) => {
        setLista([]);
        setIsLoading(false);
      });
  };

  useEffect(() => {
    document.title = `Licencias`;
  }, []);

  return (
    <>
      <div class="d-flex justify-content-between flex-wrap my-4">
        <div className="p-2">
          <h4 className="text-left font-AvenirBold">Licencias</h4>
        </div>
        <div className="p-2">
          <Stack spacing={2} direction={{ xs: "column", sm: "row" }}>

            <Button
              ref={btnNuevaLicencia}
              onClick={() => {
                setShowDialogLicencia(true);
                setLicenciaSeleccionada(0)
              }}
              className="btn btn-Kalendar px-4 "
              variant="contained">
              Crear nueva
            </Button>
          </Stack>
        </div>
      </div>

      <Card variant="outlined" className="p-0 shadow">
        <Row className="p-3">
          <Col xs={12} md={8}>
            <Buscador
              placeholder="Buscar licencia por empresa o vigencia"
              ValueBusqueda={ValueBusqueda}
              setValueBusqueda={setValueBusqueda}
            />
          </Col>
          <Col xs={12} md={4}>
            <SelectOrdenamiento
              ValueSelect={ValueSelect}
              setValueSelect={setValueSelect}
            />
          </Col>
        </Row>
        <Row>
          <Col sm={12}>
            <TableLicencia
              btnNuevaLicencia={btnNuevaLicencia}
              Lista={Lista ?? []}
              IsLoading={IsLoading}
              setLista={setLista}
              setLicenciaSeleccionada={setLicenciaSeleccionada}
              setShowDialogLicencia={setShowDialogLicencia}
              setShowDialogLicenciaExtra={setShowDialogLicenciaExtra}
              setShowDialogoCargarComprobante={setShowDialogoCargarComprobante}
              setShowDialogVerComprobante={setShowDialogVerComprobante}
              fechaActual={fechaActual}
            />
          </Col>
        </Row>
      </Card>

      {
        showDialogLicencia && licenciaSeleccionada != -1 && (
          <ModalLicencia
            getListaLicencia={getListaLicencia}
            licenciaSeleccionada={licenciaSeleccionada}
            setLicenciaSeleccionada={setLicenciaSeleccionada}
            setShowDialogLicencia={setShowDialogLicencia}
          />
        )
      }

      {
        showDialogLicenciaExtra && licenciaSeleccionada != -1 && (
          <ModalLicenciasExtra
            getListaLicencia={getListaLicencia}
            licenciaSeleccionada={licenciaSeleccionada}
            setLicenciaSeleccionada={setLicenciaSeleccionada}
            setShowDialogLicenciaExtra={setShowDialogLicenciaExtra}
          />
        )
      }

      {
        showDialogComprobanteCargar && licenciaSeleccionada != -1 && (
          <ModalComprobanteCargar
            getListaLicencia={getListaLicencia}
            setLicenciaSeleccionada={setLicenciaSeleccionada}
            licenciaSeleccionada={licenciaSeleccionada}
            setShowDialogoCargarComprobante={setShowDialogoCargarComprobante}
          />
        )
      }

      {
        showDialogComprobanteVer && licenciaSeleccionada != -1 && (
          <ModalComprobante
            licenciaSeleccionada={licenciaSeleccionada}
            setLicenciaSeleccionada={setLicenciaSeleccionada}
            setShowDialogVerComprobante={setShowDialogVerComprobante}
          />
        )
      }

    </>
  );
};

export default Licencias;
