import { getData } from "../../../../Context/backend";
import { stringify } from "query-string";

export const getOpcionesCola = async (query = "") => {
    let data = [];
    let queryParamsObj = {
        query: query,
    };
    const url = `colaboradores/getOpcionesCola?${stringify(queryParamsObj)}`;
    const res = await getData(url);
    return new Promise((resolve, reject) => {
      if (!res.error) {
        data = res.data.respuesta;
        // console.log(data);
        resolve(data);
      } else {
        reject(res);
      }
    });
  };