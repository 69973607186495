import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import Error404 from "./Includes/Error404";
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
import ReportesPDF from "./Reportes/ReportesPDF";
import BuscarCorreo from "./Login/componentes/BuscarCorreo";
import CambiarContrasena from "./Login/componentes/CambiarContrasena";
import Asistencia from "./Asistencia/Asistencia";
import AppMovil from "./AppMovil/AppMovil";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <Router>
      <Routes>
        <Route path="/pdf/*" element={<ReportesPDF />}></Route>
        <Route path="/restablecer-contrasena" element={<BuscarCorreo />} />
        <Route path="/asistencia/*" element={<Asistencia />} />
        <Route path="/app/*" element={<AppMovil />} />
        <Route
          path="/cambiar-contrasena/:uuid"
          element={<CambiarContrasena />}
        />
        <Route path="/*" element={<App />} />
      </Routes>
    </Router>
  </React.StrictMode>
);
