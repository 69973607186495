import { getData } from "../../Context/backend";
import { stringify } from "query-string";

export const GetdataDashboard = async () => {
  let data = [];
  const url = "Dashboard/Getdata";
  const res = await getData(url);

  return new Promise((resolve, reject) => {
    if (!res.error) {
      data = res.data.respuesta;
      resolve(data);
    } else {
      reject(res);
    }
  });
};

export const getServicios4Sucursal = async (ID) => {
  let data = [];
  let queryParamsObj = {
    ID_SUCURSAL: ID,
  };
  const url = `Dashboard/getServicios4Sucursales?${stringify(queryParamsObj)}`;
  const res = await getData(url);
  return new Promise((resolve, reject) => {
    if (!res.error) {
      data = res.data.respuesta;
      //console.log(data);
      resolve(data);
    } else {
      reject(res);
    }
  });
};

export const updateRadial = async (type, date) => {
  let data = [];
  let url;

  if (type === 1) {
    let queryParamsObj = {
      ID_SUCURSAL: date,
    };
    url = `Dashboard/updateEventos4Semana?${stringify(queryParamsObj)}`;
  } else {
    let queryParamsObj = {
      FECHA: date,
    };
    url = `Dashboard/updateGanancias4Sucursal?${stringify(queryParamsObj)}`;
  }

  const res = await getData(url);
  return new Promise((resolve, reject) => {
    if (!res.error) {
      data = res.data.respuesta;
      //console.log(data);
      resolve(data);
    } else {
      reject(res);
    }
  });
};
