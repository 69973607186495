import {
  Alert,
  Button,
  Card,
  CardContent,
  Avatar,
  Snackbar,
  Stack,
  TextField,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useNavigate } from "react-router-dom";
import { guardar, getInfoCliente, Eliminar } from "../Funciones/Clientes";
import Loading from "../../../Includes/Loading";
import LoadingButton from "@mui/lab/LoadingButton";
import { styled } from "@mui/material/styles";
import { useParams } from "react-router-dom";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import { IMG } from "../../../Lib/Funciones Generales/Constantes";
import RegimenFiscal from "../../../Lib/SAT/Componentes/RegimenFiscal";
import Switch from "./../../../Includes/Switch";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import AccEditarImagen from "./../../../assets/EditarImagen.svg";
import ModalCargarImagen from "../../../Lib/Imagen/Componentes/ModalCargarImagen";
import "../../../Lib/Funciones Generales/Prototipos";

const MySwal = withReactContent(Swal);

const Cliente = () => {
  // Definicion de variables para la carga de imagenes
  const [openModal, setOpenModal] = React.useState(false);
  const handleOpenModal = () => setOpenModal(true);
  const handleCloseModal = () => setOpenModal(false);
  const fnDevolverImagen = (img) => {
    //console.log("SETEAR IMAGEN: ", img)
    setValues({ ...Values, img: img });
  };

  const { id } = useParams();
  const navigate = useNavigate();
  const [IsLoading, setIsLoading] = useState(true);
  const [IsGuardando, setIsGuardando] = useState(false);
  const [open, setOpen] = useState(false);
  const [mensaje, setMensaje] = useState("");

  const [Values, setValues] = useState({
    id: id ?? "0",
    nombre: "",
    apellidos: "",
    correo: "",
    telefono: "",
    rfc: "",
    razon_social: "",
    cp: "",
    regimen_fiscal: "",
    requiere_factura: false,
    img: AccEditarImagen,
  });
  const [Errores, setErrores] = useState({
    nombre: false,
    correo: false,
    telefono: false,
    rfc: false,
    razon_social: false,
    cp: false,
    regimen_fiscal: false,
    requiere_factura: false,
  });

  const handleClose = () => {
    setOpen(false);
  };

  const handlInputChange = ({ target }) => {
    const Name = target.name;
    const Value = target.value;
    let NewValue = {
      ...Values,
      [Name]: Value,
    };

    if (Name == "cp" || Name == "telefono") {
      NewValue = { ...NewValue, [Name]: Value.toNumber() };
    }
    if (Name == "requiere_factura") {
      NewValue = { ...NewValue, requiere_factura: Value };
    }

    setValues(NewValue);
    setErrores({
      ...Errores,
      [Name]: false,
    });
  };

  useEffect(() => {
    setIsLoading(true);

    if (id != undefined) {
      getInfoCliente(id, AccEditarImagen)
        .then((resp) => {
          //console.log(resp);
          if (resp?.err?.length == 0) {
            MySwal.fire({
              title: "Error",
              html: resp.mensaje,
              icon: "error",
              confirmButtonColor: "#3ABE88",
              showConfirmButton: true,
              allowEscapeKey: false,
              allowEnterKey: false,
              allowOutsideClick: false,
            }).then(() => {
              setIsLoading(false);
              navigate(-1);
            });
          } else {
            setValues(resp);
            setIsLoading(false);
          }
        })
        .catch((resp) => {
          MySwal.fire({
            title: "Error",
            html: resp.mensaje,
            icon: "error",
            confirmButtonColor: "#3ABE88",
            showConfirmButton: true,
            allowEscapeKey: false,
            allowEnterKey: false,
            allowOutsideClick: false,
          }).then(() => {
            setIsLoading(false);
            navigate(-1);
          });
        });
    } else {
      setIsLoading(false);
    }
  }, []);

  const GuardarCliente = () => {
    setIsGuardando(true);
    guardar(Values, Errores, setErrores)
      .then((data) => {
        if (data.codigo == "200") {
          setIsGuardando(false);
          MySwal.fire({
            title: "Correcto",
            html: data.mensaje,
            icon: "success",
            confirmButtoColor: "#3ABE88",
            showConfirmButton: false,
            timer: 1700,
          }).then((result) => {
            navigate(-1);
          });
        } else {
          setMensaje(data.mensaje);
          setOpen(true);
          setIsGuardando(false);
        }
      })
      .catch((data) => {
        setMensaje(data.mensaje);
        setOpen(true);
        setIsGuardando(false);
      });
  };

  const img_url = Values.img == AccEditarImagen ? AccEditarImagen : Values.img;

  const StyledSnackbar = styled((props) => <Snackbar {...props} />)(
    ({ theme }) => ({
      "& .MuiSnackbar-root": {
        top: theme.spacing(15),
      },
    })
  );

  // console.log("IMAGEN: ", Values.img)
  // console.log("URL: ", img_url)

  return (
    <>
      <StyledSnackbar
        direction="right"
        open={open}
        autoHideDuration={6000}
        onClose={handleClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      >
        <Alert onClose={handleClose} severity="error" sx={{ width: "100%" }}>
          {mensaje}
        </Alert>
      </StyledSnackbar>
      <Button
        onClick={() => navigate(-1)}
        className="btn  font-AvenirBold my-4 text-star ps-0 pe-4  "
        variant="text"
      >
        <ArrowBackIcon className="me-3" />
        Clientes
      </Button>
      <Card elevation={3}>
        <CardContent className="p-0">
          <div className="p-3">
            <h6 className="mb-0 text-left font-AvenirBold">
              {id ? "Editar cliente" : "Nuevo cliente"}
            </h6>
          </div>
          <hr
            style={{ backgroundColor: "#DFDEE0", height: "1px", opacity: "1" }}
            className="m-0 p-0"
          />

          {IsLoading ? (
            <Row className="px-3">
              <Col sm={12} className="p-3">
                <Loading />
              </Col>
            </Row>
          ) : (
            <Row className="p-3">
              <Col sm={12} md={2} className="p-3 ">
                <div className="d-flex justify-content-center">
                  <Avatar
                    className="shadow"
                    sx={{ width: 120, height: 120 }}
                    src={img_url}
                  />
                </div>
                <strong
                  className="w-100 text-center px-0 pt-3 d-flex justify-content-center"
                  style={{ color: "#3ABE88", cursor: "pointer" }}
                  onClick={handleOpenModal}
                >
                  Editar foto
                </strong>
              </Col>

              <Col sm={12} md={10}>
                <Row>
                  <Col sm={12} md={6} className="p-3">
                    <TextField
                      fullWidth
                      inputProps={{ maxLength: 100 }}
                      name="nombre"
                      label="Nombre"
                      variant="outlined"
                      value={Values.nombre}
                      error={Errores.nombre}
                      onChange={handlInputChange}
                    />
                  </Col>

                  <Col sm={12} md={6} className="p-3">
                    <TextField
                      fullWidth
                      inputProps={{ maxLength: 100 }}
                      name="apellidos"
                      label="Apellidos"
                      variant="outlined"
                      value={Values.apellidos}
                      error={Errores.apellidos}
                      onChange={handlInputChange}
                    />
                  </Col>

                  <Col sm={12} md={6} className="p-3">
                    <TextField
                      fullWidth
                      inputProps={{ maxLength: 150 }}
                      name="correo"
                      label="Correo electrónico"
                      variant="outlined"
                      error={Errores.correo}
                      helperText={Errores.correo ? "Correo incorrecto" : ""}
                      value={Values.correo}
                      onChange={handlInputChange}
                    />
                  </Col>

                  <Col sm={12} md={6} className="p-3">
                    <TextField
                      fullWidth
                      name="telefono"
                      label="Teléfono"
                      variant="outlined"
                      inputProps={{ maxLength: 10 }}
                      helperText={Errores.telefono ? "Teléfono inválido" : ""}
                      value={Values.telefono}
                      error={Errores.telefono}
                      onChange={handlInputChange}
                    />
                  </Col>
                </Row>

                <Col Col sm={12} className="p-3">
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Switch
                          checked={Values.requiere_factura}
                          name="requiere_factura"
                          error={Errores.requiere_factura}
                          onChange={(checked) => {
                            handlInputChange({
                              target: {
                                name: "requiere_factura",
                                value: checked,
                              },
                            });
                          }}
                          tituloActivo="No requiere factura"
                          tituloInactivo="Requiere factura"
                          on
                        />
                      }
                      label="Requiere Factura"
                    />
                  </FormGroup>
                </Col>

                {Values.requiere_factura ? (
                  <Row>
                    <Col sm={12} md={6} className="p-3">
                      <TextField
                        fullWidth
                        name="rfc"
                        label="RFC"
                        variant="outlined"
                        helperText={Errores.rfc ? "RFC invalido" : ""}
                        value={Values.rfc}
                        error={Errores.rfc}
                        onChange={handlInputChange}
                      />
                    </Col>

                    <Col sm={12} md={6} className="p-3">
                      <TextField
                        fullWidth
                        inputProps={{ maxLength: 250 }}
                        name="razon_social"
                        label="Razón social"
                        variant="outlined"
                        helperText={
                          Errores.razon_social ? "Razón social invalida" : ""
                        }
                        value={Values.razon_social}
                        error={Errores.razon_social}
                        onChange={handlInputChange}
                      />
                    </Col>

                    <Col sm={12} md={6} className="p-3">
                      <TextField
                        fullWidth
                        name="cp"
                        inputProps={{ maxLength: 5 }}
                        label="Código postal"
                        variant="outlined"
                        helperText={Errores.cp ? "Código postal invalido" : ""}
                        value={Values.cp}
                        error={Errores.cp}
                        onChange={handlInputChange}
                      />
                    </Col>

                    <Col sm={12} md={6} className="p-3">
                      <RegimenFiscal
                        Value={Values.regimen_fiscal}
                        Error={Errores.regimen_fiscal}
                        handlInputChange={handlInputChange}
                      />
                    </Col>
                  </Row>
                ) : (
                  <p></p>
                )}
              </Col>
            </Row>
          )}
          <Stack
            className="p-3"
            spacing={2}
            direction={{ xs: "column", sm: "row" }}
            style={{}}
          >
            <LoadingButton
              loading={IsGuardando}
              loadingPosition="start"
              disabled={IsLoading}
              onClick={() => GuardarCliente()}
              className="btn btn-Kalendar font-AvenirMedium  py-2 px-4 "
              variant="contained"
              xs={{ with: "100$" }}
            >
              <span className={IsGuardando ? "px-4" : "px-2"}>
                {IsGuardando ? "Guardando..." : id ? "Guardar" : "Crear"}
              </span>
            </LoadingButton>
            <Button
              onClick={() => navigate(-1)}
              className="btn btn-cancelar font-AvenirMedium py-2 px-4"
              variant="outlined"
            >
              Cancelar
            </Button>
          </Stack>
        </CardContent>
      </Card>

      {openModal && (
        <ModalCargarImagen
          id={Values.id}
          tipo={IMG.TIPO_USUARIOS}
          Titulo="Cargar imagen de cliente"
          mostrar={openModal}
          imgUrl={Values.img}
          fnCerrar={handleCloseModal}
          fnDevolverImagen={fnDevolverImagen}
        />
      )}
    </>
  );
};

export default Cliente;
