import { Route, Routes } from "react-router-dom";
import Error404 from "../../Includes/Error404";
import Colaborador from "./Componentes/Colaborador";
import RepColaboradores from "./Componentes/RepColaboradores";
import HorariosColab from "./Componentes/HorariosColab";
const routerColaboradores = () => {
  return (
    <>
      <Routes>
        <Route index element={<RepColaboradores />} />
        <Route path="/add" element={<Colaborador />} />
        <Route path="/times/:id" element={<HorariosColab />} />
        <Route path="/edit/:id" element={<Colaborador />} />
        <Route path="*" element={<Error404 />} />
      </Routes>
    </>
  );
};

export default routerColaboradores;
