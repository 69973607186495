import {
  Alert,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormLabel,
  IconButton,
  Radio,
  RadioGroup,
  Snackbar,
  TextField,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useEffect, useState } from "react";
import { Button, Col, Row } from "react-bootstrap";

import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import Loading from "../../../../Includes/Loading";
import { styled } from "@mui/material/styles";
import { LoadingButton } from "@mui/lab";
import MasterCard from "../../../../assets/masterCard.png";
import visa from "../../../../assets/visa.png";
import conektaLogo from "../../../../assets/conektaLogo.png";
// import { guardar, guardarTarjeta } from "../../Funciones/Tarjetas";
import { guardar, guardarTarjeta } from "../../Funciones/FormaPago";

const MySwal = withReactContent(Swal);

const ModalTarjeta = ({ 
    id
  , getTarjetas 
  , setShowModalTarjeta
}) => {

  const [IsLoading, setIsLoading] = useState(true);
  const [IsGuardando, setIsGuardando] = useState(false);
  const [open, setOpen] = useState(false);
  const [mensaje, setMensaje] = useState("");
  const [token, setToken] = useState("");
  const [isDefault, setIsDefault] = useState(false);

  const [Values, setValues] = useState({
    id: id ? id : 0,
    nombre: "",
    numero: "",
    vencimiento: "",
    ccv: "",
    isPredeterminada: false,
  });
  const [errores, setErrores] = useState({
    nombre: false,
    numero: false,
    vencimiento: false,
    ccv: false,
  });

  function formatExpirationDate(expirationDate) {
    const cleanedInput = expirationDate.replace(/[^0-9]/g, ""); // Remove all non-numeric characters
    let month = cleanedInput.slice(0, 2);
    month = month > 12 ? 12 : month;
    let year = cleanedInput.slice(2);
    // year = year<23 ? 23 : year;
    const formattedDate = `${month}/${year}`;
    return formattedDate;
  }

  const handlInputChange = ({ target, buscador }) => {
    const Name = target ? target.name : buscador.name;
    const Value = target ? target.value : buscador.value;
    let NewValue = {
      ...Values,
      [Name]: Value,
    };

    if (Name == "numero") {
      const re = /^[0-9\s]+$/; // Expresión regular que solo permite números
      if (target.value != "") {
        if (re.test(target.value) && target.value.length <= 19) {
          let cleanedValue = target.value.replace(/\D/g, ""); // Elimina todos los caracteres no numéricos
          let formattedValue = cleanedValue.match(/.{1,4}/g)?.join(" "); // Divide en grupos de 4 dígitos y une con espacio
          NewValue = { ...NewValue, [Name]: formattedValue };
        } else {
          return;
        }
      }
    }
    if (Name == "ccv") {
      const re = /^[0-9\b]+$/; // Expresión regular que solo permite números
      if (target.value != "") {
        if (re.test(target.value) && target.value.length <= 4) {
          NewValue = { ...NewValue, [Name]: target.value };
        } else {
          return;
        }
      }
    }
    if (Name == "vencimiento") {
      const re = /^[0-9\b]+$/; // Expresión regular que solo permite números
      if (target.value != "") {
        if (target.value.length <= 5) {
          const inputExpirationDate = target.value;
          const formattedDate = formatExpirationDate(inputExpirationDate);

          NewValue = { ...NewValue, [Name]: formattedDate };
        } else {
          return;
        }
      }
    }
    setValues(NewValue);
    setErrores({
      ...errores,
      [Name]: false,
    });
  };

  const GuardarTarjeta = () => {
    setIsGuardando(true);
    guardar(Values, errores, setErrores, setToken)
      .then((data) => {
        setIsGuardando(false);
      })
      .catch((data) => {
        setIsGuardando(false);
      });
  };

  useEffect(() => {
    if (token != "") {
      if (token == "Error") {
        MySwal.fire({
          title: "Lo sentimos",
          html: "Ha ocurrido un error al registrar tu tarjeta, verifica los datos e intenta nuevamente, si el problema persiste, contacta a tu banco.",
          icon: "warning",
          confirmButtoColor: "#C9A396",
          showConfirmButton: false, 
        }).then((result) => {
          setToken("") ;
        }); 
        return;
      }
      setIsGuardando(true);
      guardarTarjeta(token, Values.nombre, isDefault)
        .then((data) => {
          if (data.codigo == "200") {
            setIsGuardando(false);
            MySwal.fire({
              title: "Correcto",
              html: data.mensaje,
              icon: "success",
              confirmButtoColor: "#C9A396",
              showConfirmButton: false,
              timer: 1700,
            }).then((result) => {
              setIsGuardando(false);
              getTarjetas();
              setShowModalTarjeta(false);
            });
          } else {
            setMensaje(data.mensaje);
            setOpen(true);
            setIsGuardando(false);
          }
        })
        .catch((data) => {
          setMensaje(data.mensaje);
          setOpen(true);
          setIsGuardando(false);
        });
    }
  }, [token]);

  useEffect(() => {
    setIsLoading(false);
  }, []);

  const handleClose = () => {
    setOpen(false);
  };
  const StyledSnackbar = styled((props) => <Snackbar {...props} />)(
    ({ theme }) => ({
      "& .MuiSnackbar-root": {
        top: theme.spacing(15),
      },
    })
  );

  return (
    <>
      <StyledSnackbar
        direction="right"
        open={open}
        autoHideDuration={6000}
        onClose={handleClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      >
        <Alert onClose={handleClose} severity="error" sx={{ width: "100%" }}>
          {mensaje}
        </Alert>
      </StyledSnackbar>

      <Dialog fullWidth maxWidth="xs" open={true}>
        <IconButton
          aria-label="Close"
          className="m-3"
          style={{ position: "absolute", right: "0%" }}
          onClick={() => {
            setShowModalTarjeta(false);
          }}
        >
          <CloseIcon size="lg" />
        </IconButton>
        <DialogTitle>
          <h6 className="fw-bolder f-12 mt-2">Agregar nueva tarjeta</h6>
        </DialogTitle>
        <DialogContent>
          {IsLoading ? (
            <Col sm={12} className="pt-5">
              <Loading />
            </Col>
          ) : (
            <Row>
              <Col sm={12} className="p-2 d-flex justify-content-between">
                <div className="aligin-self-center">
                  <span
                    className="font-OxygenLight"
                    style={{ fontSize: "10px" }}
                  >
                    Powered By{" "}
                  </span>
                  <img
                    src={conektaLogo}
                    className="img-fluid "
                    style={{ maxWidth: "80px" }}
                  />
                </div>
                <div className="aligin-self-center">
                  <img
                    src={MasterCard}
                    className="img-fluid"
                    style={{ maxWidth: "50px" }}
                  />
                  <img
                    src={visa}
                    className="img-fluid"
                    style={{ maxWidth: "42px" }}
                  />
                </div>
              </Col>
              <Col sm={12} className="p-2">
                <TextField
                  fullWidth
                  name="nombre"
                  label="Nombre del titular"
                  variant="outlined"
                  placeholder="Nombre como aparece en la tarjeta"
                  value={Values.nombre}
                  error={errores.nombre}
                  helperText={errores.nombre && "Ingresa el nombre del titular"}
                  onChange={handlInputChange}
                />
              </Col>

              <Col sm={12} className="p-2">
                <TextField
                  fullWidth
                  name="numero"
                  label="Número de tarjeta"
                  variant="outlined"
                  placeholder="16 Dígitos"
                  helperText={
                    errores.numero && "El número de tarjeta es inválido"
                  }
                  value={Values.numero}
                  error={errores.numero}
                  onChange={handlInputChange}
                />
              </Col>
              <Col sm={12} className="p-2">
                <TextField
                  fullWidth
                  name="vencimiento"
                  label="Vencimiento"
                  variant="outlined"
                  placeholder="mm/yy"
                  helperText={
                    errores.vencimiento && "La fecha de vencimiento es inválida"
                  }
                  value={Values.vencimiento}
                  error={errores.vencimiento}
                  onChange={handlInputChange}
                />
              </Col>
              <Col sm={12} className="p-2">
                <TextField
                  fullWidth
                  name="ccv"
                  label="CCV"
                  variant="outlined"
                  placeholder="Código de seguridad"
                  helperText={
                    errores.ccv && "El código de seguridad es inválido"
                  }
                  value={Values.ccv}
                  error={errores.ccv}
                  onChange={handlInputChange}
                  maxLength="5"
                  pattern="\d{1,2}\/\d{1,2}" // Verifica que la entrada coincida con el patrón MM/DD
                />
              </Col>
              <Col sm={12} className="p-2">
                <FormControlLabel
                  onClick={() => {
                    setIsDefault(!isDefault);
                  }}
                  className="mb-2"
                  control={<Checkbox checked={isDefault} />}
                  label={
                    <Typography
                      className="font-Oxygen"
                      variant="body1"
                      component="span"
                      style={{ fontSize: "14px" }}
                    >
                      Tarjeta predeterminada
                    </Typography>
                  }
                />
              </Col>
            </Row>
          )}
        </DialogContent>
        <DialogActions>
          <Col sm={12} className="p-3">
            <LoadingButton
              loading={IsGuardando}
              loadingPosition="start"
              disabled={IsLoading}
              onClick={() => GuardarTarjeta()}
              className="btn shadow-none w-100 btn-Kalendar font-OxygenMedium  py-2 px-4 "
              variant="contained"
              xs={{ with: "100$" }}
            >
              <span
                className={IsGuardando ? "px-4 " : "px-2 font-OxygenMedium "}
              >
                {IsGuardando ? "Validando..." : "Agregar tarjeta"}
              </span>
            </LoadingButton>
          </Col>
        </DialogActions>
      </Dialog>
    </>
  );
};
export default ModalTarjeta;