import { stringify } from "query-string";
import { getData, postdData, postUrl } from "../../../Context/backend";

///////////////////
/* FUNCIONES GET */
///////////////////
export const getLista = async (iTake, iSkip, Order, query) => {
  //onsole.log(query)
  let data = [];
  let queryParamsObj = {
    iTake: iTake,
    iSkip: iSkip,
    Order: Order,
    query: query,
    cat: 0,
  };

  const url = `productos/Listar?${stringify(queryParamsObj)}`;
  const res = await getData(url);
  return new Promise((resolve, reject) => {
    if (!res.error) {
      data = res.data.respuesta;
      resolve(data);
    } else {
      reject(res);
    }
  });
};

export const getListaByCategoria = async (cat, query) => {
  //onsole.log(query)
  let data = [];
  let queryParamsObj = {
    iTake: 0,
    iSkip: 0,
    Order: 1,
    query: query,
    cat: cat,
  };

  const url = `productos/Listar?${stringify(queryParamsObj)}`;
  const res = await getData(url);
  return new Promise((resolve, reject) => {
    if (!res.error) {
      data = res.data.respuesta;
      resolve(data);
    } else {
      reject(res);
    }
  });
};

export const getInfoProducto = async (id) => {
  let data = [];
  let relacion = [];
  let colaboradores = [];
  let queryParamsObj = {
    id: id,
  };
  const url = `productos/Consultar?${stringify(queryParamsObj)}`;
  const res = await getData(url);
  return new Promise((resolve, reject) => {
    if (!res.error) {
      data = JSON.parse(res.data.respuesta.data);
      relacion = JSON.parse(res.data.respuesta.relacion);
      //console.log(relacion);
      if (data.length == 0) {
        resolve({
          err: [],
          mensaje: "No se encontró información de este producto",
        });
      } else {
        data = JSON.parse(res.data.respuesta.data)[0];
        relacion = JSON.parse(res.data.respuesta.relacion);
        colaboradores = JSON.parse(res.data.respuesta.colaboradores);
  
        //console.log("Relacion:"+JSON.stringify(relacion));
        let Values = {
          id: String(data.ID),
          iS_SERVICIO: Boolean(data.IS_SERVICIO),
          iS_FACTURA: Boolean(data.IS_FACTURA),
          codigo: String(data.CODIGO),
          descripcion: String(data.DESCRIPCION),
          descripcion_larga: String(data.DESCRIPCION_LARGA),
          precio: String(data.PRECIO),
          horas: String(data.HORAS),
          minutos: String(data.MINUTOS),
          id_categoria: String(data.ID_CATEGORIA),
          
          unidad_de_medida: String(data.UNIDAD_DE_MEDIDA),
          clave_codigo_prod_sat: String(data.CLAVE_CODIGO_PROD_SAT),
          clave_unidad_medida_sat: String(data.CLAVE_UNIDAD_MEDIDA_SAT), 
          iva: String(data.IVA),
          moneda: String(data.MONEDA),
          relacion: relacion,
          colaboradores : colaboradores

        };
        resolve(Values);
      }
    } else {
      reject({
        mensaje: "Error al conectar a internet, revisa tu conexion a internet",
      });
    }
  });
};

////////////////////
/* FUNCIONES POST */
////////////////////
export const guardar = async (Values, Errores, setErrores, data) => {
  let error = false;
  // let error = false;
   console.log(Values);

  // Values.iva = String(Values.iva)
  // Values.precio_de_venta = String(Values.precio_de_venta)

  if (!Values.id_categoria || Values.id_categoria == "0") {
    Errores.id_categoria = true;
    setErrores(Errores);
    error = true;
  }


  if (!Values.descripcion || Values.descripcion == "") {
    Errores.descripcion = true;
    setErrores(Errores);
    error = true;
  }

  if (!Values.descripcion_larga || Values.descripcion_larga == "") {
    Errores.descripcion_larga = true;
    setErrores(Errores);
    error = true;
  }

  if (!Values.precio || Values.precio == "" || Values.precio == "0") {
    Errores.precio = true;
    setErrores(Errores);
    error = true;
  }

  if (Values.precio != 0) {
    if (Values.precio < 0) {
      Errores.precio = true;
      setErrores(Errores);
      error = true;

      return new Promise((resolve, reject) => {
        resolve({ mensaje: "El precio de venta no puede ser menor a 0" });
      });
    }
  }

  if (Values.iS_SERVICIO) {
    if (!Values.horas || Values.horas == "") {
      Errores.horas = true;
      setErrores(Errores);
      error = true;
    }

    if (!Values.minutos || Values.minutos == "") {
      Errores.minutos = true;
      setErrores(Errores);
      error = true;
    }

    if (Values.horas === "00" && Values.minutos === "00") {
      Errores.minutos = true;
      setErrores(Errores);
      error = true;
    }
  }
  if (Values.iS_FACTURA) {
    if (!Values.iva || Values.iva == "") {
      Errores.iva = true;
      setErrores(Errores);
      error = true;
    }
    // if (!Values.clave_unidad_medida_sat || Values.clave_unidad_medida_sat == "") {
    //   Errores.clave_unidad_medida_sat = true;
    //   setErrores(Errores);
    //   error = true;
    // }
    // if (!Values.clave_codigo_prod_sat || Values.clave_codigo_prod_sat == "") {
    //   Errores.clave_codigo_prod_sat = true;
    //   setErrores(Errores);
    //   error = true;
    // } 

  }

 
  console.log(data);
   
  console.log("DATA:"+JSON.stringify(data));
  Values.id_categoria = String(Values.id_categoria);
  Values.horas = String(Values.horas);
  Values.minutos = String(Values.minutos);
  Values.relacion = data;
  Values.clave_codigo_prod_sat = String(Values.clave_codigo_prod_sat);
  Values.clave_unidad_medida_sat = String(Values.clave_unidad_medida_sat);
  if (!error) {
    let data = [];
    const body = Values;
    //console.log(body);
    const url =
      Values.id == undefined || Values.id == "" || Values.id == 0
        ? "productos/Insertar"
        : "productos/Editar";
    //return;
    const res = await postdData(url, body);
    return new Promise((resolve, reject) => {
      if (!res.error) {
        data = res.data;
        console.log(data);
        resolve(data);
      } else {
        console.log(res);
        reject(res);
      }
    });
  } else {
    return new Promise((resolve, reject) => {
      resolve({ mensaje: "Completa todos los campos requeridos" });
    });
  }
};

export const Eliminar = async (id) => {
  if (id != undefined) {
    let data = [];
    let queryParamsObj = {
      id: id,
    };
    const url = `productos/Eliminar?${stringify(queryParamsObj)}`;
    const res = await postUrl(url);
    return new Promise((resolve, reject) => {
      if (!res.error) {
        data = res.data;
        resolve(data);
      } else {
        reject(res);
      }
    });
  } else {
    return new Promise((resolve, reject) => {
      resolve({ mensaje: "No se pudo obtener el identificador" });
    });
  }
};

export const Desactivar = async (id, checked) => {
  if (id != undefined) {
    let data = [];
    let queryParamsObj = {
      id: id,
      activo: checked,
    };
    // console.log(queryParamsObj)
    const url = `productos/Desactivar?${stringify(queryParamsObj)}`;
    const res = await postUrl(url);
    return new Promise((resolve, reject) => {
      if (!res.error) {
        data = res.data;
        resolve(data);
      } else {
        reject(res);
      }
    });
  } else {
    return new Promise((resolve, reject) => {
      resolve({ mensaje: "No se pudo obtener el identificador" });
    });
  }
};

export const getOpciones = async (ID) => {
  let data = [];
  let queryParamsObj = {
    ID_COLABORADOR: ID,
  };
  const url = `productos/getOpciones?${stringify(queryParamsObj)}`;
  const res = await getData(url);
  return new Promise((resolve, reject) => {
    if (!res.error) {
      data = res.data.respuesta;
      // console.log(data);
      resolve(data);
    } else {
      reject(res);
    }
  });
};

export const getListSucColabs = async () => {
  let data = [];

  const url = `productos/getListColaboradores`;
  //console.log(url);
  const res = await getData(url);
  return new Promise((resolve, reject) => {
    if (!res.error) {
      //console.log(res);
      data = JSON.parse(res.data.respuesta.data);
      //console.log(data);
      //console.log(JSON.stringify(JSON.parse(data)));
      resolve(data);
    } else {
      reject(res);
    }
  });
};


export const getListaSucursal = async () => {
  let data = [];

  const url = `colaboradores/getOpciones`;
  console.log(url);
  const res = await getData(url);
  return new Promise((resolve, reject) => {
    if (!res.error) {
      console.log(res);
      data = JSON.parse(res.data.respuesta.data);
      console.log(data);
      console.log(JSON.stringify(JSON.parse(data.data)));
      resolve(data);
    } else {
      reject(res);
    }
  });
};
