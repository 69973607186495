import { GetdataDashboard } from "./Funciones/Dashboard";
import React, { useEffect, useState } from "react";
import PaidIcon from "@mui/icons-material/Paid";
import { Paper, Button } from "@mui/material";
import { Col, Row } from "react-bootstrap";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import { json, Link } from "react-router-dom";

import TotalSucursales from "../assets/TotalSucursales.svg";
import TotalClientes from "../assets/TotalClientes.svg";
import TotalServicios from "../assets/TotalServicios.svg";
import TotalIngresos from "../assets/TotalIngresos.svg";

import "../Lib/Styles/Styles.css";

import ChartBar from "./Charts/v2/ChartBar";
import ChartTop from "./Charts/v2/ChartTop";
import RadilBarServiceSuc from "./Charts/v2/RadialBarServiceSuc";
import ChartDonut from "./Charts/v2/ChartDonut";

import Box from "@mui/material/Box";
import LinearProgress from "@mui/material/LinearProgress";
import ChartBar2 from "./Charts/v2/ChartBar2";
import {
  getTiempoFromHorasToMinutos,
  getTiempoFromMinutos,
} from "../Lib/Funciones Generales/Generales";
import { usePermisos } from "../Context/PermissionsContext";
import { getLocalStorage } from "../Context/Storage";
import logo from "../assets/LogoTextoNegro.svg";

function createData(img, titulos, subtitulos) {
  return { img, titulos, subtitulos };
}

function totalServio(total) {
  return total;
}

const Dashboard = () => {
  const [IsLoading, setIsLoading] = useState(true);
  const [graficas, setgraficas] = useState([]);
  const [cargado, setCargado] = useState(false);

  const GetDataDash = (setgraficas) => {
    setIsLoading(true);
    GetdataDashboard()
      .then((resp) => {
        setgraficas(resp.data);
        setIsLoading(false);
      })
      .catch((resp) => {
        setgraficas([]);
        setIsLoading(false);
      });
  };

  function formatDate(date) {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  }

  const currentDate = new Date();
  const year = currentDate.getFullYear();
  const month = currentDate.getMonth();

  const firstDayOfMonth = new Date(year, month, 1);
  const lastDayOfLastMonth = new Date(year, month, 0);
  const lastDayOfMonth = new Date(year, month + 1, 0);

  const firstDayFormatted = formatDate(firstDayOfMonth);
  const lastDayFormatted = formatDate(lastDayOfMonth);

  useEffect(() => {
    document.title = `Dashboard`;
    GetDataDash(setgraficas);
    setCargado(true);
  }, []);

  let top10Clientes;

  if (graficas["getTop10Clientes"] != undefined) {
    const topClientes = JSON.parse(graficas["getTop10Clientes"]);
    top10Clientes = topClientes.map((data, index) => {
      return createData(data.IMG, data.NOMBRE, `${data.CANTIDAD} servicios`);
    });
  }

  let top10Prodcutos;

  if (graficas["getTop10Productos"] != undefined) {
    const topProductos = JSON.parse(graficas["getTop10Productos"]);
    top10Prodcutos = topProductos.map((data, index) => {
      return createData(
        "",
        data.DESCRIPCION,
        `Duración ${getTiempoFromMinutos(
          getTiempoFromHorasToMinutos(data.DURACION)
        )}`
      );
      //`Duración ${data.DURACION}`
    });
  }

  let serviciosTotal, serviciosNombre;

  if (graficas["getServicios4Tipo"] != undefined) {
    const servicios = JSON.parse(graficas["getServicios4Tipo"]);
    serviciosTotal = servicios.map((data) => {
      return totalServio(data.TOTAL);
    });

    serviciosNombre = servicios.map((data) => {
      return totalServio(data.NOMBRE);
    });
  }
  const { userPermissions } = usePermisos();

  return (
    <>
      {getLocalStorage("tipo") == "EMPRESA" ||
      userPermissions.includes("Dashboard") === true ? (
        <>
          <Row>
            <Col sm={12} md={12}>
              <Row>
                <Col xs={12} sm={6} md={3} className="pt-4 pt-sm-4 pt-md-4">
                  <Paper
                    className=" shadow-sm mt-2 p-3 d-flex justify-content-between flex-column text-dark me-2"
                    elevation={5}
                    style={{
                      width: "100%",
                      height: "100%",
                      backgroundColor: "#fff",
                    }}
                  >
                    <div>
                      <h5 className="font-AvenirMedium  text-center">
                        Total sucursales
                      </h5>
                    </div>

                    <div className="d-flex justify-content-start flex-row align-content-center algin-align-items-center">
                      <img src={TotalSucursales}></img>
                      <h3 className="text-end font-AvenirBold text-dark m-2">
                        {IsLoading ? (
                          <h1 className="parpadea">...</h1>
                        ) : (
                          graficas["totalSucursales"]
                        )}
                      </h3>
                    </div>
                    <div className="text-end">
                      <Link
                        to="../sucursales/"
                        style={{ textDecoration: "none", color: "black" }}
                      >
                        <Button
                          className="font-Avenir btn text-dark pe-0 text-end pe-0 text-end"
                          size="small"
                        >
                          Ver sucursales
                          <KeyboardArrowRightIcon className="ms-1" />{" "}
                        </Button>
                      </Link>
                    </div>
                  </Paper>
                </Col>
                <Col xs={12} sm={6} md={3} className="pt-4 pt-sm-4 pt-md-4">
                  <Paper
                    className=" shadow-sm mt-2 p-3 d-flex justify-content-between flex-column text-dark me-2"
                    elevation={5}
                    style={{
                      width: "100%",
                      height: "100%",
                      backgroundColor: "#fff",
                    }}
                  >
                    <div>
                      <h5 className="font-AvenirMedium  text-center">
                        Total de clientes
                      </h5>
                    </div>

                    <div className="d-flex justify-content-start flex-row align-content-center algin-align-items-center">
                      <img src={TotalClientes}></img>
                      <h3 className="text-end font-AvenirBold text-dark m-2">
                        {IsLoading ? (
                          <h1 className="parpadea">...</h1>
                        ) : (
                          graficas["totalClientes"]
                        )}
                      </h3>
                    </div>
                    <div className="text-end">
                      <Link
                        to="../clientes/"
                        style={{ textDecoration: "none", color: "black" }}
                      >
                        <Button
                          className="font-Avenir btn text-dark pe-0 text-end pe-0 text-end"
                          size="small"
                        >
                          Ver clientes
                          <KeyboardArrowRightIcon className="ms-1" />{" "}
                        </Button>
                      </Link>
                    </div>
                  </Paper>
                </Col>
                <Col xs={12} sm={6} md={3} className="pt-4 pt-sm-4 pt-md-4">
                  <Paper
                    className=" shadow-sm mt-2 p-3 d-flex justify-content-between flex-column text-dark me-2"
                    elevation={5}
                    style={{
                      width: "100%",
                      height: "100%",
                      backgroundColor: "#fff",
                    }}
                  >
                    <div>
                      <h5 className="font-AvenirMedium  text-center">
                        Servicios atendidos en el mes
                      </h5>
                    </div>

                    <div className="d-flex justify-content-start flex-row align-content-center algin-align-items-center">
                      <img src={TotalServicios}></img>
                      <h3 className="text-end font-AvenirBold text-dark m-2">
                        {IsLoading ? (
                          <h1 className="parpadea">...</h1>
                        ) : (
                          graficas["totalServicios1Mes"]
                        )}
                      </h3>
                    </div>
                    <div className="text-end">
                      <Link
                        to={
                          "/pdf/reservaciones/TODAS/" +
                          firstDayFormatted +
                          "/2023-06-30"
                        }
                        target="_blank"
                        style={{ textDecoration: "none", color: "black" }}
                      >
                        <Button
                          className="font-Avenir btn text-dark pe-0 text-end pe-0 text-end"
                          size="small"
                        >
                          Ver servicios
                          <KeyboardArrowRightIcon className="ms-1" />{" "}
                        </Button>
                      </Link>
                    </div>
                  </Paper>
                </Col>
                <Col xs={12} sm={6} md={3} className="pt-4 pt-sm-4 pt-md-4">
                  <Paper
                    className=" shadow-sm mt-2 mt-md-2 p-3 d-flex justify-content-between flex-column text-dark me-2"
                    elevation={5}
                    style={{
                      width: "100%",
                      height: "100%",
                      backgroundColor: "#fff",
                    }}
                  >
                    <div>
                      <h5 className="font-AvenirMedium  text-center">
                        Ingresos en el mes
                      </h5>
                    </div>

                    <div className="d-flex justify-content-start flex-row align-content-center algin-align-items-center">
                      <img src={TotalIngresos}></img>
                      <h3 className="text-end font-AvenirBold text-dark m-2">
                        {IsLoading ? (
                          <h1 className="parpadea">...</h1>
                        ) : (
                          graficas["totalIngresos1Mes"]
                        )}
                      </h3>
                    </div>
                    <div className="text-end">
                      <Link
                        to={
                          "/pdf/reservaciones/TODAS/" +
                          firstDayFormatted +
                          "/2023-06-30"
                        }
                        target="_blank"
                        style={{ textDecoration: "none", color: "black" }}
                      >
                        <Button
                          className="font-Avenir btn text-dark pe-0 text-end pe-0 text-end"
                          size="small"
                        >
                          Ver ingresos
                          <KeyboardArrowRightIcon className="ms-1" />{" "}
                        </Button>
                      </Link>
                    </div>
                  </Paper>
                </Col>
              </Row>
            </Col>
            <Col sm={12} md={12}>
              <Row>
                <Col
                  xs={12}
                  sm={12}
                  md={6}
                  lg={4}
                  className="pt-4 pt-sm-4 pt-md-4"
                >
                  {IsLoading ? (
                    <Box sx={{ width: "100%" }}>
                      <LinearProgress />
                    </Box>
                  ) : (
                    <ChartBar2
                      titulo="Reservaciones por semana"
                      porcentajes={JSON.parse(
                        graficas["totalReservaciones4Semana"]
                      )}
                      nombreBarras={{
                        N1: "Actual",
                        N2: "-1",
                        N3: "-2",
                        N4: "-3",
                      }}
                      nombreBarrasResponsive={{
                        NR1: "SA",
                        NR2: "SP",
                        NR3: "H2S",
                        NR4: "H3S",
                      }}
                      type={1}
                    />
                  )}
                </Col>
                <Col
                  xs={12}
                  sm={12}
                  md={6}
                  lg={4}
                  className="pt-4 pt-sm-4 pt-md-4"
                >
                  {IsLoading ? (
                    <Box sx={{ width: "100%" }}>
                      <LinearProgress />
                    </Box>
                  ) : (
                    <ChartBar2
                      titulo="Ingresos por sucursal"
                      porcentajes={JSON.parse(graficas["gananciasForSucursal"])}
                      color="#7FE5F3"
                      type={2}
                    />
                  )}
                </Col>
                <Col
                  xs={12}
                  sm={12}
                  md={6}
                  lg={4}
                  className="pt-4 pt-sm-4 pt-md-4"
                >
                  {IsLoading ? (
                    <Box sx={{ width: "100%" }}>
                      <LinearProgress />
                    </Box>
                  ) : (
                    <ChartTop
                      titulo="Top Clientes Consumo"
                      rows={top10Clientes}
                      avatarColor={"#99A2AD"}
                      namePropios
                    />
                  )}
                </Col>
                <Col
                  xs={12}
                  sm={12}
                  md={6}
                  lg={4}
                  className="pt-4 pt-sm-4 pt-md-4"
                >
                  {IsLoading ? (
                    <Box sx={{ width: "100%" }}>
                      <LinearProgress />
                    </Box>
                  ) : (
                    <ChartDonut
                      titulo="Total servicios por categoría"
                      values={serviciosTotal}
                      Labels={serviciosNombre}
                    />
                  )}
                </Col>
                <Col
                  xs={12}
                  sm={12}
                  md={6}
                  lg={4}
                  className="pt-4 pt-sm-4 pt-md-4"
                >
                  {IsLoading ? (
                    <Box sx={{ width: "100%" }}>
                      <LinearProgress />
                    </Box>
                  ) : (
                    <ChartTop
                      titulo="Top productos más vendidos"
                      rows={top10Prodcutos}
                      avatarColor={"#3465A0"}
                    />
                  )}
                </Col>
                <Col
                  xs={12}
                  sm={12}
                  md={6}
                  lg={4}
                  className="pt-4 pt-sm-4 pt-md-4"
                >
                  {IsLoading ? (
                    <Box sx={{ width: "100%" }}>
                      <LinearProgress />
                    </Box>
                  ) : (
                    <RadilBarServiceSuc
                      label="Servicios"
                      val={graficas["getServicios4Sucursal"]}
                    />
                  )}
                </Col>
              </Row>
            </Col>
          </Row>
        </>
      ) : (
        <>
          <Row style={{ height: "calc(100vh - 5rem)" }}>
            <Col sm={12} md={12}>
              <div className="d-flex justify-content-center w-100 h-100">
                <img
                  className=" d-flex align-items-center"
                  src={logo}
                  style={{ maxWidth: "150px" }}
                />
              </div>
            </Col>
          </Row>
        </>
      )}
    </>
  );
};
export default Dashboard;

/*
<div className="d-flex justify-content-between flex-wrap my-4">
        <div className="p-2">
          <h2 className="text-left font-AvenirBold">Dashboard</h2>
        </div>
        <div className="col-12">
          <div className="card shadow m-3 p-2"></div>
        </div>
        <div className="col-12 ">
          <div className="card shadow m-3 p-2">
            <div className="w-100 d-flex justify-content-around flex-wrap ">
              <div className="p-2">
                <center>
                  <PeopleAltIcon
                    fontSize="large"
                    style={{ color: "#FE8D27" }}
                  />
                  <h4 className="mt-2 fw-bold">Clientes</h4>
                  <h5>300</h5>
                </center>
              </div>
              <div className="p-2">
                <center>
                  <StoreIcon fontSize="large" style={{ color: "#0653BB" }} />
                  <h4 className="mt-2 fw-bold">Sucursales</h4>
                  <h5>10</h5>
                </center>
              </div>
              <div className="p-2">
                <center>
                  <PaidIcon fontSize="large" style={{ color: "#15AF00" }} />
                  <h4 className="mt-2 fw-bold">Ingresos mensuales</h4>
                  <h5>122k</h5>
                </center>
              </div>
              <div className="p-2">
                <center>
                  <MiscellaneousServicesIcon
                    fontSize="large"
                    style={{ color: "#CF00A7" }}
                  />
                  <h4 className="mt-2 fw-bold">Servicios</h4>
                  <div className="d-flex align-self-center">
                    <TabletMacIcon
                      //fontSize="medium"
                      //style={{ color: "" }}
                      sx={{
                        fontSize: "20px",
                      }}
                    />
                    <h5>122k</h5>
                    <LocalPhoneIcon
                      //fontSize="medium"
                      //style={{ color: "", marginLeft: "20px" }}
                      sx={{
                        marginLeft: "20px",
                        fontSize: "25px",
                      }}
                    />
                    <h5>122k</h5>
                  </div>
                </center>
              </div>
            </div>
          </div>
        </div>
        <Cards />

        <div className="col-12 col-md-6">
          <div className="card shadow m-3 p-0">
            <PastelData
              titulo="Servicios por Sucursal"
              data={[
                ["Task", "Hours per Day"],
                ["Peñitas", 20],
                ["El sauz", 12],
                ["Springfield", 8],
                ["Data", 60],
              ]}
            />
          </div>
        </div>
        <div className="col-12 col-md-6">
          <div className="card shadow m-3 p-0">
            <PastelData
              titulo="Productos mas vendidos"
              data={[
                ["Task", "Hours per Day"],
                ["Lociones", 20],
                ["Corte de Cabello", 90],
                ["Gel", 12],
                ["Afeitadoras", 8],
              ]}
            />
          </div>
        </div>
        <div className="col-12 col-md-12">
          <div className="card shadow m-3 p-4">
            <ChartBarData
              titulo="Ingresos Ultimas 7 Semanas"
              data={[
                ["Semanas atras", "Ingresos"],
                ["7 semanas", 600],
                ["6 semanas", 400],
                ["5 semanas", 800],
                ["4 semanas", 100],
                ["3 semanas", 60],
                ["2 semanas", 700],
                ["1 semana", 120],
              ]}
            />
          </div>
        </div>
        <div className="col-12 col-md-6">
          <div className="card shadow m-3 p-0">
            <BarPersonas />
          </div>
        </div>
        <div className="col-12 col-md-6">
          <div className="card shadow m-3 p-0">
            <BarPersonas />
          </div>
        </div>
        <div className="col-12 col-md-12">
          <div className="card shadow m-3 p-4">
            <ChartBarData
              titulo="Ingresos Ultimos 30 Dias"
              data={[
                ["Sucursales", "Ingresos"],
                ["'El sauz'", 600],
                ["'Peñitas'", 400],
                ["'Springfield'", 800],
                ["'Data'", 100],
              ]}
            />
          </div>
        </div>
      </div>
*/
