import { Button } from "@mui/material";
import { useEffect, useState } from "react";
import { Row } from "react-bootstrap";


import ModalTarjeta from './Dialogos/ModalTarjeta'
import DoneIcon from "@mui/icons-material/Done";
import Loading from "../../../Includes/Loading";
import { getLista } from "../Funciones/FormaPago";

const SelectTarjetas = ({ paymentMethod, tarjeta, setTarjeta }) => {


    const [IsLoading, setIsLoading] = useState(true);
    const [showModalTarjeta, setShowModalTarjeta] = useState(false);
    const [Lista, setLista] = useState([]);



    const getTarjetas = () => {
        setIsLoading(true);
        getLista("")
            .then((resp) => {
                let list = JSON.parse(resp.data);
                const tarjetaDefault = list.find(x => x.DEFAULT == 1 || x.DEFAULT == true)
                console.log("DEFAULT: ", tarjetaDefault)
                if (tarjetaDefault) {
                    setTarjeta({
                        id_tarjeta: tarjetaDefault.ID_TARJETA_CONEKTA
                        , numero_tarjeta: tarjetaDefault.TIPO + "**** **** **** " + tarjetaDefault.NUMERO
                    })
                }
                setLista(list);
                setIsLoading(false);
            })
            .catch((resp) => {
                setLista([]);
                setIsLoading(false);
            });
    };

    useEffect(() => {
        getTarjetas();
    }, []);



    return (
        <>
            {showModalTarjeta && (
                <ModalTarjeta
                    setShowModalTarjeta={setShowModalTarjeta}
                    getTarjetas={getTarjetas}
                />
            )}

            <div className="pt-2">
                {paymentMethod == "TARJETA" && (
                    <h6 className=" text-star mb-1 mt-3 fw-bolder font-PlayfairDisplay">
                        Selecciona una tarjeta
                    </h6>
                )}

                <Row>

                    {IsLoading ? (
                        <div className="col-12 my-5">
                            <Loading />
                        </div>
                    ) :
                        <>
                            <div className="col-12">
                                {Lista.map((row) => (
                                    <>
                                        <div
                                            onClick={() => {
                                                setTarjeta({
                                                    id_tarjeta: row.ID_TARJETA_CONEKTA,
                                                    numero_tarjeta: row.TIPO + "**** **** **** " + row.NUMERO,
                                                });
                                            }}
                                            className="px-3 py-2  mt-3 direccion-hover card"
                                            style={{
                                                boxShadow: '0px 0px 63px -34px rgba(0,0,0,0.75)',
                                                backgroundColor:
                                                    tarjeta.id_tarjeta == row.ID_TARJETA_CONEKTA
                                                        ? "#ebe1d6"
                                                        : "#F7F7F7",
                                                cursor: "pointer",
                                            }}
                                        >
                                            <div className="d-flex justify-content-star w-100">
                                                <div className="row">
                                                    <div className="col-12">
                                                        <h6 className="font-OxygenBold">{row.NOMBRE} </h6>
                                                    </div>

                                                    <div className="col-12  mt-2 mt-md-0">
                                                        <small>
                                                            <p className="font-OxygenBold my-1">
                                                                {row.TIPO} **** **** **** {row.NUMERO}
                                                            </p>
                                                        </small>
                                                    </div>
                                                </div>
                                                <div className="flex-grow-1 text-end align-self-center">
                                                    {tarjeta.id_tarjeta == row.ID_TARJETA_CONEKTA && (
                                                        <DoneIcon className="" />
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                ))}
                            </div>
                            <div className="col-12 mt-3 text-star">
                                <Button
                                    onClick={() => setShowModalTarjeta(true)}
                                    className="btn shadow-none btn-outline-black font-OxygenMedium  py-2 px-4 "
                                    variant="contained"
                                    style={{ max: "400px" }}
                                >
                                    <span className={"px-2 font-OxygenMedium"}>Agregar tarjeta</span>
                                </Button>
                            </div>
                        </>
                    }
                </Row>
            </div>



        </>
    );
};

export default SelectTarjetas;
