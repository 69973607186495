import {
  FormControl,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";

const MultiSelect = ({
  opciones,
  seleccionados,
  setSeleccionados,
  setBanderaSeleccion,
  titulo,
}) => {
  opciones = opciones
    ? opciones.length == 0
      ? [{ ID: 0, DESCRIPCION: "Sin opciones" }]
      : opciones
    : opciones;
  const theme = useTheme();

  const handleSelectAll = (event) => {
    if (event.target.value.includes(-1)) {
      setSeleccionados(opciones.filter((s) => s.ID !== -1).map((s) => s.ID));
      setBanderaSeleccion(true); // actualizar el estado de la bandera
    } else {
      setSeleccionados([]);
      setBanderaSeleccion(false); // actualizar el estado de la bandera
    }
  };

  return (
    <FormControl fullWidth>
      <InputLabel id="demo-multiple-sucursal-label">{titulo}</InputLabel>
      <Select
        fullWidth
        labelId="demo-multiple-sucursal-label"
        multiple
        value={seleccionados}
        onChange={(e) => {
          if (e.target.value.includes(-1)) {
            handleSelectAll(e);
          } else {
            setSeleccionados(e.target.value);
            setBanderaSeleccion(false); // actualizar el estado de la bandera
          }
        }}
        input={<OutlinedInput label={titulo} />}
      >
        {opciones.map((sucursal) => (
          <MenuItem
            key={sucursal.ID}
            value={sucursal.ID}
            style={getStyles(sucursal.DESCRIPCION, opciones, theme)}
          >
            {sucursal.DESCRIPCION} {seleccionados.includes(sucursal.ID) && ""}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

function getStyles(name, opciones, theme) {
  return {
    fontWeight:
      opciones.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

export default MultiSelect;
